import { Injectable } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
      private router: Router,
      private afAuth: Auth) 
    {}

    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return authState(this.afAuth)
          .pipe(
            map(user => !!user),
            tap(loggedIn => {
              if (!loggedIn) {
                console.log('AuthGuard: User is not logged in')
                this.router.navigate(['login'])
              }
            }))
    }
  
}
