import { LoggerInterface } from "./logger-interface";
import { OpenAIAssistant } from "./openai-assistant";
import { OpenAICompletions } from "./openai-completions";
import { OPENAI_API_TYPES, Prompt } from "./prompts-base";

export type OpenAIResult = {
    message: any,
    finish_reason: string,
    input_usage: number,
    output_usage: number,
    usage: number,
    elapsedMsec: number,
    temperature: number,
    top_p: number,
    model: string
}

export interface OpenAIInterface {
  
  logChatQuery(
    config: OpenAIConfig,
    prompt: string,
    prompts: Prompt[],
    expectsJson: boolean)
    : Promise<OpenAIResult>
}

export interface OpenAIConfig {
    isAzure: boolean
    temperature?: number
    topP? : number
    env?: any
    model: string,
    logger: LoggerInterface
}
// Factory method for using completions or assistant API
export class OpenAIFactory {
    completions: OpenAICompletions
    assistant: OpenAIAssistant
    getOpenAPI(key: string, config: OpenAIConfig): OpenAIInterface {
        switch(key) {
            case OPENAI_API_TYPES.COMPLETIONS:
                if(!this.completions) {
                    this.completions = new OpenAICompletions(config)
                    config.logger.log('OpenAIFactory created new completions API instance using', config)
                }
                return this.completions
            case OPENAI_API_TYPES.ASSISTANT: 
                if(!this.assistant) {
                    this.assistant = new OpenAIAssistant(config)
                    config.logger.log('OpenAIFactory created new assistance API instance using', config)

                }
                return this.assistant 
            default:
                throw new Error(`Unknown API type ${key} specified in OpenAIFactory - getOpenAPI`)
        }
}

}
