import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController, AlertController } from '@ionic/angular';
import { UtilityService } from './utility.service';
import { EntryService } from './entry.service';
import { SharedUserService } from './shared-user.service';
import { ContentService } from './content.service';
import { BEHAVIOR_COLLECTIBLE, BEHAVIOR_FAVORITABLE, Entry, ellipsify } from '@cheaseed/node-utils';
import { firstValueFrom } from 'rxjs';

const PitchQuickAdds = {
  type: "Pitch",
  menu: [
    { addNew: true, entrySpecId: "Pitch" },
    {
      title: "I pitched",
      dynamicMenu: {
        entrySpecId: "DeliverPitchAction",
        parentEntrySpecId: "Pitch"
      },
    },
    {
      title: "Add Practice",
      icon: 'videocam',
      dynamicMenu: {
        entrySpecId: "PracticePitchAction",
        parentEntrySpecId: "Pitch"
      },
    },
  ],
}

const AskQuickAdds = {
    type: "Ask",
    menu: [
      { addNew: true, entrySpecId: "Ask" },
      {
        title: "I asked",
        dynamicMenu: {
          entrySpecId: "DeliverAskAction",
          parentEntrySpecId: "Ask",
        },
      },
      {
        title: "Add Practice",
        icon: 'videocam',
        dynamicMenu: {
          entrySpecId: "PracticeAskAction",
          parentEntrySpecId: "Ask",
        },
      },
    ],
}

const AccomplishmentQuickAdds = {
  type: "Accomplishment",
  // menu: [
  //   { addNew: true, entrySpecId: "Accomplishment" },
    // {
    //   title: "I took the credit",
    //   dynamicMenu: {
    //     entrySpecId: "TakeCreditAction",
    //     parentEntrySpecId: "Accomplishment",
    //   },
    // },
  // ],
}

const AccoladeQuickAdds = {
  type: "Accolade",
  // menu: [
  //   { addNew: true, entrySpecId: "Accolade" },
    // {
    //   title: "I took the credit",
    //   dynamicMenu: {
    //     entrySpecId: "TakeCreditAction",
    //     parentEntrySpecId: "Accolade",
    //   },
    // },
  // ],
}

const CollectionQuickAdds = { type: "Collection" }
// const GoalQuickAdds = { type: "Goal" }
// const MotivationQuickAdds = { type: "Motivation" }
// const ObjectiveQuickAdds = { type: "Objective" }
const SatisfactionQuickAdds = { type: "TrackSatisfactionAction" }
const MarketInfoQuickAdds = { type: "MarketInfo" }

const NetworkQuickAdds = {
    type: "NetworkLead",
    title: "Networking",
    menu: [
      { addNew: true, type: "NetworkLead" },
      { title: "I networked",
        titlePreposition: " with",
        dynamicMenu: {
          entrySpecId: "NetworkAction",
          parentEntrySpecId: "NetworkLead",
        }
      }
    ]
}

// const EffortQuickAdds =   {
//   type: "Efforts",
//   title: "Efforts",
//   menu: [
//     PitchQuickAdds,
//     NetworkQuickAdds,
//     AskQuickAdds,
//     {
//       title: "I took the credit",
//       menu: [
//         {
//           title: "Accomplishment",
//           dynamicMenu: {
//             title: "I took the credit for",
//             entrySpecId: "TakeCreditAction",
//             parentEntrySpecId: "Accomplishment"
//           },
//         },
//         {
//           title: "Shoutout",
//           dynamicMenu: {
//             title: "I took the credit for",
//             entrySpecId: "TakeCreditAction",
//             parentEntrySpecId: "Accolade"
//           },
//         },
//       ],
//     },
//   ],
// }

const CreditQuickAdds = {
  type: "TakeCreditAction",
  title: "I took the credit",
  hidden: true,
  menu: [
    { addNew: true, type: "TakeCreditAction" },
    { title: "I took the credit",
      dynamicMenu: {
        entrySpecId: "TakeCreditAction",
        parentEntrySpecId: "Accomplishment",
      }
    }
  ]
}

const QuickAddActions = [
  AccomplishmentQuickAdds,
  AccoladeQuickAdds,
  SatisfactionQuickAdds,
  CollectionQuickAdds,
  MarketInfoQuickAdds,
  PitchQuickAdds,
  NetworkQuickAdds,
  AskQuickAdds,
  // GoalQuickAdds,
  // MotivationQuickAdds,
  // ObjectiveQuickAdds,
  CreditQuickAdds,
  { type: "ReviewInfo" }, // admin: true },
  { type: "ReviewCheckIn" }, //admin: true },
  { type: "ReviewCheckInQuick" }, // admin: true },
]


@Injectable({
  providedIn: 'root'
})
export class QuickAddService {

  constructor(
    private actionSheetController: ActionSheetController,
    private alertController: AlertController,
    private utilityService: UtilityService,
    private userService: SharedUserService,
    private entryService: EntryService,
    private contentService: ContentService,
    private router: Router
  ) { }


  getSingularTerm(type: string): string {
    return this.contentService.getSingularGlobal(type)
  }

  async quickAdd(action: any) {
    const type = action.type || action.entrySpecId
    if (action.menu || action.dynamicMenu)
      await this.showActionSheet(action)
    else if (type || action.route) {
      // console.log('quickAdd', action)
      this.router.navigate(action.route || [ 'sectionentryform', 
        action.parentEntryId ? { entrySpecId: type, parentEntryId: action.parentEntryId } : { entrySpecId: type } ])
    }
  }

  async showQuickAdd(type: string|null = null) {
    // console.log(type)
    if (type) {
      const submenu:any = QuickAddActions.find((item:any) => item.type === type)
      if (submenu) {
        // const dynamic = submenu.menu?.find((item:any) => item.dynamicMenu)
        // const nextMenu = skipToDynamicMenu ? dynamic || submenu : submenu
        // return await this.showActionSheet(nextMenu)
        return await this.showActionSheet(submenu)
      }
      else
        this.quickAdd( { entrySpecId: type })
    }
    else {
      const isAdmin = await firstValueFrom(this.userService.isAdminRole$)
      return await this.showActionSheet({ 
        title: "Quick Add", 
        menu: QuickAddActions.filter((a:any) => !a.hidden && (!a.admin || isAdmin))
      })
    }
  }

  findDynamicQuickAddMenu(type:string) {
    let item:any
    for (item of QuickAddActions) {
      if (item.menu) {
        const submenu = item.menu.find((opt:any) => opt.dynamicMenu?.entrySpecId === type)
        if (submenu)
          return submenu
      }
    }
    return null
  }

  async showDynamicQuickAdd(type: string) {
    const submenu = this.findDynamicQuickAddMenu(type)
    if (submenu)
      await this.showActionSheet(submenu)
    return submenu
  }

  async showActionSheet(action: any) {
    let title = '', buttons:any[] = []
    // console.log(action)
    if (action.dynamicMenu) {
      const menu = action.dynamicMenu
      title = menu.title || (action.title + (action.titlePreposition || " for"))
      buttons = this.entryService.getEntriesOfType(menu.parentEntrySpecId).map(item => {
        const alreadyInCollection = menu.childEntryId && this.entryService.isChildInCollection(item, menu.childEntryId)
        return {
          text: ellipsify(item.displayName as string, 80), // + ( alreadyInCollection ? ' ✔️' : '' )
          cssClass: alreadyInCollection ? 'dimmed-action-button' : 'regular-action-button',
          // icon: alreadyInCollection ? 'checkmark' : null,
          handler: async () => {
            if (menu.entrySpecId)
              this.quickAdd({ entrySpecId: menu.entrySpecId, parentEntryId: item.docId })
            else if (menu.childEntryId) {
              // The childEntryId is a new child of a Collection (item.docId)
              await this.updateCollection(item, menu.childEntryId)
            }
          },
        }
      })
      const term = this.getSingularTerm(menu.parentEntrySpecId)
      buttons.unshift({
        icon: 'add-circle-outline',
        text: `New ${term}`,
        handler: async () => {
          // Prompt for a name -- if cancelled or empty, do not create
          await this.promptForName(menu)
        }
      })
    }
    else if (action.menu) {
      title = action.title
      buttons = action.menu.map((item: any) => {
        const text = item.title || (item.addNew ? "New " : "") + this.getSingularTerm(item.type || item.entrySpecId)
        return {
          // cssClass: text.replace(" ", "-"),
          icon: item.addNew ? 'add-circle-outline' : item.icon,
          text: text,
          handler: () => {
            // console.log(item.title + " clicked")
            this.quickAdd(item)
          }
        }
      })
    }
    else if (action.entrySpecId || action.type) {
      this.quickAdd(action)
      return
    }
    buttons.push(this.getCancelButton())
    const ac = await this.actionSheetController.create({
      header: title || this.getSingularTerm(action.type),
      cssClass: "plus-action-sheet",
      translucent: true,
      backdropDismiss:true,
      buttons: buttons
    })
    return await ac.present()
  }

  quickAddToCollection(displayName: string|null, parentEntryId: string) {
    const title = displayName ? ` "${displayName}"` : ''
    this.quickAdd( { 
      dynamicMenu: {
        title: `Add${title} to Collection`,
        childEntryId: parentEntryId,    // Use the just created entry (parentEntryId) as the child of a Collection
        parentEntrySpecId: "Collection"
      } 
    })
  }

  async promptForActions(entrySpec: any, parentEntryId: string) {
    const buttons = []
    const isCollectible = entrySpec.behaviors?.includes(BEHAVIOR_COLLECTIBLE)
    const isFavoritable = entrySpec.behaviors?.includes(BEHAVIOR_FAVORITABLE)
    if (isCollectible) {      
      buttons.push({
        text: "Share Entry",
        handler: () => {
          this.router.navigate( [ 'share-entry-tile', parentEntryId ])
        }
      })
    }    
    entrySpec.actionLinks.forEach((act:any) => {
      // let act = this.contentService.getAction(action.name)
      const icon = act.menuIcon
      // console.log(icon)
      buttons.push({
        text: act.description,
        ...icon ? { icon: icon } : {},
        handler: () => {
          this.router.navigate([ 'sectionentryform', { entrySpecId: act.actionEntrySpec.name, parentEntryId: parentEntryId }])
        }
      })
    })
    if (isCollectible) {      
      buttons.push({
        text: "Add to Collection",
        handler: () => {
          this.quickAddToCollection(null, parentEntryId)
        }
      })
    }
    if (isFavoritable) {
      buttons.push({
        text: "Add to Favorites",
        handler: () => {
          this.entryService.addToFavorites(this.entryService.getEntryById(parentEntryId))
        }
      })
    }
    buttons.push(this.getCancelButton())
    // console.log(buttons)
    const ac = await this.actionSheetController.create({
      header: `For this ${this.getSingularTerm(entrySpec.name)}`,
      cssClass: "plus-action-sheet",
      translucent: false,
      buttons: buttons
    })
    await ac.present()
  }

  getCancelButton() {
    return { 
      text: 'Not now',
      icon: 'close',
      cssClass: 'cancel-button'
    }
  }

  private async updateCollection(collectionEntry: Entry, childDocId: string) {
    const set = new Set(collectionEntry.attributes?.entries),
          init_size = set.size
    set.add(childDocId)
    // console.log("updateCollection", { childDocId, init_size, set, collectionEntry })
    if (set.size > init_size) {
      collectionEntry.setAttributeNamed("entries", Array.from(set))
      await this.entryService.updateEntryLite(collectionEntry)
      const childName = this.entryService.getDisplayNameForId(childDocId)
      await this.utilityService.presentToast(`Added ${childName} to ${collectionEntry.displayName}`)
    }
  }

  async promptForName(menu: any) {
    const term = this.getSingularTerm(menu.parentEntrySpecId)
    const alert = await this.alertController.create({
        header: `What is the name of your new ${term}?`,
        inputs: [
          {
            name: 'newName',
            type: 'text',
            placeholder: `Enter name`
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel'
          }, 
          {
            text: 'Ok',
            handler: async data => {
              if (data.newName) {
                const parentEntry:Entry = await this.entryService.createEntryLite(menu.parentEntrySpecId, null, { name: data.newName })
                if (menu.entrySpecId)
                  this.quickAdd( { entrySpecId: menu.entrySpecId, parentEntryId: parentEntry.docId })
                else if (menu.childEntryId) {
                  // The childEntryId is a new child of a Collection (parentEntry)
                  await this.updateCollection(parentEntry, menu.childEntryId)
                }
              }
            }
          }
        ]
      });
      await alert.present();
  }
  
}
