import { marked } from 'marked'

/* 
  Based on Markedjs lexer https://marked.js.org/using_pro#lexer
*/

export class ExtractService {

  // Static variable for current parse trees, by key
  static parseTreeMap = new Map<string, any>()

  constructor() {
    marked.setOptions({
      gfm: true,
      breaks: false
    });
   }

  reset() {
    ExtractService.parseTreeMap = new Map<string, any>()
  }

  getLexer(key: string, content: string) {
    if (!ExtractService.parseTreeMap.has(key)) {
      const nodes = marked.lexer(content)
      if (key) 
        ExtractService.parseTreeMap.set(key, nodes)
      return nodes
    }
    return ExtractService.parseTreeMap.get(key)
  }

  getHeadingNodeIndexFor(nodes: any[], heading: string) {
    const index = nodes.findIndex(node => node.type === 'heading' && node.text.includes(heading))
    if (index < 0)
      throw new Error(`Heading not found: ${heading}`)
    return index
  }

  getListItemNodeIndexFor(nodes: any[], text: string) {
    const index = nodes.findIndex(node => node.type === 'list_item' && node.text.includes(text))
    if (index < 0)
      throw new Error(`list_item not found: ${text}`)
    return index
  }

  dumpNodes(content: string) {
    return marked.lexer(content)
  }

  /**
   * @param key the key for the content
   * @param content the content to extract from
   * @param heading the heading to extract from
   * @returns the text contained within the section starting with the headning
   *
   * @example
   *
   *    ```
   *     const content = getUserKey('user.finalized.cpCareerDirection')
   *     const text = extractTextFrom('user.finalized.cpCareerDirection', content, 'Approach for Discussion')
   *    ```
   */

  extractTextFrom(key: string, content: string, heading: string) {
    let text = []
    const nodes = this.getLexer(key, content)
    const index = this.getHeadingNodeIndexFor(nodes, heading)

    // Start after the heading index and go until the next heading
    for (const node of nodes.slice(index + 1)) {
      if (node.type === 'heading')
        break
      text.push(node.raw)
    }
    return text.join('\n').trim()
  }

  /**
   * @param key the key for the content
   * @param content the content to extract from
   * @param heading the heading to extract from
   * @returns a JSON list of top-level list_item strings contained within the heading
   *
   * @example
   *
   *    ```
   *     const content = getUserKey('user.finalized.cpCareerDirection')
   *     const text = extractTextFrom('user.finalized.cpCareerDirection', content, 'Approach for Discussion')
   *    ```
   */
  extractListFrom(key: string|null, content: string, heading: string) {
    let json = []
    const nodes = this.getLexer(key, content)
    const index = this.getHeadingNodeIndexFor(nodes, heading)

    // Start after the heading index and go until the next heading
    for (const node of nodes.slice(index + 1)) {
      if (node.type === 'heading')
        break
      else if (node.type === 'list') {
        // Find the first list after the heading
        // Note: if there are line breaks in the input, a new list may restart; test this case!
        for (const item of node.items) {
          if (item.type === 'list_item') {
            const indent = this.getIndent(item.raw)
            if (indent === 0) {
              let text = item.text //json.push(item.text)
              // Override with text token if present
              if (item.tokens[0].type === 'text') {
                text = item.tokens[0].text
              }
              json.push(text)
            }
          }
        }
      }
    }
    return json
  }

  getIndent(raw: string) {
    let indent = 0
    for (const char of raw) {
      if (char === ' ')
        indent++
      else
        break
    }
    return indent
  }

    /**
   * @param key the key for the content
   * @param content the content to extract from
   * @param heading the heading to extract from
   * @param itemText the text of the list_item to start at
   * @returns a JSON list of strings representing the list of child items of the node with the specified itemText, found under the specified heading
   *
   * @example
   *
   *    ```
   *     const content = getUSerKey('user.finalized.cpCareerDirection')
   *     const text = extractTextFrom('user.finalized.cpCareerDirection', content, 'Approach for Discussion')
   *    ```
   */
    extractSublistFrom(key: string, content: string, heading: string, itemText: string) {
      let json = []
      const nodes = this.getLexer(key, content)
      const headingIndex = this.getHeadingNodeIndexFor(nodes, heading)
  
      // Start after the heading index and go until the next heading
      for (const node of nodes.slice(headingIndex + 1)) {
        if (node.type === 'heading')
          break
        else if (node.type === 'list') {
          const itemIndex = this.getListItemNodeIndexFor(node.items, itemText)
          if (itemIndex >= 0) {
            // Get depth of itemText
            const itemDepth = this.getIndent(node.items[itemIndex].raw)
            // Find subsequent list_items at depth + 1
            for (const itemNode of node.items.slice(itemIndex + 1)) {
              if (itemNode.type === 'list_item') {
                const indent = this.getIndent(itemNode.raw)
                if (indent === itemDepth + 1) {
                  json.push(itemNode.text)
                }
                else {
                  // break as soon as depth changes
                  break
                }
              }
            }
            return json // exit early if itemText found
          }
        }
      }
      return json
    }
  
}
