import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Sequence } from '@cheaseed/node-utils'
import { ContentService } from './content.service'
import { SharedUserService } from './shared-user.service'

export const LEARN_STATE_KEY = "user.learnState"  // [ 'New', 'InProgress', 'Completed', 'Inactive' ]
export const LEARN_CURRENT_SERIES_KEY = "user.learnCurrentSeries"
export const LEARN_CURRENT_SERIES_NUM_KEY = "user.learnCurrentSeriesNum"
export const LEARN_CURRENT_CHAT = "user.learnCurrentChat"
export const LEARN_STATE_NEW = 'new'
export const LEARN_STATE_IN_PROGRESS = 'inprogress'
export const LEARN_STATE_COMPLETED = 'completed'
export const LEARN_STATE_INACTIVE = 'inactive'
export const LEARN_COMPLETION_CHAT_KEY = "LearnCompleted.chat"
@Injectable({
  providedIn: 'root'
})
export class LearnService {

  learnSequence: Sequence[]
  currentStateSubject = new BehaviorSubject<string>(null)
  currentState$ = this.currentStateSubject.asObservable()
  activeSubject = new BehaviorSubject<boolean>(null)
  active$ = this.activeSubject.asObservable()
  currentSeriesSubject = new BehaviorSubject<string>(null)
  currentSeries$ = this.currentSeriesSubject.asObservable()

  constructor(
    private userService: SharedUserService,
    private contentService: ContentService
  ) { }

  initialize() {
    this.contentService.loader$
      .subscribe(data => {
        // console.log("learnService received content loaded", data)
        if (data) {
          this.learnSequence = this.contentService.learnSequence          
          // console.log("learnSequence", this.learnSequence)
          this.setLearnState(this.getLearnStateDefault())
          const series = this.getCurrentSeriesName()
          if (series)
            this.setCurrentSeriesName(series)      
        }
      })
  }

  setLearnInProgress() { this.setLearnState(LEARN_STATE_IN_PROGRESS)}
  setLearnCompleted() { this.setLearnState(LEARN_STATE_COMPLETED)}
  setLearnInactive() { this.setLearnState(LEARN_STATE_INACTIVE)}
  getLearnState() { return this.userService.getUserKey(LEARN_STATE_KEY) }
  getLearnStateDefault() { return this.getLearnState() || LEARN_STATE_NEW }
  setLearnState(state) { 
    this.userService.setUserKey(LEARN_STATE_KEY, state)
    this.setSubjects(state)
  }
  setSubjects(state) {
    // console.log("setLearnStateSubject", state)
    this.currentStateSubject.next(state)
    this.activeSubject.next(state !== LEARN_STATE_INACTIVE)
  }
  setCurrentSeriesName(name) { 
    this.userService.setUserKey(LEARN_CURRENT_SERIES_KEY, name)
    const names = this.learnSequence.map((seq:any) => seq.series.name)
    const idx = names.findIndex(item => item === name)
    this.setCurrentSeriesNum(idx)
    const series = this.contentService.pathMap.get(name)
    // Assign idx to series so we can observe it
    series.learnSeriesNum = idx
    series.learnSeriesLength = this.learnSequence.length
    this.currentSeriesSubject.next(series)
    // console.log("LearnService currentSeries", series)
    return series
  }
  getCurrentSeriesName() { 
    return this.userService.getUserKey(LEARN_CURRENT_SERIES_KEY)
  }
  setCurrentSeriesNum(val) { this.userService.setUserKey(LEARN_CURRENT_SERIES_NUM_KEY, val)}
  getCurrentChat() { return this.userService.getUserKey(LEARN_CURRENT_CHAT) }
  setCurrentChat(name) { this.userService.setUserKey(LEARN_CURRENT_CHAT, name) }

  getNextChat() {
    let series:any = this.currentSeriesSubject.getValue(),
          chats = this.contentService.getNextChatsInSeries(series, this.getCurrentChat())
    console.log({series, chats})
    if (chats.length === 0) {
      const num = series.learnSeriesNum
      if (num + 1 === this.learnSequence.length) {
        this.completeLearnSequence()
        return [null, chats]
      }
      else {
        // Get next series in sequence and start it
        const seriesName = this.learnSequence[num + 1].series.name
        series = this.startSeries(seriesName)
        chats = this.contentService.getNextChatsInSeries(series)
      }
    }
    else {
      this.startChat(chats[0])
    }
    return [series.name, chats]
  }

  start() {
    const seriesName = this.learnSequence[0].series.name
    this.startSeries(seriesName)
    return this.getCurrentChat()
  }

  startSeries(seriesName) {
    const series:any = this.setCurrentSeriesName(seriesName)
    const chat = series.conversationLinks[0]
    this.setCurrentChat(chat.name)
    return series
  }

  startChat(chat) {
    this.setCurrentChat(chat.name)
    return chat
  }

  completeLearnSequence() {
    this.setLearnState(LEARN_STATE_COMPLETED)
  }

  getCompletionChat() {
    return this.contentService.getGlobal(LEARN_COMPLETION_CHAT_KEY) || "Test"
  }

  // Completion card arrival should advance series and chat when launchSource is Learn

  resume() {
    const series:any = this.currentSeriesSubject.getValue()
    const chat = this.getCurrentChat()
    console.log("resume", series.name, chat)
    return chat
  }

}