import { Injectable } from '@angular/core';
import { BEHAVIOR_DEFAULT_TO_LAST_USED_VALUE, HIDDEN, TIMESTAMP_ISO_FORMAT, YYYYMMDD_FORMAT, addstr, formatDate, nowstr, timestr } from '@cheaseed/node-utils';
import { ContentService } from './content.service';
import { EvaluatorService } from './evaluator.service';
import { SharedUserService, USER_CREATED_AT } from './shared-user.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { NOTNOW_ID, REMINDME_ID } from './entry.service';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private userService: SharedUserService,
    private contentService: ContentService,
    private evaluatorService: EvaluatorService,
    private firebase: FirebaseService,
    public formBuilder: UntypedFormBuilder
  ) { }

  populateDefault(attr:any, patch:any) {
    if (attr.behaviors?.includes("defaultToNextSequence")) {
      const key = attr.entrySpec.name
      const seq = this. userService.incrementSequence(key)
      const root = this.contentService.getSingularGlobal(key)
      attr.value = root  + " " + seq
      attr.changed = true
      patch[attr.attributeName] = attr.value
    }
    else if (attr.behaviors?.includes("defaultToTodaysDate")) {
      console.log("Found defaultToTodaysDate")
      attr.value = new Date()
      attr.changed = true
      patch[attr.attributeName] = attr.value
      // console.log(attr.value)
    }
    else if (attr.behaviors?.includes("defaultToSixMonthsFromNow")) {
      console.log("Found defaultToSixMonthsFromNow")
      attr.value = addstr( { months: 6 } )
      attr.changed = true
      patch[attr.attributeName] = attr.value
    }
    else if (attr.behaviors?.includes("defaultToTwelveWeeksFromNow")) {
      console.log("Found defaultToTwelveWeeksFromNow")
      attr.value = addstr( { weeks: 12 } )
      attr.changed = true
      patch[attr.attributeName] = attr.value
    }
    else if (attr.behaviors?.includes("defaultToUserCreationDate")) {
      console.log("Found defaultToUserCreationDate behavior")
      attr.value = this.userService.getUserKey(USER_CREATED_AT)
      attr.changed = true
      patch[attr.attributeName] = attr.value
    }  
    else if (attr.inputType === 'COMBOBOX' && attr.behaviors?.includes(BEHAVIOR_DEFAULT_TO_LAST_USED_VALUE)) {
      const val = this.userService.getUserKeyLastUsed(attr.attributeName)
      if (val) {
        console.log(`Found ${BEHAVIOR_DEFAULT_TO_LAST_USED_VALUE}`, val)
        attr.value = val
        attr.changed = true
        patch[attr.attributeName] = attr.value
      }
    }
    else if (['OPTIONS', 'SEGMENTED', 'SCALE'].includes(attr.inputType)) {
      const defaultOption = attr.optionLinks.find(o => o.behaviors?.includes('isDefault'))
      if (defaultOption) {
        console.log("Found default Option", defaultOption)
        attr.value = defaultOption.name
        attr.changed = true
        patch[attr.attributeName] = attr.value
      }
    }  
  }

  getAttributeSpecsMap(attributeSpecs: any[]) {
    return new Map<string, any>(attributeSpecs.filter(a => !a.isFeedback).map(a => [a.attributeName, a]))
  }

  computeBlocks(specs:Map<string, any>) {
    const vals = Array.from(specs.values())
    const names = new Set(vals.map(a => a.inputBlockName))
    const blocks = new Map<string, any>(Array.from(names).map(n => [ n, [] ]))
    vals.forEach(v => {
      const b = blocks.get(v.inputBlockName)
      if (b)
        b.push(v)
    })
    return Array.from(blocks.keys())
      .map(b => ({ 
        name: b, 
        attributes: blocks.get(b), 
        state: false, 
        hidden: false }))
  }

  fillAttributes(specs:Map<string, any>, attrs:Map<string, any>) {
    specs.forEach((attributeSpec, k) => {
      const key = attributeSpec.attributeName,
          attr = attrs.get(key)
      // OptionMap
      if(attributeSpec.optionLinks.length > 0) {
        attr.optionMap = {}
        attr.valueMap = {}
        attributeSpec.optionLinks.forEach((opt:any) => {
          attr.optionMap[opt.name] = opt.description ?? 'MISSING'
        })
      }
      // Display value
      if (!attr.shadow && attr.value) {
        const value = attr.value
        if (['OPTIONS', 'SCALE'].includes(attributeSpec.inputType)) {
          attributeSpec.optionLinks.forEach((opt:any) => {
            if (opt.name === value) {
              attr.displayValue = opt.description
              attr.value = value
            }
          })
        }
        else if (attributeSpec.inputType==='TEXTAREA') {
          attr.value = value
          attr.displayValue = value.replace(/\n/g,'<br>')
        }
        else {
          attr.value = value
        }
      }
    })
  }

  evaluateForCapture(
    specs:Map<string, any>, 
    attrs:Map<string, any>, 
    valueMap:any,
    blocks:any[])
  {
    // create context for evaluator
    // keys in context must be simple attribute names (not Entity.AttributeName)
    const context:any = {}
    specs.forEach(a => {
      context[a.attributeName] = valueMap 
        ? valueMap[a.attributeName] 
        : attrs.get(a.attributeName).value
    })
    const hidden:any = {}
    specs.forEach(a => {
      const attr = attrs.get(a.attributeName)
      if (a.behaviors?.includes(HIDDEN)) // Always hide because spec says so
        attr.hidden = true
      else if (a.captureIf) // Check whether to capture
        attr.hidden = !this.evaluatorService.evaluate(a.captureIf, context)
      hidden[a.attributeName] = attr.hidden
    })
    for (const b of blocks) {
      b.hidden = b.attributes.filter(a => !hidden[a.attributeName]).length === 0
      // logger.log("block", b)
    }
  }

  hasValue(attr: any, values: any[]): boolean {
    const type = attr.inputType
    const val = values[attr.attributeName]
    if (type === "ENTRYSELECTOR") {
      return val.length > 0
    }
    else if (type === "MULTIOPTIONS") {
      return val !== 'null'
    }
    else {
      return !!val
    }
  }

  hasAllRequiredFields(entrySpec: any, values: any[]): boolean {
    return entrySpec.attributeSpecs
      .filter((a:any) => a.isRequired)
      .reduce((valid:boolean, attr:any) => valid && this.hasValue(attr, values), true)
  }

  private evaljson(val: string) {
    try {
      return (typeof val === 'string' ? JSON.parse(val) : val)
    }
    catch (err) {
      console.error(err)
      return null
    }
  }

  assignComboBoxChoiceList(attr: any, val: any) {
    const keys = this.userService.getUserKeyCombo(attr.attributeName)
    const list = attr.optionLinks.map((opt:any) => opt.description)
    attr.choicelist = list.concat(keys || [])
    // Make sure val is always in list
    if (val && val !== 'Other' && !attr.choicelist.includes(val))
      attr.choicelist.push(val)
    // console.log("assignComboBoxChoiceList", attr, val, attr.choicelist)  
  }

  assignFormControls(specs:Map<string, any>, attrs:Map<string, any>, readonly: boolean) {
    // Assign FormGroup controls
    const controls:any = {}
    specs.forEach(a => {
      let val = attrs.get(a.attributeName).value
      val = a.inputType === "MULTIOPTIONS" && val ? this.evaljson(val) : val
      if (a.inputType === "COMBOBOX") {
        this.assignComboBoxChoiceList(a, val)
      }
      else if (a.inputType === 'ENTRYSELECTOR') {
        val = (typeof val === 'string' ? JSON.parse(val) : val) as string[] || []
      }
      else if (a.inputType === 'HOUROFDAY') {
        const time = val ? timestr(val / 100, 0) : nowstr()
        val = time
      }
      const fbv = readonly || a.behaviors?.includes("readonly") ? {value: val, disabled: true } : val
      controls[a.attributeName] = a.isRequired ? [fbv, Validators.required] : [fbv] // set initial default values here
    })
    return controls
  }  

  populateEntryForm(controls:any[], specs:Map<string, any>, attrs:Map<string, any>) {
    const form = this.formBuilder.group(controls)
    const patch = {}
    specs.forEach(a => {
      const obj = Object.assign({}, a)
      const attr = attrs.get(a.attributeName)
      
      if (!attr.value) {
        this.populateDefault(obj, patch)
        attr.value = obj.value
      }
    })

    // Apply patch
    if (Object.keys(patch).length > 0) {
      form.patchValue(patch)
      // console.log("Form Group Patched:", patch)
    }
    return form
  }

  shouldSave(form: UntypedFormGroup, specs:Map<string, any>, defaults: any[]) {
    let mustSave = false,
        includeExtras = false,
        remindMe = false,
        notNow = false
    const saveAttribs:Record<string, any> = {},
          syncAttribs:Record<string, any> = {}
    for (const key of Object.keys(form.value)) {
      const control = form.controls[key]
      // console.log("shouldSave", key, mustSave, control)
      if (control) {
        const attr: any = specs.get(key)
        let val:string|null = control.value
        if (typeof val === 'string' && val.length === 0) {
          console.log(`Resetting val for ${key} to null`)
          val = null
        }
        if (attr.isRequired && !val) {
          return { save: false, missingRequired: true }
        }
        mustSave = mustSave || (control.dirty || !!defaults)
        if (attr.inputType === "MULTIOPTIONS")
          val = JSON.stringify(control.value)
        if (attr.inputType === "DATE" && control.value)
          val = formatDate(control.value)
        if (attr.inputType === "ENTRYSELECTOR" && control.value) {
          includeExtras = true
          remindMe = remindMe || !!control.value.find((v:string) => v === REMINDME_ID)
          notNow = notNow || !!control.value.find((v:string) => v === NOTNOW_ID)
        }
        // Check for attributeSpec behaviors for syncUserProperty
        if (attr.behaviors?.includes("syncUserProperty")) {
          const propName = key.replace('.', '_')
          syncAttribs[propName] = val
        }
        saveAttribs[key] = val
      }
    }
    if (includeExtras) {
      saveAttribs[NOTNOW_ID] = notNow
      saveAttribs[REMINDME_ID] = remindMe
    }
    return { save: mustSave, attributes: saveAttribs, syncAttributes: syncAttribs }
  }

  async prepareVideoCaptureAttributes(specs: any[], attrMap: any, oldAttrs: any) {
    // Determine whether videos need upload
    const videoAttrs = specs.filter(a => a.inputType === 'VIDEOCAPTURE' && attrMap[a.attributeName]?.includes("capacitor"))
    if (videoAttrs.length > 0) {
      // console.log("oldAttrs", JSON.stringify(oldAttrs))
      for (const a of videoAttrs) {
        const localUrl = attrMap[a.attributeName]
        const filename = await this.firebase.uploadFile(localUrl)
        // console.log("filename", filename)
        // Rewrite attribute value with cloud-based filename
        attrMap[a.attributeName] = filename
        // If prior value exists, delete from cloud storage
        const oldfile = oldAttrs[a.attributeName]
        if (oldfile && oldfile !== filename) {
          console.log("Removing old video file", oldfile)
          await this.firebase.deleteVideoUrl(oldfile)
        }
      }
      // console.log("attrMap", JSON.stringify(attrMap))
      return attrMap
    }
    return null
  }

}
