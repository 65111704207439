import { ErrorHandler, Inject, Injectable } from '@angular/core'
import * as StackTrace from 'stacktrace-js'
import { Platform } from '@ionic/angular'
import { Device, DeviceInfo } from '@capacitor/device'
import { SlackService } from './slack.service'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(
        @Inject('environment') private environment: any,
        @Inject('UserService') private userService: any,
        private platform: Platform,
        private slackService: SlackService
    ) { }

    async handleError(error: Error) {
        // default behavior
        console.error(error.message ? error.message : 'Error', error)
    
        // send all errors to slack including ones in the web app (except if developing locally)
        if (!window.location.href.includes('localhost') || this.platform.is('capacitor')) {
            let stackFrames = await StackTrace.fromError(error)
            // sometimes, the error stack has already been popped and fromError
            // returns an empty array. In that case we rely on get to create
            // the stack from this point in the cide
            if(stackFrames.length === 0) 
                stackFrames = await StackTrace.get()
            //console.log('Stack Frame', stackFrame)
            const deviceInfo: DeviceInfo = await Device.getInfo()
            const obj:any = {
                os: deviceInfo.operatingSystem,
                osVersion: deviceInfo.osVersion,
                platform: deviceInfo.platform,
                manufacturer: deviceInfo.manufacturer,
                model: deviceInfo.model,
                name: deviceInfo.name,
                userAgent: navigator.userAgent,
                build: this.environment.BuildTag,
                release: this.environment.ReleaseTag,
                patchLevel: this.environment.PatchLevel
            }
            // Get userId from injected UserService, if any
            const userId = this.userService?.getCurrentUserId() as string
            this.slackService.postErrorOnSlack(userId, JSON.stringify(obj), error, stackFrames)
        }
    }
}