/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core'
import { YYYYMMDD_FORMAT } from '@cheaseed/node-utils'
import { add, differenceInDays, differenceInMinutes, differenceInMonths, differenceInWeeks, format, formatISO, sub } from 'date-fns'

/*
  Consolidate use of date-fns here, replacing moment
  Limit use elsewhere to services
  All pages and components should use this service

  Moment is EOL: See https://momentjs.com/docs/
*/

@Injectable({
  providedIn: 'root'
})
export class DateService {

  guessTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  yyyymmddToLocalDate(isoString: string): Date {
    const [year, month, day] = isoString.split('-')
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
  }

  toDate(val: any): Date {
    return new Date(val)
  }
  
  todaystr(fmt:string|undefined = undefined): string {
    return this.format(new Date(), fmt || YYYYMMDD_FORMAT)
  }

  nowstr(fmt:string|undefined = undefined): string {
    return this.format(new Date(), fmt)
  }

  timestr(hr: number, min: number): string {
    const d = new Date()
    d.setHours(hr)
    d.setMinutes(min)
    return this.format(d)
  }

  // Handle the case where an YYYY-MM-DD string must be converted to
  // a Date object in the local timezone, and then formatted
  formatISODate(isoString: Date|string, format: string|undefined = undefined): string {    
      return (typeof isoString === 'string')
        ? this.format(this.yyyymmddToLocalDate(isoString), format)
        : this.format(isoString, format)
  }

  format(val: any, fmt:string|undefined = undefined): string {
    const type = typeof val
    if (type === 'string' && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(val)) {
      console.log('format encountered YYYY-MM-DD string:', val)
      // If this prints to console, the caller should use formatISODate()
    }
    const d = new Date(val)
    if (fmt)
      fmt = fmt.replace('YYYY', 'yyyy').replace('DD', 'dd')
    return fmt 
      ? format(d, fmt) 
      : formatISO(d)
  }

  addstr(duration: any): string {
    return this.format(this.add(duration))
  }

  add( duration: any ): Date {
    return add(new Date(), duration)
  }

  addTo(val: any, n: any, unit: string): Date {
    if (!unit.endsWith('s'))
      unit += 's'
    return add( new Date(val), { [unit]: n } )  
  }

  subtractstr(duration: any): string {
    return this.format(this.subtract(duration))
  }

  subtract(duration: any): Date {
    return sub(new Date(), duration)
  }

  subtractFrom(val: any, duration: any): Date {
    return sub(new Date(val), duration)
  }

  since(d: string, unit: any) {
    return this.diff(d, null, unit)
  }

  diff(start: string|undefined, end: string|null, unit: any) {
    const endDate = (!end || end === 'null') ? new Date() : new Date(end)
    const startDate = (!start || start === 'null') ? new Date() : new Date(start)
    if (unit === 'day')
      return differenceInDays(endDate, startDate)
    else if (unit === 'week')
      return differenceInWeeks(endDate, startDate)
    else if (unit === 'month')
      return differenceInMonths(endDate, startDate)
    else if (unit === 'minutes')
      return differenceInMinutes(endDate, startDate)
    else
      return 0
  }

  diffpercent(startdate: string, enddate: string) {
    const total = this.diff(startdate, enddate, 'day')
    const current = this.since(startdate, 'day')
    return current / total
  }

  isFuture(d: string): boolean {
    return new Date(d) > new Date()
  }

  isWithinHours(eventDate: string, withinHours: number) {
    const now = new Date(),
          mins = withinHours * 60,  // handle withinHours = 0.5, 0.1, 0.05, etc...
          within = this.addTo(new Date(eventDate),
            mins > 60 ? withinHours : mins,
            mins > 60 ? 'hours': 'minutes')
    console.log("comparing", now, within)            
    return (now < within)
  }

}
