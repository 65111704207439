import { Inject, Injectable, effect, signal } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ConsoleService {

    consoleActive = signal(true)

    constructor(
        @Inject('environment') public environment: any
    ) { 
        if (this.environment.production)
            this.consoleActive.set(false)

        effect(() => {
            const active = this.consoleActive()
            if (!active) {
                console.log('Console is now inactive')
                window.console.log = () => { null }
            }
            else {
                window.console.log = console.info
                console.log('Console is now active')
            }
        })  
    }

    toggleConsole() {
        this.consoleActive.update(val => !val)      
    }
}