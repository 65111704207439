import { getTermsForType } from '@cheaseed/node-utils'
import { ContentService } from './content.service'

export class AbstractPage {

  constructor(
    protected contentService: ContentService
  ) {}

  getPlural(type:string) {
    return this.contentService.getPluralGlobal(type) 
  }

  getSingular(type:string) { 
    return this.contentService.getSingularGlobal(type) 
  }

  getGlobal(key:string) {
    return this.contentService.getGlobal(key) || `undefined ${key}`
  }

  getTerm(type:string) {
    return getTermsForType(type)
  }

}