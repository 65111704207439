/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */

import airtable  from "airtable"

export class AirtableBase {
  base: any;

  constructor() {}

  initialize() {
    if (!this.base)
      this.base = new airtable({ apiKey: "key4U3DKTvEpV78r7" }).base("appjIoM6vPuSdZz7c")
  }

  async selectStatement(name:string) {
    this.initialize()
    return this.base("Statement")
      .select({ filterByFormula: `Name = "${name}"`, fields: [ "Name", "TextBlock", "EnableIf", "OnCompletion", "Behaviors", "AttributeSpec", "PromptSpecs" ] })
      .all()
      .then((records: { fields: any; }[]) => {
        const result = records[0].fields
        console.log("airtable statement", result)
        return result
      })
      .catch((err: any) => {
        console.error(err)
        return err
      })
  }

  async selectChat(name:string) {
    this.initialize()
    return this.base("Conversation")
      .select({ filterByFormula: `Name = "${name}"`, fields: [ "Name", "Title", "Subtitle", "Statements", "Behaviors" ] })
      .all()
      .then((records: { fields: any; }[]) => {
        const result = records[0].fields
        console.log("airtable conversation", result)
        return result
      })
      .catch((err: any) => {
        console.error(err)
        return err
      })
  }

  async find(type:string, id:string) {
    return this.base(type)
      .find(id)
      .then((record: { fields: any; }) => {
        const result = record.fields
        console.log(`airtable ${id} ${type}`, result)
        return result
      })
      .catch((err: any) => {
        console.error(err)
        return err
      })
  }

}
