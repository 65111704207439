import { Inject, Injectable, inject } from '@angular/core';
import { orderBy, where } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Consumable, YYYYMMDD_FORMAT, formatDate, isWithinHours, todaystr } from '@cheaseed/node-utils';
import { combineLatestWith, map, tap, firstValueFrom, filter, switchMap, shareReplay, from, scan, mergeMap, auditTime, BehaviorSubject } from 'rxjs';
import { FirebaseService } from './firebase.service';
import { PointsService } from './points.service';
import { Actions, Events, SharedEventService } from './shared-event.service';
import { SharedUserService, USER_CREATED_AT } from './shared-user.service';

export const OFFERSTAT_PATH = 'offerstats'
export const GROUPSTAT_PATH = 'groupstats'

export interface OfferSpec {
  name: string;
  code?: string;
  group?: string;
  offerGroup?: { name: string };
  autoJoinGroup?: boolean;
  maxUses?: number;
  points: number;
  startDate?: string;
  endDate?: string;
  eventType?: string
  eventParameter?: string;
  withinHours?: number;
  triggerEvent?: string;
  triggerConversation?: { name: string };
  triggerParameter?: string;
  message?: string;
  willRedeemMessage?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  shareMessage?: string;
  disabled?: boolean;
  devOnly?: boolean;
  maxUsesPerUser?: number;
}

export interface GroupSpec {
  name: string;
  persona?: { name: string };
  points?: number;
  welcomeMessage?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
}

export interface OfferStat {
  name: string;
  code?: string;
  group?: string;
  createdAt: Date;
  updatedAt: Date;
  maxUses?: number;
  numUses: number;
  link: string;
  inviteLink: string;
  persona: string;
}

export interface GroupStat {
  name: string;
  updatedAt: Date;
  inviteLink: string;
  numUsers: number;
}

export interface UserOffer {
  docId: string;
  userId?: string;
  name: string;
  createdAt: Date;
  appliedAt: Date;
  points: number;
  code: string;
  group: string;
}

export type Offer = OfferSpec & OfferStat;

@Injectable({
  providedIn: 'root'  
})
export class OffersService {

  private firebaseService = inject(FirebaseService)
  private userService = inject(SharedUserService)
  // Can't figure this out:
  // private environment:any = inject(new InjectionToken<any>('environment', { factory: () => environment }))
  // So we use this instead with a constructor injection
  initialized$ = new BehaviorSubject(false)

  offerstats$ = this.firebaseService
    .collection$(OFFERSTAT_PATH, orderBy('updatedAt', 'desc'))
    .pipe(
      map(res => res as OfferStat[]),
      tap(res => console.log(`retrieved ${res.length} offer stats`)),
      shareReplay(1))

  offerspecs$ = this.initialized$
    .pipe(
      filter(init => !!init),
      switchMap(() => this.firebaseService.collection$(`content/config/${this.environment.releaseTag}/web/offerSpecs`, orderBy('name', 'asc'))),
      map(res => res as OfferSpec[]),
      // Sort by code (asc), group (asc), endDate (asc) -- nulls last
      map(res => res.sort((a, b) => {
        if (!a.code || a.code > (b.code as string)) return 1;
        if (!b.code || a.code < b.code) return -1;
        if (!a.offerGroup || a.offerGroup.name > (b.offerGroup?.name as string)) return 1;
        if (!b.offerGroup || a.offerGroup.name < b.offerGroup.name) return -1;
        if (!a.endDate || a.endDate > (b.endDate as string)) return 1;
        if (!b.endDate || a.endDate < b.endDate) return -1;
        return 0;
      })),      
      tap(res => console.log(`retrieved ${res.length} offer specs`)),
      shareReplay(1))

  useroffers$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      switchMap(user => this.firebaseService.collection$(`users/${user?.docId}/offers`, orderBy('createdAt', 'desc'))),
      map(res => res as UserOffer[]),
      map(offers => {
        const offerMap:Map<string, UserOffer[]> = new Map(offers.map(offer => [ offer.name, [] ]))
        offers.forEach(offer => {
          const existing = offerMap.get(offer.name) as UserOffer[]
          offerMap.set(offer.name, [ ...existing, offer ])
        })
        return offerMap
      })
    )

  groupspecs$ = this.initialized$
    .pipe(
      filter(init => !!init),
      switchMap(() => this.firebaseService.collection$(`content/config/${this.environment.releaseTag}/web/offerGroups`, orderBy('name', 'asc'))),
      map(res => res as GroupSpec[]),
      shareReplay(1))
      
  groupstats$ = this.firebaseService
      .collection$(`groupstats`, orderBy('updatedAt', 'desc'))
      .pipe(
        map(res => res as GroupStat[]),
        tap(res => console.log(`retrieved ${res.length} group stats`)),
        shareReplay(1))
  
  offergroups$ = this.groupspecs$
      .pipe(
        combineLatestWith(this.groupstats$),
        map(([specs, stats]) => {
          const map:Map<string, GroupStat> = new Map(stats.map(stat => [stat.name, stat]))
          return specs.map(spec => (
            { 
              ...map.get(spec.name),
              ...spec
            })) as GroupSpec[]
        }))

  offers$ = this.offerspecs$
      .pipe(
        combineLatestWith(this.offerstats$, this.groupspecs$),
        map(([specs, stats, groups ]) => {
          const personaMap: Map<string, string|undefined> = new Map(groups.map(g => [g.name, g.persona?.name]))
          const statMap: Map<string, OfferStat> = new Map(stats.map(stat => [stat.name, stat]))
          return specs.map(offer => (
            { 
              ...statMap.get(offer.name),
              ...offer,
              persona: personaMap.get(offer.offerGroup?.name as string)
            })) as Offer[]
        }))

  constructor(
    @Inject('environment') private environment: any,
    @Inject('UtilityService') private utilityService: any,
    private pointsService: PointsService,
    private eventService: SharedEventService,
    private router: Router
  ) {
    // this.initialized$.next(true)  // this is done in the app.component for app only
  } 

  getOfferSpecsPath() {
    return `content/config/${this.environment.releaseTag}/web/offerSpecs`
  }

  getOfferGroupsPath() {
    return `content/config/${this.environment.releaseTag}/web/offerGroups`
  }

  getGroupSpecNamed(groupName: string) {
    return this.groupspecs$
      .pipe(
        map(groups => groups.find(g => g.name === groupName)))
  }

  getOfferNamed(name: string) {
    return this.firebaseService.doc$(`content/config/${this.environment.releaseTag}/web/offerSpecs/${name}`)
      .pipe(
        map(res => res as OfferSpec),
        shareReplay(1))
  }

  getUsagesOfOfferNamed(name: string) {
    return this.firebaseService.collectionGroup$('offers', 
      where('name', '==', name),
      orderBy('createdAt', 'desc'))
      .pipe(        
        map(res => res.filter((offer:any) => offer.docId !== name)),
        // See https://stackoverflow.com/questions/67514628/angular-firestore-subscribe-fires-twice/67514861#67514861
        auditTime(100), // throttles the firebase query so that the online firestore read is emitted, not the cache read
        // tap(res => console.log(`Found1 ${res.length} usages of offer ${name}`, res)),
        shareReplay(1)
      )
  }

  getUsagesAndPurchasesOfOfferNamed(name: string) {
    return this.getUsagesOfOfferNamed(name)
      .pipe(
        // turn list into stream of usages 
        // tap((usages:any[]) => console.log(`Found2 ${usages.length} usages of offer ${name}`, usages)),
        switchMap(usages => from(usages)),
        mergeMap((usage:any) => this.firebaseService.collection$(`users/${usage.userId}/consumables`, where('purchased', '==', true))
          .pipe(
            auditTime(100),
            // tap(purchases => console.log(`Found ${purchases.length} purchases for user ${usage.userId} since ${usage.createdAt.toDate()}`, purchases)),
            map((purchases:Consumable[]) => {
              console.log(`Found ${purchases.length} purchases for user ${usage.userId}`, purchases)
              const total = purchases.reduce((sum:number, purchase) => sum + purchase.quantity, 0)
              const revenue = purchases.reduce((sum:number, purchase) => sum + (purchase.purchaseSource?.price || 0), 0.00)
              return { ...usage, purchases, numPurchases: purchases.length, totalSeeds: total, revenue }
            })
          )),
        scan<any, any[]>((all, usage) => [...all, usage], []))
  }

  getPurchasesForUser(userid: string) {
    return this.firebaseService.collection$(
      `users/${userid}/consumables`, 
      where('purchased', '==', true),
      orderBy('createDate', 'desc'))
  }

  async processOffer(event: Events, eventParameter?: string) {
    // Find Instant offer for event (check not already applied) or Pending Requested offer entered by user
    const useroffers = await firstValueFrom(this.useroffers$)
    const offers = await firstValueFrom(this.offers$)
    const offer = this.findOfferFor(event, eventParameter, offers, useroffers)
    // Check trigger condition 
    if (offer && (!offer.triggerEvent || await this.shouldApplyOffer(offer))) {
      // Add bonus points
      this.pointsService.triggerPoints(event, 'Bonus', offer.points, { id: offer.name })
      // Record offer on user (mark completed if pending on requested)
      // Increment numUses on offerstats
      await this.recordUserOffer(offer, true, useroffers)
      if (offer.message)
        await this.utilityService.notify({ header: offer.message } )
    }
  }

  private findOfferFor(eventType:string, eventParameter:string|null = null, offers: Offer[], useroffers: Map<string, UserOffer[]>) {
    const result = offers.find((o:Offer) => 
      this.isOfferValid(o)
      && (!o.persona || this.userService.userHasPersona(o.persona))
      && (!o.eventType || (o.eventType === eventType))
      && (!o.triggerEvent || o.triggerEvent !== Events.UserCreation || this.shouldApplyWithinHoursOfUserCreation(o))
      && (!o.eventParameter || (o.eventParameter == eventParameter))
      && (!o.maxUses || o.maxUses > (o.numUses || 0))
      && (!o.code || useroffers.has(o.name)) // if the offer has a code and the user activated it
      && (!this.isOfferAlreadyApplied(o, useroffers)))
    // console.log("findOfferFor found result", result)
    return result
  }

  isOfferValid(o: Offer) {
    const today = todaystr() // returns yyyy-mm-dd
    // console.log("isOfferValid", o)
    return (o.disabled !== true)
      && (!o.endDate || formatDate(new Date(o.endDate as string), YYYYMMDD_FORMAT) >= today)
      && (!o.startDate || formatDate(new Date(o.startDate as string), YYYYMMDD_FORMAT) <= today)      
  }

  isOfferAlreadyApplied(offer: Offer, useroffers: Map<string, UserOffer[]>) {
    // user offers may be present but not applied (if a code has been activated)
    // if a user offer has an appliedAt, it has been applied and counts as a use
    // console.log("isOfferAlreadyApplied", offer, useroffers)
    if (useroffers.has(offer.name)) {
      const offers = useroffers.get(offer.name) as UserOffer[]
      // count the number of offers that have been applied 
      // and return true if the number of applied offers is equal to the max uses
      return offers.filter(o => !!o.appliedAt).length === (offer.maxUsesPerUser || 1)
    }
    else
      return false
  }

  shouldApplyWithinHoursOfUserCreation(offer: Offer) {
    if (offer.triggerEvent === Events.UserCreation) {
      const createdAt = this.userService.getUserKey(USER_CREATED_AT)
      if (offer.withinHours)
        return isWithinHours(createdAt, offer.withinHours)
    }
    return false
  }

  async shouldApplyOffer(offer: Offer) {
    console.log("evaluating offer condition", offer)
    const result = this.shouldApplyWithinHoursOfUserCreation(offer)
    if (result)
      return result
    else if (offer.triggerEvent === Events.HomeLevelCompleted) {
      const events = await firstValueFrom(this.userService.getUserEventWhere(Actions.LevelCompleted, 'level', parseInt(offer.triggerParameter as string)))
      const trigger = events[0]
      if (trigger && offer.withinHours)
        return isWithinHours(trigger.createdAt, offer.withinHours)
    }
    else if (offer.triggerEvent === Events.ConversationEnd) {
      const events = await firstValueFrom(this.userService.getUserEventWhere(Actions.Ended, 'eventConversationId', offer.triggerConversation?.name))
      const trigger = events[0]
      if (trigger && offer.withinHours)
        return isWithinHours(trigger.createdAt, offer.withinHours)
    }
    return false
  }

  getOfferStatPath(name: string) {
    return `${OFFERSTAT_PATH}/${name}`
  }

  async recordUserOffer(offer: Offer, applied: boolean, useroffers: Map<string, UserOffer[]>) {
    // Check for existing unapplied user offer
    const unapplied = useroffers.get(offer.name)?.find((o:UserOffer) => !o.appliedAt)

    // Record offer on user
    const now = new Date()
    if (unapplied)
      // Update existing unapplied user offer
      this.firebaseService.updateAt(this.userService.getUserOfferPath(unapplied.docId), { appliedAt: now })
    else
      // Add a new offer
      this.firebaseService.updateAt(this.userService.getUserOffersPath(), 
        {
          name: offer.name,
          userId: this.userService.getCurrentUserId(),
          code: offer.code,
          offerGroup: offer.offerGroup?.name,
          points: offer.points,
          createdAt: now,
          appliedAt: applied ? now : undefined
        })

    // Increment numUses on offerstats
    if (applied) {
      const stats = await firstValueFrom(this.offerstats$)
      const stat = stats.find(stat => stat.name === offer.name)
      if (stat) {
        this.firebaseService.updateAt(this.getOfferStatPath(offer.name), {
          updatedAt: now,
          numUses: this.firebaseService.increment(1)
        })
      }
      else
        this.updateOfferStat(offer, { numUses: 1 })
      // Notify user of offer result
      // if (offer.message)
      //   await this.utilityService.notify({ header: offer.message } )
    }
    
  }

  updateOfferStat(offer: Offer, extras: any) {
    const now = new Date()
    this.firebaseService.updateAt(this.getOfferStatPath(offer.name), {
      name: offer.name,
      code: offer.code,
      maxUses: offer.maxUses,
      numUses: 0,
      ...extras,
      createdAt: now,
      updatedAt: now
    })
  }

  updateGroupStat(spec: GroupSpec, extras: any) {
    const now = new Date()
    this.firebaseService.updateAt(`${GROUPSTAT_PATH}/${spec.name}`, {
      name: spec.name,
      updatedAt: now,
      ...extras
    })
  }

  shouldAddRequestedOffer(o: Offer, useroffers: Map<string, UserOffer[]>) {
    const today = todaystr() // returns yyyy-mm-dd
    const flags = {
      alreadyApplied: useroffers.has(o.name),
      disabled: !!o.disabled,
      expired: !!o.endDate && formatDate(new Date(o.endDate), YYYYMMDD_FORMAT) < today,
      notStarted: !!o.startDate && formatDate(new Date(o.startDate), YYYYMMDD_FORMAT) > today,
      maxUsesReached: !!o.maxUses && o.maxUses <= o.numUses,
      notInGroup: !!o.persona && !this.userService.userHasPersona(o.persona),
      notWithinHours: !!o.withinHours && o.triggerEvent === Events.UserCreation && !this.shouldApplyWithinHoursOfUserCreation(o)
    }
    const status = {
      ...flags,
      shouldAdd: !flags.alreadyApplied 
        && !flags.disabled
        && !flags.expired
        && !flags.notStarted
        && !flags.maxUsesReached
        && !flags.notInGroup
        && !flags.notWithinHours
    }
    console.log('shouldAddRequestedOffer', status)
    return status    
  }

  generateOfferMessage(offer: Offer, status: any) {
    const msg = status.alreadyApplied ? 'has already been applied' 
      : status.expired ? 'has expired'
        : status.notWithinHours ? 'is no longer available'
          : status.disabled ? 'is not active'
            : status.notStarted ? 'has not started'
              : status.maxUsesReached ? 'has reached its limit'
                : status.notInGroup ? `is not available. You must be a member of ${offer.offerGroup?.name }`
                  : !status.shouldAdd ? 'cannot be applied'
                    : offer.willRedeemMessage ?? 'will be applied'
    return `Offer ${offer.code} ${msg}.`
  }

  // Route to redeem-offer page
  async launchRedeemOfferCodeDialog() {
    await this.utilityService.prompt({
      header: 'Redeem Bonus Code',
      message: 'Enter a bonus code',
      confirm: async (data:any) => {
        if (data.value)
          this.router.navigate(['/redeem-offer', data.value])
      }
    })
  }

  async redeemOffer(o: Offer) {
    const useroffers = await firstValueFrom(this.useroffers$)
    if (o.autoJoinGroup && o.offerGroup) {
      const group = await firstValueFrom(this.getGroupSpecNamed(o.offerGroup?.name))
      if (this.addUserToGroup(group))
        console.log('added user to group', group?.name)
    }
    const status = this.shouldAddRequestedOffer(o, useroffers)
    let msg = this.generateOfferMessage(o, status)
    if (status.shouldAdd) {
      if (o.eventType) {
        // add offer but do not apply
        await this.recordUserOffer(o, false, useroffers)
      }
      else {
        // apply offer
        await this.pointsService.triggerPoints(o.eventType as string, 'Bonus', o.points, { id: o.name })
        await this.recordUserOffer(o, true, useroffers)
        msg = o.message || msg
      }
    }
    console.log('returning', { offer: o, message: msg, status })
    return { offer: o, message: msg, status }
  }

  async addUserToGroupNamed(name: string) {
    const group = await firstValueFrom(this.getGroupSpecNamed(name))
    return this.addUserToGroup(group)
  }

  addUserToGroup(group: GroupSpec | undefined) {
    console.log('addUserToGroup', group)
    const persona = group?.persona?.name as string
    if (persona && group) {
      const added = this.userService.addPersona(persona)
      if (added) {
        this.updateGroupStat(group, { numUsers: this.firebaseService.increment(1) })
        this.eventService.recordJoinOfferGroup({ group: group.name, persona })
        // TODO: Record in CleverTap
        if (group.points)
          this.pointsService.triggerPoints(Events.JoinOfferGroup, 'Bonus', group.points as number, { id: group.name })
        return true
      }
    }
    return false
  }
}