import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatestWith, filter, Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { ContentService } from './content.service';
import { EntryService } from './entry.service';
import { SharedEventService } from './shared-event.service';
import { SharedUserService } from './shared-user.service';
import { todaystr } from '@cheaseed/node-utils';

const ACCOMPLISHMENT = 'Accomplishment'
const SATISFACTION = 'TrackSatisfactionAction'

export interface StatsFeedState {
  type: string,
  entryType: string,
  valid: (val:number) => boolean,
  route: any[],
  maxDaysToDisplay?: number,
  refreshAfterDays?: number,
  trackTapped?: boolean,
  title?: string,
  subtitle?: string,
  lastCount: number,
  showHelp?: boolean
}

const feedStates = {
  TrackSatisfactionAction: [
    { type: 'InsightsSatNoneFeed', entryType: SATISFACTION, valid: (val, daysOld) => val === 0 && daysOld > 0, route: [ 'sectionentryform', { entrySpecId: SATISFACTION } ], trackTapped: true, showHelp: true },
    { type: 'InsightsSatSomeFeed', entryType: SATISFACTION, valid: (val, daysOld) => val > 0 && val < 10, route: [ 'sectionentryform', { entrySpecId: SATISFACTION } ], maxDaysToDisplay: 1, refreshAfterDays: 2, showHelp: true },
    { type: 'InsightsSat10XFeed', entryType: SATISFACTION, valid: (val, daysOld) => val > 0 && val % 10 === 0, route: [ '/tabs/career/stats/Satisfaction' ], trackTapped: true },
  ],
  Accomplishment: [
    { type: 'InsightsAccNoneFeed', entryType: ACCOMPLISHMENT, valid: (val, daysOld) => val === 0 && daysOld > 0, route: [ 'sectionentryform', { entrySpecId: ACCOMPLISHMENT } ], trackTapped: true, showHelp: true },
    { type: 'InsightsAccSomeFeed', entryType: ACCOMPLISHMENT, valid: (val, daysOld) => val > 0 && val < 10, route: [ 'sectionentryform', { entrySpecId: ACCOMPLISHMENT } ], maxDaysToDisplay: 1, refreshAfterDays: 2, showHelp: true },
    { type: 'InsightsAcc10XFeed', entryType: ACCOMPLISHMENT, valid: (val, daysOld) => val > 0 && val % 10 === 0, route: [ '/tabs/career/stats/Accomplishment' ], trackTapped: true }
  ]  
}

@Injectable({
  providedIn: 'root'
})
export class StatsFeedService {
  private accompFeedStateSubject = new BehaviorSubject<StatsFeedState>(null)
  accompFeedState$ = this.accompFeedStateSubject.asObservable()
  private satFeedStateSubject = new BehaviorSubject<StatsFeedState>(null)
  satFeedState$ = this.satFeedStateSubject.asObservable()
  private tapFeedSubject = new BehaviorSubject<boolean>(null)
  tapFeed$ = this.tapFeedSubject.asObservable()
  subscription: Subscription = null

  constructor(
    private router: Router,
    private contentService: ContentService,
    private entryService: EntryService,
    private eventService: SharedEventService,
    private userService: SharedUserService,
    private auth: AuthService,
  ) { }

  initialize() {
    // Release any prior subscriptions
    if (this.subscription)
      this.subscription.unsubscribe()
    this.subscription = this.entryService.entryCounts$
      .pipe(
        this.auth.takeUntilAuth(),
        combineLatestWith(this.tapFeed$),
        filter(([counts, tapped]) => !!counts))
      .subscribe(([counts, tapped]) => {
        // console.log('StatsFeedService.initialize detected change to', counts, tapped)
        this.selectState(ACCOMPLISHMENT, counts[ACCOMPLISHMENT], this.accompFeedStateSubject)
        this.selectState(SATISFACTION, counts[SATISFACTION], this.satFeedStateSubject)
        })
  }

  // Should we check the current feed state to ensure that we don't reassign to it if it just expired
  selectState(entryType: string, cnt: number, subj: BehaviorSubject<StatsFeedState>) {
    const numEntries =  cnt || 0
    const userDaysOld = this.userService.userDaysOld()
    for (const state of feedStates[entryType]) {
      const valid = state.valid(numEntries, userDaysOld)
      const feedKey = this.getFeedKey(state.type)
      const lastFeedValue = this.userService.getUserKey(feedKey) // { tapped, lastCount, lastDate}
      const countChanged = numEntries !== lastFeedValue?.lastCount
      // console.log('StatsFeedService.selectState', { numEntries, valid, countChanged, lastFeedValue })
      if (valid) {
        // Update feed key
        const tapped = countChanged ? false : lastFeedValue.tapped
        this.userService.setUserKey(feedKey, { 
          tapped: tapped,
          lastCount: numEntries,
          lastFeedDate: lastFeedValue?.lastFeedDate || todaystr()
        })
        // Update observable
        if (!lastFeedValue || countChanged || !tapped) {
          subj.next({
            title: this.getTitle(state.type)?.replace('$num', numEntries.toString()),
            subtitle: this.getSubtitle(state.type),
            ...state
          })
          return
        }
      }
      else if (!valid && lastFeedValue) {
        // Remove existing feed keys if invalid
        this.userService.deleteUserKey(feedKey)
      }
    }
    subj.next(null)
  }

  public getContent(key) {
    return this.contentService.getGlobal(key)
  }

  private getFeedKey(type: string) {
    return `feed.${type}`
  }

  private getTitle(type: string) {
    const str = type[0].toLowerCase() + type.slice(1)
    return this.contentService.getGlobal(`${str}.title`)
  }

  private getSubtitle(type: string) {
    const str = type[0].toLowerCase() + type.slice(1)
    return this.contentService.getGlobal(`${str}.subtitle`)
  }

  tappedFeed(state: StatsFeedState) {
    const feedKey = this.getFeedKey(state.type)
    const lastFeedValue = this.userService.getUserKey(feedKey)
    const newVal = { ...lastFeedValue, tapped: true }
    console.log('StatsFeedService.tappedFeed', state.type, newVal)
    this.userService.setUserKey(feedKey, newVal)
    this.tapFeedSubject.next(true)
    if (state.route)
      this.router.navigate(state.route)
    this.eventService.recordClick({ button: 'StatsFeed', location: 'Home', state: state.type })
  }

}
