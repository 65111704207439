import { nowstr } from "./date-functions";
import { CHATGPT_COSTS, GPT35, GPT4, OPENAI_35_MODEL_NAME, OPENAI_40_MODEL_NAME, OPENAI_40_TURBO_MODEL_NAME } from "./constants";
import { LoggerInterface } from "./logger-interface";

export const CONTINUE_RESPONSE_PROMPT = 'continue_response'
export const CONTINUE_RESPONSE_PROMPT_JSON = 'continue response and preserve correct JSON syntax'
export const PROMPT_STATUS_KEY = 'user.promptStatus'

// error codes returned in the Azure OpenAI RestError. CONTEXT_LENGTH_EXCEEDED
// is one example. Add others and remove numeric error codes
export enum AzureOpenAIErrorCodes {
  CONTEXT_LENGTH_EXCEEDED = 'context_length_exceeded'
  //TODO - add others
}

export enum AzureOpenAIErrorTypes {
  INVALID_REQUEST_ERROR = 'invalid_request_error'
}
export interface Prompt {
  chatId: string;
  createdAt: string;
  userId: string;
  threadId?: string;
  promptKey: string;
  promptGroup?: string;
  prompt: string;
  response?: any;
  extractions?: any[];
  role: string;
  system?: boolean;
  usage?: number;
  input_usage?: number;
  output_usage?: number;
  input_chars?: number;
  output_chars?: number;
  total_cost?: number;
  finish_reason?: string;
  selected?: boolean;
  elapsedMsec?: number;
  modelVersion?: string;
  temperature?: number;
  top_p?: number;
  docId?: string;
}

export interface ErrorPrompt extends Prompt {
  error: {}
}
export interface PromptGuide {
  name: string;
  guideText: string;
  tacticalSummary: string;
  languageScript: string;
  globalTags: { name: string }[];
}

//export const COMPLETIONS = 'completions'
//export const ASSISTANT = 'assistant'
export enum OPENAI_API_TYPES  {
  COMPLETIONS = 'completions',
  ASSISTANT ='assistant' 
}
interface ResponseExtractionSpec {
  type: 'list' | 'text';
  key: string;
  heading: string;
}

export class PromptSpec {
  name!: string;
  promptType?: string;
  promptTemplate!: string;
  nextPromptSpec?: { name: string; };
  promptGroup?: string;
  behaviors?: string[];
  format?: string;
  headers?: string;
  rootElement?: string;
  key?: string;
  defaultKeyValue?: string;
  spinningTitle?: string;
  spinningSubtitle?: string;
  maxContinuations?: number;
  temperature?: string;
  testTemperatures: string;
  top_p?: string;
  extractions?: ResponseExtractionSpec[];
  modelVersion?: string;  // 3.5 or 4;
  openAIAPI: OPENAI_API_TYPES

  constructor(args: any) {
    Object.assign(this, args)
  }

  isQA() {
    return this.promptType === 'QA'
  }

  isFormatJSON() {
    return this.format === 'json'
  }

  isFormatItemsToJSON() {
    return this.format === 'itemsToJson'
  }

  hasBehavior(behavior: string) {
    return !!this.behaviors?.includes(behavior)
  }

  hasSystemRole() {
    return !!this.hasBehavior('SystemRole')
  }

  shouldSendCompletionEvent() {
    return this.hasBehavior('SendCompletionEvent')
  }

  shouldRememberPrompt() {
    return this.hasBehavior('RememberPrompt')
  }

  getOpenAPILookupKey() {
    return this.openAIAPI || OPENAI_API_TYPES.COMPLETIONS
  }

  parseResponse(content: any) {
    // console.log(`Parsing response`, content)
    try {
      // If itemsToJSON format, attempt to parse; if no results, fall through
      if (typeof content === 'string' && this.isFormatItemsToJSON()) {
        // Assume each line of content is an item; remove item markdown and load into json
        // console.log(`itemsToJSON detected for`, content)
        const items = content.split('\n')
          .map(str => {
            const bulletItem = /^\s*[-|\*]\s+([^\n]*)/.exec(str)
            const numberItem = /^\s*\d+\.\s+([^\n]*)/.exec(str)
            return bulletItem                      
              ? bulletItem[1].trim()
              : numberItem
                ? numberItem[1].trim()
                : undefined
          })
          .filter(item => item?.length > 0)
          .map((item, index) => ({ name: `${index + 1}`, description: item }))
        // Only return if we have items
        // console.log(`itemsToJSON computed`, items)
        if (items.length > 0) {
          console.log(`JSON detected itemsToJSON, returning ${items.length} items`)
          return items
        }
      }
      if (typeof content === 'string') {
        // Exclude any errant 3.5 json markup
        return JSON.parse(content.replace('```json', '').replace('```', ''))
      }
      else if (content.example || content.examples || content.items) {
        // Handle frequent case where 3.5 returns repeated schema + example/examples/items property
        console.log("JSON detected example/examples/items with schema, returning example")
        return content.example || content.examples || content.items
      }
      else
        return content
    }
    catch(e:any) {
      if (this.isFormatJSON())
        throw new Error(`Expected JSON, but response is not JSON: ${e.message}: ${content} `)
      else 
        return content
    }
  }
}

export interface PromptStatusValue {
  status: 'INITIALIZING' | 'RUNNING' | 'COMPLETED' | 'NOTIFIED' | 'ERROR',
  chatId: string,
  chatName?: string;            // name of chat
  threadId?: string;            // id of chain
  startPromptName?: string,     // first prompt in chain
  promptName?: string,          // current prompt in chain
  promptCount?: number,         // index of current prompt
  promptUserKey?: string,       // userkey saved by current prompt
  pendingPrompts?: string[],    // history of prompts executed
  spinnerTitle?: { title: string, subtitle: string } // current spinner title
  isLocal?: boolean,
  isAzure?: boolean,
  error?: any,
  createdAt?: string,           // from userkey
  updatedAt?: string,           // from userkey
}

export interface StatusKeyWriter extends LoggerInterface {
  isLocal: boolean,
  isAzure: boolean,
  updatePromptStatusKey(data: PromptStatusValue, createdAt: string): Promise<void>
  getUserKeyDoc(key:string): Promise<any>
  getGlobal(key: string): string
  getNowStr(): string
  setErrorPrompt(data: ErrorPrompt): Promise<void>
}

abstract class PromptStats {
  input_usage: number = 0
  output_usage: number = 0
  elapsedMsec: number = 0
  input_chars: number = 0
  output_chars: number = 0
  usage: number = 0
  total_cost: number = 0

  updateStats(prompt: Prompt) {
    this.input_usage += prompt.input_usage || 0
    this.output_usage += prompt.output_usage || 0
    this.elapsedMsec += prompt.elapsedMsec || 0
    this.input_chars += prompt.input_chars || 0
    this.output_chars += prompt.output_chars || 0
    this.usage += prompt.usage || 0
    this.total_cost += prompt.total_cost || 0
  }
}
export class ThreadStats extends PromptStats {
  threadId: string

  constructor(threadId: string) {
    super()
    this.threadId = threadId
  }

}
export class ChatStats extends PromptStats {
  chatId: string
  threads: ThreadStats[] | undefined
  createdAt: string
  completedAt: string
  
  constructor(chatId: string, createdAt: string) {
    super()
    this.chatId = chatId
    this.createdAt = createdAt
  }

  markComplete() {
    this.completedAt = nowstr()
  }

  addPrompt(prompt: Prompt) {
    let thread: ThreadStats
    if (!this.threads)
      this.threads = []
    else {
      thread = this.threads.find(t => t.threadId === prompt.threadId)
    }
    if (!thread) {
      thread = new ThreadStats(prompt.threadId)
      this.threads.push(thread)
    }
    thread.updateStats(prompt)
    this.updateStats(prompt)

  }
}

export const threadStatsConverter = {
  toFirestore(obj: ThreadStats[]) {
    if (!obj || obj.length == 0)
      return undefined
    const threads = []
    obj.forEach(o => {
      threads.push(Object.assign({}, o))
    })
    return threads
  },
  // Cant type d as we want to share this between the app and cloud code which each use
  // different libraries; so keep it as any
  fromFirestore(objs: any[]) {
    if (!objs || objs.length === 0)
      return undefined
    let threads: ThreadStats[] =[]
    objs.forEach(o => {
      let thread = new ThreadStats(o.threadId)
      Object.assign(thread, o)
      threads.push(thread)
    })
    return threads
  }
}
export const chatStatsConverter = {
  toFirestore(obj: ChatStats): any {
    const o: any = Object.assign({}, obj as any)
    o.threads = threadStatsConverter.toFirestore(obj.threads)
    return o
  },
  fromFirestore(snapshot: any) {
    let o = snapshot.data()
    let c = new ChatStats(o.chatId, o.createdAt)
    Object.assign(c, o)
    c.threads = threadStatsConverter.fromFirestore(o.threads)
    return c
  }
}

export function getFallbackModel(err: any, modelVersion: string, isAzure: boolean) {
  //TODO - lookup the fallback model from the airtable definitions
  //Maybe there are different fallbacks depending on
  // the kind of error encountered - maybe it is a complete spec
  // that includes topP and temperature. TBD
  // In Azure, regardless of model, always fallback to turbo
  if(err.type && err.type === AzureOpenAIErrorTypes.INVALID_REQUEST_ERROR && 
     err.code && err.code === AzureOpenAIErrorCodes.CONTEXT_LENGTH_EXCEEDED) {
      return isAzure ? OPENAI_40_TURBO_MODEL_NAME : GPT35
    }
  return null 
}

//promptStatus update functions
export async function writeCompleteStatus( 
  statusKeyWriter: StatusKeyWriter,
  spec: PromptSpec,
  chatId: string,
  promptName: string,
  chatName: string,
  threadId: string,
  error?: any,
  ) {
    const keydoc = await statusKeyWriter.getUserKeyDoc(PROMPT_STATUS_KEY)
    const current = keydoc?.value as PromptStatusValue
    const pendingPrompts = current?.pendingPrompts || []
    const value: PromptStatusValue = {
      chatId,
      threadId,
      chatName,
      promptName,
      promptUserKey: spec?.key,
      startPromptName: pendingPrompts[pendingPrompts.length - 1],
      status: error ? 'ERROR' : 'COMPLETED',
      error: error ? getErrorObj(error) : undefined,
      pendingPrompts,
      spinnerTitle: getSpinnerTitle(spec, statusKeyWriter),
      isLocal: statusKeyWriter.isLocal,
      isAzure: statusKeyWriter.isAzure
    }
    // console.log(`Setting completed status for thread ${threadId} value ${JSON.stringify(value)}`)
    if (error)
      statusKeyWriter.error(error.message)
    else
      statusKeyWriter.log(`Setting promptstatus to ${value.status} for thread ${threadId}`)

    await statusKeyWriter.updatePromptStatusKey(value, keydoc?.createdAt)
}

/**
 * @deprecated - remove later
 * @param statusKeyWriter 
 * @param promptKey 
 */
export async function removePromptStatusPendingPrompt(
  statusKeyWriter: StatusKeyWriter,
  promptKey: string) {
    const keydoc = await statusKeyWriter.getUserKeyDoc(PROMPT_STATUS_KEY)
    const current = keydoc?.value as PromptStatusValue
    let prevPrompts = current?.pendingPrompts || []
    current.pendingPrompts = prevPrompts.filter(p => p !== promptKey)
    statusKeyWriter.log(`Removed pending prompt ${JSON.stringify(promptKey)}`)
    statusKeyWriter.log(`Setting promptstatus to ${JSON.stringify(keydoc)}`)
    keydoc.value = current
    await statusKeyWriter.updatePromptStatusKey(keydoc, keydoc?.createdAt)

}
export async function writePromptStatus(
  statusKeyWriter: StatusKeyWriter, 
  spec: PromptSpec, 
  chatId: string,
  promptCount: number, 
  threadId: string,
  chatName: string,
  error?: any) {
    const status: PromptStatusValue["status"] = error ? 'ERROR' : 'RUNNING'
    const keydoc = await statusKeyWriter.getUserKeyDoc(PROMPT_STATUS_KEY)
    const current = keydoc?.value as PromptStatusValue
    // Erase old pendingPrompts if this is the start of a chain
    const prevPrompts = promptCount === 0 ? [] : (current?.pendingPrompts || [])
    const pendingPrompts = [ spec.name, ...prevPrompts ]
    const value: PromptStatusValue = {
      chatId,
      threadId,
      chatName,
      pendingPrompts: pendingPrompts,
      startPromptName: pendingPrompts[pendingPrompts.length - 1],
      promptName: spec.name,
      promptCount,
      promptUserKey: spec.key,
      status,
      error: error ? getErrorObj(error) : undefined,
      spinnerTitle: getSpinnerTitle(spec, statusKeyWriter),
      isLocal: statusKeyWriter.isLocal,
      isAzure: statusKeyWriter.isAzure
    }
    statusKeyWriter.log(`Setting promptstatus to ${value.status} for thread ${threadId}`)

    // Reset createdAt for promptStatus
    const createdAt = promptCount === 0 ? undefined : keydoc?.createdAt
    await statusKeyWriter.updatePromptStatusKey(value, createdAt as string)
}

export async function writeErrorPrompt(
  chatId: string,
  promptKey: string,
  prompt: string, 
  userId: string, 
  threadId: string,
  modelVersion: string,
  temperature: number,
  top_p: number,
  statusKeyWriter: StatusKeyWriter,
  error: Error) {

  const nowstr = statusKeyWriter.getNowStr()
 
  const data: ErrorPrompt = {
    chatId,
    createdAt: nowstr,
    userId,
    threadId,
    promptKey,
    prompt,
    response: null,
    role: null,
    usage: null,
    finish_reason: 'ERROR',
    input_chars: prompt?.length,
    elapsedMsec: 0,
    modelVersion,
    temperature,
    top_p,
    error: error.message
  }
  statusKeyWriter.debug('Adding error prompt', JSON.stringify(data))
  await statusKeyWriter.setErrorPrompt(data)
  return data
}

function getSpinnerTitle(spec: PromptSpec, statusKeyWriter: StatusKeyWriter)
{
  return {
    title: spec?.spinningTitle || statusKeyWriter.getGlobal('prompt.spinner.title.message'),
    subtitle: spec?.spinningSubtitle || statusKeyWriter.getGlobal('prompt.spinner.subtitle.message')
  }
}

//TODO - write our own error classes so that we can write more informational 
// error statuses
function getErrorObj(error: any) {
  return { message: error.message, type: error.name, code: error.code || 'generic-error' }
}

export function getPromptCost(input_usage: number, output_usage: number, model_version: string) {
  const input = input_usage || 0.0
  const output = output_usage || 0.0
  const model = model_version === GPT4 ? OPENAI_40_MODEL_NAME : OPENAI_35_MODEL_NAME
  const cost = (input * CHATGPT_COSTS[model].input / 1000) + (output * CHATGPT_COSTS[model].output / 1000)
  return Math.round(cost * 10000) / 10000
}