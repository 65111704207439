import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { BranchReferringParams } from 'capacitor-branch-deep-links';
import { BehaviorSubject } from 'rxjs';
import { SharedChatService } from './shared-chat.service';

@Injectable({
  providedIn: 'root'
})
export class DeepLinkService {

  deeplinkParams$ = new BehaviorSubject<BranchReferringParams|null>(null)

  constructor(
    private router: Router,
    private zone: NgZone,
    private conversationService: SharedChatService,
  ) { }

  setDeeplinkParams(params: BranchReferringParams|null) {
    console.log("setDeeplinkParams", JSON.stringify(params))
    this.deeplinkParams$.next(params)
  }

  clearDeepLinks() {
    this.setDeeplinkParams(null)
  }

  checkLinkParams(params: BranchReferringParams|null) {
    // console.log("checkLinkParams", JSON.stringify(params))
    // Check if any deeplinkParams remain and process
    if (params && params['+clicked_branch_link']) {
      this.checkDeeplink(params.$deeplink_path)
      // clear the lastDeepParams so that we dont navigate again
      this.clearDeepLinks()
    }
  }

  checkDeeplink(deepUrl: string) {
    if (!deepUrl)
      return
    console.log("deep link detected", deepUrl)
    // await this.notify(deepUrl)
    this.zone.run(() => {
      const COM = 'cheaseed.com',
        BRANCHIO = 'app.link',
        scheme = 'cheaseed://',
        url = deepUrl
      let slug = ''
      if (url.includes(COM))
        slug = url.split(COM).pop() as string
      else if (url.includes(BRANCHIO))
        slug = url.split(BRANCHIO).pop() as string
      else if (url.includes(scheme))
        slug = url.split(scheme).pop() as string
      else if (url.startsWith('/'))
        slug = url
      if (url.includes('share/'))
        slug = '/'  // Route to default
      if (slug) {
        slug = decodeURIComponent(slug)
        if (slug.includes('conversation')) {
          const slugMatch = slug.match(/conversation\/([^;\?]*)/)
          const slugChat = slugMatch ? slugMatch[1] : null
          const routeUrl = this.router.url
          console.log("navigating to", slug, "from", routeUrl)
          const match = routeUrl.match(/conversation\/([^;]*)/)
          const routeChat = match ? match[1] : null
          console.log("Checking conversation deeplink", { slug, routeUrl, slugChat, routeChat })
          // If user is currently not on conversation page, navigate to conversation
          if (!routeChat) {
            this.router.navigateByUrl(slug)
          }
          // If user is currently on conversation page, reset next conversation if different from current (routeChat may be old)
          else {
            const currChat = this.conversationService.nextConversation$.getValue()
            if (slugChat && slugChat !== currChat)
              this.conversationService.setNextConversation(slugChat)
          }
        }
        else 
          this.router.navigateByUrl(slug)
      }
    })
  }

  getInviter(params: BranchReferringParams|null) {
    return params?.friendInviter || params?.inviter
  }

}