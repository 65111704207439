import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular'
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx'
import clevertap  from 'clevertap-web-sdk'
@Injectable({
  providedIn: 'root'
})
export class CleverTapService {

  constructor(
    private platform: Platform,
    // private appRef: ApplicationRef,
    private clevertap: CleverTap
  ) {}

  init(accountID: string, passCode: string) {
    if(!this.isCapacitor()) {
      clevertap.init(accountID)
      console.log('clevertap sdk initialized')
      clevertap.setLogLevel(3)
      clevertap.privacy.push({optOut: false}) //set the flag to true, if the user of the device opts out of sharing their data
      clevertap.privacy.push({useIP: false})
      clevertap.spa = true // recommended
    }
    else {
      // From https://github.com/CleverTap/clevertap-cordova/blob/master/docs/Usage.md
      // Seems like we could handle in-app button clicks with the following code:
      document.addEventListener('onCleverTapInAppButtonClick', this.onCleverTapInAppButtonClick, false) // optional, to check if InApp button was clicked with custom payload
    }
  }

  onCleverTapInAppButtonClick(event: any) {
    console.log('onCleverTapInAppButtonClick', event)
  }

  isCapacitor() {
    return this.platform.is('capacitor')
  }

  isAndroid() {
    return this.platform.is('android')
  }

  registerPush() {
    if(this.isCapacitor()) {
      this.clevertap.registerPush()
    }
  }

  createNotificationChannel() {
     // Channels are relevant only on Android. 
      // importance == 3 indicates IMPORTANCE_DEFAULT; plays a sound
      if (this.isAndroid())
        this.clevertap.createNotificationChannel('CHEASEED_CHANNEL', 'Cheaseed Channel', 'Channel for chea seed notifications', 3, true)
  }

  onUserLogin(userId: string, profileData:any = {}) {
    const data:any = this.formatAttributes({ ...profileData })
    if(this.isCapacitor())
      this.clevertap.onUserLogin(data)
    else
      clevertap.onUserLogin.push({ Site: data })    
  }

  isObject(object:any): boolean {
    return object != null && typeof object === 'object'
  }

  formatAttributes(data:any) {    
    for (const [key, value] of Object.entries(data)) {
      if (this.isObject(value) || Array.isArray(value)) {
        data[key] = JSON.stringify(value)
      }
    }
    return data
  }

  track(userId: string, name: string, attributes = {}) {
    // workaround for issue with CleverTap - arrays and objects are not accepted
    const data:any = this.formatAttributes({ ...attributes })
    if(this.isCapacitor())
      this.clevertap.recordEventWithNameAndProps(name, data)
    else
      clevertap.event.push(name, data)    
  }

  createUserAndSendEvent(userId: string, attributes:any, eventName: string) {
    // Function will use clevertap API to create profile and send an EmailAcquired event
      // Clevertap user should be marked as email-only, not created in our app
      // Attributes should be added as user properties
      // If user subsequently logins to app with same email, will profile be reused? Implications?
    // TODO: Test wheterh this could work for web and Capacitor
    if (this.isCapacitor())
      this.clevertap.onUserLogin({ Identity: userId, ...attributes })
    else
      clevertap.onUserLogin.push({ Site: { Identity: userId, ...attributes } }) 

    // EmailAcquired event will trigger a campaign email immediately.
    // TODO: Need to figure out how to push an event to a different userId
    //this.track(userId, eventName)
  }

}
