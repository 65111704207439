// Entry class
// Shared by cloud functions and apps

import { format } from "date-fns";
import { COLLECTION_ENTRIES_ATTRIBUTE_NAME } from "./constants";

export class Entry {
  docId!: string;
  type?: string;
  createdAt?: string;
  updatedAt?: string;
  displayName?: string;
  parentEntryId?: string;
  subtype?: string;
  attributes?: any;
  favorited?: boolean;
  approved?: boolean;
  approvedAt?: string;

  constructor(args: unknown) {
    Object.assign(this, args)
  }

  indexType(): string | undefined { return this.subtype || this.type }
  hasWarning(): boolean { return this.attributes?.remindMe || this.attributes?.notNow }
  getAttributeNamed(attributeName: string) { return this.attributes[attributeName] }
  setAttributeNamed(attributeName: string, value: any) { this.attributes[attributeName] = value }
  isCollectionType(): boolean { return this.type === 'Collection' }
  isGoalType(): boolean { return this.type === 'Goal' }
  isUnapproved(): boolean { return this.approved === false }
  isViewed(): boolean { return this.attributes?.viewed }

  prepareReport(ids: Record<string, Entry>, attributeSpecs: Map<string, any> ) {
    const questions: any = {},
        responses: any = {},
        displayableAttributes: any[] = []
    // console.log("prepareReport", this)
    for (const [key, value] of Object.entries(this.attributes)) {
        const attr = `${this.indexType()}.${key}`
        const spec: any = attributeSpecs.get(attr)
        if (spec && !spec.behaviors?.includes("readonly") && !['name', COLLECTION_ENTRIES_ATTRIBUTE_NAME].includes(key)) {
            // console.log('looked up', attr, spec)
            if ((spec.inputType === 'ENTRYSELECTOR' && (value as string[]).length === 0)
                || (spec.inputType === 'MULTIOPTIONS' && value === 'null'))
                continue
            questions[key] = spec.shortQuestion || spec.question || key
            responses[key] = value
            if (value) {
                if (spec.inputType === 'SCALE') {
                    const val: any = value
                    responses[key] = spec.scaleLabels[val - 1]
                }
                else if (spec.inputType === 'DATE') {
                    const val = new Date(value as string)
                    responses[key] = format(val, 'MMMM dd, yyyy')
                }
                else if (spec.inputType === 'VIDEOCAPTURE') {
                    responses[key] = "📹"
                }
                else if (spec.inputType === 'ENTRYSELECTOR') {
                    // console.log("prepareReport ENTRYSELECTOR", this, value)
                    const val = (typeof value === 'string' ? JSON.parse(value as string) : value) as string[]
                    const names = (val as string[]).map(str => ids[str]?.displayName)
                    responses[key] = names.join(', ')
                }
                else if (spec.inputType === 'MULTIOPTIONS') {
                    const val = JSON.parse(value as string) || {}
                    const matches: string[] = spec.optionLinks.filter((opt: any) => val[opt.name]).map((opt: any) => opt.description)
                    responses[key] = matches.join(', ')
                }
                else if (["SEGMENTED", "OPTIONS", "RADIOCHIPS"].includes(spec.inputType)) {
                    // console.log("translating", spec, a.value)
                    const match = spec.optionLinks.find((opt: any) => opt.name === value)
                    if (match)
                        responses[key] = match.description
                    else {
                        if (spec.inputSubtype === 'Goal')
                        responses[key] = ids[value as string]?.displayName
                    }
                }
            }
            displayableAttributes.push({ attributeName: key, value: value, order: spec.order })
        }
    }
    // Use natural order of attribute spec
    displayableAttributes.sort((a, b) => a.order > b.order ? 1 : -1)
    // console.log({displayableAttributes, questions, responses})
    return { displayableAttributes, questions, responses }
  }

}
