import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import {
  PreloadAllModules,
  RouteReuseStrategy,
  RouterModule,
} from '@angular/router';
import { provideFirebaseApp, initializeApp, getApp } from '@angular/fire/app';
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions,
} from '@angular/fire/functions';
import {
  connectFirestoreEmulator,
  provideFirestore,
  initializeFirestore,
} from '@angular/fire/firestore';

import {
  connectStorageEmulator,
  getStorage,
  provideStorage,
} from '@angular/fire/storage';
import { provideAuth, connectAuthEmulator, getAuth } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { BuildTag } from 'buildtag';
import { PatchLevel, ReleaseTag } from 'releasetag';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { CleverTap } from '@awesome-cordova-plugins/clevertap/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // required for webchats
import { AirtableBase, ExtractService } from '@cheaseed/node-utils';
import { UtilityService } from '@cheaseed/cheaseed-core';

@NgModule({ 
    declarations: [AppComponent],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
        RouterModule.forRoot([
            { path: '', redirectTo: '/share', pathMatch: 'full' },
            // {
            //   path: 'home',
            //   loadChildren: () =>
            //     import('@cheaseed/web/feature-home').then(
            //       (m) => m.WebFeatureHomeModule
            //     ),
            // },
            {
                path: 'share',
                loadChildren: () => import('@cheaseed/web/feature-share').then((m) => m.WebFeatureShareModule),
            },
            // {
            //   path: 'share/:id/:source',
            //   loadChildren: () =>
            //     import('@cheaseed/web/feature-share').then(
            //       (m) => m.WebFeatureShareModule
            //     ),
            // },
            {
                path: 'share/:id',
                loadChildren: () => import('@cheaseed/web/feature-share').then((m) => m.WebFeatureShareModule),
            },
            // {
            //   path: 'chat',
            //   loadChildren: () =>
            //     import('@cheaseed/web/feature-chat').then(
            //       (module) => module.WebFeatureChatModule
            //     ),
            // },
            {
                path: 'chat',
                loadChildren: () => import('@cheaseed/web/feature-chat').then((module) => module.WebFeatureChatModule),
            },
            {
                path: 'webchat/:chatId',
                loadChildren: () => import('@cheaseed/web/feature-chat').then((module) => module.WebFeatureChatModule),
            },
            {
                path: 'entry/:userId/:entryId',
                loadChildren: () => import('@cheaseed/web/feature-entry').then((module) => module.WebFeatureEntryModule),
            },
            {
                path: 'entry/:encoded',
                loadChildren: () => import('@cheaseed/web/feature-entry').then((module) => module.WebFeatureEntryModule),
            },
            { path: '**', redirectTo: '' },
        ], {
            initialNavigation: 'enabledBlocking',
            //relativeLinkResolution: 'legacy',
            preloadingStrategy: PreloadAllModules,
            // initialNavigation: 'enabledBlocking'
        }),
        ScullyLibModule.forRoot({
            useTransferState: true,
            alwaysMonitor: false,
            manualIdle: true,
        }),
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatNativeDateModule], providers: [
        CleverTap,
        SocialSharing,
        provideHttpClient(withInterceptorsFromDi()),
        provideFirebaseApp(() => initializeApp(environment.firebase)),
        provideFirestore(() => {
            const firestore = initializeFirestore(getApp(), {
                ignoreUndefinedProperties: true,
            });
            if (environment.useEmulators) {
                connectFirestoreEmulator(firestore, 'localhost', 8080);
            }
            return firestore;
        }),
        provideAuth(() => {
            const auth = getAuth(getApp());
            if (environment.useEmulators) {
                connectAuthEmulator(auth, 'http://localhost:9099', {
                    disableWarnings: true,
                });
            }
            return auth;
        }),
        provideStorage(() => {
            const storage = getStorage();
            if (environment.useEmulators) {
                connectStorageEmulator(storage, 'localhost', 9199);
            }
            return storage;
        }),
        provideFunctions(() => {
            const functions = getFunctions();
            if (environment.useEmulators) {
                connectFunctionsEmulator(functions, 'localhost', 5001);
            }
            return functions;
        }),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: 'environment', useValue: { ...environment, buildTag: BuildTag, releaseTag: ReleaseTag, patchLevel: PatchLevel } },
        { provide: 'AirtableService', useClass: AirtableBase },
        { provide: 'ExtractService', useClass: ExtractService },
        { provide: 'UtilityService', useClass: UtilityService },
    ] })
export class AppModule {}
