import { Inject, Injectable } from '@angular/core'
import { FirebaseService } from './firebase.service'
import { ContentService } from './content.service'
import { BehaviorSubject, timer } from 'rxjs'
import { SharedUserService } from './shared-user.service'
import { CleverTapService } from './clevertap.service'
import { PointsService } from './points.service'
import { limit, orderBy, where } from '@angular/fire/firestore'
import { Entry, diff, nowstr } from '@cheaseed/node-utils'

export enum Actions {
  Ended = "ENDED",
  Points = "POINTS",
  Consumed = "CONSUMED",
  ChatConsumed = "CHAT_CONSUMED",
  ChallengeCreated = "CHALLENGE_CREATED",
  LevelCompleted = "LEVEL_COMPLETED"
}

export enum Events {
  ConversationAcceptNext = "ConversationAcceptNext",
  ConversationAcceptOther = "ConversationAcceptOther",
  ConversationEnd = "ConversationEnd",
  ConversationFlowDone = "ConversationFlowDone",
  ConversationPin = "ConversationPin",
  ConversationStart = "ConversationStart",
  ConversationSuspend = "ConversationSuspend",
  ConversationUnpin = "ConversationUnpin",
  ContentShared = "ContentShared",
  EntryUpdate = "EntryUpdate",
  EntryCreate = "EntryCreate",
  EntryDelete = "EntryDelete",
  EntryRemind = "EntryRemind",
  NewsflashView = "NewsflashView",
  NotificationListView = "NotificationListView",
  NotificationView = "NotificationView",
  PushNotificationActionPerformed = "PushNotificationActionPerformed",
  PushNotificationReceived = "PushNotificationReceived",
  StatementEnd = "StatementEnd",
  UserCreation = "UserCreation",
  UserStats = "UserStats",
  DrawerSelect = "DrawerSelect",
  DrawerSelectSource = "DrawerSelectSource",
  TabChanged = "TabChanged",
  AppActivated = "AppActivated",
  AppDeactivated = "AppDeactivated",
  PageNavigation = "PageNavigation",
  StatsClicked = "StatsClicked",
  ProgramStart = "ProgramStart",
  ProgramEnd = "ProgramEnd",  
  ProgramCompleted = "ProgramCompleted",
  ProgramStepClick = "ProgramStepClick",
  ProgramStepComplete = "ProgramStepComplete",
  SignOut = "SignOut",
  ChallengeCreate = "ChallengeCreate",
  ChallengeInvite = "ChallengeInvite",
  ChallengeJoin = "ChallengeJoin",
  ChallengeLeave = "ChallengeLeave",
  ChallengeDelete = "ChallengeDelete",
  ChallengeUpdateParticipant = "ChallengeUpdateParticipant",
  FilterChange = "FilterChange",
  Click = "Click",
  CloudProgramEventTrigger = "CloudProgramEventTrigger",
  ChatFeedback = "ChatFeedback",
  QueuedChat = "QueuedChat",
  HomeLevelCompleted = "HomeLevelCompleted",
  SeedsPurchased = "SeedsPurchased",
  PointsRedeemed = "PointsRedeemed",
  JoinOfferGroup = "JoinOfferGroup",
  DeclineChatPay = "DeclineChatPay",
  ReportApprovalStateChanged = "ReportApprovalStateChanged",
  PromptCompleted = "PromptCompleted",
  ReportGenerated = "ReportGenerated",
  GiftPurchased = "GiftPurchased",
  PortalHomeChatAction = "PortalHomeChatAction",
  PortalOfferActivated = "PortalOfferActivated",
  PortalOfferRedeemed = "PortalOfferRedeemed",
  GroupBalanceLow = "GroupBalanceLow",
  GroupInviteAccepted = "GroupInviteAccepted",
  GroupInvitationSent = "GroupInvitationSent",
  GroupSendInvitation = "GroupSendInvitation",
  GroupJoined = "GroupJoined",
  GroupCreated = "GroupCreated",
  GroupUpdated = "GroupUpdated",
  GroupMemberApproved = "GroupMemberApproved"
}

const POINT_TIMES_KEY = "user.pointsTimes"

@Injectable({
  providedIn: 'root'
})
export class SharedEventService {
  
  eventRecorded$ = new BehaviorSubject<any>(null)

  constructor(
    @Inject('environment') private environment: any,
    protected contentService: ContentService,
    protected firebase: FirebaseService,
    protected clevertapService: CleverTapService,
    protected userService: SharedUserService,
    private pointsService: PointsService
  ) {  }

  record(name:string, attributes:any = {}) {
    const attr: any = { 
      ...attributes, 
      environment: this.environment.production ? 'prod' : 'dev',
      buildTag: this.environment.buildTag,
      releaseTag: this.environment.releaseTag
    }
    if(this.environment.patchLevel > 0)
      attr.patchLevel = this.environment.patchLevel
    // Delay to allow user to update if signing out/signing in
    // Avoids firestore permissions error
    timer(500).subscribe(() => {
      this.track(name, attr)
      this.eventRecorded$.next({ name, attributes })
    })
  }

  lastChatConsumedEventFor(userId: string, chatId:string) {
    return this.firebase.collection$(
      this.userService.getUserEventsPath(userId), 
      where('action', '==', Actions.ChatConsumed), 
      where('chatId', '==', chatId),
      orderBy('createdAt', 'desc'),
      limit(1))
}

  lastChatEndedEventFor(userId: string, chatId:string) {
    return this.firebase.collection$(
      this.userService.getUserEventsPath(userId), 
      where('action', '==', Actions.Ended), 
      where('eventConversationId', '==', chatId),
      orderBy('createdAt', 'desc'),
      limit(1))
  }

  async setProfile(attributes:any = {}) {
    if (Object.keys(attributes).length === 0)
      return
    // console.log("setProfile", attributes)
    await this.clevertapService.onUserLogin(this.userService.getCurrentUserId() as string, { ...attributes })    
  }

  async createLoggedInEvent(isAdmin: boolean) {
    await this.setProfile({ isInternalUser: isAdmin })    
  }

  // obj contains the id of a target (conv, entry, route, content, source, entryId)
  async createPointsEvent( event: Events, obj: any) {
    // Exit if anonymous user
    if (this.userService.isAnonymous())
      return
    let hit
    const events = this.contentService.points?.filter(p => p.eventType === event) || [],
          matches = events.filter(p => p.conversation?.name === obj.id 
            || p.entrySpec?.name === obj.id 
            || obj.id?.includes(p.route)),
          defaults = events.filter(p => 
          ( !p.conversation && [Events.ConversationEnd, Events.ConversationPin].includes(event)) 
          || (!p.entrySpec && [Events.EntryCreate, Events.EntryUpdate].includes(event))
          || (!p.route && [Events.TabChanged, Events.PageNavigation].includes(event))
          || [ Events.DrawerSelectSource, 
               Events.ContentShared, 
               Events.UserCreation, 
               Events.ProgramCompleted,
               Events.ChallengeCreate,
               Events.ChallengeJoin
             ].includes(event))
    if (matches.length > 0)
      hit = matches[0]
    else if (defaults.length > 0)
      hit = defaults[0]
    if (hit && hit.points > 0) {
      // console.log("createPointsEvent", {hit})
      // Check MaxOncePerDay behavior, especially for un-targeted events
      const maxOnce = hit.behaviors?.includes('MaxOnce')
      const maxOncePerDay = hit.behaviors?.includes('MaxOncePerDay')
      if (maxOncePerDay || maxOnce) {
        const pointTimes = { ...this.userService.getUserKey(POINT_TIMES_KEY) },
            now = nowstr(),
            target = hit.conversation?.name || hit.entrySpec?.name || hit.route,
            key = target ? `${hit.name}${obj.entryId || ''}` : `${hit.eventType}:${obj.entryId || obj.id}`,  // for targeted events, use the event type and entryId, otherwuse use obj id
            lastTime = pointTimes[key]
        // console.log("createPointsEvent MaxOnce*", {pointTimes, now, target, key, lastTime})
        if (lastTime && maxOnce) {
          console.log("createPointsEvent MaxOnce found for", key)
          return
        }
        else if (lastTime && maxOncePerDay && diff(lastTime, now, 'day') === 0) {
          console.log("createPointsEvent MaxOncePerDay found for", key)
          return
        }
        else
          pointTimes[key] = now
        this.userService.setUserKey(POINT_TIMES_KEY, pointTimes)
      }
      await this.pointsService.triggerPoints(hit.eventType, hit.category, hit.points, obj)
    }
  }

  track(name:string, attributes = {}) {
    const u = this.userService.getCurrentUser()
    if (u) {
      // console.log("track", u.docId, { name, attributes })
      this.clevertapService.track(u.docId as string, name, attributes)

      // Track in firestore
      const docId = this.firebase.generateDocID()
      const path = `users/${u.docId}/clevertap-events/${docId}`
      // console.log("about to write", path, { name, attributes })
      this.firebase.updateAt(path, {
        eventName: name,
        attributes,
        createdAt: new Date()
      })
    }
  }

  recordSignOut() {
    this.record(Events.SignOut)
  }

  recordPageNavigation(attributes: any) {
    this.createPointsEvent(Events.PageNavigation, { id: attributes.page } )    
    this.record(Events.PageNavigation, attributes)
  }

  recordSeedsConsumedByEntryEvent(attributes: any) {
    console.log("recordSeedsConsumedByEntryEvent", attributes)
    this.userService.putUserEvent({ ...attributes, action: Actions.Consumed })
  }
  
  recordSeedsConsumedByChatEvent(attributes: any) {
    console.log("recordSeedsConsumedByChatEvent", attributes)
    this.userService.putUserEvent({ ...attributes, action: Actions.ChatConsumed })
  }

  recordSeedsConsumedByChallengeEvent(attributes: any) {
    console.log("recordSeedsConsumedByChallengeEvent", attributes)
    this.userService.putUserEvent({ ...attributes, action: Actions.ChallengeCreated })
  }

  // 2021-09-05 disable for clevertap
  recordAppActivated() {
    // this.record(Events.AppActivated)
  }
  recordAppDeactivated() {
    // this.record(Events.AppDeactivated)
  }

  recordShareContent(attributes:any) {
    // TODO: record whether share was successful ?
    this.createPointsEvent(Events.ContentShared, attributes)
    this.record(Events.ContentShared, attributes)
  }

  recordEntry(event:Events, entry:any, duration:number|null) {
    // remove null attribute values
    const specifieds = Object.entries(entry.attributes).filter(([_, v]) => v != null)
    // identify reportable keys
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const type = entry.subtype || entry.type
    const reportablePairs = specifieds.filter(([k, _]) => { return this.contentService.isReportableAttributeSpec(`${type}.${k}`) })
    const reportables = Object.fromEntries(reportablePairs)
    const specifiedKeys = specifieds.map(([k, _]) => k)
    // console.log("recordEntry", event, entry, specifiedKeys, reportables)
    this.createPointsEvent(event, { id: type, entryId: entry.docId } )
    this.record(event, { entryType: type, id: entry.docId, specifiedAttributes: specifiedKeys, duration, ...reportables })    
  }

  recordEntryDeleted(entry:any) {
    this.record(Events.EntryDelete, { entryType: entry.subtype || entry.type, id: entry.docId })
  }

  recordEntryRemind(entry:Entry) {
    // this.record(Events.EntryRemind, { entryType: entry.indexType(), id: entry.docId })
    this.pointsService.eventCreated$.next({ ...entry.attributes, eventType: Events.EntryRemind, entryId: entry.docId, id: entry.indexType() })
  }

  recordConversationAcceptNext(conv:any) {
    this.record(Events.ConversationAcceptNext, { id: conv.id })
  }

  recordConversationAcceptOther(conv:any) {
    this.record(Events.ConversationAcceptOther, { id: conv.id })
  }

  recordConversationFlowDone() {
    this.record(Events.ConversationFlowDone, {})
  }

  recordConversationStart(attributes:any) {
    if (attributes) {
      attributes.series = attributes.series ? Array.from(attributes.series) : null
      attributes.topics = attributes.topics ? Array.from(attributes.topics) : null
      this.record(Events.ConversationStart, attributes)
    }
  }

  async recordConversationEnd(attributes: any) {
    // console.log("recordConversationEnd", attributes)
    const { id, launchSource, series, topics } = attributes
    if (id) {
      await this.createPointsEvent(Events.ConversationEnd, { id })
      this.record(Events.ConversationEnd,
        { id,
          launchSource,
          series: series ? Array.from(series) : null,
          topics: topics ? Array.from(topics) : null,
        })
      // Must come after points effects to delay deeplink
      this.userService.putUserEvent({ eventConversationId: id, action: Actions.Ended })
    }
  }

  recordConversationSuspend(conv:any, attributes:any) {
    if (conv)
      this.record(Events.ConversationSuspend, { id: conv.id, ...attributes })
  }

  recordDrawerSelect(attributes:any) {
    this.record(Events.DrawerSelect, attributes )
  }

  recordDrawerSelectSource(attributes:any) {
    this.createPointsEvent(Events.DrawerSelectSource, { id: attributes.sourceId, url: attributes.sourceUrl })
    this.record(Events.DrawerSelectSource, attributes )
  }

  recordStatementEnd(attributes: any) {
    // Perhaps: Add statement index
    this.record(Events.StatementEnd, attributes)
  }
  
  recordConversationPin(id:string) {
    this.createPointsEvent(Events.ConversationPin, { id })
    this.record(Events.ConversationPin, { id })
  }

  recordConversationUnpin(id:string) {
    this.record(Events.ConversationUnpin, { id })
  }

  recordUserStats(stats:any) {
    if (this.userService.getCurrentUser()) {
      this.record(Events.UserStats, stats)
      this.setProfile(stats)
    }
  }

  recordNotificationListView() {
    this.record(Events.NotificationListView, {})
  }

  recordNewsflashView(msg:any) {
    this.record(Events.NewsflashView, { id: msg.detail })
  }

  recordNotificationView(msg:any) {
    this.record(Events.NotificationView, { id: msg.detail })
  }

  recordUserCreation(attributes:any) {
    this.record(Events.UserCreation, attributes)
    this.createPointsEvent(Events.UserCreation, {})
    this.setProfile({
      name: attributes.name,
      provider: attributes.provider,
      inviter: attributes.inviter,
      isInternalUser: attributes.isInternalUser,
      RequestAccountDeletion: false
    })
  }
  
  recordPushNotificationReceived(attributes:any) {
    this.record(Events.PushNotificationReceived, attributes)
  }

  recordPushNotificationActionPerformed(attributes:any) {
    this.record(Events.PushNotificationActionPerformed, attributes)
    // Trigger event to allow home items containing route to update
    // this.pointsService.eventCreated$.next({ ...attributes, eventType: Events.PushNotificationActionPerformed })
  }

  recordStatsClicked(attributes:any) {
    this.record(Events.StatsClicked, attributes)
  }

  recordProgramStart(attributes: any) {
    this.record(Events.ProgramStart, attributes)
    this.setProfile({ currentProgram: attributes.program })
  }

  recordProgramEnd(attributes:any) {
    this.record(Events.ProgramEnd, attributes)
    this.createPointsEvent(Events.ProgramEnd, { id: attributes.program, complete: attributes.complete })
    this.setProfile({ currentProgram: 'undefined' })
  }

  recordProgramCompleted(attributes:any) {
    this.record(Events.ProgramCompleted, attributes)
    this.createPointsEvent(Events.ProgramCompleted, { id: attributes.program })
    this.setProfile({ currentProgram: 'undefined' })
  }

  recordPointsRedeemed(attributes:any) {
    this.record(Events.PointsRedeemed, attributes)
  }

  async recordSeedsPurchased(attributes:any) {
    this.record(Events.SeedsPurchased, attributes)    
  }
  
  async recordHomeLevelCompleted(attributes:any) {
    this.userService.putUserEvent({ ...attributes, action: Actions.LevelCompleted })
    this.record(Events.HomeLevelCompleted, attributes)
  }

  setHomeLevelUserProperty(level:number) {
    this.setProfile({ currentHomeLevel: level })
  }

  recordProgramStepComplete(attributes:any) {
    this.record(Events.ProgramStepComplete, attributes)
  }

  recordProgramStepClick(attributes:any) {
    this.record(Events.ProgramStepClick, attributes)
  }

  recordChatFeedback(attributes:any) {
    this.record(Events.ChatFeedback, attributes)
  }

  recordTabChange(tab:string) {
    if (this.userService.getCurrentUser()) {
      this.createPointsEvent(Events.TabChanged, { id: tab } )
      this.record(Events.TabChanged, { tab: tab })
    }
  }
  
  recordChallengeEvent(type: string, attributes:any) {
    if ([ Events.ChallengeCreate, Events.ChallengeJoin ].includes(type as Events)) {
      this.createPointsEvent(type as Events, attributes)
    }
    this.record(type, attributes)
    timer(1200).subscribe(() => this.pointsService.eventCreated$.next({ ...attributes, eventType: type }))
  }
  recordFilterChange(attributes:any) {
    this.createPointsEvent(Events.FilterChange, { id: attributes.type } )
    this.record(Events.FilterChange, attributes)
  }
  recordClick(attributes:any) {
    this.record(Events.Click, attributes)
  }
  recordCloudProgramEventTrigger(attributes:any) {
    this.record(Events.CloudProgramEventTrigger, attributes)
  }
  recordDeclineChatPay(attributes:any) {
    this.record(Events.DeclineChatPay, attributes)
  }
  recordReportApproval(attributes:any) {
    this.record(Events.ReportApprovalStateChanged, attributes)
  }
  recordJoinOfferGroup(attributes:any) {
    this.record(Events.JoinOfferGroup, attributes)
  }
}
