import { Inject, Injectable, inject } from '@angular/core';
import { orderBy } from 'firebase/firestore';
import { map, debounceTime, switchMap } from 'rxjs';
import { FirebaseService } from './firebase.service';
import { SharedUserService } from './shared-user.service';
import { 
  CONTINUE_RESPONSE_PROMPT, 
  CONTINUE_RESPONSE_PROMPT_JSON, 
  OpenAIResult, 
  Prompt, PromptSpec, 
  getPromptCost, 
  nowstr
} from '@cheaseed/node-utils';
import { where } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class PromptService {

  private userService = inject(SharedUserService)
  private firebase = inject(FirebaseService)
  // private environment:any = inject(new InjectionToken('environment'))

  promptSpecMap: Map<string, PromptSpec> = new Map()
  promptspecs$ = this.userService.userLoggedIn$
    .pipe(
      switchMap(() => this.firebase.collection$(`content/config/${this.environment.releaseTag}/web/promptSpecs`, orderBy('name', 'asc'))),
      debounceTime(1500),
      map(res => res.map((spec:any) => new PromptSpec(spec))),
      // tap((specs:PromptSpec[]) => console.log(`Loaded ${specs?.length} prompt specs`))
    )

  constructor(
    @Inject('environment') private environment: any,
  )
  { 
      this.promptspecs$
        .pipe(this.firebase.takeUntilAuth())
        .subscribe(specs => {
          console.log(`Reloaded ${specs?.length} prompt specs`)
          this.promptSpecMap = new Map(specs.map((spec:PromptSpec) => [spec.name, spec]))
      })
  }

  setSpec(promptName: string, attributes: any) {
    const spec = this.getPromptSpecNamed(promptName)
    if (spec) {
      const newSpec = new PromptSpec({ ...spec, ...attributes })
      this.promptSpecMap.set(promptName, newSpec)
      console.log(`Updated prompt spec ${promptName} to`, newSpec)
    }
  }

  getPromptsForThreadId(userId: string, threadId: string) {
    return this.firebase.collection$(`users/${userId}/prompts`, where('threadId', '==', threadId || 'unknown'))
      .pipe(
        map((prompts:any[]) => {
          prompts.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)
          return prompts as Prompt[]
      }))
  }

  getPromptsForChatId(userId: string, chatId: string) {
    return this.firebase.collection$(`users/${userId}/prompts`, 
      where('chatId', '==', chatId || 'unknown'))
        .pipe(
          map((prompts:any[]) => {
            prompts.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)
            return prompts as Prompt[]
        }))
  }

  getContinuePrompt(spec: PromptSpec) {
    return spec.isFormatJSON() ? CONTINUE_RESPONSE_PROMPT_JSON : CONTINUE_RESPONSE_PROMPT
  }

  getPromptSpecNamed(name: string) {
    return this.promptSpecMap.get(name)
  }

  appendJSON(content: string, next: string) {
    // Check stitching of JSON content for common GPT mistakes
    const lastChar = content[content.length - 1]
    const nextChar = next[0]
    const errChars = [ '"', '{', '}', '\'' ]
    if (errChars.includes(lastChar) && lastChar === nextChar) {
      console.log(`Omitting duplicate first char ${lastChar} of incoming JSON response`)
      return content + next.slice(1)
    }
    else
      return content + next
  }

  addSystemPrompt(
    userId: string, 
    chatId: string, 
    promptKey: string,
    threadId: string, 
    prompt: string) {
      const nowstring = nowstr()
      const data:Prompt = {
        chatId,
        createdAt: nowstring,
        userId,
        promptKey,
        threadId,
        prompt,
        system: true,
        role: 'system'
      }
      this.firebase.updateAt(`${this.userService.getUserPromptsPath(userId)}/${nowstring}`, data)
      return data
    }

  addPrompt(
    userId: string, 
    chatId: string,
    promptKey: string, 
    promptGroup: string | undefined,
    prompt: string, 
    threadId: string, 
    response: OpenAIResult) {
    const nowstring = nowstr()
    // Attempt to parse the content as JSON, otherwise leave as string
    let content = response.message.content
    try {
      content = JSON.parse(response.message.content)
    }
    catch (e) {
      // console.log("Response is not JSON")
    }
    const data:Prompt = {
      chatId: chatId,
      createdAt: nowstring,
      userId,
      threadId,
      promptKey,
      promptGroup,
      prompt,
      response: content,
      role: response.message.role,
      usage: response.usage,
      input_usage: response.input_usage,
      output_usage: response.output_usage,
      input_chars: prompt.length,
      output_chars: content.length,
      total_cost: getPromptCost(response.input_usage, response.output_usage, response.model as string),
      finish_reason: response.finish_reason,
      elapsedMsec: response.elapsedMsec,
      modelVersion: response.model,
      temperature: response.temperature,
      top_p: response.top_p,
    }
    this.firebase.updateAt(`${this.userService.getUserPromptsPath(userId)}/${nowstring}`, data)
    return data
  }

  updatePrompt(docId: string, userId: string, newData: any) {
    this.firebase.updateAt(`${this.userService.getUserPromptsPath(userId)}/${docId}`, newData)
  }

}
