import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core'
import { VideoRecorder, VideoRecorderCamera, VideoRecorderPreviewFrame, VideoRecorderOptions } from '@teamhive/capacitor-video-recorder'
import { Directory, Filesystem } from '@capacitor/filesystem'
import { BehaviorSubject } from 'rxjs'
import { Platform } from '@ionic/angular'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { FirebaseService } from './firebase.service';
import { ref, UploadTask, uploadBytesResumable, percentage, getStorage } from '@angular/fire/storage'

@Injectable({
  providedIn: 'root'
})
export class VideoService {

  constructor(
    private platform: Platform,
    private firebase: FirebaseService,
    private sanitizer: DomSanitizer) {}

  // Observables
  private lastVideoSubject = new BehaviorSubject<any>(null)
  public lastVideo$ = this.lastVideoSubject.asObservable()
  private saveVideoSubject = new BehaviorSubject<any>(null)
  public saveVideo$ = this.saveVideoSubject.asObservable()

  getFixedConfig() {
    const w = 375, h = 400
    const config : VideoRecorderPreviewFrame = {
      id: 'video-record',
      stackPosition: 'front', // 'front' overlays your app', 'back' places behind your app.
      width: w, // 'fill'
      height: h, // 'fill'
      x: (this.platform.width() - w) / 2,
      y: (this.platform.height() - h) / 2, // 200,
      borderRadius: 4
    }
    return config
  }

  async initializeFixedConfig() {
    const config = this.getFixedConfig()
    const init: VideoRecorderOptions = {
      camera: VideoRecorderCamera.FRONT, // Can use BACK
      previewFrames: [config]
    }
    console.log("About to initialize the video recorder plugin")
    const result = await VideoRecorder.initialize(init)
    console.log(`Video recorder init returned ${JSON.stringify(result)}`)
    //VideoRecorder.addListener('onVolumeInput', (value) => {console.log(`In onVolumeInput parameter = ${JSON.stringify(value)}`)})
    return result
  }

  destroy() {
    return VideoRecorder.destroy()
  }

  startRecording() {
    VideoRecorder.startRecording()
  }

  async stopRecording() {
    const res = await VideoRecorder.stopRecording();
    // The video url is the local file path location of the video output.
    console.log("stopRecording", JSON.stringify(res))

    // Convert to fetchable url
    let url:string|null = Capacitor.convertFileSrc(res.videoUrl),
        ext:string|undefined = 'mp4', 
        base64Data = ""
    if (url === 'some/file/path') 
      url = null
    else if (url) {
      const response = await fetch(url)
      const blob = await response.blob()
      console.log("blob", blob.size, blob.type)
      base64Data = await this.convertBlobToBase64(blob) as string
      console.log("base64Data", base64Data.slice(1,100))
      ext = url?.split('.').pop()
    }
    // Write the file to the data directory so we can play it back
    const fileName = `${Date.now()}.${ext}`
    const result = await Filesystem.writeFile({
      data: base64Data,
      directory: Directory.Data,
      path: fileName
    })
    console.log("WriteFile returned", JSON.stringify(result))
    
    this.lastVideoSubject.next({ type: ext, fetchableUrl: url, file: fileName })
  }

  async saveVideo() {
    const last = this.lastVideoSubject.getValue()
    console.log("saveVideo", JSON.stringify(last))
    this.saveVideoSubject.next(last)
    this.lastVideoSubject.next(null)
  }

    // const base64Data = await this.readAsBase64(res.videoUrl);
    // // Write the file to the data directory
    // const fileName = new Date().getTime() + '.mp4';
    // console.log({fileName})
    // await Filesystem.writeFile({
    //   path: fileName,
    //   data: base64Data,
    //   directory: FilesystemDirectory.Data
    // })
    // let videoPath = Capacitor.convertFileSrc(fileName);
    // console.log({videoPath})

    // capacitor://localhost/_capacitor_file_/private/var/mobile/Containers/Data/Application/A018D02D-C48F-4652-9588-5D00BD84261A/tmp/0BE4FE31-6626-4CCC-AECD-1D2B158ADAE8.mp4
    // file:///var/mobile/Containers/Data/Application/A018D02D-C48F-4652-9588-5D00BD84261A/tmp/0BE4FE31-6626-4CCC-AECD-1D2B158ADAE8.mp4

    // capacitor://localhost/_capacitor_file_/var/mobile/Containers/Data/Application/A018D02D-C48F-4652-9588-5D00BD84261A/Documents/1582270536420.jpeg
    // file:///var/mobile/Containers/Data/Application/A018D02D-C48F-4652-9588-5D00BD84261A/Documents/1582266562236.jpeg

    // let fileUrl = res.videoUrl.replace('capacitor://localhost/_capacitor_file_/private/', 'file:///');
    // }

  getLastFileUrl() {
    const last = this.lastVideoSubject.getValue()
    return last?.fetchableUrl.replace('capacitor://localhost/_capacitor_file_/private', 'file://')
  }

  getLastFetchableUrl() {
    const last = this.lastVideoSubject.getValue()
    return last?.fetchableUrl
  }

  async playRecording() {
    console.log("playRecording pressed")
  }

  private async readAsBase64(webPath) {
      // Fetch the photo, read as a blob, then convert to base64 format
      console.log("readAsBase64 fetching from ", webPath)
      const response = await fetch(webPath!)
      const blob = await response.blob()
      return await this.convertBlobToBase64(blob) as string
  }

  convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader
    reader.onerror = reject
    reader.onload = () => {
        resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })

   // Retrieve the video from local capacitor scheme url (Careful of CORS)
   async getSanitizedBlobUrl(url: string) {
    if (url) {
      if (url.includes("capacitor")) {
        console.log("getSanitizedBlobUrl", url)
        const response = await fetch(url)
        const blob = await response.blob()
        console.log("blob", blob.size, blob.type)
        // Create an object url from the blob;
        const blobUrl = URL.createObjectURL(blob)        
        console.log("blobURL = " + {blobUrl})
        // Create a safe url and set it to the video source.
        const cleanUrl:SafeUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl)
        console.log({ cleanUrl })
        return cleanUrl
      }
      else 
        return this.sanitizer.bypassSecurityTrustUrl(url);
      }
    else {
        return null // this.sanitizer.bypassSecurityTrustUrl("https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4")
    }
  }

}
