import { Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SocialService {
  private publicHost = '';
  private defaultImage = '';

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private http: HttpClient
  ) {}

  initialize(host: string) {
    this.publicHost = host;
    this.defaultImage = host + '/assets/img/cheaCwhiteSplash250px.jpg';
  }

  addTags(url:string|null = null) {
    this.titleService.setTitle('chea seed');
    const tags: MetaDefinition[] = [
      { property: 'og:title', content: 'Try free' },
      { property: 'og:description', content: "we're here to help" },
      { property: 'og:url', content: this.publicHost },
      { property: 'og:site_name', content: this.publicHost },
      { property: 'og:image', content: url || this.defaultImage },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:site', content: '@cheaseed' },
      // <meta name="apple-itunes-app" content="app-id=myAppStoreID, app-argument=myURL">
      // chea seed app-id: 1501912850
      { property: 'apple-itunes-app', content: 'app-id=1501912850' },
    ];
    this.metaService.addTags(tags);
    // console.log("addTags", tags)
  }

  // addSmartAppBanner() {
  //   const tags: MetaDefinition[] = [
  //     // <meta name="apple-itunes-app" content="app-id=myAppStoreID, app-argument=myURL">
  //     // chea seed app-id: 1501912850
  //     { property: 'apple-itunes-app', content: 'app-id=1501912850' },
  //   ];
  //   this.metaService.addTags(tags);
  // }

  // getValidTiles() {
  //     return this.http.get(TILES_LIST, {responseType: 'text'});
  // }

  getImageUrl(id: string): Observable<any> {
    if (id.endsWith('takeaway')) {
      const url = `/assets/tiles/${id}.png`;
      return this.http.head(url).pipe(
        tap((res) => console.log('fetched url', res)),
        catchError(this.handleError<any>('getImageUrl', null))
      );
    } else return of(null);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
