import { Injectable } from '@angular/core';
import { SpeechRecognition } from '@capacitor-community/speech-recognition';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class SpeechService {

  private speechResults: string[] | null = null

  constructor(
    private platform: Platform 
  ) { }

  async startSpeechRecording() {
    if (!this.platform.is('capacitor')) {
      console.log('SpeechRecognition: Only supported on mobile devices')
      return
      //await this.utilityService.notify({ header: 'Not supported', message: 'Speech Recognition is supported on mobile devices' })
    }
    if ((await SpeechRecognition.available()).available) {
      if (!(await SpeechRecognition.checkPermissions()))
        await SpeechRecognition.requestPermissions()
      // listen to partial results in iOS - partial results dont work on Android
      const isIOS = this.platform.is("ios")
      if (isIOS) {
        SpeechRecognition.addListener("partialResults", async (res: any) => {
          console.log("partialResults was fired", res.matches)
          this.speechResults = res.matches
          console.log("this.speechResults", this.speechResults)
          //await this.utilityService.notify({header: 'Translated Text', message: data.matches})
        })
      }
      // Throws error for "results"
      // else {
      //   SpeechRecognition.addListener("results", async (res: any) => {
      //     console.log("results was fired", res.matches)
      //     this.speechResults = res.matches
      //     //await this.utilityService.notify({header: 'Translated Text', message: data.matches})
      //   });
      // }

      try { // dont resolve the promise - throws causes a "client side error" though speech recognition starts
        SpeechRecognition.start({
          language: "en-US",
          maxResults: 2,
          prompt: "Say something",
          partialResults: isIOS,
          popup: isIOS
        })
      }
      catch (error) {
        // Needed for android - first attempt throws an error
        console.log('Error while starting speech recognition; will retry,', error)
        SpeechRecognition.start({
          language: "en-US",
          maxResults: 2,
          prompt: "Say something",
          partialResults: isIOS,
          popup: isIOS
        })
      }
    }
  }

  async stopSpeechRecording() {
    if (this.platform.is('capacitor')) {
      console.log("Stopping speech recording", this.speechResults)
      try {
        SpeechRecognition.stop()
      }
      catch (e) {
        console.log('Error while stopping speech recognition', e)
        console.log('this.speechResults', this.speechResults)
      }
      await SpeechRecognition.removeAllListeners()      
    }
    return this.speechResults
  }

}
