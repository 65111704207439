import { BEHAVIOR_COLLECTIBLE, BEHAVIOR_FAVORITABLE, BEHAVIOR_READ_ONLY_REPORT_TEMPLATE } from "./constants";

export class Conversation {
  name: string;
  sequence?: string;
  type?: string;
  subtitle?: string;
  title: string;
  summary?: string;
  completionActions: CompletionAction[];
  behaviors?: string[];
  seedCost?: number;
  seedType?: string;
  estimatedTime?: string;
  persona?: { name: string };
  onHold?: boolean;
  topics?: Set<any>;
  series?: Set<any>;
  statements?: any[];
  globalTags?: { name: string }[];

  constructor(args: any) {
    Object.assign(this, args)
    this.topics = new Set()
    this.series = new Set()
    this.statements = []
    this.title = this.title || ''
  }

  hasBehavior(behavior: string): boolean {
    return !!this.behaviors?.includes(behavior)
  }

}

export interface CompletionAction {
  name?: string;
  message?: string;
  actionType?: string;
  routeActionId?: string;
  entryActionId?: EntryActionId;
  chatActionId?: ChatActionId;
}

export interface EntryActionId {
  name: string;
}

export interface ChatActionId {
  name: string;
}

export interface Statement {
  name?: string;
  conversation?: Conversation;
  order?: number;
  statementName?: string;
  textBlock?: string;
  queueActions: Conversation[];
  primaryEvidenceSources: EvidenceSource[];
  secondaryEvidenceSources: EvidenceSource[];
  contraryEvidenceSources: EvidenceSource[];
  opinionSources: EvidenceSource[];
  exampleSources: EvidenceSource[];
  productSources: EvidenceSource[];
  classSources: any[];
  tipSources: any[];
  enableIf?: string;
  attributeSpec?: AttributeSpec;
  behaviors?: string[];
  onCompletion?: string;
  onHold?: boolean;
  videoURL?: string;
}

export interface EvidenceSource {
  sourceId?: string;
  sourceType?: string;
  title?: string;
  url?: string;
}

export interface Path {
  name?: string;
  conversationLinks?: Conversation[];
  nextSeries?: Path;
  title?: string;
  topic?: Topic[];
  type?: string;
  code?: string;
  description?: string;
  behaviors?: string[];
}

export interface Topic {
  name?: string;
  color?: string;
  altColor?: string;
  path?: Path[];
}

export interface Sequence {
  name?: string;
  type: string;
  order: number;
  conversation?: Conversation;
  series?: Path;
}

export interface Carousel {
  name?: string;
  order?: number;
  orientation?: string;
  paths?: Path[];
  view?: string;
  programs?: Path[];
}

export class ProgramSpec {
  name: string;
  description: string;
  steps: Step[];
  behaviors: string[];
  order: number;
  title: string;
  programCategory: any;
  resources: any[];

  constructor(args: any) {
    Object.assign(this, args)
  }

  isHidden(): boolean {
    return this.behaviors?.includes('Hidden')
  }

  getStep(stepname: string): Step {
    return this.steps.find(s => s.name === stepname)
  }
}
export interface Step {
  name: string;
  order?: number;
  title?: string;
  description?: string;
  behaviors?: string[];
  conversation?: Conversation;
  series?: Path;
  entrySpec?: Conversation;
  displayQuickAdd?: boolean;
  defaultEntryAttributes?: string,
  route?: string,
  expanded?: boolean;
}

export class ProgramCategory {
  name: string;
  order: number;
  programs: ProgramSpec[];

  constructor(args: any) {
    Object.assign(this, args)
  }
}
export interface AttributeSpec {
  name?: string;
  attributeName?: string;
  entrySpec?: EntrySpec;
  inputBlockName?: string;
  inputType?: string;
  order?: number;
  question?: string;
  optionLinks?: OptionLink[];
  behaviors?: string[];
  inputSubtype?: string;
  isRequired?: boolean;
  shortQuestion?: string;
  isFeedback?: boolean;
  captureIf?: string;
  placeholder?: string;
}

export interface OptionLink {
  name?: string;
  description?: string;
  behaviors?: string[];
  enableIf?: string;
  messageIfSelected?: string;
}
export class EntrySpec {
  name: string;
  id?: string;
  actionLinks?: ActionLink[];
  baseType?: { name: string };
  behaviors?: string[];
  onSave?: string;
  reportTemplate?: string;
  attributeSpecs?: any[];
  seedCost?: number;
  instructions?: string;
  guideChat?: string;
  backgroundColor: string;

  constructor(args: any) {
    Object.assign(this, args)
  }

  isCollectible(): boolean {
    return this.hasBehavior(BEHAVIOR_COLLECTIBLE)
  }

  isFavoritable(): boolean {
    return this.hasBehavior(BEHAVIOR_FAVORITABLE)
  }

  isReadOnlyReport(): boolean {
    return this.hasBehavior(BEHAVIOR_READ_ONLY_REPORT_TEMPLATE)
  }

  hasBehavior(behavior: string): boolean {
    return this.behaviors?.includes(behavior)
  }
}
export interface FeedbackSpec {
  name?: string;
  description?: string;
  messageFragment?: string;
  entrySpecs?: ActionEntrySpec;
  feedbackAttributes?: FeedbackAttribute[];
}

export interface FeedbackAttribute {
  attributeName?: string;
}

export interface ActionLink {
  name: string;
  description: string;
  actionEntrySpec: ActionEntrySpec;
  icon?: string;
}

export interface ActionEntrySpec {
  name?: string;
}

export interface PricingSpec {
  name: string;
  title: string;
  description: string;
  order: number;
  price: number;
  seedCredits: any;
  specialPrice?: number;
  startDate?: string;
  endDate?: string;
  behaviors?: string[];
}