export class ChallengeSpec {
  name: string;
  title?: string;
  shortName?: string;
  description?: string;
  instructions?: string;
  behaviors?: string[];
  period: string;
  durationOptions: any[];
  goalOptions: any[];
  entrySpec?: { name: string };
  dayOfNotification?: string;
  timeOfNotification?: string;
  startDate: string;
  endDate: string;

  constructor(args: any) {
    Object.assign(this, args)
    if (!this.title)
      this.title = this.name + ' Challenge'
  }
}
export class Challenge {
  docId?: string;       // firestore doc id
  userId: string;       // email of user in challenge
  ownerDocId?: string;  // docId of original challenge
  owner: boolean;       // true if user created challenge vs joined
  public?: boolean;
  name: string;
  title: string;
  shortName?: string;
  description: string;
  nickname?: string;
  ownerNickname?: string; // nickname of ownerDocId
  entryType: string;
  period: string;
  duration?: string;
  goal?: string;
  createdAt: string;
  startDate?: string;
  projectedEndAt?: string;
  completedAt?: string;
  abortedAt?: string|null;
  invites?: string[];
  count?: number;
  lastRank?: number;            // last rank of user in challenge
  lastNumParticipants?: number; // last challenge size

  constructor(args: any) {
    Object.assign(this, args)
  }
}
