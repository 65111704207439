import { Injectable, inject } from '@angular/core'
import { Platform } from '@ionic/angular'
import { firstValueFrom, timer } from 'rxjs'
import { FirebaseService } from './firebase.service'
import { SharedUserService } from './shared-user.service'
import {
  ProductPricingOffer,
  nowstr,
  StoreTransaction,
  STRIPE_TRANSACTION_IDENTIFIER,
  PricingSpec,
  STRIPE_IN_PROGRESS_TRANSACTION_IDENTIFIER,
  Receipt,
  Consumable
} from '@cheaseed/node-utils'
import { CustomerInfo } from '@awesome-cordova-plugins/purchases/ngx'
import { PaymentIntent } from '@stripe/stripe-js'

// The Seed Service manages the purchase and depletion of seeds, as well as authorization

@Injectable({
  providedIn: 'root'
})
export class PurchaseUtilitiesService {

  userService = inject(SharedUserService)
  firebase = inject(FirebaseService)
  platform = inject(Platform)

  createConsumable(quantity: number, purchased: boolean, purchaseSource: any) {
    const createDate = new Date()
    return new Consumable({
        quantity,
        createDate,
        purchased,
        purchaseSource
    })
  }
  createStripePortalConsumable(item: PricingSpec | ProductPricingOffer, receipt?: Receipt) {
    if(!receipt)
        receipt = this.getDefaultStripePortalReceipt(this.getAppStoreName(), item.name as string, STRIPE_IN_PROGRESS_TRANSACTION_IDENTIFIER, item.specialPrice || item.price as number)
    const createDate = new Date()
    return new Consumable({
        seedCredits: item.seedCredits,
        createDate,
        purchased: false,
        purchaseSource: receipt,
        isStripePortalConsumable: true
    })
  }

  async addConsumable(consumable: Consumable, docId: string | null) {
    if (!docId)
      docId = this.firebase.generateDocID()
    consumable.docId = docId
    await this.firebase.updateAt(this.userService.getUserConsumablePath(docId), { ...consumable })
    return docId
  }

  async getConsumableDocument(item: PricingSpec) {
    const c = this.createStripePortalConsumable(item, undefined)
    const docId = this.firebase.generateDocID()
    c.docId = docId
    const path = this.userService.getUserConsumablePath(docId)
    await this.firebase.updateAt(path, { ...c })
    console.log('created document', path)
    return docId
  }
  async removeConsumable(docId: string) {
    return await this.firebase.delete(this.userService.getUserConsumablePath(docId))
  }


  async introduceNavigationDelay() {
    // introduce a delay greater than the debounce time for firestore updates 
    // (currently 1000) to work around timing issues
    await firstValueFrom(timer(1600))
  }

  getAppStoreName() {
    return this.platform.is("capacitor") ?
       this.platform.is('android') ?
        'PLAY_STORE' : 'APP_STORE'
      : 'STRIPE'
  }

  getReceiptFrom(cInfo: CustomerInfo): Receipt {
    const t = cInfo.nonSubscriptionTransactions
    return new Receipt(t[t.length - 1], this.getAppStoreName())
  }

  //TODO - implement invoice creation in Stripe
  // and get the actual invoice - for now, generate
  // a dummy receipt
  getStripePortalReceiptFrom(offer: ProductPricingOffer, paymentIntent: PaymentIntent): Receipt {
    
    const t: StoreTransaction = { 
      transactionIdentifier: STRIPE_TRANSACTION_IDENTIFIER,
      purchaseDate: new Date(paymentIntent.created).toISOString(),
      productIdentifier: offer.code,
      price: paymentIntent.amount

    }
    return new Receipt(t, this.getAppStoreName())
  }

  
  getDefaultReceipt(storeName: string, productId: string, transactionId: string): Receipt {
    return new Receipt(
      {
        productIdentifier: productId,
        purchaseDate: nowstr(),
        transactionIdentifier: transactionId
      },
      storeName
    )
  }

  getDefaultStripePortalReceipt(storeName: string, productId: string, transactionId: string, price: number): Receipt {
    return new Receipt(
      {
        productIdentifier: productId,
        purchaseDate: nowstr(),
        transactionIdentifier: transactionId,
        price
      },
      storeName
    )
  }
  
  
}
