import { Inject, Injectable } from '@angular/core'
import { BehaviorSubject, debounceTime, firstValueFrom, from, map, mergeMap, Observable, of, shareReplay, switchMap, tap } from 'rxjs'
import { COLLECTION_ENTRIES_ATTRIBUTE_NAME, ENTRYSHARE_MSG, Entry, articlize, formatDate, nowstr, subtractstr } from '@cheaseed/node-utils'
import { deleteDoc, orderBy, QueryDocumentSnapshot, SnapshotOptions, where } from '@angular/fire/firestore'
import { getDoc } from '@firebase/firestore'
import { ContentService } from './content.service'
import { FirebaseService } from './firebase.service'
import { SharedEventService, Events } from './shared-event.service'
import { SharedUserService } from './shared-user.service'
import { Browser } from '@capacitor/browser'
import { Platform } from '@ionic/angular'
import { MediaService } from './media.service'
import { AuthService } from './auth.service'
import { SeedService } from './seed.service'
import { OffersService } from './offers.service'

const ENTRY_FEED_KEY = "feed.entryFeed.lastEntryId"
export const REMINDME_ID = 'remindMe'
export const NOTNOW_ID = 'notNow'

const entryConverter = {
  toFirestore(entry: Entry) {
    return entry
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Entry {
    const data = snapshot.data(options);
    return new Entry(data)
  }
};

export enum EntryState {
  Idle = "IDLE",
  Opened = "OPENED",
  Saved = "SAVED",
  Aborted = "ABORTED"
}

export interface RecentHistoryState {
  entries: Entry[]
  entriesMap: Map<string, Entry>
  favoritesMap: Map<string, Entry>
  entryCounts: Record<string, number>
  childCounts: Record<string, Record<string, number>>
  collectionCounts: Record<string, number>
}

@Injectable({
  providedIn: 'root'
})
export class EntryService {

  private cachedReadEntries: Record<string, Entry> = {}
  public entryCountsSubject = new BehaviorSubject<Record<string, number>>({})
  public entryCounts$ = this.entryCountsSubject.asObservable()
  public childCountsSubject = new BehaviorSubject<Record<string, Record<string, number>>>({})
  public childCounts$ = this.childCountsSubject.asObservable()
  public collectionCountsSubject = new BehaviorSubject<Record<string, number>>({})
  public collectionCounts$ = this.collectionCountsSubject.asObservable()
  private recentHistorySubject = new BehaviorSubject<Entry[] | null>(null)
  public recentHistory$ = this.recentHistorySubject.asObservable()
  // Entry feed state, stores the most recent entry doc, resets when feed is clicked
  private entryFeedStateSubject = new BehaviorSubject<Entry | null>(null)
  public entryFeedState$ = this.entryFeedStateSubject.asObservable()
  public addableEntryMap$ = new BehaviorSubject<Map<string, string>>(new Map())
  public currentAddableEntryMapId$ = new BehaviorSubject<string | null>(null)
  favorites$ = new BehaviorSubject<Map<string, Entry> | null>(null)
  deletedEntry$ = new BehaviorSubject<string>("")
  lastEntryUpdated$ = new BehaviorSubject<string>("")

  constructor(
    @Inject('environment') private environment: any,  // Injected in app.module
    private platform: Platform,
    private userService: SharedUserService,
    private firebase: FirebaseService,
    private contentService: ContentService,
    private mediaService: MediaService,
    private auth: AuthService,
    private eventService: SharedEventService,
    private offersService: OffersService,
    private seedService: SeedService) { }

  getUserEntries(id: string | null = null) {
    return this.firebase.collection$(this.userService.getUserEntriesPath(id),
      orderBy('updatedAt', 'desc'))
      .pipe(
        map(data => data.map(d => new Entry(d))),
        shareReplay(1))
  }

  getUserEntryDoc(docId: string): Observable<Entry> {
    const path = `${this.userService.getUserEntriesPath()}/${docId}`
    return this.firebase.docWithConverter$(path, entryConverter)
      .pipe(shareReplay(1))
  }

  getUserEntryDoc$(userId: string, entryId: string) {
    const path = this.userService.getUserEntriesPath(userId)
    return (userId && entryId)
      ? this.firebase.doc$(`${path}/${entryId}`)
        .pipe(
          map(d => new Entry(d)),
          // tap(entry => console.log("getUserEntryDoc$", entryId, entry)),
          tap(entry => { if (entry) this.cacheEntry(entry) }))
      : of(null)
  }


  // Get the entry document after retrieving all referenced documents so that their names are loaded
  // Note: Couldn't get this to work with concatMap, had to use mergeMap and debounceTime
  getUserEntryDocAndReferences$(userId: string, entryId: string) {
    return this.getUserEntryDoc$(userId, entryId)
      .pipe(
        switchMap(entry => {
          const ids = this.getEntryDocReferences(userId, entry)
          return ids.length > 0
            ? from(ids).pipe(mergeMap(id => this.getUserEntryDoc$(userId, id)), debounceTime(200))
            : of(entry)
        }),
        map(() => this.cachedReadEntries[entryId]))
  }

  getEntryDocReferences(userId: string, entry: Entry | null) {
    let ids: string[] = []
    if (entry) {
      const entryType = entry.indexType()
      // Collect all the document references in the entry attributes
      for (const [key, value] of Object.entries(entry.attributes)) {
        const attr = `${entryType}.${key}`
        const spec = this.contentService.getAttributeSpec(attr)
        if (value && spec?.inputType === 'ENTRYSELECTOR') {
          const val: string[] = (typeof value === 'string' ? JSON.parse(value as string) : value) as string[]
          ids = [...ids, ...val].filter(id => id !== REMINDME_ID && id !== NOTNOW_ID)
        }
      }
    }
    return ids
  }

  getUserEntriesForParent(userId: string, parentId: string) {
    return this.firebase.collection$(this.userService.getUserEntriesPath(userId),
      where('parentEntryId', '==', parentId))
      .pipe(
        map(array => array.map(d => new Entry(d)))
      )
  }

  getUserEntriesForChatId(type: string, chatId: string) {
    return this.firebase.collection$(this.userService.getUserEntriesPath(),
        // where('attributes.chatId', '==', chatId),  // see if this works
        where('type', '==', type))
      .pipe(
        debounceTime(500),
        map(array => array.map(d => new Entry(d)))
      )
  }

  getUserEntriesForGoal(userId: string, goalId: string) {
    const path = this.userService.getUserEntriesPath(userId)
    return this.firebase.doc$(`${path}/${goalId}`)
      .pipe(
        map(d => new Entry(d)),
        switchMap((entry:Entry) => {
          return entry.isGoalType() ?
            this.firebase.collection$(path, where('attributes.goal', '==', goalId)) :
            of([])
        })
      )
  }

  getCollectionEntries(userId: string, docId: string) {
    const path = this.userService.getUserEntriesPath(userId)
    return this.firebase.doc$(`${path}/${docId}`)
      .pipe(
        map(d =>  new Entry(d)),
        switchMap((doc:Entry) => {
          // console.log("getCollectionEntries", doc)
          return doc.isCollectionType() ?
            this.firebase.collection$(path, orderBy('updatedAt', 'desc'))
              .pipe(
                map(array => array.map( d => new Entry(d) )),
                map((array: Entry[]) => array.filter(item => doc.attributes.entries.includes(item.docId)))) :
            of([])
        })
      )
  }

  setRecentHistory() {
    // Called for web 
    this.recentHistorySubject.next([])
  }

  reinitialize() {
    this.getUserEntries()
      .pipe(
        // debounceTime(600),
        this.auth.takeUntilAuth())
      .subscribe(data => {
        // console.log("received update for entries", data)

        // Count entry types and cache ids
        const cache: Record<string, Entry> = {}
        const cnts: Record<string, number> = {}
        for (const item of data) {
          const key = item.indexType()
          cnts[key] = cnts[key] ? cnts[key] + 1 : 1
          cache[item.docId] = item
        }
        this.cachedReadEntries = cache

        // Count the number of collections that each entry is contained in
        const collectionCounts: Record<string, number> = {}
        data
          .filter((item: Entry) => item.isCollectionType())
          .forEach((entry: Entry) => {
            for (const child of entry.attributes?.entries || [])
              collectionCounts[child] = (collectionCounts[child] || 0) + 1
          })

        // Count children for each entry
        const childCounts: Record<string, Record<string, number>> = {}
        const children = data.filter((item: Entry) => item.parentEntryId)
        for (const child of children) {
          const parent = child.parentEntryId
          if (!childCounts[parent])
            childCounts[parent] = {}
          const key = child.indexType()
          childCounts[parent][key] = (childCounts[parent][key] || 0) + 1
        }

        this.entryCountsSubject.next(cnts)
        this.childCountsSubject.next(childCounts)
        this.collectionCountsSubject.next(collectionCounts)
        this.recentHistorySubject.next(data)
        // Set entry feed state to most recent entry id, if same as feed userkey
        const mostRecentEntry = data[0]
        if (mostRecentEntry?.docId === this.userService.getUserKey(ENTRY_FEED_KEY))
          this.entryFeedStateSubject.next(mostRecentEntry)
        this.loadFavorites()
      })
  }

  unsubscribe() {
    this.recentHistorySubject.next(null)
  }

  getLastEntry(): Entry | null {
    const history = this.recentHistorySubject.getValue()
    return history ? history[0] : null
  }

  getEntryById(id: string) {
    return this.cachedReadEntries[id]
  }

  cacheEntry(entry: Entry) {
    this.cachedReadEntries[entry.docId] = entry
    // console.log("Cached entry", entry.docId, this.cachedReadEntries)
  }

  getEntriesByIds(ids: string[]) {
    return ids
      .map(id => this.getEntryById(id))
      .filter(entry => !!entry)
  }

  remindMeEntry() {
    return new Entry({
      docId: REMINDME_ID,
      displayName: this.contentService.getGlobal(`${REMINDME_ID}.message`) || 'Remind me later'
    })
  }

  notNowEntry() {
    return new Entry({
      docId: NOTNOW_ID,
      displayName: this.contentService.getGlobal(`${NOTNOW_ID}.message`) || 'Not now'
    })
  }

  getEntryForId(id: string) {
    return id === REMINDME_ID ? this.remindMeEntry() :
      id === NOTNOW_ID ? this.notNowEntry() :
        this.getEntryById(id)
  }

  getDisplayNameForId(id: string): string {
    const entry = this.getEntryForId(id)
    return entry?.displayName as string
  }

  getEntriesOfTypes(types: string[]) {
    const data = this.recentHistorySubject.getValue()
    return data?.filter(entry => types.includes(entry.indexType() as string))
  }

  getEntriesOfType(type: string | null = null) {
    return (type ?
      this.getEntriesOfTypes([type]) :
      this.recentHistorySubject.getValue()) || []
  }

  async updateChildDisplayName(entry: Entry, displayName: string) {
    if (entry.attributes.name && entry.displayName !== displayName) {
      console.log("updateChildDisplayName", entry, displayName)
      entry.attributes.name = displayName
      await this.updateEntryLite(entry, false)
    }
  }

  async createEntryLite(type: string, subtype: string | null, attributes: any = {}) {
    const displayName = attributes.name,
      now = nowstr()
    const entry = new Entry({
      createdAt: now,
      updatedAt: now,
      type: type,
      subtype: subtype,
      displayName: displayName,
      attributes: attributes
    })
    const ref = this.userService.getUserEntriesCollectionRef()
    const entryId = await this.firebase.setData(ref, { ...entry })
    // Update entry feed state
    // this.setEntryFeedState(entry, entryId)
    // console.log("createEntryLite", entryId, entry)
    entry.docId = entryId
    this.eventService.recordEntry(Events.EntryCreate, { ...entry }, null)
    this.offersService.processOffer(Events.EntryCreate, type)
    return entry
  }

  isInMemoryEntry(entry: Entry) {
    return entry.createdAt === entry.updatedAt
  }

  createInMemoryEntry(type: string, subtype: string|null, attributes: any = {}) {
    const displayName = attributes['name'],
      now = nowstr()
    const data: any = {
      createdAt: now,
      updatedAt: now,
      type: type,
      subtype: subtype,
      displayName: displayName,
      attributes: attributes,
      docId: null
    }
    data.docId = this.firebase.generateDocID()  // await this.firebase.setData(ref, data)
    // console.log("createInMemoryEntry", data)
    return data
  }

  async removeEntryFromCollection(entry: Entry, subentry: Entry) {
    const subentries: string[] = entry.attributes.entries || []
    const newlist = subentries.filter(item => item !== subentry.docId)
    entry.setAttributeNamed(COLLECTION_ENTRIES_ATTRIBUTE_NAME, newlist)
    await this.updateEntryLite(entry)
  }

  isChildInCollection(coll: Entry, childId: string) {
    // console.log("checking whether child in collection", childId, coll.attributes.entries)
    return coll.attributes.entries?.includes(childId)
  }

  async updateEntryLite(entry: Entry, recordFlag = true, startTime = null) {
    const data = new Entry(entry)
    const docId = data.docId
    // remove null attributes and replace empty space with null (convention for clearing existing value)
    data.attributes = Object.fromEntries(Object.entries(entry.attributes).filter(([_, v]) => v != null).map(([k, v]) => [k, v === ' ' ? null : v]))
    data.updatedAt = nowstr()
    data.displayName = data.attributes.name

    // Check if doc exists before we update, so we can record the proper event
    const ref = this.userService.getUserEntryDocRef(docId)
    const docExists = (await getDoc(ref)).exists()

    // Update entry doc in Firestore
    await this.userService.updateUserEntryDoc(docId, { ...data })
    this.lastEntryUpdated$.next(docId)

    // Update entry feed state
    // this.setEntryFeedState(data, docId)

    // Record event
    const duration = startTime ? Date.now() - startTime : null
    if (recordFlag) {
      const event = docExists ? Events.EntryUpdate : Events.EntryCreate
      this.eventService.recordEntry(event, data, duration)
      this.offersService.processOffer(event, data.type)
    }

    // Record in addableEntryMap
    const mapId = this.currentAddableEntryMapId$.getValue()
    if (mapId)
      this.updateAddableEntry(mapId, docId)

    if (this.contentService.isSeedsEnabled()) {
      if (!docExists && recordFlag) {
        // decrement seed count
        const cost = this.getEntrySeedCost(data.indexType() as string)
        const seeds = await this.seedService.decrementSeedBalance(cost)
        console.log('Decremented seed count by ', seeds)

        this.eventService.recordSeedsConsumedByEntryEvent({
          seedsUsed: seeds,
          docId: docId
        })
      }
    }
    return data
  }

  getEntrySeedCost(entrySpecId: string): number {
    return this.contentService.getEntrySpec(entrySpecId)?.seedCost || this.contentService.getGlobal('default.seedCost.entry') || 0
  }

  async deleteEntryLites() {
    const entries = this.recentHistorySubject.getValue() || []
    for (const e of entries) {
      await this.deleteEntryLite(e)
    }
    console.log("Cleared all EntryLite records")
  }

  async deleteEntryLite(entry: any) {
    const docId = entry.docId
    const result = await this.userService.getUserEntryDoc(docId)
    if (!result) {
      console.log(`No entry found while trying to delete entry with id ${docId}`)
      return null
    }
    console.log("deleteEntryLite", docId)
    const ref = this.userService.getUserEntryDocRef(docId)
    try {
      await deleteDoc(ref)
      this.deletedEntry$.next(docId)
    }
    catch (error) {
      console.log(`Error deleting entry with id ${docId}`, error)
    }
    await this.deleteChildEntries(docId)
    // this.deleteMediaAttachments(result)   // TODO: needs more testing
    this.removeFromFavorites(result)
    this.eventService.recordEntryDeleted(result)
    return result
  }

  async deleteMediaAttachments(entry: Entry) {
    const attrSpecs = this.contentService.getEntrySpecAttributes(entry.indexType() as string)
    const media = attrSpecs?.filter((a: any) => a.inputType === 'VIDEOCAPTURE' && entry.getAttributeNamed(a.attributeName))
    if (media && media.length > 0) {
      for (const attr of media) {
        const file = entry.getAttributeNamed(attr.attributeName)
        if (file)
          await this.firebase.deleteVideoUrl(file)
      }
    }
  }

  async resetEntry(id: string | undefined) {
    const entry = await this.userService.getUserEntryDoc(id as string) as Entry
    if (!entry) {
      return null
    }
    await this.resetChildEntries(entry)
    this.removeFromFavorites(entry)
    return entry
  }

  async resetChildEntries(parent: Entry) {
    const children: Entry[] = this.findActionsForEntry(parent.docId)
    for (const child of children) {
      console.log('About to reset child entry in resetChildEntries entryservice.ts')
      await this.resetEntry(child.docId)
    }
  }

  async deleteChildEntries(docId: string) {
    const children = this.findActionsForEntry(docId)
    for (const child of children) {
      console.log('deleteChildEntries', 'about to delete entry', child.docId)
      await this.deleteEntryLite(child)
    }
  }

  findActionsForEntry(entryId: string | undefined) {
    const actions = this.recentHistorySubject.getValue() || []
    return actions.filter(item => item.type === 'Action' && entryId && item.parentEntryId === entryId)
  }

  // Handle Favorites Observable
  loadFavorites() {
    const ids = this.userService.getUserKey("user.favorites") || []
    const favoritesMap = new Map<string, any>(ids.map((id: any) => [id, this.getEntryById(id)]))
    this.favorites$.next(favoritesMap)
  }
  setFavorites(val: string[]) { 
    return this.userService.setUserKey("user.favorites", val) 
  }

  async addToFavorites(entry: Entry) {
    const map = await firstValueFrom(this.favorites$)
    const key = entry.docId
    // Only add if not present
    if (map && !map.has(key)) {
      map.set(key, entry)
      this.favorites$.next(map)
      this.setFavorites(Array.from(map.keys()))
      this.userService.setUserKey("user.favoritesChanged", true)
    }
  }
  async removeFromFavorites(entry: any) {
    // console.log("removeFromFavorites", entry)
    const map = await firstValueFrom(this.favorites$)
    if (map && map.delete(entry.docId)) {
      this.favorites$.next(map)
      this.setFavorites(Array.from(map.keys()))
    }
  }

  toggleFavorite(entry: any) {
    if (entry.favorited)
      this.removeFromFavorites(entry)
    else
      this.addToFavorites(entry)
  }

  isFavorited(entry: any) {
    const faves = this.favorites$.getValue()
    return faves?.has(entry?.docId)
  }

  hasFavorites(): boolean {
    const faves = this.favorites$.getValue()
    return faves ? faves.size > 0 : false
  }

  listEntriesWithAttributeDateSince(type: string, attrName: string|null, sinceDate: string) {
    const datestr = sinceDate?.toString()  // to handle SafeString parameter
    console.log("listEntriesWithAttributeDateSince", type, attrName, sinceDate, datestr)
    const result = (this.recentHistorySubject.getValue() || []).filter(entry =>
                      (!type || entry.indexType() === type)
                      && (attrName
                          ? entry.getAttributeNamed(attrName) as string
                          : entry.createdAt as string)
                          > (datestr || "0"))
    // console.log("listEntriesWithAttributeDateSince filtered", result)
    result.sort((a, b) => 
      (attrName
        ? (a.getAttributeNamed(attrName) as string)
        : (a.updatedAt as string)) < 
      (attrName
        ? (b.getAttributeNamed(attrName) as string)
        : (b.updatedAt as string)) 
      ? 1 : -1)
    
    return result
  }

  numEntriesCreatedSince(type: string, unit: string, numUnits: number): number {
    const duration = { [unit]: numUnits }
    const sinceDate = subtractstr(duration)
    const result = this.listEntriesWithAttributeDateSince(type, null, sinceDate)
    return result.length
  }

  async listEntriesSince(type: string, attributeName: string, sinceDate: string) {
    const result = (this.recentHistorySubject.getValue() || [])
      .filter(entry => entry.indexType() === type && entry.getAttributeNamed(attributeName) >= (sinceDate || "0"))
    result.sort((a, b) => (a.updatedAt as string < (b.updatedAt as string) ? 1 : -1))
    return result
  }

  prepareReport(entry: Entry) {
    const questions: any = {},
      responses: any = {},
      displayableAttributes: any[] = []
    // console.log("prepareReport", entry)
    for (const [key, value] of Object.entries(entry.attributes)) {
      const attr = `${entry.indexType()}.${key}`
      const spec: any = this.contentService.getAttributeSpec(attr)
      if (spec && !spec.behaviors?.includes("readonly") && !['name', COLLECTION_ENTRIES_ATTRIBUTE_NAME].includes(key)) {
        // console.log('looked up', attr, spec)
        if ((spec.inputType === 'ENTRYSELECTOR' && (value as string[]).length === 0)
          || (spec.inputType === 'MULTIOPTIONS' && value === 'null'))
          continue
        questions[key] = spec.shortQuestion || spec.question || key
        responses[key] = value
        if (value) {
          if (spec.inputType === 'SCALE') {
            const val: any = value
            responses[key] = spec.scaleLabels[val - 1]
          }
          else if (spec.inputType === 'DATE') {
            responses[key] = formatDate(value, 'MMMM dd, yyyy')
          }
          else if (spec.inputType === 'VIDEOCAPTURE') {
            responses[key] = "📹"
          }
          else if (spec.inputType === 'ENTRYSELECTOR') {
            // console.log("prepareReport ENTRYSELECTOR", entry, value)
            const val = (typeof value === 'string' ? JSON.parse(value as string) : value) as string[]
            const names = (val as string[]).map(str => this.getDisplayNameForId(str))
            responses[key] = names.join(', ')
          }
          else if (spec.inputType === 'MULTIOPTIONS') {
            const val = JSON.parse(value as string) || {}
            const matches: string[] = spec.optionLinks.filter((opt: any) => val[opt.name]).map((opt: any) => opt.description)
            responses[key] = matches.join(', ')
          }
          else if (["SEGMENTED", "OPTIONS", "RADIOCHIPS"].includes(spec.inputType)) {
            // console.log("translating", spec, a.value)
            const match = spec.optionLinks.find((opt: any) => opt.name === value)
            if (match)
              responses[key] = match.description
            else {
              if (spec.inputSubtype === 'Goal')
                responses[key] = this.getDisplayNameForId(value as string)
            }
          }
        }
        displayableAttributes.push({ attributeName: key, value: value, order: spec.order })
      }
    }
    // Use natural order of attribute spec
    displayableAttributes.sort((a, b) => a.order > b.order ? 1 : -1)
    // console.log({displayableAttributes, questions, responses})
    return { displayableAttributes, questions, responses }
  }

  generateEntriesAsReportObjects(type: string, max: number, attributeName: string|null, sinceDate: string) {
    // filter by sinceDate and sort by most recent
    const entries = this.getEntriesOfType(type)
    const datestr = sinceDate?.toString()  // to handle SafeString parameter
    console.log("generateEntriesAsReportObjects", type, max, attributeName, sinceDate, datestr)
    const hits = datestr && datestr?.length > 0
      ? entries.filter(entry => 
        (attributeName 
          ? entry.getAttributeNamed(attributeName) 
          : entry.createdAt as string) 
        >= datestr)
      : entries
    // console.log("generateEntriesAsReportObjects hits", hits)
    hits.sort((a, b) => 
      ((attributeName ? a.getAttributeNamed(attributeName) as string : a.createdAt as string) < 
       (attributeName ? b.getAttributeNamed(attributeName) as string : b.createdAt as string)
        ? 1 
        : -1))
    console.log("generateEntriesAsReportObjects sorted", hits)
    return hits.slice(0, max)
      .map(entry => {
        const report = this.prepareReport(entry)
        const data = Object.keys(entry.attributes)
          .filter(key => !!report.questions[key])
          .map(key => [ key, report.responses[key] ])
        const array = [ ...data, [ 'name', entry.displayName ] ]
        // console.log("array", array)
        return Object.fromEntries(array)
      })
  }

  setEntryFeedState(data: Entry, docId: string | null = null) {
    // console.log("setEntryFeedState", docId, data)
    // Only update feed state if the data is a parent entry
    if (!data?.parentEntryId) {
      this.entryFeedStateSubject.next(data)
      this.userService.setUserKey(ENTRY_FEED_KEY, docId)
    }
  }

  generateWebEntryLink(entry: Entry) {
    // Handle userId embedded in report attributes
    const userId = entry.attributes.userId || this.userService.getCurrentUserId()
    const obj = { u: userId, e: entry.docId }
    const encoded = window.btoa(JSON.stringify(obj))
    const url = `${this.environment.linkHost}/entry/${encoded}`
    console.log("generateWebEntryLink", url)
    return url
  }

  async launchWebEntryLink(entry: Entry) {
    const url = this.generateWebEntryLink(entry)
    if (this.platform.is("capacitor"))
      await Browser.open({ url });
    else
      window.open(url, '_blank')
  }

  private replaceTokens(str: string, entry: Entry) {
    const term = this.contentService.getSingularGlobal(entry.indexType() as string)
    const articleTerm = articlize(term)
    return str?.replace("$entryType", articleTerm)
      .replace("$inviter", this.userService.getCurrentUser()?.name || 'A friend')
  }

  async shareLink(entry: Entry) {
    const result = await this.generateBranchLink(entry)
    if (result?.completed)
      this.eventService.recordShareContent({ id: 'entry-link', app: result.app })
  }

  async generateBranchLink(entry: Entry) {
    const analytics = {
      channel: 'app',
      feature: 'webentry',
      campaign: 'webentry',
    }

    const og_image = this.contentService.getGlobal("webentry.social.imageurl")
    const og_description = this.replaceTokens(this.contentService.getGlobal("webentry.social.description"), entry)
    const og_title = this.replaceTokens(this.contentService.getGlobal("webentry.social.title"), entry)
    const shareText = this.replaceTokens(this.contentService.getGlobal(ENTRYSHARE_MSG), entry)
    const url = this.generateWebEntryLink(entry)

    // optional fields
    const properties:any = {
      inviter: this.userService.getCurrentUserId(),
      $og_title: og_title,
      $og_description: og_description,
      $og_image_url: og_image,
      $og_url: url,
      web_only: true,
      $web_only: true,
      $desktop_web_only: true,
      // this seems to force the web link to open even though the branch 
      // debugger says the app will open
      $mobile_web_only: true, 
      // $fallback_url: url,  // overwrites og tags, so use $ios_url, $android_url, $desktop_url instead
      $ios_url: url,
      $android_url: url,
      $desktop_url: url,
      $ipad_url: url,
      $twitter_card: 'summary_large_image',
      $twitter_title: og_title,
      $twitter_description: og_description,
      $twitter_image_url: og_image,
      $twitter_site: '@cheaseed'
     
    }
    console.log("generateBranchLink", properties)
    return await this.mediaService.shareBranchDeepLink(analytics, properties, shareText)
  }

  updateAddableEntry(id: string, entryId: string) {
    const map = this.addableEntryMap$.getValue()
    const newMap = new Map(map.entries())
    newMap.set(id, entryId)
    this.setAddableEntryMap(newMap)
  }

  setAddableEntryMap(map: Map<string, string>) {
    // console.log("setAddableEntryMap", map)
    this.addableEntryMap$.next(map)
  }

  setCurrentAddableEntryMapId(id: string) {
    // console.log("setCurrentAddableEntryMapId", id)
    this.currentAddableEntryMapId$.next(id)
  }

  hasAddableEntry(id: string) {
    return this.addableEntryMap$.getValue().has(id)
  }

  async findReportEntryByChatId(type: string, chatId: string) {
    // const entries = await firstValueFrom(this.recentHistory$)
    // Perform an explicit read from the entries collection to test existence
    const entries = await firstValueFrom(this.getUserEntriesForChatId(type, chatId))
    return entries?.find((entry:any) => entry.attributes.chatId === chatId)
  }

}
