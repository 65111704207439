import { Inject, Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { 
  ActionSheetController, 
  AlertController, 
  LoadingController, 
  ToastController,
  NavController
} from '@ionic/angular'
import { FirebaseService } from './firebase.service'
import { upperFirst } from '@cheaseed/node-utils'

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  emojis:any = {
    excited: "&#x1F92A",
    happy: "&#x1F600",
    nervous: "&#x1F627",
    bad: "&#x1F92E",
    sad: "&#x1F641",
  }
  constructor(
    @Inject('environment') public environment: any,
    private firebase: FirebaseService,
    private alertController: AlertController,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private actionSheetController: ActionSheetController,
    private location: Location,
    private navController: NavController,
  ) { }

  setSatisfactionRemindersFromData(user:any, taskSetName: string, data: any) {
    const dayList = [
      'sunday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday'
    ]
    const levelHigh = "level.high",
          levelMedium = "level.medium",
          levelLow = "level.low"
    const days = Object.fromEntries(Object.values(data.dayString || '12345').map((i:string) => [ dayList[i], true ]))
    const freq = [levelHigh, levelMedium, levelLow].includes(data.freq) ? 
                data.freq : 
                levelLow
    const params = {
        taskSetName: taskSetName,
        email: user.docId,
        numWeeksToSchedule: 2,
        end_work: data.end_work || '1800',
        start_work: data.start_work || '0800',
        days: days,
        freq: freq
    } 
    return this.firebase.callCloudFunction("scheduleSatisfactionReminders", params)
  }

  async notify(props: { header?: string, message?: string, okText?: string, cancel?: () => void }) {
    const alert = await this.alertController.create({
      header: props.header,
      message: props.message,
      buttons: [
        {
          text: props.okText || 'OK',
          handler: (props.cancel ? props.cancel : undefined)
        }
      ]
    })
    await alert.present()
    return alert
  }

  async prompt(props: { 
    header?: string, 
    message?: string, 
    inputType?: 'textarea' | 'text' | 'tel', 
    inputValue?: string,
    inputId?: string,
    inputCssClass?: string,
    placeholder?: string, 
    okText?: string, 
    cancelText?: string, 
    cancel?: () => void, 
    confirm?: (data:any) => void }) {
  const alert = await this.alertController.create({
    header: props.header,
    message: props.message,
    inputs: [
      {
        name: 'value',
        id: props.inputId || 'value',
        type: props.inputType || 'text',
        cssClass: props.inputCssClass,
        value: props.inputValue || '',
        placeholder: props.placeholder
      }
    ],
    buttons: [
      {
        text: props.cancelText || 'Cancel',
        role: 'cancel',
        handler: props.cancel || undefined
      }, {
        text: props.okText || 'OK',
        handler: props.confirm || undefined
      }
    ]
  })
  await alert.present()
}

  async password(props: { 
      header?: string, 
      message?: string, 
      inputValue?: string,
      inputCssClass?: string,
      placeholder?: string, 
      okText?: string, 
      confirm?: (data:any) => void }) {
    const alert = await this.alertController.create({
      header: props.header || 'Enter password',
      message: props.message || 'Password required',
      inputs: [
        {
          name: 'value',
          type: 'password',
          cssClass: props.inputCssClass,
          value: props.inputValue || '',
          placeholder: props.placeholder
        }
      ],
      buttons: [
        {
          text: props.okText || 'OK',
          handler: props.confirm || undefined
        }
      ]
    })
    await alert.present()
  }

  async confirm(props: { header?: string, message?: string, noLabel?: string, yesLabel?: string, cancel?: () => void, confirm?: () => void }) {
    const alert = await this.alertController.create({
      header: props.header,
      message: props.message,
      cssClass: 'alert-confirm',
      buttons: [
        {
          text: props.noLabel || 'No',
          role: 'cancel',
          handler: props.cancel || undefined
        }, {
          text: props.yesLabel || 'Yes',
          handler: props.confirm || undefined
        }
      ]
    })
    await alert.present()
  }

  async multiconfirm(props:{ header?: string, cssClass?: string, message?: string, buttons?: any[] }) {
    const alert = await this.alertController.create({
      header: props.header,
      message: props.message,
      cssClass: props.cssClass,
      buttons: props.buttons
    })
    await alert.present()
  }

  upperFirst(s: string): string {
    return upperFirst(s)
  }

  async presentToast(msg:string, properties: any = null) {
    const props = {      
      message: msg,
      duration: 2000,
      color: "secondary",  // override with null to defer to cssClass
      position: "middle",
      ...properties
    }
    // console.log(props)
    const toast = await this.toastController.create(props)
    await toast.present()
  }

  async showActionSheet(header:string, buttons: any[]) {
    buttons.push(
      { 
      text: 'Not now',
      icon: 'close',
      cssClass: 'cancel-button',
      }
    )
    const ac = await this.actionSheetController
      .create({
        header,
        cssClass: 'plus-action-sheet',
        translucent: true,
        buttons: buttons
        })
    return await ac.present()
  }

  unicodeEmoji(feeling: string) {
    return this.emojis[feeling]
  }

  getRandomInt(max: number) {
    return Math.floor(Math.random() * Math.floor(max))
  }

  trackByDocId(index: number, item: any): string {
    // console.log("trackByDocId", index, item)
    return item.docId
  }

  async loading(message: string) {
    const loading = await this.loadingController.create({
      message: message,
      cssClass: 'alert-confirm'
    });
    await loading.present();
    return loading
  }

  // centralized back button behavior
  goBack() {
    // console.log("goBack")
    this.navController.setDirection('back')
    this.location.back()
  }
}