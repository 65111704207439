import { NgModule } from '@angular/core';
import { AudioService } from './audio.service';
import { ChatQueueService } from './chat-queue.service';
import { CheaseedCoreComponent } from './cheaseed-core.component';
import { ContentService } from './content.service';
import { EntryService } from './entry.service';
import { EvaluatorService } from './evaluator.service';
import { FirebaseService } from './firebase.service';
import { HandlebarsService } from './handlebars.service';
import { LearnService } from './learn.service';
import { OnboardingService } from './onboarding.service';
import { PointsService } from './points.service';
import { ProgramService } from './program.service';
import { SequenceAdvisorService } from './sequence-advisor.service';
import { SharedChallengeService } from './shared-challenge.service';
import { SharedChatService } from './shared-chat.service';
import { SharedMessageService } from './shared-message.service';
import { StatsFeedService } from './stats-feed.service';
import { StatsManagerService } from './stats-manager.service';
import { TaskSchedulerService } from './task-scheduler.service';
import { UtilityService } from './utility.service';
import { ChatStateService } from './chat-state.service';
import { CheaseedStripeService } from './stripe.service';
import { SlackService } from './slack.service';
import { GlobalErrorHandler } from './globalErrorHandler';

@NgModule({
  declarations: [CheaseedCoreComponent],
  imports: [],
  exports: [CheaseedCoreComponent],
  providers: [
    FirebaseService,
    UtilityService,
    ContentService,
    SharedChallengeService,
    SharedChatService,
    ChatStateService,
    SharedMessageService,
    AudioService,
    EntryService,
    LearnService,
    StatsFeedService,
    OnboardingService,
    ProgramService,
    StatsManagerService,
    HandlebarsService,
    EvaluatorService,
    ChatQueueService,
    SequenceAdvisorService,
    TaskSchedulerService,
    PointsService,
    CheaseedStripeService,
    SlackService,
    GlobalErrorHandler
    ],
})
export class CheaseedCoreModule {}
