import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialService } from './social.service';
import { ConsoleService } from './console.service';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  exports: [],
  providers: [
    SocialService,
    ConsoleService
  ]
})
export class SharedUtilModule {}
