import { Step } from './metadata.models'
export class Program {
  docId?: any;
  programSpec?: any;
  createdAt?: any;
  updatedAt?: any;
  completedAt?: any;
  stepTimes?: any;
  isCurrent?: boolean;

  constructor(args: any) {
    Object.assign(this, args)
  }

  getNumSteps(): number {
    return this.programSpec?.steps.length || 0
  }

  getNextStep(): Step {
    const next = this.getFirstIncompleteStep()
    return next || this.programSpec?.steps[this.getNumSteps() - 1]
  }

  getPreviousStep(step: Step): Step {
    const idx = this.programSpec?.steps.findIndex(s => s.name === step.name)
    return idx > 0 ? this.programSpec?.steps[idx - 1] : null
  }

  getNextStepAfter(step: Step): Step {
    const idx = this.programSpec?.steps.findIndex(s => s.name === step.name)
    return idx < this.getNumSteps() ? this.programSpec?.steps[idx + 1] : null
  }

  isLastStep(step: Step): boolean {
    const idx = this.programSpec?.steps.findIndex(s => s.name === step.name)
    return idx === this.getNumSteps() - 1
  }
  
  isFirstStep(step: Step): boolean {
    const idx = this.programSpec?.steps.findIndex(s => s.name === step.name)
    return idx === 0
  }

  getFirstIncompleteStep(): Step {
    return this.programSpec?.steps.find(s => !this.stepTimes[s.name])
  }

  getIncompleteSteps(): Step[] {
    return this.programSpec?.steps.filter(s => !this.stepTimes[s.name]) || []
  }

  isComplete(): boolean {
    const incs = this.getIncompleteSteps()
    return incs.length === 0
  }

  lastUpdateDaysAgo(): number {
    return this.updatedAt ?
      Math.round((Date.now()/1000 - this.updatedAt.seconds)/60/60/24) :
      0
  }

}