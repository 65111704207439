import { Inject, Injectable, inject } from '@angular/core'
import { Platform } from '@ionic/angular'
import { BehaviorSubject, firstValueFrom, map } from 'rxjs'
import { PurchaseService } from './purchase.service'
import { SharedUserService } from './shared-user.service'
import { ActivatedRouteSnapshot, Router } from '@angular/router'
import { Events, SharedEventService } from './shared-event.service'
import {
  STRIPE_IN_PROGRESS_TRANSACTION_IDENTIFIER,
  AVAILABLE_OFFERS,
  ProductPricingOffer,
  nowstr,
  DEFAULT_SEED_BALANCE} from '@cheaseed/node-utils'
import { PointsService } from './points.service'
import { CustomerInfo } from '@awesome-cordova-plugins/purchases/ngx'
import { Consumable, Receipt } from '@cheaseed/node-utils'
import { OffersService } from './offers.service'
import { PurchaseUtilitiesService } from './purchase-utilities.service'
// The Seed Service manages the purchase and depletion of seeds, as well as authorization

@Injectable({
  providedIn: 'root'
})
export class SeedService {

  userService = inject(SharedUserService)
  purchaseUtilitiesService = inject(PurchaseUtilitiesService)
  enabled$ = new BehaviorSubject(false)
  currentSeedCount$ = this.userService.user$.pipe(map(user => user?.seedBalance || 0))
  promptPurchase$ = new BehaviorSubject<any>(undefined)

  constructor(
    @Inject('UtilityService') private utilityService: any,
    private platform: Platform,
    private purchaseService: PurchaseService,
    private eventService: SharedEventService,
    private pointsService: PointsService,
    private offersService: OffersService,
    private router: Router,
  ) { 
  }

  createConsumable(quantity: number, purchased: boolean, purchaseSource: any) {
    return this.purchaseUtilitiesService.createConsumable(quantity, purchased, purchaseSource)
  }

  async addConsumable(consumable: Consumable, docId: string | null) {
    return await this.purchaseUtilitiesService.addConsumable(consumable, docId)
  }

  async removeConsumable(docId: string) {
    return await this.purchaseUtilitiesService.removeConsumable(docId)
  }

  async initialize() {
    //TODO   write script to initialize users with 100 seeds

    const balance = await this.userService.getCurrentSeedBalance()
    if (balance === null || balance === undefined || isNaN(balance)) {
      // TODO - temporary code - create the user property seedBalance 
      // initialized to  default value
      console.log('Initializing seed balance for user')
      // null source indicates a purchase has never been made
      await this.updateFirestoreInfo(DEFAULT_SEED_BALANCE, 0, false, null, null)
      //console.log('Seed balance is now ', this.userService.getCurrentSeedBalance())
    }

    // purchases are only available on mobile apps
    if (this.platform.is('capacitor')) {
      try {
        await this.purchaseService.initialize(this.userService.getCurrentUserId() as string)
      }
      catch (e) {
        console.error('Failed to initialize Purchase Service', e)
      }
    }
  }

  async updateFirestoreInfo(quantity: number, currentBalance: number, purchased: boolean, receipt: any, docId: string | null) {
    if (receipt)
      await this.addConsumable(this.createConsumable(quantity, purchased, receipt), docId)
    await this.userService.setSeedBalance(quantity + currentBalance)
  }

  async introduceNavigationDelay() {
    // introduce a delay greater than the debounce time for firestore updates 
    // (currently 1000) to work around timing issues
    return await this.purchaseUtilitiesService.introduceNavigationDelay()
  }

  getAppStoreName() {
    return this.purchaseUtilitiesService.getAppStoreName()
  }

  async purchaseSeeds(offer: ProductPricingOffer, route: ActivatedRouteSnapshot) {
    let docId: string | null = null
    try {
      const isCapacitor = this.platform.is('capacitor')
      const currentBalance = this.userService.getCurrentSeedBalance()

      // add a consumable entry with in process transactionIdentifier and store set
      let receipt = this.getDefaultReceipt(this.getAppStoreName(), offer.code, STRIPE_IN_PROGRESS_TRANSACTION_IDENTIFIER)
      docId = await this.addConsumable(this.createConsumable(offer.quantity as number, false, receipt), null)
      if (isCapacitor) {
        receipt = await this.purchaseInApp(docId, offer)
        await this.executeSuccessLogic(offer, currentBalance, isCapacitor, receipt, docId, route)
      }
      else {
        // pretend the seeds were successfully purchased in the web app
        await this.executeSuccessLogic(offer, currentBalance, isCapacitor, receipt, docId, route)
      }

      return true
    }
    catch (e) {
      console.error('Purchase Seeds', e)
      return false
    }
  }

  async executeSuccessLogic(offer: ProductPricingOffer, currentBalance: number, purchased: boolean, receipt: Receipt, docId: string | null, route: ActivatedRouteSnapshot | undefined) {
    const simulate = await firstValueFrom(this.enabled$)
      // if the feature flag to simulate a crash is set, dont update the seed balance or the consumable doc
      // the web hook in revenue cat will do the updation. The webhook will update the seed balance
      // for the in_progress_transaction. For the payment_pending_transaction, we assume it will eventually
      // succeed and grant seeds right away. The web hook will either debit the seed balance if the
      // transaction eventually fails or add the transaction identifier if it succeeds
      if (!simulate)
        await this.updateFirestoreInfo(offer.quantity as number, currentBalance, purchased, receipt, docId)
      await this.eventService.recordSeedsPurchased({ quantity: offer.quantity })
      await this.offersService.processOffer(Events.SeedsPurchased)
      if (route) {
        await this.introduceNavigationDelay()
        //console.log('Navigating to ', route.url)
        if (route?.url) {
          const url = '/' + route.url.join('/')
          console.log('Navigating to ', url)
          await this.router.navigateByUrl(url)
        }
      }
  }
  
  async purchaseInApp(docId: string, offer: ProductPricingOffer) {
    let continueOnPendingPayment = false
    let response: CustomerInfo | null = null
    try {
      response = await this.purchaseService.purchaseProduct(offer.code)
    }
    catch (e) {
      console.error('Error purchasing RevenueCat product', e)
      // workaround for revenuecat not exposing the PurchasesError interface
      // correctly. does not have userCancelled property
      const revenueCatError = e as any
      let 
        remove = true
      if (revenueCatError.userCancelled !== undefined &&
        revenueCatError.userCancelled === false) {
        let
          title = "Purchase Error",
          message = "Your purchase failed"
        switch (revenueCatError.error.readableErrorCode) {
          case 'PaymentPendingError':
            // TODO - for now treat pending transactions as success
            title = 'Purchase Pending'
            message = 'Your payment is pending'
            remove = false
            continueOnPendingPayment = true
            break;
          case 'PurchaseNotAllowedError':
            // e.g. credit card not accepted
            message = 'Your payment was refused'
            break
          case 'PurchaseInvalidError':
            //e.g. payment is pending and the user tries to buy again
            message = 'Your payment was marked as invalid. This is likely because an earlier payment is pending'
            break
          case 'INVALID_RECEIPT':
            // on test transactions on iOS, revenueCat throws this because
            // of a Storekit bug. Pretend the transaction is ok
            continueOnPendingPayment = true
            break
          default:
            message = revenueCatError.error.message
            break
        }
        if(!continueOnPendingPayment) {
          this.utilityService.notify({
            header: title,
            message: message,
            okText: "OK"
          })
        }
      }
      // remove the consumable for all errors other than payment pending error
      if (docId && remove)
        this.removeConsumable(docId)
    }
    return this.getReceiptFrom(response as CustomerInfo)

  }

  getReceiptFrom(cInfo: CustomerInfo): Receipt {
    const t = cInfo.nonSubscriptionTransactions
    return new Receipt(t[t.length - 1], this.getAppStoreName())
  }

  //TODO - implement invoice creation in Stripe
  // and get the actual invoice - for now, generate
  // a dummy receipt
  /*getStripeReceiptFrom(offer: ProductPricingOffer, cInfo: PaymentIntent): Receipt {
    
    const t: StoreTransaction = { 
      transactionIdentifier: STRIPE_TRANSACTION_IDENTIFIER,
      purchaseDate: new Date(cInfo.created).toISOString(),
      productIdentifier: offer.code

    }
    return new Receipt(t, this.getAppStoreName())
  }
*/
  getDefaultReceipt(storeName: string, productId: string, transactionId: string): Receipt {
    return new Receipt(
      {
        productIdentifier: productId,
        purchaseDate: nowstr(),
        transactionIdentifier: transactionId
      },
      storeName
    )
  }

  async redeemPoints(numPoints: number, route: ActivatedRouteSnapshot) {
    const currentBalance = await this.userService.getCurrentSeedBalance()
    await this.updateFirestoreInfo(numPoints, currentBalance, false, null, null)
    this.pointsService.redeemPoints(numPoints)
    this.eventService.recordPointsRedeemed({ quantity: numPoints })

    await this.introduceNavigationDelay()
    if (route?.url) {
      const url = '/' + route.url.join('/')
      console.log('Navigating to ', url)
      await this.router.navigateByUrl(url)
    }
    return true
  }

  async getOffers() {
    // For web, we cant access revenuecat - so use static definition
    if (!this.platform.is('capacitor')) {
      return AVAILABLE_OFFERS
    }
    const productCodes: string[] = AVAILABLE_OFFERS.map(o => o.code)
    const o = await this.purchaseService.getProducts(productCodes)
    return o.map(psp => {
      const of = AVAILABLE_OFFERS.find(x => x.code === psp.identifier)
      return {
        code: psp.identifier,
        price: psp.priceString,
        title: psp.title || of?.title as string,
        quantity: of?.quantity as number,
        discount: of?.discount
      } as ProductPricingOffer
    }).sort((a, b) => (a.quantity || 0) - (b.quantity || 0))
  }

  getSeedsForOffer(offerCode: string) {
    const of = AVAILABLE_OFFERS.find(x => x.code === offerCode)
    return of?.quantity
  }

  async decrementSeedBalance(quantity: number) {
    const currentBalance = this.userService.getCurrentSeedBalance()
    await this.userService.setSeedBalance(Math.max(currentBalance - quantity, 0))
    return quantity
  }

  toggleSimulateDelayedPaymentSync() {
    const newval = !this.enabled$.value
    this.enabled$.next(newval)
    return newval
  }
}
