import { Injectable, inject } from '@angular/core';
import { FirebaseService } from './firebase.service'
import { Entry, ChallengeSpec, Challenge, CHALLENGESHARE_MSG, addTo, diff, formatISODate, isFuture, nowstr, todaystr, yyyymmddToLocalDate, articlize } from '@cheaseed/node-utils'
import { ContentService } from './content.service';
import { SharedUserService } from './shared-user.service'
import { BehaviorSubject, debounceTime, firstValueFrom, map, Observable, switchMap, shareReplay, filter, timer, take, of, combineLatestWith } from 'rxjs';
import { AlertController } from '@ionic/angular';
import { orderBy, where } from '@angular/fire/firestore';
import { Events, SharedEventService } from './shared-event.service';
import { UtilityService } from './utility.service';
import { SharedMessageService } from './shared-message.service';
import { MediaService } from './media.service';
import { AuthService } from './auth.service';
import { EntryService } from './entry.service';
import { SeedService } from './seed.service';

export const NICKNAME_KEY = "Onboarding.nickName"
export const CHALLENGE_FEED = 'challengesPublicFeed'

export enum ChallengeState {
  Current = 'Current',
  Completed = 'Completed'
}
@Injectable({
  providedIn: 'root'
})
export class SharedChallengeService {

  userService = inject(SharedUserService)

  challengeSpecs: ChallengeSpec[] = []
  // list of all private challenge specs
  privateChallengeSpecs: ChallengeSpec[] = []
  // list of all public challenge specs
  publicChallengeSpecs: ChallengeSpec[] = []
  // list of public challenge specs that are not currently joined by user
  availablePublicChallengeSpecs$: Observable<ChallengeSpec[] | null> = of(null)
  // list of public challenge specs that are eligible for the feed component
  challengeFeedSubject = new BehaviorSubject<ChallengeSpec[] | null>(null)
  challengeFeed$ = this.challengeFeedSubject.asObservable()
  userChallenges$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      switchMap(() => this.getUserChallenges()),
      map(challenges => ({ 
        challenges,
        activeChallenges: challenges.filter(c => !c.completedAt && !c.abortedAt),
        completedChallenges: challenges.filter(c => c.completedAt)
      })),
      // tap(data => console.log("userChallenges$", data))
      )

  deletingChallenge$ = new BehaviorSubject(false)

  constructor(
    private entryService: EntryService,
    protected contentService: ContentService,
    protected firebaseService: FirebaseService,
    protected eventService: SharedEventService,
    private messageService: SharedMessageService,
    private mediaService: MediaService,
    protected utilityService: UtilityService,
    protected alertController: AlertController,
    private auth: AuthService,
    private seedService: SeedService
  ) { }

  initialize() {

    // Observe changes to entries to update challenges
    this.entryService.getUserEntries()
      .pipe(
        combineLatestWith(this.userChallenges$),
        this.auth.takeUntilAuth())
      .subscribe(([ entries, challenges]) => {
        // Update challenges if necessary (do not await since this might be expensive)
        this.updateChallenges(entries, challenges.activeChallenges)
      })

    this.contentService.loader$
      .pipe(
        filter(loaded => !!loaded),
        this.auth.takeUntilAuth())
      .subscribe(() => {
        this.challengeSpecs = Array.from(this.contentService.challengeMap.values())
        this.privateChallengeSpecs = this.challengeSpecs.filter(spec => !spec.behaviors?.includes('Public'))
        this.publicChallengeSpecs = this.challengeSpecs.filter(spec => spec.behaviors?.includes('Public'))
        this.availablePublicChallengeSpecs$ = this.getAvailablePublicChallenges()
        this.availablePublicChallengeSpecs$
          .pipe(
            debounceTime(200),
            map(availables => this.filterPublicChallengesForFeed(availables)),
            this.auth.takeUntilAuth())
          .subscribe(availables => {
            // console.log("subscriber to availablePublicChallenges updating challenge feed with", availables)
            this.challengeFeedSubject.next(availables)
          })
        // console.log("SharedChallengeService initialized")
      })
  }

  getUserChallenges(): Observable<Challenge[]> {
    return this.firebaseService.collection$(this.challengeDocPath(), orderBy('createdAt', 'desc'))
      .pipe(
        // debounceTime(1000), // 1 second to prevent too many updates
        // tap(result => console.log(`getUserChallenges retrieved ${result.length} items`)),
        shareReplay(1)
      )
  }

  getChallengeSpecNamed(name: string): ChallengeSpec {
    return this.contentService.challengeMap.get(name) as ChallengeSpec
  }

  getAvailablePublicChallenges(): Observable<ChallengeSpec[]> {
    return this.userChallenges$
      .pipe(
        map(challenges => {
          // Only include as public challenge if not already active for user and not ended in the past
          return this.publicChallengeSpecs
            .filter(spec => !spec.endDate || spec.endDate > todaystr())
            .filter(spec => !challenges.activeChallenges.find(c => c.name === spec.name))
        }))
  }

  filterPublicChallengesForFeed(challenges: ChallengeSpec[] | null) {
    // Filter out challenges that are already tapped by user
    return challenges?.filter(spec =>
      diff(spec.startDate, null, 'days') <= 14 &&
      isFuture(spec.endDate) &&
      !this.userService.getUserKey(this.getFeedKey(CHALLENGE_FEED, spec))?.tapped)
  }

  async updateChallengeFeed(upd: ChallengeSpec) {
    const feedKey = this.getFeedKey(CHALLENGE_FEED, upd)
    const lastValue = this.userService.getUserKey(feedKey) ||
      { tapped: true, lastFeedDate: todaystr() }
    this.userService.setUserKey(feedKey, { ...lastValue, tapped: true })
    const availables = this.filterPublicChallengesForFeed(this.challengeFeedSubject.getValue())
    console.log("updateChallengeFeed", availables)
    this.challengeFeedSubject.next(availables)
  }

  // Feed key has { tapped, lastFeedDate }
  getFeedKey(type: string, spec: ChallengeSpec) {
    return `feed.${type}.${spec.name}`
  }

  challengeDocPath(docId: string | null = null, userId: string | null = null) {
    return `users/${userId || this.userService.getCurrentUserId()}/challenges${docId ? '/' + docId : ''}`
  }

  convertTimestampToDate(ts) {
    return ts ? new Date(ts.seconds * 1000) : null
  }

  isChallengeActive(c: Challenge) {
    const now = nowstr()
    return c.startDate < now
      && c.projectedEndAt > now
      && !c.completedAt
      && !c.abortedAt
  }

  async updateChallenge(c: Challenge, entries: Entry[], writeFlag: boolean = true) {
    // console.log('updateChallenge', c, entries, writeFlag)

    // Count entries that qualify for the challenge
    const selectedEntries = entries.filter(e =>
      (e.indexType() === c.entryType)
      && (e.createdAt as string) > (c.startDate as string)
      && this.isChallengeActive(c))
    const cnt = selectedEntries.length

    // TODO: Compute rank of user in challenge (expensive, should probably happen in cloud function)

    // If count changed, update challenge
    // If challenge is over, mark challenge complete
    const now = nowstr()
    const changes: any = {}
    if (cnt !== c.count)
      changes.count = cnt
    if (c.projectedEndAt && c.projectedEndAt < now)
      changes.completedAt = now
    // Avoid writes during saveChallenge
    if (writeFlag && Object.keys(changes).length > 0) {
      // console.log('updateChallenge updating', c, changes)
      await this.firebaseService.updateAt(this.challengeDocPath(c.docId), changes)
      this.eventService.recordChallengeEvent(Events.ChallengeUpdateParticipant, {
        docId: c.docId,
        name: c.name,
        count: cnt,
        public: c.public,
        owner: c.owner
      })
    }
    return changes
  }

  async updateChallenges(entries: Entry[], challenges: Challenge[]) {
    for (const c of challenges) {
      await this.updateChallenge(c, entries)
    }
  }

  // Get a challenge belonging to a specified user or the current user
  getUserChallenge(docId: string, userId: string): Observable<Challenge> {
    return this.firebaseService.doc$(userId ? this.challengeDocPath(docId, userId) : this.challengeDocPath(docId))
  }

  // return unique nicknames across all challenges across all users (may be expensive!)
  getChallengeNicknames(): Observable<any> {
    return this.firebaseService.collectionGroup$('challenges')
      .pipe(
        map(results => new Set(results.map(c => c.nickname))),
        map(results => [...results])
      )
  }

  getChallengeToJoin(docId: string): Observable<Challenge> {
    return this.firebaseService.collectionGroup$('challenges', where('docId', '==', docId))
      .pipe(
        // tap(res => console.log("getChallengeToJoin", res)),
        map(res => res.length > 0 ? res[0] : null)
      )
  }

  getGroupChallenges(ownerDocId: string, includeAborted = false): Observable<Challenge[]> {
    return this.firebaseService.collectionGroup$('challenges',
      where('ownerDocId', '==', ownerDocId || ''))
      .pipe(
        // Not sure why we need this, but we've seen multiple calls to this when we should be getting one
        debounceTime(300),
        // tap(result => console.log("getGroupChallenges", ownerDocId, result)),
        // Exclude challenges that have been left/aborted
        map((result: Challenge[]) => result.filter(c => includeAborted || !c.abortedAt))
      )
  }

  getOwnerChallenge(ownerDocId: string | undefined): Observable<any> {
    return this.firebaseService.collectionGroup$('challenges',
      where('docId', '==', ownerDocId))
      .pipe(
        map(res => res.length > 0 ? res[0] : null)
      )
  }

  createChallengeFromSpec(c: ChallengeSpec): Challenge {
    return {
      name: c.name,
      title: c.title as string,
      shortName: c.shortName,
      description: c.description as string,
      entryType: c.entrySpec?.name as string,
      period: c.period,
      public: false,
      owner: true,
      userId: this.userService.getCurrentUserId() as string,
      nickname: this.getNickname(),
      invites: [],
      count: 0,
      createdAt: nowstr()
    }
  }

  computeProjectedEndDate(c: Challenge, spec: ChallengeSpec) {
    if (c.duration) {
      const duration = spec.durationOptions.find((opt: any) => opt.name === c.duration).description
      const start = yyyymmddToLocalDate(c.startDate as string)
      const endDate = addTo(start, duration, c.period)
      c.projectedEndAt = formatISODate(endDate)
    }
  }

  async saveChallenge(c: Challenge) {
    const spec = this.contentService.challengeMap.get(c.name) as ChallengeSpec
    let newChallenge = false

    // If a new challenge
    if (!c.docId) {
      c.docId = this.firebaseService.generateDocID()
      newChallenge = true
      console.log(`Generated docId ${c.docId} for a new challenge`)
    }

    if (!c.ownerDocId) {
      c.ownerDocId = c.docId
    }

    // If private invited challenge, update the ownerNickname if not set
    if (!c.public && c.ownerDocId !== c.docId && !c.ownerNickname) {
      const owner = await firstValueFrom(this.getOwnerChallenge(c.ownerDocId))
      c.ownerNickname = owner.nickname || owner.userId
    }

    // Always recompute end date
    this.computeProjectedEndDate(c, spec)
    if (!c.nickname) {
      c.nickname = this.getNickname()
    }

    // Update challenge statistics if active
    if (this.isChallengeActive(c)) {
      const entries = await firstValueFrom(this.entryService.getUserEntries())
      const changes = await this.updateChallenge(c, entries, false)
      c.count = changes.count
    }

    // Single write
    // console.log('saveChallenge', c)
    await this.firebaseService.updateAt(this.challengeDocPath(c.docId), c)

    // Record event
    const event = {
      docId: c.docId,
      ownerDocId: c.ownerDocId,
      title: c.title,
      public: c.public,   // true if public
      owner: c.owner,     // true if owned by user
      name: c.name,
      startDate: c.startDate
    }
    // Send ChallengeCreate if user owns challenge
    const isChallengeCreate = newChallenge && c.docId === c.ownerDocId

    this.eventService.recordChallengeEvent(
      isChallengeCreate ? Events.ChallengeCreate : Events.ChallengeJoin,
      event)

    // If created challenge is private and user is admin, decrement seed count
    if (isChallengeCreate && !c.public && this.contentService.isSeedsEnabled()) {
      // decrement seed count
      const cost = this.contentService.getChallengeSeedCost()
      const seeds = await this.seedService.decrementSeedBalance(cost)
      console.log('Add Challenge: Decremented seed count by ', seeds)

      this.eventService.recordSeedsConsumedByChallengeEvent({
        seedsUsed: seeds,
        docId: c.docId
      })

    }
    // Schedule challenge tasks for delayed trigger update
    timer(3000)
      .pipe(
        take(1),
        switchMap(() =>
          this.firebaseService.callCloudFunction('scheduleChallengeTriggers', {
            userId: this.userService.getCurrentUserId(),
            docId: c.docId,
            day: spec.dayOfNotification || 'Wednesday',
            time: spec.timeOfNotification || '0930'
          })
        ))
      .subscribe(() => console.log("scheduled tasks for challenge", c.docId))

    // Add a message if private and not your own challenge
    if (!c.public && !c.owner) {
      const msgId = await this.messageService.putMessage({
        title: `You joined the ${c.title}`,
        detail: `/challenge-view/${c.docId}`,
      })
      console.log("Added message", msgId)
    }

    return c
  }

  async saveChildChallenge(c: Challenge) {
    const newChallenge = {
      ownerDocId: c.ownerDocId,
      name: c.name,
      title: c.title,
      shortName: c.shortName,
      description: c.description,
      entryType: c.entryType,
      period: c.period,
      duration: c.duration,
      goal: c.goal,
      startDate: c.startDate,
      public: c.public,
      projectedEndAt: c.projectedEndAt,
      owner: false,
      nickname: this.getNickname(),
      userId: this.userService.getCurrentUserId(),
      invites: [],
      count: 0,
      createdAt: nowstr()
    }
    await this.saveChallenge(newChallenge)
  }

  publicChallengeOwnerDocId(name: string): string {
    return `public-${name.replace(/\s+/g, '-')}`
  }

  getDurationOption(spec: ChallengeSpec, value: string | null = null) {
    const opt: any = value ? spec.durationOptions.find((opt: any) => opt.name === value) : spec.durationOptions[0]
    return `${opt?.description} ${spec.period + (opt?.description > 1 ? 's' : '')}`
  }

  getGoalOption(spec: ChallengeSpec, value: string | null = null) {
    const opt: any = value ? spec.goalOptions.find((opt: any) => opt.name === value) : spec.goalOptions[0]
    return `${opt?.description} per ${spec.period}`
  }

  async joinPublicChallenge(spec: ChallengeSpec) {
    // If there exists a challenge for this spec that was already left, rejoin it, otherwise create it
    const today = todaystr()
    const challenges = await firstValueFrom(this.userChallenges$)
    const challenge = challenges.challenges.find(c => c.name === spec.name && c.projectedEndAt > today)
    if (challenge?.abortedAt) {
      challenge.abortedAt = null
      await this.saveChallenge(challenge)
    }
    else if (!challenge) {
      await this.savePublicChallenge(spec)
    }
  }

  async savePublicChallenge(spec: ChallengeSpec) {
    const challenge = this.createChallengeFromSpec(spec)
    challenge.duration = spec.durationOptions[0].name
    challenge.goal = spec.goalOptions[0].name
    challenge.nickname = this.getNickname()
    challenge.owner = false
    challenge.public = true
    challenge.startDate = spec.startDate
    challenge.ownerDocId = this.publicChallengeOwnerDocId(spec.name)
    // console.log(challenge, spec)
    return this.saveChallenge(challenge)
  }

  cancelChallengeTasks(docId: string | undefined, handler: any = null) {
    // Cancel challenge tasks
    this.firebaseService.callCloudFunction('cancelChallengeTrigger', {
      userId: this.userService.getCurrentUserId(),
      willDeleteChallenge: !!handler,
      docId: docId
    })
      .subscribe(
        async () => {
          console.log("cancelled challenge tasks")
          if (handler)
            await handler()
        })
  }

  deleteChallenge(c: Challenge, onCompletion: any = null) {
    console.log("deleteChallenge", c)
    this.deletingChallenge$.next(true)
    this.cancelChallengeTasks(c.docId,
      async () => {
        await this.firebaseService.delete(this.challengeDocPath(c.docId))
        this.deletingChallenge$.next(false)
        // Record event
        this.eventService.recordChallengeEvent(Events.ChallengeDelete, {
          docId: c.docId,
          ownerDocId: c.ownerDocId,
          title: c.title,
          public: c.public,   // true if public
          owner: c.owner,     // true if owned by user
          name: c.name,
          startDate: c.startDate
        })
        if (onCompletion)
          onCompletion()
      })
  }

  async leaveChallenge(c: Challenge, onCompletion: any = null) {
    const now = nowstr()
    if (now < (c.startDate as string))
      this.deleteChallenge(c, onCompletion)
    else {
      await this.firebaseService.updateAt(this.challengeDocPath(c.docId), { abortedAt: now })
      this.cancelChallengeTasks(c.docId)
      // Record event
      this.eventService.recordChallengeEvent(Events.ChallengeLeave, {
        docId: c.docId,
        ownerDocId: c.ownerDocId,
        title: c.title,
        public: c.public,   // true if public
        owner: c.owner,     // true if owned by user
        name: c.name,
        startDate: c.startDate
      })
      if (onCompletion) {
        // console.log("Calling onCompletion", onCompletion)
        onCompletion()
      }
    }
  }

  async updateNickname(c: Challenge, nickname: string) {
    await this.firebaseService.updateAt(this.challengeDocPath(c.docId), { nickname })
  }

  async updateAllNicknames(nickname: string) {
    const challenges = await firstValueFrom(this.userChallenges$)
    for (const c of challenges.challenges) {
      console.log("updateAllNicknames", c.docId, nickname)
      await this.firebaseService.updateAt(this.challengeDocPath(c.docId), { nickname })
    }
  }

  async isNicknameUnique(nickname: string) {
    const nicknames = (await firstValueFrom(this.getChallengeNicknames())).map(n => n?.toLowerCase())
    return !nicknames.includes(nickname.toLowerCase())
  }

  // Return true if email is already participating or invited
  async isAlreadyInvited(challenge: Challenge, email: string): Promise<boolean> {
    // Check that email is not a userId in one of the existing group challenges
    if (challenge.invites?.includes(email))
      return true
    const challenges: Challenge[] = await firstValueFrom(this.getGroupChallenges(challenge.ownerDocId as string))
    return !!challenges.find(c => c.userId === email)
  }

  replaceTokens(str: string, challenge: Challenge) {
    return str
      .replace("$title", challenge.shortName || challenge.title)
      .replace("$chat", challenge.title)
      .replace("$shortName", challenge.shortName ? articlize(challenge.shortName) : "a cheaseed challenge")
      .replace("$inviter", this.userService.getCurrentUser().name || 'A friend')
  }

  async generateBranchLink(challenge: Challenge) {

    const analytics = {
      channel: 'app',
      feature: 'challenge',
      campaign: 'challenge',
    }

    const og_image = this.contentService.getGlobal("challenge.social.imageurl")
    const og_title = this.replaceTokens(this.contentService.getGlobal("challenge.social.title"), challenge)
    const og_description = this.replaceTokens(this.contentService.getGlobal("challenge.social.description"), challenge)
    const shareText = this.contentService.getGlobal(CHALLENGESHARE_MSG) || 'Join this chea seed challenge!'

    // optional fields
    const properties = {
      $desktop_url: 'https://www.cheaseed.com',
      inviter: this.userService.getCurrentUserId(),
      challenge: challenge.name,
      challengeDocId: challenge.docId,
      $deeplink_path: `/challenge-join/${challenge.docId}`,
      $og_title: og_title,
      $og_description: og_description,
      $og_image_url: og_image,
      // $og_url: 'https://www.cheaseed.com'
      $twitter_card: 'summary_large_image',
      $twitter_title: og_title,
      $twitter_description: og_description,
      $twitter_image_url: og_image,
      $twitter_site: '@cheaseed'
    }

    console.log("GenerateBranchLink properties", properties)
    return await this.mediaService.shareBranchDeepLink(analytics, properties, shareText)
  }

  async invite(challenge: Challenge) {
    const result:any = await this.generateBranchLink(challenge)
    if (result?.completed)
      this.eventService.recordShareContent({ id: 'challenge-invite', app: result.app })
  }

  getNickname() {
    return this.userService.getUserKey(NICKNAME_KEY)
  }

  async checkNickname(newval: string, handler: any = null) {
    const currval = this.userService.getUserKey(NICKNAME_KEY)
    console.log("checkNickname", currval, newval)
    if (newval && newval !== currval) {
      const unique = await this.isNicknameUnique(newval)
      if (unique) {
        this.userService.setUserKey(NICKNAME_KEY, newval)
        await this.updateAllNicknames(newval)
        if (handler)
          await handler(newval)
        return true
      }
      else {
        await this.utilityService.notify({
          header: `${newval} is already in use.`,
          message: `Please choose another handle.`
        })
      }
    }
    return false
  }


  async promptForNickname(changeFlag = false, handler: any = null) {
    // Early exit if already defined and we don't want to change it
    if (!changeFlag && this.getNickname())
      return
    const alert = await this.alertController.create({
      header: 'Enter your chea handle',
      message: 'Please set the handle you want to use in challenge leaderboards.',
      inputs: [
        {
          name: 'handle',
          type: 'text',
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: null
        },
        {
          text: 'OK',
          handler: async data => await this.checkNickname(data.handle, handler)
        }
      ]
    })
    await alert.present()
  }
}
