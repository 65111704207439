import { Entry } from "./entry"
import { areObjectsEqual } from "./utilities"

export interface StatsProvider {
    getAttributeSpec(attr:string): any,
    getGlobal(key:string): string
}

export function computeDistributions(
    entryType: string,
    inputAttributes: string[],
    outputAttribute: string,
    entries: any[]) {
    
    const data = (entries || []).filter(entry => entry.indexType() === entryType)
    const result:Record<string, any> = {}
    // Count all input values for each output value
    data.forEach(entry => {
        const outval = entry.attributes[outputAttribute]
            const hist:any = result[outval] ? result[outval]['dist'] : {}
            inputAttributes.forEach(a => {
                const input = hist[a] || {}
                const val = entry.attributes[a]
                if (!hist[a])
                    hist[a] = {}
                hist[a][val] = (input[val] || 0) + 1
            })
            if (!result[outval])
                result[outval] = {}
            result[outval]['dist'] = hist            
    })
    Object.keys(result).forEach(outval => {
        const summary:Record<string,string> = {}
        const hist:any = result[outval] ? result[outval]['dist'] : {}
        inputAttributes.forEach(a => {
            let maxval = 0
            Object.keys(hist[a]).forEach(v => {
                // console.log(`Comparing hist[${a}][${v}] ${hist[a][v]} to ${maxval}`, summary)
                if (hist[a][v] > (maxval || 0)) {
                    maxval = hist[a][v]
                    summary[a] = v
                    // console.log(`Updating ${outval} max for ${a} to ${v}, ${maxval}`, summary)
                }
            })
        })
        result[outval]['summary'] = summary
    })
    result['numEntries'] = data?.length || 0
    result['elapsedDays'] = 999
    return result
}
    
export function computeInsights(
    entryType:string, 
    inputAttributes: string[], 
    outputAttribute: string,
    outputLowValue: string,
    outputHighValue: string,
    minNumEntries: number,
    minElapsedDays: number,        
    entries: Entry[] | null,
    attributeSpecProvider: StatsProvider
    ) {

    const data = computeDistributions(entryType, inputAttributes, outputAttribute, entries)
    let state
    if (data.numEntries < minNumEntries)
        state = 2
    else {
        const lowSummary = data[outputLowValue] ? data[outputLowValue]['summary'] : null
        const highSummary = data[outputHighValue] ? data[outputHighValue]['summary'] : null
        state = !lowSummary || !highSummary || areObjectsEqual(lowSummary, highSummary) ? 3 : 4
        if (state === 4) {
            data.lowChips = []
            data.highChips = []
            inputAttributes.forEach(a => { 
                const spec = attributeSpecProvider.getAttributeSpec(`${entryType}.${a}`)
                const lowDesc = spec.optionLinks.find((item:any) => item.name == lowSummary[a])?.description || lowSummary[a]
                const highDesc = spec.optionLinks.find((item:any) => item.name == highSummary[a])?.description || highSummary[a]
                data.lowChips.push({ label: spec.shortQuestion || spec.question, value: lowDesc })
                data.highChips.push({ label: spec.shortQuestion || spec.question, value: highDesc })
            })
        }
    }
    // console.log("computeInsights", {data, state})
    return { 
        ...data, 
        state
    }
}
    
export function generateInsightSummary(
    term: string,
    entryType:string, 
    inputAttributes: string[], 
    outputAttribute: string,
    outputLowValue: string,
    outputHighValue: string,
    minNumEntries: number,
    minElapsedDays: number,
    inconclusiveMessage: string,
    conclusiveMessage: string,
    tooFewEntriesMessage: string,
    tooFewDaysMessage: string,
    entries: any[],
    attributeSpecProvider: StatsProvider) {
    
    const data:any = computeInsights(
        entryType, 
        inputAttributes, 
        outputAttribute,
        outputLowValue,
        outputHighValue,
        minNumEntries,
        minElapsedDays,
        entries,
        attributeSpecProvider)

    if (data.state === 4) {
        const highest = `The highest ${term} is when ${data.highChips.map((item:any) => `${item.label} is '${item.value}'`).join(', ')}.`
        const lowest = `The lowest ${term} is when ${data.lowChips.map((item:any) => `${item.label} is '${item.value}'`).join(', ')}.`
        return `${highest}.\n${lowest}.`
    }
    else if (data.state === 3) {
        return inconclusiveMessage
    }
    else if (data.state === 2) {
        return tooFewEntriesMessage
    }
    else if (data.state === 1) {
        return tooFewDaysMessage
    }
    else 
        return "Missing summary"
}

export function generateSatisfactionInsightsSummary(
    entries: Entry[] | null,
    provider: StatsProvider) {
        // console.log("generateSatisfactionInsightsSummary", entries, provider)
        return generateInsightSummary(
            'Satisfaction',
            'TrackSatisfactionAction',
            [ 'typeOfWork', 'actionTime', 'workingWith' ],
            'satisfaction',
            '1',
            '3',
            parseInt(provider.getGlobal('satInsights.minNumEntries')),
            parseInt(provider.getGlobal('satInsights.minElapsedDays')),
            provider.getGlobal('satInsights.inconclusive.message'),
            provider.getGlobal('satInsights.conclusive.message'),
            provider.getGlobal('satInsights.tooFewEntries.message'),
            provider.getGlobal('satInsights.tooFewDays.message'),
            entries,
            provider)
}