import { Inject, Injectable, inject } from '@angular/core';
import { filter, combineLatestWith, map, switchMap, BehaviorSubject, firstValueFrom, debounceTime, shareReplay, timer } from 'rxjs';
import { FirebaseService } from './firebase.service';
import { CheaseedUser, SharedUserService } from './shared-user.service';
import { getDoc, setDoc, where } from '@angular/fire/firestore'
import { UtilityService } from './utility.service';
import { AppSource, diff, nowstr, todaystr } from '@cheaseed/node-utils';
import { SharedMessageService, UserMessage } from './shared-message.service';
import { ContentService } from './content.service';
import { Actions } from './shared-event.service';
import { Haptics } from '@capacitor/haptics'
import { Platform } from '@ionic/angular';
import * as confetti from 'canvas-confetti'
import { AudioService } from './audio.service';

const POINTS_KEY = "user.points"
const NUM_DAYS_TO_SHARE_POINTS_KEY = "numDaysToSharePoints"

export interface Points {
  Track?: number;
  Learn?: number;
  Share?: number;
  Reflect?: number;
  invitee?: number;
  inviteeTimes?: Record<string, string>,
  Redemptions?: number;
  Bonus?: number;
}

@Injectable({
  providedIn: 'root'
})
export class PointsService {

  private userService = inject(SharedUserService)
  
  eventCreated$ = new BehaviorSubject<any>(null)
  streakUnit$ = new BehaviorSubject('week')

  activeStreak$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      switchMap(() => this.userService.getActiveUserStreak()),
      map(x => x[0]))
      
  userPoints$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      switchMap(() => this.userService.watchUserKey(null, POINTS_KEY)),
      debounceTime(300),
      map(doc => {
        if (doc) {
          const points = doc.value as Points
          const totalPoints = Object.values(points)
            .reduce((sum:number, x) => { return sum + (typeof x === 'number' ? x : 0) }, 0)
          const result = { points, totalPoints }
          // console.log("userPoints$", result)
          return result
        }
        else {
          // Use cached setUserKey to set initial value
          // this.userService.setUserKey(POINTS_KEY, {})
          const doc = this.userService.createUserKeyObject(POINTS_KEY, {}, {})          
          this.userService.writeUserKey(POINTS_KEY, doc) // don't await
          return { points: {}, totalPoints: 0 }
        }
      }),
      shareReplay(1)
    )
  
  pointsStats$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      combineLatestWith(
        this.userPoints$,
        this.activeStreak$,
        this.streakUnit$),
      debounceTime(150),
      map(([user, { points, totalPoints }, streak, streakUnit]) => {        
        const data = {
          numInvitees: Object.keys(points.inviteeTimes || {}).length,
          points,
          totalPoints,
          streak,
          streakValue: streak?.streak || 0,
          streakUnit
        }
        // console.log("pointsStats$", data)
        return data
      }),
      shareReplay(1)
    )

  private lastEventDay: string|undefined = undefined

  constructor(
    @Inject('environment') private environment: any,
    private platform: Platform,
    private firebase: FirebaseService,    
    private utilityService: UtilityService,
    private messageService: SharedMessageService,
    private contentService: ContentService,
    private audio: AudioService
  ) {} 

  setStreakUnit(unit:string) {
    if (['week', 'hour', 'minute'].includes(unit))
      this.streakUnit$.next(unit)
  }

  async getCurrentStreak() {
    let streak =  await firstValueFrom(this.activeStreak$)
    if (!streak)
      streak = await this.createStreak()
    return streak
  }

  async createStreak() {
    const nowstring = nowstr()
    const data = {
      createdAt: nowstring,
      updatedAt: nowstring,
      user: this.userService.getCurrentUserId(),
      endDate: "ACTIVE",
      streak: 0,
      period: "week"
    }
    const result = await this.firebase.updateAt(this.userService.getUserStreaksPath(), data)
    console.log("createStreak", data)
    return data
  }

  getStreaks() {    
    return this.firebase.collectionGroup$('streaks', 
      where('endDate', '==', 'ACTIVE'),
      where('period', '==', 'week'),
      where('updatedAt', '<', nowstr()))
  }

  // Streaks run Sunday-Saturday
  // On Sunday AM, we run a checkWeekStreaks job to end any ACTIVE streaks that have had no points activity in the last week
  // Here we update an ACTIVE streak if it hasn't already been updated this week
  async updateStreak() {
    const streak = await this.getCurrentStreak(),
        lastUpdate = streak.updatedAt,
        nowstring = nowstr(),
        dateDiff = diff(nowstring, lastUpdate, this.streakUnit$.value)
    // console.log("updateStreak", streak, lastUpdate)
    if (streak.streak == 0 || dateDiff !== 0) {
      const docpath = `${this.userService.getUserStreaksPath()}/${streak.docId}`
      // console.log("updateStreak diff", { diff })
      if (dateDiff <= 1) {
        // Extend active streak
        console.log("updateStreak incrementing to", streak.streak + 1)
        await this.firebase.updateAt(docpath, { streak: streak.streak + 1, updatedAt: nowstring })
      }
      else if (dateDiff > 1) {
        // Close active streak
        console.log("updateStreak closing", docpath)
        await this.firebase.updateAt(docpath, { endDate: nowstring })
        await this.createStreak()
      }
    }
  }

  setPoints(data: Points) {
    console.log("setPoints", data)
    this.firebase.updateAt(this.userService.getUserKeyPath(null, POINTS_KEY), { value: data })
  }

  async adjustPoints(key: string, numPoints: number) {
    const data:any = await firstValueFrom(this.userPoints$)
    const points = data.points
    // console.log('adjustPoints', key, numPoints, points)
    points[key] = (points[key] || 0) + numPoints
    this.setPoints(points)
    if (this.environment.appSource !== AppSource.Web && numPoints > 0)
      await this.notify(numPoints)
  }

  async redeemPoints(numPoints: number) {
    await this.adjustPoints('Redemptions', 0 - numPoints)
  }

  async updatePoints(category: string, numPoints: number) {
    // console.log("updatePoints", category, numPoints)
    await this.updateStreak()
    await this.adjustPoints(category, numPoints)
    // Check if inviter eligible for points sharing
    // If so, write invitee points in inviter's user document
    // Note: a cloud function would result in frequent invocations, high cost
    const invitee = this.userService.getCurrentUser(),
          daysToShare = this.contentService.getGlobal(NUM_DAYS_TO_SHARE_POINTS_KEY) || 9999,
          daysSinceCreated = diff(invitee?.createdAt, null, 'day')
    // console.log("createPointsEvent", {daysToShare, daysSinceCreated, invitee})
    if (invitee?.invitedBy && daysSinceCreated <= daysToShare) {
      // console.log("will call sharePointsWithInviter with", {invitee, points})
      this.sharePointsWithInviter(invitee, numPoints)
    }
  }

  async triggerPoints(eventType: string, category: string, points: number, obj: any) {
    // console.log("triggerPoints", {eventType, category, points, obj})
    // Create event in firestore
    const event = this.userService.putUserEvent({ ...obj, eventType, category, points, action: Actions.Points })
    // Update points for user and inviter, if appropriate
    await this.updatePoints(category, points)
    // Broadcast event for home items use
    if (category !== 'Bonus') {
      // console.log("triggerPoints", {event})
      this.eventCreated$.next(event)
    }
  }

  async sharePointsWithInviter(invitee: CheaseedUser, pointsEarned:number) {
    const inviter = invitee.invitedBy
    // TODO: Check that inviter exists as a user
    // console.log("sharePointsWithInviter", "entering with", invitee, pointsEarned)
    // Add pointsEarned.points to invitee category of points key of inviter user
    const inviterPointsRef = this.userService.getUserKeyDocRef(POINTS_KEY, inviter)
    const doc = await getDoc(inviterPointsRef),
          data = doc.data(),
          points = data ? data.value : {}
    points.invitee = (points.invitee || 0) + pointsEarned
    points.inviteeTimes = points.inviteeTimes || {}
    // record the most recent day of the invitee's points
    // used to track the # of active invitees the inviter has
    const id = invitee.docId as string
    const lastTime = points.inviteeTimes[id]
    points.inviteeTimes[id] = todaystr()
    const obj = this.userService.createUserKeyObject(POINTS_KEY, data, points)
    console.log("sharePointsWithInviter", `updating ${inviter} ${POINTS_KEY}`, data)
    await setDoc(inviterPointsRef, obj)

    // If first shared points of the day from this invitee, add a message for the inviter
    // First check for previous message for today in this session
    if (!this.lastEventDay || this.lastEventDay !== todaystr()) {
      this.lastEventDay = todaystr()
      console.log("sharePointsWithInviter", `detected first call of day in session`, this.lastEventDay)
      // Next check if invitee key exists for today in inviter's userkeys
      // This key is also how we track the # of active invitees the inviter has
      if (!lastTime || lastTime !== this.lastEventDay) {
        // Write the message document for today
        const messagesPath = this.userService.getUserMessagesPath(inviter)
        const message:UserMessage = this.messageService.createUserMessage(inviter as string)
        const today = todaystr('MMM dd YYYY')
        message.title = `You earned points from ${invitee.name || 'a friend'} on ${today}`
        message.subtitle = ''
        this.firebase.updateAt(messagesPath, message)
        console.log("sharePointsWithInviter", `wrote message to ${messagesPath}`, message)
      }
    }
  }

  private async notify(points: number) {
    // Run all these effects at the same time
    this.utilityService.presentToast(`+${points} xp`, { 
      color: null,
      cssClass: "points-toast", 
      duration: points >= 10 ? 2000: 1000, 
      position: "top",
    } )

    const options = {
      // shapes: ['square'],
      particleCount: 100,
      startVelocity: 10,
      ticks: points >= 10 ? 800: 400,
      spread: 180,
      origin: {
          y: 0.5,
          x: 0.65
      }
    }
    confetti.create()(options)
    if (this.platform.is('capacitor'))
      Haptics.vibrate({ duration: 500 })

    if (this.userService.isSoundEnabled()) 
      this.audio.play('confetti')
    
    // Delay 2 secs for confetti and sound to finish
    await firstValueFrom(timer(2000))

    // 7/12/2021 Decided to remove sound
    // this.audio.play('pop')

    // let myCanvas = document.createElement('canvas');
    // document.appendChild(myCanvas);
    // var myConfetti = confetti.create(myCanvas, {
    //   resize: true,
    //   useWorker: false
    // });
    // myConfetti(options)
  }

}
