import { Injectable } from '@angular/core'
import { ContentService } from './content.service'
import { FirebaseService } from './firebase.service'
import { SharedUserService } from './shared-user.service'
import { UtilityService } from './utility.service'
import { formatDate } from '@cheaseed/node-utils'

const WORKDAYS = "StatsConfig.workDays"
const STARTWORKTIME = "StatsConfig.startTime"
const ENDWORKTIME = "StatsConfig.endTime"
const REMINDERFREQUENCY = "StatsConfig.reminderFrequency"

@Injectable({
  providedIn: 'root'
})
export class TaskSchedulerService {
  userKeys: any

  constructor(
    private userService: SharedUserService,
    private contentService: ContentService,
    private firebase: FirebaseService,
    private utility: UtilityService
  ) { }

  // Main entry point, called from evaluator
  scheduleByType(taskSetType: string, context: any) {
    this.userKeys = context
    this.contentService.getTaskSetsOfType(taskSetType)
        .forEach(ts => {
          // console.log("scheduleByType processing", ts)
          if (taskSetType === "SatisfactionReminder" && ts.perpetual)
            this.setSatisfactionRemindersFromKeys(ts.taskSetName)
              .subscribe(result => {
                console.log("scheduled taskSet with computed payload", result)  

                // Generate formatted moments for display
                // result.data.params is undefined if the task wasnt scheduled because invalid hours 
                // were specified
                const plan = result.data.params?.schedule.map(item => formatDate(item, "EEE MM/dd ha"))
                if(plan)
                  this.userService.setUserKey(ts.taskSetName, plan)  // can't use dot notation in #each
              })
          else 
            this.scheduleTaskSet(ts)
          // Update schedule.taskSetName immediately, since we don't observce userkey changes from cloud functions
          this.userService.setUserKey(`schedule.${ts.taskSetName}`, true)
        })
  }

  schedule(taskSetName: string) {
    const ts = this.contentService.getTaskSetNamed(taskSetName)
    this.scheduleTaskSet(ts)
  }

  cancelByType(taskSetType: string) {
    this.contentService.getTaskSetsOfType(taskSetType)
        .forEach(ts => this.cancel(ts.taskSetName))
  }

  cancel(taskSetName: string) {
    const data = {
      email: this.userService.getCurrentUserId(),
      taskSetName: taskSetName
    }
    this.firebase.callCloudFunction("cancelNotification", data).subscribe(() => {
      console.log("cancelled taskSet", data.taskSetName)
      this.userService.setUserKey(taskSetName, null)  // removes previously stored plan description
      this.userService.setUserKey(`schedule.${taskSetName}`, false)
    })
  }

  // Run all tasksets of type SatisfactionReminder
  scheduleSatisfactionRemindersFromData(taskSetName:string, data: any) {
    this.utility.setSatisfactionRemindersFromData(this.userService.getCurrentUser(), taskSetName, data)
      .subscribe(() => {
        console.log("scheduled taskSet", data)
      })
  }

  /**
   * Schedule satisfaction reminders based on user's current keys
   * 
   * @param taskSetName the taskset name
   */
  setSatisfactionRemindersFromKeys(taskSetName:string) {
    const data = {
        end_work: parseInt(this.userKeys[ENDWORKTIME]),       // arrives as 0800...1700
        start_work: parseInt(this.userKeys[STARTWORKTIME]),   // arrives as 0800...1700
        days: this.userKeys[WORKDAYS],                        // arrives as object, e.g. { monday: true, tuesday: true, friday: true }
        freq: this.userKeys[REMINDERFREQUENCY]                // High, medium, or low
    } 
    return this.utility.setSatisfactionRemindersFromData(this.userService.getCurrentUser(), taskSetName, data)
  }

  /**
   * Schedule satisfaction reminders based on data passed in from content as code
   * e.g. in OnCompletion, there m
   * 
   * @param taskSetName the taskset name
   */
  /*setSatisfactionRemindersFromData(taskSetName: string, data: any) {
    let days = data.dayString ?
                Object.fromEntries(Object.values(data.dayString).map((i:string) => [ dayList[i], true ])) :
                data.days
    let freq = [levelHigh, levelMedium, levelLow].includes(data.freq) ? 
                data.freq : 
                levelLow
    let params = {
        taskSetName: taskSetName,
        email: this.userService.getCurrentUserId(),
        numWeeksToSchedule: 2,
        timezone: this.userService.timezone,
        end_work: data.end_work,
        start_work: data.start_work,
        days: days,
        freq: freq
    } 
    return this.firebase.callCloudFunction("scheduleSatisfactionReminders", params)
  }
*/

  async scheduleTaskSet(taskSet, updatable = true) {
    //TODO - cloud functions support the isNotRescheduleable attribute
    // Use instead of the code below
    if (!updatable) {
      // only proceed if task is not already present in Firebase
      // e.g. Followups are not updatable
      const path = `users/${this.userService.getCurrentUserId()}/tasks/${taskSet.taskSetName}`
      const task = await this.firebase.getFirestoreDoc(path)
      if (task) {
        console.log(`Found taskset already scheduled at ${path} document`)
        return
      }
    }

    // Exit if taskSet is OneTimeOnly and already ran
    const onetime = taskSet.behaviors?.includes("OneTimeOnly")
    const oneTimeKey = taskSet.taskSetName + "Scheduled"
    if (onetime && this.userService.getUserKey(oneTimeKey))
        return

    const data:any = Object.assign({}, taskSet)
    data.email = this.userService.getCurrentUserId()
    // Reset the timeOfMessage on the taskSet to the first non-null timeOfMessage on a task, if any

    data.timeOfMessage = taskSet.tasks.map(t => 
      t.timeOfMessage ? this.userService.getUserKey(t.timeOfMessage): undefined
    ).find(t => t)
    
    this.firebase.callCloudFunction('scheduleNotifications', data).subscribe(_ => {
      console.log("scheduled taskSet", data)
      // Update if OneTimeOnly taskset
      if (onetime)
        this.userService.setUserKey(oneTimeKey, true)  
    })
  }
}
