import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SlackService {
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
    responseType: 'text' as 'json',
  };

  constructor(
    @Inject('environment') private environment: any,
    private http: HttpClient,
  ) {}

  postErrorOnSlack(
    userId: string,
    deviceInfo: string,
    error: Error,
    stackFrame: StackTrace.StackFrame[],
  ): void {
    const message = {
      channel: this.environment.globalErrors.channelName,
      text: error.message ? error.message : 'Error',
      attachments: [
        {
          author_name: window.location.href,
          color: 'danger',
          title: `Stack Trace for ${userId} Device Info: ${deviceInfo}`,
          text: stackFrame.toString().replace(/,/g, '\n'), //error.stack
        },
      ],
    };
    this.http
      .post(this.environment.globalErrors.slackURL, message, this.options)
      .pipe(take(1))
      .subscribe((data) => console.log('Posted to slack', data));
  }
}
