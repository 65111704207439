import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NativeAudio } from '@capacitor-community/native-audio';

interface Sound {
    key: string;
    asset: string;
}

const AUDIO_PATH = 'assets/shared/audio/'

const ASSETS:Sound[] = [
    { key: 'next', asset: 'score.mp3' },
    { key: 'prev', asset: 'score0.mp3' },
    { key: 'clink', asset: 'oak5.mp3' },
    { key: 'boing', asset: 'boing.wav' },
    { key: 'pop', asset: 'tear_paper.wav' },
    { key: 'confetti', asset: 'confetti.mp3' }
]

@Injectable({
    providedIn: 'root'
})
export class AudioService {

    private sounds: Map<string, Sound> = new Map()
    private audioPlayer: HTMLAudioElement = new Audio()
    private forceWebAudio = false
    private isNative = false

    constructor(private platform: Platform) { }

    initialize() {
        this.platform.ready()
            .then(() => {
                this.isNative = this.platform.is('capacitor')
                ASSETS.forEach(asset => this.preload(asset.key, AUDIO_PATH + asset.asset))
            })
    }

    preload(key: string, asset: string): void {
        if (!this.sounds.get(key)) {
            if (this.isNative && !this.forceWebAudio) {
                asset = "public/" + asset   // see https://github.com/capacitor-community/native-audio/issues/9
                try {
                    NativeAudio.unload({assetId: key})
                }
                catch(e) {
                    console.warn(`Preload audio assets - failed to unload ${key}`, e)
                }
                NativeAudio.preload({ assetPath: asset, assetId: key })
            }
            else {
                const audio = new Audio()
                audio.src = asset
            }
            this.sounds.set(key, { asset: asset, key: key })
            // console.log(this.sounds)
        }
    }

    play(key: string): boolean {
        // console.log('play', key)
        const soundToPlay = this.sounds.get(key)
        if (soundToPlay) {
            if (this.isNative) {
                try {
                    console.log('ABOUT TO PLAY sound')
                    NativeAudio.play({ assetId: soundToPlay.key, time: 0.0 })
                }
                catch (err) {
                    console.error('play error', JSON.stringify(soundToPlay), err)
                }
            }
            else {
                this.audioPlayer.src = soundToPlay.asset
                this.audioPlayer.play()
                    .catch(err => { console.error(err) }) // ignore web player errors
            }
            return true
        } else {
            return false
        }
    }
}