import { Injectable } from '@angular/core';
import { filter, firstValueFrom, map, Observable, of, tap } from 'rxjs';
import { ContentService } from './content.service';
import { FirebaseService } from './firebase.service';
import { SharedUserService } from './shared-user.service';
import { addstr, nowstr } from '@cheaseed/node-utils';

export const UNREAD = 'UNREAD';
export const READ = 'READ';
export const EXPIRED = 'EXPIRED';
export const lastViewedKey = 'user.lastMessagesViewTime';
export const ENGAGEMENT_TYPE = 'Engagement'
export const NOTIFICATION_TYPE = 'Notification'
export interface UserMessage {
  createdAt: string,
  expiresAt: string,
  viewedAt?: string,
  detail?: string,
  status: string,
  title?: string,
  subtitle?: string,
  type: string,
  userid: string,
  docId?: string
}

@Injectable({
  providedIn: 'root',
})
export class SharedMessageService {
  unreadMessages$: Observable<any> = of(null)

  constructor(
    protected firebase: FirebaseService,
    protected userService: SharedUserService,
    protected contentService: ContentService
  ) {}

  setLastTimeViewed() {
    const now = nowstr();
    this.userService.setUserKey(lastViewedKey, now);
  }

  getUnreadMessagesForChat(id: string) {
    return this.unreadMessages$
      .pipe(
        filter(msgs => !!msgs),
        map((msgs:any) => msgs.messages.filter(msg => msg.status === UNREAD && msg.detail === id)))
  }

  initialize() {
    this.unreadMessages$ = this.userService.getUnreadMessages()
      .pipe(
        // Package summary info for unread messages
        map(messages => {
          const
            lastTimeViewed = this.userService.getUserKey(lastViewedKey),
            newMsgs = messages.filter(m => !lastTimeViewed || m.createdAt > lastTimeViewed),
            num = newMsgs.length,
            numUnreadMessagesLabel = num > 9 ? '9+' : num === 0 ? '' : num + '',
            firstEngagementMessage = messages.find(m => m.type === ENGAGEMENT_TYPE)
          return {
            messages,
            firstEngagementMessage,
            numUnreadMessagesLabel
          }
        }))
    // Clean up expired messages on initialization
    firstValueFrom(this.unreadMessages$)
      .then(msgs => this.expireUnreadMessages(msgs.messages))
      .catch(err => console.error('Error cleaning up expired messages', err))
  }

  async createMessage(userId: string, obj: any) {
    // console.log('createMessage', obj);
    const data = Object.assign({}, obj);
    data.userId = userId;
    data.createdAt = nowstr(); // this should be removed when sort keys allow nullable createdAt
    data.status = UNREAD;
    const ref = this.userService.getUserMessagesCollectionRef();
    data.id = await this.firebase.setData(ref, data);
  }

  createUserMessage(userId: string, type: string = NOTIFICATION_TYPE): UserMessage {
    const now = nowstr()
    return {
      userid: userId,
      createdAt: now,
      expiresAt: addstr( { weeks: 1 } ),
      title: undefined,
      subtitle: undefined,
      detail: undefined,
      status: UNREAD,
      type: type
    }
  }

  async putMessage(obj: any) {
    const msg = this.createUserMessage(this.userService.getCurrentUserId() as string)
    const data = { ...msg, ...obj }
    const ref = this.userService.getUserMessagesCollectionRef()
    return await this.firebase.setData(ref, data)
  }

  private async update(msg: UserMessage) {
    const data = { ...msg }
    delete data['docId']
    await this.firebase.updateAt(
      this.userService.getUserMessagePath(msg.docId as string),
      data
    )
    return msg
  }

  async viewMessage(msg: UserMessage) {
    // console.log(msg)
    if (msg.status === UNREAD) {
      msg.status = READ
      msg.viewedAt = nowstr()
      return await this.update(msg)
    }
    else
      return null
  }

  expireUnreadMessages(data: UserMessage[]) {
    const now = nowstr()
    for (const m of data) {
      if (m.status === UNREAD && m.expiresAt <= now) 
        this.expireMessage(m)
    }
  }

  async expireMessage(msg: UserMessage) {
    msg.status = EXPIRED;
    return await this.update(msg);
  }

  async markChatAsViewed(id: string) {
    const msgs = await firstValueFrom(this.getUnreadMessagesForChat(id))
    for (const hit of msgs) 
      this.viewMessage(hit)
  }

  getMessageTitle(chat: any) {
    const key = `${chat.type}.title.template`.toLowerCase()
    return this.contentService.getGlobal(key)?.replace('$title', chat.title)
  }

  getMessageSubtitle(chat: any) {
    const key = `${chat.type}.subtitle.template`.toLowerCase()
    return this.contentService
      .getGlobal(key)
      ?.replace('$subtitle', chat.subtitle)
  }

  async generateChatMessage(userId: string, chat: any) {
    // Abort if message already exists in UNREAD state
    // Must be firstValueFrom, lastValueFrom doesn't return
    const hits = await firstValueFrom(this.getUnreadMessagesForChat(chat.name));
    if (hits.length > 0) return;
    const expireDate = addstr( { weeks: 1 } )
    const title = this.getMessageTitle(chat) || chat.title;
    const subtitle = this.getMessageSubtitle(chat) || chat.subtitle;
    await this.createMessage(userId, {
      type: chat.type,
      status: UNREAD,
      title: title,
      subtitle: subtitle,
      detail: chat.name,
      expiresAt: expireDate,
    });
  }

  async generateReminderMessage(
    userId: string,
    title: string,
    subtitle: string
  ) {
      // Abort if message already exists in UNREAD state
      const hits = await firstValueFrom(this.unreadMessages$)
      if (hits.messages.find(m =>
            m.type === 'Reminder' &&
            m.title === title &&
            m.subtitle === subtitle &&
            m.status === UNREAD))
        return
      const expireDate = addstr( { weeks: 1 } )
      await this.createMessage(userId, {
        type: 'Reminder',
        status: UNREAD,
        title: title,
        subtitle: subtitle,
        detail: null,
        expiresAt: expireDate,
      })
    }
}
