import { Injectable } from '@angular/core'
import { HIDDEN } from '@cheaseed/node-utils'
import { ChatQueueService } from './chat-queue.service'
import { ContentService } from './content.service'
import { SharedUserService } from './shared-user.service'

const deadlineLong = "deadlineLong"
const deadlineMid = "deadlineMid"
const deadlineShort = "deadlineShort"
const levelHigh = "level.high"
const levelMedium = "level.med"
const levelLow = "level.low"

// Career Goals attributes, rated high/med/low
const TIME_MANAGEMENT = "Onboarding.timeManagement"
const PERSONAL_BRAND = "Onboarding.personalBrand"
const COMMUNICATION_SKILLS = "Onboarding.communicationSkills"
const PAID_FAIRLY = "Onboarding.paidFairly"
const ADVANCE_AT_WORK = "Onboarding.advanceAtWork"
const BETTER_SELF_ADVOCATE = "Onboarding.betterSelfAdvocate"
const BETTER_NEGOTIATOR = "Onboarding.betterNegotiator"
const BE_UNSTUCK = "Onboarding.beUnstuck"
const MORE_AT_WORK = "Onboarding.wantMoreAtWork"
const MORE_MEANING_OR_IMPACT = "Onboarding.moreMeaningOrImpact"
// const LEARN_MORE = "Onboarding.learnMore"

@Injectable({
  providedIn: 'root'
})
export class SequenceAdvisorService {
  userKeys: {}
  sequence: string[]

  constructor(
      protected contentService: ContentService,
      protected userService: SharedUserService,
      protected chatQueueService: ChatQueueService
  ) {}

  // Main entry point, called from evaluator
  async invoke(goal, context) {
      this.userKeys = context
      switch(goal) {
        case "highestStuck" : 
          this.handleStuck()
          break
        case "highestMeaningImpact" : 
          this.handleMeaningImpact()
          break
        case "highestMoreOfSomething" : 
          this.handleMoreOfSomething()
          break
        case "highestAdvance" : 
          this.handleAdvance()
          break
        case "highestPaidFairly" : 
          this.handlePaidFairly()
          break
        case "highestNegotiator" : 
          this.handleNegotiator()
          break
        case "highestSelfAdvocate" : 
          this.handleSelfAdvocate()
          break
        default:
          this.handleExploreMore()
          break
      }
      console.log("SequenceAdvisor computed", this.sequence)
      let seriesList = this.sequence.map(code => this.contentService.getSeriesByCode(code))
      seriesList = seriesList.filter(s => !s.behaviors?.includes(HIDDEN))  // Remove Hiddens
      console.log({ seriesList })
      // Assign curent sequence to seriesSequence 
      this.userService.setUserKey("user.seriesSequence", this.sequence)
      this.userService.setUserKey("seriesSequenceTitles", seriesList.map(s => s.id))   // can't use dot notation in handlebars #each statement
      // Use handlebars to render user.seriesSequence in statment textBlock as a list
      // Insert seriesList into playlist
      const allChats = []
      for (const series of seriesList)
        if (series.hasPopulatedChats) {
            for (const item of series.conversations)
                if (item.statements.length > 0)
                    allChats.push(item.id)    
        }
      await this.chatQueueService.addMultipleToPlaylist(allChats)
    }

    // 2	$Onboarding.ImpComm === 'High'	Append EC-2
    // 3	$Onboarding.ImpBrand === 'High'	Append EB-2
    // 4	$Onboarding.ImpTime === 'High'	Append ET-1, ET-2

    handleCommBrandTime(deadline) {
      if (this.isHigh(COMMUNICATION_SKILLS) && this.isDeadlineLong(deadline))
        this.moveLast("EC-2")
      if (this.isHigh(PERSONAL_BRAND) && this.isDeadlineLong(deadline))
        this.moveLast("EB-2")
      if (this.isHigh(TIME_MANAGEMENT) && this.isDeadlineLong(deadline))
        this.moveLast(["ET-1", "ET-2"])
    }
  
  // TRUE	Set CP-4, CP-1, CP-2, NG-1, NG-3, NG-5, SA-1, SA-2, SA-3, SA-4, EC-1
  // $Onboarding.Timeline === 'SHORT' 	Remove CP-1, CP-2
  // $Onboarding.Timeline === 'SHORT' and ($Onboarding.BetterAdvocate ==='Low' or $Onboarding.BetterAdvocate ==='Skip')	Remove SA-1, SA-2, SA-3, SA-4
  // $Onboarding.Timeline === 'SHORT' and ($Onboarding.BetterAdvocate ==='Med' or $Onboarding.BetterAdvocate ==='High')	Remove SA-1, SA-2, SA-4
  // $Onboarding.Timeline === 'SHORT' and ($Onboarding.ImpComm ==='Low' or $Onboarding.ImpComm ==='Med' or  $Onboarding.ImpComm ==='Skip') and ($Onboarding.BetterNegotiator === 'Low' or $Onboarding.BetterNegotiator === 'Med' or $Onboarding.BetterNegotiator ==='Skip')	Remove EC-1
  // ($Onboarding.PaidFair ==='Med' or $Onboarding.PaidFair ==='High')	Append NG-2 
  // $Onboarding.Timeline === 'MID'	Remove SA-4
  // ($Onboarding.ImpactDescribesBest === 'NeedHelpMaking')	Remove CP-1, CP-2, CP-4
  // ($Onboarding.ImpactDescribesBest === 'NeedHelpFiguring')	Remove CP-1, CP-2, EC-1
  // ($Onboarding.ImpactDescribesBest === 'NeedHelpFiguring')	Append NG-1, NG-3, NG-5, EC-1 
  // ($Onboarding.ImpactDescribesBest === 'NoIdea' or $Onboarding.ImpactDescribesBest === 'Skip')	
  // $Onboarding.ImpComm === 'High' and $Onboarding.Timeline === 'LONG'	Append EC-2
  // $Onboarding.ImpBrand === 'High' and $Onboarding.Timeline === 'LONG' 	Append EB-2
  // $Onboarding.ImpTime === 'High' and $Onboarding.Timeline === 'LONG'	Append ET-1, ET-2

  // NeedHelpMaking refers to the option meaningImpactKnow
  // NeedHelpFiguring refers to the option meaningImpactTypeI
  // NoIdea refers to the option meaningImpactGetMore
  // NeedToLearn refers to the same option meaningImpactKnow
  // NeedToFigureOutBe refers to the same option meaningImpactTypeI
  // NeedToHowToFind refers to the option meaningImpactTypeM
  // IdoNotKnow refers to the option meaningImpactGetMore

  handleStuck() {
      const deadline = "Onboarding.meaningImpactDeadline"
      const describes = "Onboarding.meaningImpactDescribes"
      this.sequence = [ "CP-4", "CP-1", "CP-2", "NG-1", "NG-3", "NG-5", "SA-1", "SA-2", "SA-3", "SA-4", "EC-1" ]
      if (this.isDeadlineShort(deadline))
          this.remove(["CP-1", "CP-2"])
      if (this.isDeadlineShort(deadline))
          this.remove(["SA-1", "SA-2", "SA-4"])
      if (this.equalsOneOf(BETTER_SELF_ADVOCATE, [null, levelLow]))
          this.remove("SA-3")
      if (this.isDeadlineShort(deadline) && this.equalsOneOf(COMMUNICATION_SKILLS, [null, levelMedium, levelLow]) && this.equalsOneOf(BETTER_NEGOTIATOR, [null, levelMedium, levelLow]))
          this.remove("EC-1")
      if (this.equalsOneOf("Onboarding.paidFairly", [levelMedium, levelHigh]))
          this.moveLast("NG-2")
      if (this.isDeadlineMid(deadline))
          this.remove("SA-4")
      if (this.equals(describes, "meaningImpactKnow"))
          this.remove([ "CP-1", "CP-2", "CP-4" ])
      if (this.equals(describes, "meaningImpactTypeI"))
          this.remove([ "CP-1", "CP-2", "EC-1" ])
      if (this.equalsOneOf(describes, [ null, "meaningImpactGetMore" ]))
          console.log("TODO: Unspecified sequence advisor action")
      this.handleCommBrandTime(deadline)
  }

  // 1	TRUE	Set NG-1, NG-3, CP-4, NG-2, NG-5, SA-1, SA-2, SA-3, SA-4, EC-1
  // 2	$Onboarding.Timeline === 'SHORT'	Remove SA-1, SA-2, SA-3, SA-4
  // 3	$Onboarding.Timeline === 'MID'	Remove SA-4
  // 4	"($Onboarding.WorkingMeaning === 'High' or $Onboarding.StuckUnstuck === 'High') and ($Onboarding.Timeline === ''MID'' or $Onboarding.Timeline === ''LONG'')"	MoveFirst CP-4
  // 5	"($Onboarding.WorkingMeaning === 'High' or $Onboarding.StuckUnstuck === 'High') and ($Onboarding.Timeline === ''SHORT'')"	MoveLast CP-4
  // 6	$Onboarding.WhatWantMoreOf === 'Satisfaction'	MoveFirst CP-4
  // 7	$Onboarding.WhatWantMoreOf === 'Promotion'	Replace NG-3 NG-7
  // 8	$Onboarding.ImpComm === 'High' and $Onboarding.Timeline === 'LONG'	Append EC-2
  // 9	$Onboarding.Timeline === 'SHORT' and $Onboarding.BetterAdvocate === 'High'	Append SA-3
  // 10	$Onboarding.ImpBrand === 'High' and $Onboarding.Timeline === 'LONG' 	Append EB-2
  // 11	$Onboarding.ImpTime === 'High' and $Onboarding.Timeline === 'LONG'	Append ET-1, ET-2

  handleMoreOfSomething() {     
      const deadline = "Onboarding.moreDeadline"
      this.sequence = [ "NG-1", "NG-3", "CP-4", "NG-2", "NG-5", "SA-1", "SA-2", "SA-3", "SA-4", "EC-1" ]
      if (this.isDeadlineShort(deadline))
          this.remove(["SA-1", "SA-2", "SA-3", "SA-4"])
      if (this.isDeadlineMid(deadline))
          this.remove("SA-4")
      if ((this.isHigh(MORE_MEANING_OR_IMPACT) || this.isHigh(BE_UNSTUCK)) && this.isDeadlineMidOrLong(deadline))
          this.moveFirst("CP-4")        
      if ((this.isHigh(MORE_MEANING_OR_IMPACT) || this.isHigh(BE_UNSTUCK)) && this.isDeadlineShort(deadline))
          this.moveLast("CP-4")        
      if (this.equals("Onboarding.moreOfWhat", "moreOfSatisfaction"))
          this.moveFirst("CP-4")
      if (this.equals("Onboarding.moreOfWhat", "moreOfPromotion"))
          this.replace("NG-3", "NG-7")
      if (this.isHigh(BETTER_SELF_ADVOCATE) && this.isDeadlineShort(deadline))
          this.moveLast("SA-3")
      this.handleCommBrandTime(deadline)
  }

  // 1	TRUE	Set CP-4, CP-1, CP-2, NG-1, NG-3, NG-5, SA-1, SA-2, SA-3, SA-4, EC-1
  // 2	$Onboarding.Timeline === 'SHORT' 	Remove CP-1, CP-2, SA-4
  // 3	$Onboarding.Timeline === 'SHORT' and ($Onboarding.BetterAdvocate ==='Low' or $Onboarding.BetterAdvocate ==='Skip')	Remove SA-1, SA-2, SA-3
  // 4	$Onboarding.Timeline === 'SHORT' and ($Onboarding.BetterAdvocate ==='Med' or $Onboarding.BetterAdvocate ==='High')	Remove SA-1, SA-2
  // 5	$Onboarding.Timeline === 'SHORT' and ($Onboarding.ImpComm ==='Low' or $Onboarding.ImpComm ==='Med' or  $Onboarding.ImpComm ==='Skip') and ($Onboarding.BetterNegotiator === 'Low' or $Onboarding.BetterNegotiator === 'Med' or $Onboarding.BetterNegotiator ==='Skip')	Remove EC-1
  // 6	($Onboarding.PaidFair ==='Med' or $Onboarding.PaidFair ==='High')	Append NG-2 
  // 7	$Onboarding.Timeline === 'MID'	Remove SA-4
  // 8	($Onboarding.ImpactDescribesBest === 'NeedToLearn')	Remove CP-1, CP-2, CP-4
  // 9	($Onboarding.ImpactDescribesBest === 'NeedToFigureOutBe')	Remove CP-1, CP-2
  // 9	($Onboarding.ImpactDescribesBest === 'NeedToFigureOutBe')	Remove EC-1
  // 10	($Onboarding.ImpactDescribesBest === 'NeedToFigureOutBe')	Append NG-1, NG-3, NG-5, EC-1 
  // 11	$Onboarding.BetterNegotiator ==='Med'	MoveFirst CP-4
  // 12	($Onboarding.ImpactDescribesBest === 'NeedToHowToFind' and ($Onboarding.BetterNegotiator ==='Low' or $Onboarding.BetterNegotiator ==='Med' or $Onboarding.BetterNegotiator ==='Skip')	Remove NG-1, NG-3, NG-5, EC-1
  // 13	$Onboarding.ImpactDescribesBest === 'IdoNotKnow'	
  // 14	$Onboarding.ImpComm === 'High' and $Onboarding.Timeline === 'LONG'	Append EC-2
  // 15	$Onboarding.ImpBrand === 'High' and $Onboarding.Timeline === 'LONG' 	Append EB-2
  // 16	$Onboarding.ImpTime === 'High' and $Onboarding.Timeline === 'LONG'	Append ET-1, ET-2

  // NeedHelpMaking refers to the option meaningImpactKnow
  // NeedHelpFiguring refers to the option meaningImpactTypeI
  // NoIdea refers to the option meaningImpactGetMore
  // NeedToLearn refers to the same option meaningImpactKnow
  // NeedToFigureOutBe refers to the same option meaningImpactTypeI
  // NeedToHowToFind refers to the option meaningImpactTypeM
  // IdoNotKnow refers to the option meaningImpactGetMore

  handleMeaningImpact() {
      const deadline = "Onboarding.meaningImpactDeadline"
      const describes = "Onboarding.meaningImpactDescribes"
      this.sequence = [ "CP-4", "CP-1", "CP-2", "NG-1", "NG-3", "NG-5", "SA-1", "SA-2", "SA-3", "SA-4", "EC-1" ]
      if (this.isDeadlineShort(deadline))
          this.remove(["CP-1", "CP-2", "SA-4"])
      if (this.isDeadlineShort(deadline) && this.equalsOneOf(BETTER_SELF_ADVOCATE, [ levelLow, null ]))
          this.remove(["SA-1", "SA-2", "SA-3"])
      if (this.isDeadlineShort(deadline) && this.equalsOneOf(BETTER_SELF_ADVOCATE, [ levelMedium, levelHigh ]))
          this.remove(["SA-1", "SA-2"])
      if (this.isDeadlineShort(deadline) && this.equalsOneOf(COMMUNICATION_SKILLS, [ levelMedium, levelLow, null ]))
          this.remove("EC-1")
      if (this.equalsOneOf(PAID_FAIRLY, [ levelMedium, levelHigh ]))
          this.moveLast("NG-2")
      if (this.isDeadlineMid(deadline))
          this.remove("SA-4")
      if (this.equals(describes, "meaningImpactKnow"))
          this.remove([ "CP-1", "CP-2", "CP-4" ])
      if (this.equals(describes, "meaningImpactTypeI"))
          this.remove([ "CP-1", "CP-2", "EC-1" ])
      if (this.equals(describes, "meaningImpactTypeI"))
          this.moveLast([ "NG-1", "NG-3", "NG-5", "EC-1" ])
      if (this.isMedium(BETTER_NEGOTIATOR))
          this.moveFirst("CP-4")
      if (this.equals(describes, "meaningImpactTypeM") && this.equalsOneOf(BETTER_NEGOTIATOR, [ levelMedium, levelLow, null ]))
          this.remove([ "NG-1", "NG-3", "NG-5", "EC-1" ])
      if (this.equals(describes, "meaningImpactGetMore"))
          console.log("TODO: Unspecified sequence advisor action")
      this.handleCommBrandTime(deadline)
  }

  // 1	TRUE	Set SA-1, SA-2, SA-3, SA-4, EC-1, EC-2
  // 2	$Onboarding.Timeline === 'SHORT' 	MoveFirst SA-3
  // 3	$Onboarding.Timeline === 'SHORT' and ($Onboarding.RateAdvocateSkills ==='Low' or $Onboarding.RateAdvocateSkills ==='Med' or $Onboarding.RateAdvocateSkills ==='Skip')	Remove SA-4, EC-1, EC-2
  // 4	($Onboarding.RateAdvocateSkills ==='High')	MoveFirst SA-2
  // 5	($Onboarding.WorkingMeaning === 'High' or $Onboarding.StuckUnstuck === 'High') and  ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Append CP-4
  // 6	($Onboarding.BetterNegotiator === 'High' or $Onboarding.PaidFair === 'High' or $Onboarding.WantAdvance === 'High' or $Onboarding.MoreOf === 'High') and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Append NG-1, NG-2, NG-3, NG-5
  // 7	($Onboarding.WantAdvance === 'High') and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Append NG-7
  // 0	$Onboarding.ImpComm === 'High' and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	MoveLast EC1, EC-2
  // 8	$Onboarding.ImpBrand === 'High' and ($Onboarding.Timeline === 'LONG')  	Append EB-2
  // 9	$Onboarding.ImpTime === 'High' and ($Onboarding.Timeline === 'LONG')  	Append ET-1, ET-2
  // 7	$Onboarding.PaidFair === 'High' 	MoveFirst NG-2

  handleSelfAdvocate() {
      const deadline = "Onboarding.selfAdvocateDeadline"
      const skills = "Onboarding.rateSelfAdvocacy"
      this.sequence = [ "SA-1", "SA-2", "SA-3", "SA-4", "EC-1", "EC-2" ]
      if (this.isDeadlineShort(deadline))
          this.moveFirst("SA-3")
      if (this.isDeadlineShort(deadline) && this.equalsOneOf(skills, [ levelMedium, levelLow, null ]))
          this.remove(["SA-4", "EC-1", "EC-2"])
      if (this.equals(skills, levelHigh))
          this.moveFirst("SA-2")
      if ((this.isHigh(MORE_MEANING_OR_IMPACT) || this.isHigh(BE_UNSTUCK)) && this.isDeadlineMidOrLong(deadline))
          this.moveLast(["CP-4"])
      if ((this.isHigh(BETTER_NEGOTIATOR) || this.isHigh(PAID_FAIRLY) || this.isHigh(ADVANCE_AT_WORK) || this.isHigh(MORE_AT_WORK)) && this.isDeadlineMidOrLong(deadline))
          this.moveLast(["NG-1", "NG-2", "NG-3", "NG-5"])
      if (this.isHigh(ADVANCE_AT_WORK) && this.isDeadlineMidOrLong(deadline))
          this.moveLast("NG-7")
      this.handleCommBrandTime(deadline)
      if (this.isHigh(PAID_FAIRLY))
          this.moveFirst("NG-2")
  }

  // 1	TRUE	Set NG-1, NG-2, NG-3, NG-5, EC-1
  // 2	$Onboarding.RateNegotiatorSkills === 'HIGH'	MoveFirst NG-2
  // 3	$Onboarding.MoreOf === 'High'	Append SA-3, CP-4
  // 4	($Onboarding.WorkingMeaning === 'High' or $Onboarding.StuckUnstuck === 'High') and  ($Onboarding.MoreOf === 'Low' or $Onboarding.MoreOf === 'Med' or $Onboarding.MoreOf === 'Skip')	InsertFirst CP-4
  // 5	($Onboarding.BetterAdvocate === 'High' and $Onboarding.MoreOf === 'High') and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Remove SA-3
  // 6	$Onboarding.BetterAdvocate === 'High' and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Append SA-1, SA-2, SA-3, SA-4
  // 7	$Onboarding.ImpComm === 'High' and $Onboarding.Timeline === 'LONG'	Append EC-2
  // 8	$Onboarding.ImpBrand === 'High' and $Onboarding.Timeline === 'LONG'  	Append EB-2
  // 9	$Onboarding.ImpTime === 'High' and $Onboarding.Timeline === 'LONG' 	Append ET-1, ET-2
  // 10	$Onboarding.PaidFair === 'High' 	MoveFirst NG-2
  // 11	$Onboarding.WantAdvance === 'High' and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Append NG-7
  // 12	($Onboarding.WantAdvance === 'High' or $Onboarding.MoreOf === 'High') and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Remove SA-3
  // 13	(($Onboarding.WantAdvance === 'High' and $Onboarding.MoreOf === 'High') and ($Onboarding.BetterAdvocate === 'Med' or $Onboarding.BetterAdvocate === 'Low' or $Onboarding.BetterAdvocate === 'Skip') ) and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Append SA-1, SA-2, SA-3

  handleNegotiator() {
      const deadline = "Onboarding.betterNegotiatorDeadline"
      const skills = "Onboarding.rateNegotiator"
      this.sequence = [ "NG-1", "NG-2", "NG-3", "NG-5", "EC-1" ]
      if (this.isHigh(skills))
          this.moveFirst("NG-2")
      if (this.isHigh(MORE_AT_WORK))
          this.moveLast([ "SA-3", "CP-4" ])
      if ((this.isHigh(MORE_MEANING_OR_IMPACT) || this.isHigh(BE_UNSTUCK)) || (this.equalsOneOf(MORE_AT_WORK, [ levelLow, levelMedium, null ])))
          this.moveFirst("CP-4")
      if (this.isHigh(BETTER_SELF_ADVOCATE) && this.isDeadlineMidOrLong(deadline))
          this.moveLast(["SA-1", "SA-2", "SA-3", "SA-4"])
      if (this.isHigh(BETTER_SELF_ADVOCATE) && this.isHigh(MORE_AT_WORK) && this.isDeadlineMidOrLong(deadline))
          this.remove("SA-3")
      this.handleCommBrandTime(deadline)
      if (this.isHigh(PAID_FAIRLY))
          this.moveFirst("NG-2")
      // TODO: Review these steps, not sure they make sense
      if (this.isHigh(ADVANCE_AT_WORK) && this.isDeadlineMidOrLong(deadline))
          this.append("NG-7")
      if ((this.isHigh(ADVANCE_AT_WORK) || this.isHigh(MORE_MEANING_OR_IMPACT)) && this.isDeadlineMidOrLong(deadline))
          this.remove("SA-3")
      if ((this.isHigh(ADVANCE_AT_WORK) && this.isHigh(MORE_MEANING_OR_IMPACT)) && (this.equalsOneOf(BETTER_SELF_ADVOCATE, [ levelMedium, levelLow, null ])) && this.isDeadlineMidOrLong(deadline))
          this.append(["SA-1", "SA-2", "SA-3" ])
  }

  // 1	TRUE	Set NG-2, NG-1, NG-3, NG-5
  // 2	$Onboard.JobTitle === 'Yes'	Append EN-1, EN-2
  // 3	$Onboard.HigherLevel === 'Yes'	Append NG-7
  // 4	($Onboarding.WorkingMeaning === 'High' or $Onboarding.StuckUnstuck === 'High') and  ($Onboarding.MoreOf === 'Low' or $Onboarding.MoreOf === 'Med' or $Onboarding.MoreOf === 'Skip')	InsertFirst CP-4
  // 5	($Onboarding.BetterAdvocate === 'High' or $Onboarding.MoreOf === 'High') and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Remove SA-3
  // 6		
  // 7	$Onboarding.BetterAdvocate === 'High' and $Onboarding.Timeline === 'LONG'  	Append SA-1, SA-2, SA-3, SA-4
  // 8	$Onboarding.ImpComm === 'High' and $Onboarding.Timeline === 'LONG' 	Append EC-2
  // 9	$Onboarding.ImpBrand === 'High' and $Onboarding.Timeline === 'LONG'	Append EB-2
  // 10	$Onboarding.ImpTime === 'High' and $Onboarding.Timeline === 'LONG'	Append ET-1, ET-2
  // 11	($Onboarding.WantAdvance === 'High' or $Onboarding.MoreOf === 'High') and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')  	Append NG-7, SA-1, SA-2, SA-3
  // 12	$Onboarding.MoreOf === 'High'	Append SA-3, CP-4

  handlePaidFairly() {
      const deadline = "Onboarding.betterNegotiatorDeadline"
      this.sequence = [ "NG-2", "NG-1", "NG-3", "NG-5" ]
      if (this.equals("Onboarding.paidFairlyForJob", "yes"))
          this.moveLast([ "EN-1", "EN-2"])
      if (this.equals("Onboarding.paidFairlyHigherLevel", "yes"))
          this.moveLast("NG-7")
      if ((this.isHigh(MORE_MEANING_OR_IMPACT) || this.isHigh(BE_UNSTUCK)) && this.equalsOneOf(MORE_AT_WORK, [levelLow, levelMedium, null]))
          this.moveFirst("CP-4")
      if ((this.isHigh(BETTER_SELF_ADVOCATE) || this.isHigh(MORE_AT_WORK)) && this.isDeadlineMidOrLong(deadline))
          this.remove("SA-3") // TODO: there is no SA-3 possible at this point
      if (this.isHigh(BETTER_SELF_ADVOCATE) && this.isDeadlineLong(deadline))
          this.append(["SA-1", "SA-2", "SA-3", "SA-4" ])
      this.handleCommBrandTime(deadline)
      if ((this.isHigh(ADVANCE_AT_WORK) || this.isHigh(MORE_AT_WORK)) && this.isDeadlineMidOrLong(deadline))
          this.append([ "NG-7", "SA-1", "SA-2", "SA-3" ])
      if (this.isHigh(MORE_AT_WORK))
          this.append([ "SA-3", "CP-4" ])
  }

  // 1	TRUE	Set NG-1, NG-3, NG-2, NG-7, SA-1, SA-2, SA-3, SA-4, EC-1
  // 2	$Onboarding.HigherLevel === 'Yes'	Remove NG-7
  // 3	$Onboarding.Promotion === 'Yes'	Remove SA-4
  // 4	$Onboarding.JustAdvance === 'Yes' and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')	Append EN-1, EN-2
  // 5	$Onboarding.Timeline === 'SHORT'	Remove SA-4
  // 6	($Onboarding.MoreOf === 'High'  or $Onboarding.WorkingMeaning === 'High' or $Onboarding.StuckUnstuck === 'High') and ($Onboarding.Timeline === 'MID' or $Onboarding.Timeline === 'LONG')	Append CP-4
  // 7	$Onboarding.ImpComm === 'High' and $Onboarding.Timeline === 'LONG' 	Append EC-2
  // 8	$Onboarding.ImpBrand === 'High' and $Onboarding.Timeline === 'LONG'	Append EB-2
  // 9	$Onboarding.ImpTime === 'High' and $Onboarding.Timeline === 'LONG'	Append ET-1, ET-2

  handleAdvance() {
      const deadline = "Onboarding.advanceDeadline"
      const how = "Onboarding.advanceHow"
      this.sequence = [ "NG-1", "NG-3", "NG-2", "NG-7", "SA-1", "SA-2", "SA-3", "SA-4", "EC-1" ]
      if (this.equals(how, "highestAdvNewCo"))
          this.remove("NG-7")
      if (this.equals(how, "highestAdvPromotion"))
          this.remove("SA-4")
      if (this.equals(how, "highestAdvOnly") && this.isDeadlineMidOrLong(deadline))
          this.append([ "EN-1", "EN-2" ])
      if (this.isDeadlineShort(deadline))
          this.remove("SA-4")
      if ((this.isHigh(MORE_AT_WORK) || this.isHigh(MORE_MEANING_OR_IMPACT) || this.isHigh(BE_UNSTUCK)) && this.isDeadlineMidOrLong(deadline))
          this.append("CP-4")
      this.handleCommBrandTime(deadline)
  }

  handleExploreMore() {
    this.sequence = [ "SA-1", "SA-2", "SA-3", "CP-4", "NG-2", "CP-1", "CP-2", "CP-3", "NG-1", "NG-3", "SA-5" ]
  }

  equals(key, val) { return this.userKeys[key] === val }
  equalsOneOf(key, vals) { return vals.includes(this.userKeys[key]) }
  isHigh(key) { return this.equals(key, levelHigh)}
  isMedium(key) { return this.equals(key, "level.medium")}
  isLow(key) { return this.equals(key, levelLow)}
  isDeadlineLong(key) { return this.equals(key, deadlineLong)}
  isDeadlineMid(key) { return this.equals(key, deadlineMid)}
  isDeadlineShort(key) { return this.equals(key, deadlineShort)}
  isDeadlineMidOrLong(key) { return this.equalsOneOf(key, [ deadlineMid, deadlineLong ]) }

  remove(items) {
      const arr = Array.isArray(items) ? items : [ items ]
      this.sequence = this.sequence.filter(item => !arr.includes(item))
  }

  moveFirst(item) {
      const index = this.sequence.indexOf(item)
      if (index > -1)
        this.sequence.splice(index, 1)
      this.sequence.unshift(item)
  }

  moveLast(items) {
      const arr = Array.isArray(items) ? items : [ items ]
      const result = this.sequence.filter(item => !arr.includes(item))
      this.sequence = result.concat(arr)
  }

  append(items) { this.moveLast(items)}

  replace(orig, repl) {
      const index = this.sequence.indexOf(orig)
      if (index > -1)
        this.sequence[index] = repl
  }
}