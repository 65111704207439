import { Injectable } from '@angular/core'
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs'
import { filter, map, tap } from 'rxjs/operators'
import { ProgramSpec, Step, Program, ProgramCategory } from '@cheaseed/node-utils'
import { ContentService } from './content.service'
import { FirebaseService } from './firebase.service'
import { SharedEventService } from './shared-event.service'
import { SharedUserService } from './shared-user.service'

export const CURRENTPROGRAM_KEY = 'user.currentProgram'
// const ENDPROGRAMALERT_HEADER_KEY = 'endprogramalert.header'
// const ENDPROGRAMALERT_MESSAGE_KEY = 'endprogramalert.message'

export enum FeedState {
  NEVER = "NEVER",  // never selected program
  NONE = "NONE",    // no current program selected
  SELECTED = "SELECTED",  // current program selected
  UNUSED = "UNUSED",  // current program selected but not used
}
interface ProgramState {
  state: FeedState,
  program?: Program | null,
  step: Step | null,
  action?: 'COMPLETED'
}

@Injectable({
  providedIn: 'root'
})
export class ProgramService {

  programSpecs: ProgramSpec[]
  programCategories: ProgramCategory[]
  userPrograms$:Observable<Program[]>
  private feedStateSubject = new BehaviorSubject<any>(null)
  public programFeedState$ = this.feedStateSubject.asObservable()

  constructor(
    protected userService: SharedUserService,
    private contentService: ContentService,
    private eventService: SharedEventService,
    private firebaseService: FirebaseService,
  ) { }

  reset() { }

  initialize() {
    this.contentService.loader$
      .pipe(filter(data => !!data))
      .subscribe(() => {
        this.programSpecs = Array.from(this.contentService.programMap.values())
        this.programCategories = Array.from(this.contentService.programCategoryMap.values())
        this.userPrograms$ = this.getUserPrograms()
        this.loadProgramState()
      })
  }

  setProgramFeedStateSubject(state: ProgramState) {
    // console.log("setProgramFeedStateSubject", state)
    this.feedStateSubject.next(state)
  }

  forceUnusedState() {
    const state = this.feedStateSubject.getValue()
    const prog = state?.program
    if (prog) {
      const d = new Date()
      d.setDate(d.getDate() - 100)
      // Force update to program
      prog.updatedAt = this.firebaseService.timestampFromDate(d)
      this.firebaseService.updateAt(this.programDocPath(prog.docId), { updatedAt: d })
      this.setProgramFeedStateSubject({
        state: this.computeProgramState(prog),
        program: prog,
        step: prog?.getNextStep()
      })  
    }
  }

  computeProgramState(program: Program) {
    return program ? 
      (program.lastUpdateDaysAgo() >= 30 ? 
        FeedState.UNUSED : 
        FeedState.SELECTED) :
      this.userService.hasUserKey(CURRENTPROGRAM_KEY) ?
        FeedState.NONE :
        FeedState.NEVER
  }

  async loadProgramState() { 
    const prog = await firstValueFrom(this.getCurrentProgram())
    const newState = this.computeProgramState(prog)
    this.setProgramFeedStateSubject({
      state: newState,
      program: prog,
      step: prog?.getNextStep()
    })
  }

  setProgramState(progname: string, stepname: string, completed = false) {
    const state = this.feedStateSubject.getValue()
    if (state) {
      if (progname && progname !== state.program?.programSpec.name)
        throw new Error(`Program mismatch: ${progname} !== ${state.program?.programSpec.name}`)
      const prog = state.program
      const step = prog.programSpec.getStep(stepname)
      const newState:ProgramState = {
        state: this.computeProgramState(prog),
        program: prog,
        step: step,
        action: completed ? 'COMPLETED' : undefined
      }
      this.setProgramFeedStateSubject(newState)
    }
  }

  programDocPath(id:string|null = null) {
    return `users/${this.userService.getCurrentUserId()}/programs${id ? '/' + id : ''}`
  }

  getUserPrograms() : Observable<Program[]>{
    return this.userService.getUserPrograms()
      .pipe(
        map(progs => progs.map(p => {
          if (p?.programSpec) {
            p.programSpec = this.contentService.programMap.get(p.programSpec.name)
            return new Program(p)
          }
          else
            return null
        })
      ))
  }

  getUserProgram(docId: string) {
    return docId ?
            this.firebaseService.doc$(this.programDocPath(docId)).pipe(
              map((p:Program) => {                
                if (p?.programSpec) {
                  p.programSpec = this.contentService.programMap.get(p.programSpec.name)
                  return new Program(p)
                }
                else
                  return null
              })
            ) :
            of(null)
  }

  getCurrentProgram() {
    const id = this.userService.getUserKey(CURRENTPROGRAM_KEY)
    return this.getUserProgram(id)
  }

  getCurrentProgramName(): string {
    const state = this.feedStateSubject.getValue()
    return state?.program?.programSpec?.name
  }

  getCurrentProgramCreatedAt() {
    const state = this.feedStateSubject.getValue()
    return state?.program?.createdAt
  }

  private setCurrentProgram(docId:string, programName:string|null = null) {
    const state = this.feedStateSubject.getValue()
    if (programName)
      this.eventService.recordProgramStart( { program: programName })
    else if (!docId)
      this.eventService.recordProgramEnd( { program: this.getCurrentProgramName(), complete: !!state.program?.isComplete() })
    this.userService.setUserKey(CURRENTPROGRAM_KEY, docId)
    this.loadProgramState()
  }

  async startProgramNamed(progname: string) {
    const spec = this.contentService.programMap.get(progname)
    if (spec)
      this.startProgram(spec)
    else
      throw new Error(`Unknown program spec named ${progname}`)
  }

  async findExistingProgram(spec: ProgramSpec) {
    // 2021-01-05 Allow at most one instance of each program type
    const progs = await firstValueFrom(this.userPrograms$)
    return progs.find(p => p.programSpec.name === spec.name)
  }

  // Use previously retrieved program if available, otherwise use spec to find program or create new program
  async startProgram(spec: ProgramSpec, prog: Program|null = null) {
    if (!prog)
      prog = await this.findExistingProgram(spec)
    if (prog) {
      console.log("restarted", spec.name, prog)
      await this.firebaseService.updateAt(this.programDocPath(prog.docId), { updatedAt: new Date() })
      this.setCurrentProgram(prog.docId, spec.name)
    }
    else {
      const data = {
        programSpec: { name: spec.name },
        createdAt: new Date(),
        updatedAt: new Date(),
        stepTimes: {}
      }
      const ref = await this.firebaseService.updateAt(this.programDocPath(), data)
      this.setCurrentProgram(ref.id, spec.name)
      console.log("started", ref.id, spec.name)
    }
  }

  endProgram() {
    // this.utilityService.confirm({
    //   header: this.contentService.getGlobal(ENDPROGRAMALERT_HEADER_KEY) || 'End Program',
    //   message: this.contentService.getGlobal(ENDPROGRAMALERT_MESSAGE_KEY) || 'Are you sure you want to end this program?',
    //   confirm: () => { this.setCurrentProgram(null) }
    // })
    // Force update to program to avoid recurring UNUSED state
    const state = this.feedStateSubject.getValue()
    this.firebaseService.updateAt(this.programDocPath(state.program.docId), { updatedAt: new Date() })
    this.setCurrentProgram(null)
  }

  checkProgramStep(program: Program, step:Step) {
    // Set step time for step
    // program.stepTimes[step.name] = program.stepTimes[step.name] ? null : new Date()
    // Abort if already set
    if (!program.stepTimes[step.name]) {
      const now = new Date()
      program.stepTimes[step.name] = now
      // Write to firestore
      this.firebaseService.updateAt(this.programDocPath(program.docId), { updatedAt: now, stepTimes: program.stepTimes })
      this.eventService.recordProgramStepComplete( { program: program.programSpec.name, step: step.name })
      this.setProgramState(program.programSpec.name, step.name, true)
    }
    // Check if program is complete and not already completed
    if (program.isComplete() && !program.completedAt) {
      const path = this.programDocPath(program.docId)
      this.firebaseService.updateAt(path, { completedAt: new Date() })
      this.eventService.recordProgramCompleted( { program: this.getCurrentProgramName() })
      // this.setCurrentProgram(null)
    }
  }

  checkCompletedChat(id:string) {
    // Update program stepTimes if chat is completed for the first time
    const state = this.feedStateSubject.getValue()
    const program = state?.program
    if (program) {
      console.log("checkCompletedChat", id, program)
      const step = program.getFirstIncompleteStep()
      if (step?.conversation.name === id) {
        this.checkProgramStep(program, step)
      }
    }
  }

  // Activate a started program
  activateProgram(docId: string, progName: string) {
    this.setCurrentProgram(docId, progName)
  }

}
