import { Injectable, inject } from '@angular/core';
import { FirstChatKey } from '@cheaseed/node-utils';
import { BehaviorSubject, filter, map, switchMap } from 'rxjs'
import { ChatQueueService } from './chat-queue.service';
import { ContentService } from './content.service'
import { SharedUserService } from './shared-user.service'

export interface OnboardingFeedState {
  onboardingSeries: any
  totalSteps: number,
  currentStep?: number,
  nextChat?: any,
}

const ONBOARDING_SERIES_BEHAVIOR = 'OnboardingSeries'
const COMPLETED_KEY = "user.onboardingfeed.completed"

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  private userService = inject(SharedUserService)

  private currentStateSubject = new BehaviorSubject<OnboardingFeedState>(null)
  currentState$ = this.currentStateSubject.asObservable()
  
  firstChatCompleted$ = this.userService.user$
    .pipe(
      filter(user => !!user),
      switchMap(() => this.chatQueueService.conversationStatus$),
      filter(statuses => !!statuses),
      map(statuses => {
        const firstChat = this.contentService.getGlobal(FirstChatKey)
        // console.log("firstChatCompleted$ returning ", firstChat, statuses?.get(firstChat))
        return !!statuses?.get(firstChat)
      }))

  onboardingSeries: any;

  constructor(
    private contentService: ContentService,
    private chatQueueService: ChatQueueService
  ) {}

  initialize() {
    this.contentService.loader$
      .pipe(
        filter(loader => !!loader))
      .subscribe(() => {
        this.onboardingSeries = this.contentService.getSeriesWithBehavior(ONBOARDING_SERIES_BEHAVIOR)[0]
        this.chatQueueService.conversationStatus$
          .pipe(filter(statuses => !!statuses))
          .subscribe(statuses => {
            this.computeFeedState(statuses) // TODO: obsolete, remove
          })
        })
  }

  isStartingOnboarding(): boolean {
    const state = this.currentStateSubject.value
    // console.log("isStartingOnboarding", state)
    return state?.currentStep === 0
  }

  computeFeedState(statusMap: any) {
    const completed = this.isMarkedCompleted()
    if (this.onboardingSeries && !completed) {
      const chats = this.onboardingSeries.conversations
      const state: OnboardingFeedState = {
        totalSteps: chats.length,
        onboardingSeries: this.onboardingSeries
      }
      state.nextChat = chats.find(c => !statusMap.get(c.name))
      state.currentStep = chats.findIndex(c => !statusMap.get(c.name))
      // const newState = state.nextChat ? state : null
      // console.log("computeFeedState", 'about to emit new state', state.currentStep, state.totalSteps)
      this.currentStateSubject.next(state)
    }
    else if (completed)
      this.currentStateSubject.next(null)
  }

  isMarkedCompleted() {
    return this.userService.getUserKey(COMPLETED_KEY)
  }

  completeOnboarding() {
    this.userService.setUserKey(COMPLETED_KEY, true)
    this.computeFeedState(null)
  }

}
