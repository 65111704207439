import { QueryDocumentSnapshot } from '@google-cloud/firestore'
import { STRIPE_TRANSACTION_IDENTIFIER } from './constants';

export interface StoreTransaction {
  /**
     * RevenueCat Id associated to the transaction.
     */
  readonly transactionIdentifier: string;
  /**
   * Product Id associated with the transaction.
   */
  readonly productIdentifier: string;
  /**
   * Purchase date of the transaction in ISO 8601 format.
   */
  readonly purchaseDate: string;
  readonly price?: number
}

export class Consumable {
    docId!: string;
    createDate!: Date;
    quantity?: number;
    price?: number
    seedCredits?: any
    purchased!: boolean;
    purchaseSource : Receipt; // documentation of purchase from a store
    isStripePortalConsumable?: boolean;
    constructor(args: unknown) {
      Object.assign(this, args)
    }
  }

  export class Receipt {
    store: string | null = null
    transactionIdentifier = STRIPE_TRANSACTION_IDENTIFIER
    purchaseDate!: string
    productIdentifier = ''
    price?: number
    constructor(tInfo: StoreTransaction, store: string) {
      return { store: store, ...tInfo }
    }
  }
  
  //TODO - do a proper union of PricingSpec and ProductPricingOffer
  //Currently, code and name are duplicated, price is a string ($1.99 ..) while 
  // priceAsNumber is the duplicate (1.99)
  export interface ProductPricingOffer {
    code: string
    title?: string
    description?: string
    price: string
    priceAsNumber?: number
    quantity?: number
    discount?: string
    name?: string
    order?: number
    specialPrice?: number
    seedCredits?: any
    startDate?: string
    endDate?: string
    behaviors?: string[]
  }
  
  
  export const AVAILABLE_OFFERS: ProductPricingOffer[] = [
    {
      code: 'seed_pack_1',
      title: '1 pack (100 seeds)',
      price: '$1.99',
      quantity: 100
      //stripe_link: 'https://buy.stripe.com/5kAcOFfjmdS33OUeUU'
      //stripe_link: 'https://buy.stripe.com/test_dR614g8o77Ol7JK8ww'
    },
    {
      code: 'seed_pack_5',
      title: '5 packs (500 seeds)',
      price: '$7.99',
      quantity: 500,
      discount: '20%'
    },
    {
      code: 'seed_pack_10',
      title: '10 packs (1000 seeds)',
      price: '$11.99',
      quantity: 1000,
      discount: '40%'
    },
    {
      code: 'seed_pack_20',
      title: '20 packs (2000 seeds)',
      price: '$19.99',
      quantity: 2000,
      discount: '50%'
    },
    {
      code: 'seed_pack_100',
      title: '100 packs (10,000 seeds)',
      price: '$49.99',
      quantity: 10000,
      discount: '75%'
    },
  ]
  
  export const ConsumableConverter = {
    toFirestore(sub: Consumable) {
      return { ...sub }
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot
    ): Consumable {
      const data = snapshot.data();
      data.createDate = data.createDate.toDate()
      return new Consumable(data)
    }
  }