import { Inject, Injectable, inject } from '@angular/core';
import { orderBy, QueryDocumentSnapshot, SnapshotOptions, where } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Challenge, ChallengeSpec, MMDDYYYY_FORMAT, formatDate, isFuture, todaystr } from '@cheaseed/node-utils';
import { tap, combineLatestWith, debounceTime, filter, firstValueFrom, Observable, map, BehaviorSubject, withLatestFrom, shareReplay, timer, switchMap, combineLatest } from 'rxjs';
import { AuthService } from './auth.service';
import { ChatQueueService } from './chat-queue.service';
import { ContentService } from './content.service';
import { EntryService } from './entry.service';
import { FirebaseService } from './firebase.service';
import { OnboardingService } from './onboarding.service';
import { PointsService } from './points.service';
import { QuickAddService } from './quick-add.service';
import { DeepLinkService } from './deep-link.service';
import { SharedChallengeService } from './shared-challenge.service';
import { Events, SharedEventService } from './shared-event.service';
import { SharedUserService, UserCollectionTypes } from './shared-user.service';
import { UtilityService } from './utility.service';
import { OffersService } from './offers.service';

export enum ItemState {
  TODO = 'TODO',
  DONE = 'DONE',
  SKIPPED = 'SKIPPED',
  EXPIRED = 'EXPIRED',
  REMOVED = 'REMOVED'
}

export enum ItemActionType {
  CHAT = 'CHAT',
  ENTRY = 'ENTRY',
  ROUTE = 'ROUTE',
  SHARE = 'SHARE'
}

export enum ItemDispositionType {
  SKIPPED = 'SKIPPED',
  REMOVED = 'REMOVED',
  KEEPINTODO = 'KEEPINTODO'
}

export interface HomeItem {
  docId: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
  state: ItemState;
  triggeredBy?: string;
  actionType: ItemActionType;
  actionId: string;
  entryId?: string;
  challengeDocId?: string;
  level?: number;
  order?: number;
  entriesRequired?: number;
  title: string;
  message: string;
  icon: string;
  required: boolean;
  completed: boolean;
  expiresAt?: Date;
  clickableUntil?: string;
  onDismissed?: ItemDispositionType;
  transient?: boolean;
  // onAdvance?: ItemDispositionType;
  // onExpire?: ItemDispositionType;
}

const homeItemConverter = {
  toFirestore(item: HomeItem) { return item },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): HomeItem {
    const data = snapshot.data(options)
    data.updatedAt = data.updatedAt.toDate()
    return data as HomeItem
  }
};

export interface ActivityItem {
  docId: string;
  name: string;
  triggeredBy: string;
  triggeredEntrySpec: { name: string; },
  triggeredParam: string;
  maxNumberForTodos: number;
  actionType: string;
  actionId: string;
  onDismissed: string;
  title: string;
  message: string;
  icon: string;
}

export interface AnnouncementItem {
  docId: string;
  name: string;
  announcementType: string;
  challenge: { name: string };
  startDate: string;  // YYYY-MM-DD
  endDate: string;
  clickableUntil?: string;
  maxDaysToDisplay: number;
  maxNumberForTodos: number;
  actionType: string;
  actionId: string;
  onDismissed: string;
  title: string;
  message: string;
  icon: string;
}

export const LEVEL1ALERT_KEY = 'user.level1alert'
export const LEVEL1ADVANCEALERT_KEY = 'user.level1advancealert'

@Injectable({
  providedIn: 'root'
})
export class HomeItemService {

  private userService = inject(SharedUserService)
  private entryService = inject(EntryService)

  todoMap$ = new BehaviorSubject<Map<string, HomeItem[]>>(new Map())
  todoItems$ = new BehaviorSubject<HomeItem[]>([])
  recommendedItems$ = new BehaviorSubject<HomeItem[]>([])

  requiredCompletionPercentage$ = new BehaviorSubject(0.0)
  advanceDisabled$ = new BehaviorSubject(true)
  archiveEnabled$ = new BehaviorSubject(false)
  alert1Dismissed$ = new BehaviorSubject(false)
  alert2Dismissed$ = new BehaviorSubject(false)
  currentLevel$ = this.userService.user$
    .pipe(
      filter(u => !!u),
      map(u => u?.currentLevel))

  currentLevelName$ = this.currentLevel$
    .pipe(
        map(level => this.contentService.getGlobal(`level.name.${level}`)))

  advancing$ = new BehaviorSubject(false)
  generatingLevelItems$ = new BehaviorSubject(false)
  
  // Rewrite to be the latest 3 report items only as HomeItems (synthetic)
  // These will be displayed on Explore page with a More link to get to the full list
  reportItems$ = this.entryService.recentHistory$
      .pipe(
        map(entries => entries?.filter(e => {
          const spec = this.contentService.getEntrySpec(e.indexType() as string)
          return spec?.isReadOnlyReport()
        })),
        map(entries => entries?.filter(e => !!e).slice(0, 3).map(e => {
          // TODO: remove this after release to production of reports
          const title = e.displayName?.includes('generated on')
            ? `${this.contentService.getSingularGlobal(e.type as string)} ${formatDate(e.createdAt, MMDDYYYY_FORMAT)}`
            : e.displayName
          return {
              docId: e.docId,
              actionType: ItemActionType.ROUTE,
              actionId: `/entryview;entryId=${e.docId}`,
              entryId: e.docId,
              title,
              message: e.isUnapproved() ? 'Pending' : 'Tap to view',
              completed: false, // defaults to using icon
              // required for HomeItem conformance
              name: 'N/A',
              createdAt: new Date(),
              updatedAt: new Date(),
              state: ItemState.TODO,
              required: false,
              icon: e.isUnapproved() ? 'schedule' : 'arrow_forward_ios',
              transient: true
            } as HomeItem
          })))
        
  doneItems$ = this.userService.user$
    .pipe(
      filter(u => !!u),
      switchMap(() => this.firebase.collectionWithConverter$(
          this.userService.getUserHomeItemsPath(),
          homeItemConverter,
          where('state', '==', ItemState.DONE),
          orderBy('updatedAt', 'desc')
        )
        .pipe(
          debounceTime(100), 
          shareReplay(1)))
    )

    skippedItems$ = this.userService.user$
      .pipe(
        filter(u => !!u),
        switchMap(() => this.firebase.collectionWithConverter$(
            this.userService.getUserHomeItemsPath(),
            homeItemConverter,
            where('state', '==', ItemState.SKIPPED),
            orderBy('updatedAt', 'desc')
          )
          .pipe(
            debounceTime(100), 
            shareReplay(1)))
    )

  isFirstTimeInLevels$ = this.userService.user$.pipe(
    filter(u => !!u),
    switchMap(() => this.onboardingService.firstChatCompleted$),
      filter(firstChatCompleted => !!firstChatCompleted),
    switchMap(() => this.deeplinkService.deeplinkParams$),
    // tap(deeplink => console.log('isFirstTimeInLevels$ deeplinkParams', deeplink)),
      filter(deeplink => !deeplink), // reject if deeplink exists, only display after deeplink processed
    switchMap(() => this.userService.watchUserKey(null, LEVEL1ALERT_KEY)),
    combineLatestWith(this.alert1Dismissed$),
      map(([ keydoc, dismissed ]) => {
        const alertSeen = keydoc?.value
        const result = !dismissed && !alertSeen
      // console.log('isFirstTimeInLevels$', result, { alertSeen, dismissed })
        return result
      }))

  isFirstTimeAdvanceEnabled$ = this.userService.user$
    .pipe(
      filter(u => !!u),
    switchMap(() => this.advanceDisabled$),
      filter(disabled => !disabled),
    // tap(disabled => console.log('isFirstTimeAdvanceEnabled$ disabled', disabled)),
    switchMap(() => this.alert2Dismissed$),
      map(dismissed => {
        const alert1Seen = this.userService.getUserKey(LEVEL1ALERT_KEY)
        const alert2Seen = this.userService.getUserKey(LEVEL1ADVANCEALERT_KEY)
        const result = !dismissed && alert1Seen && !alert2Seen
      // console.log('isFirstTimeAdvanceEnabled$', result, { alertSeen, dismissed })
        return result
      }))

  constructor(
    @Inject('environment') private environment: any,
    private router: Router,
    private eventService: SharedEventService,
    private pointsService: PointsService,
    private contentService: ContentService,
    private quickAddService: QuickAddService,
    private chatQueueService: ChatQueueService,
    private challengeService: SharedChallengeService,
    private onboardingService: OnboardingService,
    private auth: AuthService,
    private firebase: FirebaseService,
    private utilityService: UtilityService,
    private deeplinkService: DeepLinkService,
    private offersService: OffersService
  ) {
    this.userService.user$
      .pipe(
          filter(u => !!u),
          filter(u => u?.homeVersion === 2),
        // debounceTime(1000),
          this.userService.filterUntilUserChanges())
        .subscribe(user => {
        // console.log("HomeItemService constructor", user?.docId)
          this.initialize()
      })
  }

  initialize() {
    /*
      1. Chat items should be marked as completed if the chat has already been consumed since the item was created (or moved to done if post-levels).
      2. Entry action Todo items should be marked as completed if the current count is already greater than the num entries required for the item.
      3. Actvity items should be removed when the conditions that created them are no longer met.
    */

    combineLatest( [ this.currentLevel$, this.getUserTodos(), this.generatingLevelItems$ ])
      .pipe(
        this.auth.takeUntilAuth(),
        debounceTime(500),
      )
      .subscribe(([level, items, generating]) => {
        // Ensure level items generated and level set
        console.log('HomeItemService', 'received changes', { level, items, generating })
        if (!level && !generating)
          this.generateLevelItems(level || 0, items)
        else {
          const map = new Map<string, HomeItem[]>()
          items.sort((a, b) => `${a.level}:${a.order}` > `${b.level}:${b.order}` ? 1 : -1)
          items.forEach(item => {
            const curr = map.get(item.name) || []
            map.set(item.name, [...curr, item])})
          this.todoMap$.next(map)
          const numRequired = items.filter((item) => item.required).length
          const numRequiredCompleted = items.filter((item) => item.required && item.completed).length
          this.requiredCompletionPercentage$.next(numRequiredCompleted / (numRequired || 1))
          // if there are required items and all are complete, advance is enabled
          const disabled = items.length === 0 || !!items.find((item) => item.required && !item.completed)
          // console.log('Setting advance disabled to', disabled, 'for', items.length, 'items')
          this.advanceDisabled$.next(disabled)
          this.todoItems$.next(items.filter((item: HomeItem) => item.level))
          const recs = items.filter((item: HomeItem) => !item.level)
          recs.sort((a: HomeItem, b: HomeItem) => a.createdAt < b.createdAt ? 1 : -1)
          this.recommendedItems$.next(recs)
          this.archiveEnabled$.next(!!recs.find((item: HomeItem) => item.completed))
        }
      })

    // Load announcement item definitions
    combineLatest([ this.getAnnouncementItems(), this.challengeService.userChallenges$, this.todoMap$ ])
      .pipe(
        debounceTime(1000),
        this.auth.takeUntilAuth()
      )
      .subscribe(([ announcementItems, challenges, todoMap ]) => {
          // Check for announcements
        this.checkAnnouncementItems(announcementItems, challenges.activeChallenges, todoMap)
      })

    // Subscribe to entrycount changes
    combineLatest([ 
      this.entryService.entryCounts$, 
      this.currentLevel$,
      this.getTodoSkippedHomeItems(),
      this.chatQueueService.conversationStatus$ ])
    .pipe(
      debounceTime(500),
      filter(([cnts, level, homeItems, statuses]) => !!statuses),
      this.auth.takeUntilAuth()
    )
    .subscribe(([ cnts, level, homeItems, statuses ]) => {
      // console.log('HomeItemService', 'received changed entryCounts or level', cnts, level, homeItems)
      // Identify entry items that have been completed but are no longer valid
      homeItems.forEach(item => {
        if (item.actionType === ItemActionType.ENTRY) {
          const cnt = cnts[item.actionId] || 0
          // Undo completion if the entry count is less than the required number of entries
          if (item.completed && item.entriesRequired && cnt < item.entriesRequired)
            this.markItemCompleted(item, false)
          // Complete if the entry count is greater than the required number of entries
          else if (!item.completed && item.entriesRequired && cnt >= item.entriesRequired)
            this.markItemCompleted(item, true)
          // Remove if entry was deleted
          else if (!item.completed && item.triggeredBy === Events.EntryCreate && !this.entryService.getEntryById(item.entryId as string)) {
            // console.log("Detected that entry has been deleted, so deleting uncompleted home item", item)
            this.removeActivityHomeItem(item)
          }
        }
        else if (item.actionType === ItemActionType.CHAT) {
          // Handle setting chat completions when level is first attained
          const status = statuses?.get(item.actionId)
          if (item.completed && !status)
            this.markItemCompleted(item, false)
          else if (!item.completed && status) {
            this.markItemCompleted(item, true)
            if (item.state === ItemState.SKIPPED)
              this.updateItemState(item, ItemState.DONE)
          }
        }
      })
    })

    // Subscribe to events and entry counts
    this.pointsService.eventCreated$
      .pipe(
        filter(event => !!event),
        debounceTime(1000), // wait a sec for entryCounts
        withLatestFrom(
          this.entryService.entryCounts$,
          this.pointsService.userPoints$,
          this.getTodoSkippedHomeItems()),
        combineLatestWith(this.getActivityItems(), this.todoMap$),
        this.auth.takeUntilAuth())
      .subscribe(([data, activityItemsMap, todoMap]) => {
        const [event, cnts, points, todos] = data
        console.log('HomeItemService', 'received eventCreated', event.eventType)
        const uncompleteds = todos.filter((todo:HomeItem) => !todo.completed)
        if (event.eventType === Events.EntryCreate) {
          const cnt = cnts[event.id]
          const items = uncompleteds.filter((item:HomeItem) => item.actionId === event.id && item.actionType === ItemActionType.ENTRY)
          for (const item of items) {
            // if item is waiting for a certain number of entries, check the count
            if (item.entriesRequired) {
              if (cnt === item.entriesRequired)
                this.markItemCompleted(item)
            }
            // if item is simply awaiting a new entry, then mark it as completed
            else if (!item.completed) {
              this.markItemCompleted(item)
            }
          }
        }
        else if (event.eventType === Events.ChallengeDelete) {
          const item = uncompleteds.find((item:HomeItem) => item.triggeredBy === "ChallengeCreate" && item.challengeDocId === event.docId)
          // console.log("ChallengeDelete event received", {event, uncompleteds, item })
          if (item)
            this.removeActivityHomeItem(item)
        }
        // Check against activities
        this.computeActivityItems(activityItemsMap, todoMap, event, cnts, points)
      })
  }

  private replaceAnnouncementTokens(str: string, challenge: ChallengeSpec) {
    return str
      .replace('$challengeName', challenge.title as string)
      .replace('$docId', challenge.name);
  }

  checkAnnouncementItems(announcementsMap: Map<string, AnnouncementItem>, activeChallenges: Challenge[], todoMap: Map<string, HomeItem[]>) {
    const now = new Date()
    const todaystring = todaystr()
    const path = this.userService.getUserHomeItemsPath()
    // Should only do this occasionally, not on every event
    Array.from(announcementsMap.values()).forEach(item => {
      const key = `announced.${item.name}`
      // console.log("Checking announcement item", key, item, activeChallenges)
      const announced = this.userService.getUserKey(key)
      const challengeSpec = this.challengeService.getChallengeSpecNamed(item.challenge.name)
      const challengeName = this.challengeService.publicChallengeOwnerDocId(item.challenge.name)
      const foundActive = activeChallenges.find(challenge => challenge.ownerDocId === challengeName)
      // console.log("Checking announcement item", key, item, challengeSpec, challengeName, foundActive)
      if (challengeSpec &&  // ensure challenge not renamed
          !announced &&
          !todoMap.has(item.name) &&
          todaystring >= item.startDate &&
          todaystring < item.endDate &&
          !foundActive) {
        this.firebase.updateAt(path, {
          name: item.name,
          createdAt: now,
          updatedAt: now,
          state: ItemState.TODO,
          actionType: this.getActionType(item.actionType),
          actionId: this.replaceAnnouncementTokens(item.actionId, challengeSpec),
          title: this.replaceAnnouncementTokens(item.title, challengeSpec),
          message: this.replaceAnnouncementTokens(item.message, challengeSpec),
          icon: item.icon,
          required: false,
          completed: false,
          clickableUntil: item.clickableUntil,
          onDismissed: item.onDismissed?.toUpperCase()
        })
        this.userService.setUserKey(key, true)
      }
      else if (announced && item.clickableUntil && todoMap.has(item.name)) {
        const homeitems = todoMap.get(item.name)
        if (homeitems && homeitems.length > 0) {
          // update clickableUntil dates if needed
          for (const homeitem of homeitems) {
            if (homeitem.clickableUntil !== item.clickableUntil)
              this.firebase.updateAt(`${path}/${homeitem.docId}`, { clickableUntil: item.clickableUntil })
          }
        }
      }
      if (announced && foundActive) {
        const homeitem = (todoMap.get(item.name) || [])[0]
        // console.log("Found active challenge, marking announcement", {homeitem})
        if (homeitem && !homeitem.completed)
          this.markItemCompleted(homeitem)
      }
    })
  }

  computeActivityItems(activityItemsMap: Map<string, ActivityItem[]>, todoMap: Map<string, HomeItem[]>, event: any, cnts: any, points: any) {
    let items = activityItemsMap.get(event.eventType) || []
    for (const item of items) {
      if (event.eventType === Events.EntryRemind)
        this.addActivityHomeItem(item, event, todoMap)
      else if (event.eventType === Events.EntryCreate)
        this.handleEntryCreate(event, cnts, item, todoMap)
      else if (event.eventType === Events.ChallengeJoin)
        this.handleChallengeJoin(event, item, todoMap)
      else if (event.eventType === Events.ChallengeCreate)
        this.addActivityHomeItem(item, event, todoMap)
      else if (event.eventType === Events.QueuedChat) {
        // console.log("handleQueuedChat", event, item)
        const targetChat = this.contentService.getConversationNamed(event.targetId)
        const sourceChat = this.contentService.getConversationNamed(event.sourceId)
        const newEvent = { ...event, targetChatTitle: targetChat?.title, sourceChatTitle: sourceChat?.title, targetChatId: event.targetId }
        this.addActivityHomeItem(item, newEvent, todoMap)
      }
    }
    // Handle AfterPoints activities
    if (event.points) {
      items = activityItemsMap.get('AfterPoints') || []
      for (const item of items)
        this.handleAfterPointsEvent(event, cnts, points, item, todoMap)
    }
  }

  private addActivityHomeItem(item: ActivityItem, event: any, todoMap: Map<string, HomeItem[]>, dynamicProps: any = {}) {
    // console.log("addActivityHomeItem", { item, event })
    const newActionId = this.replaceTokens(item.actionId, event)
    const actionType = this.getActionType(item.actionType)
    const currentTodos = todoMap.get(item.name) || []

    // Check whether the existing item has the same actionId, if so return
    if (currentTodos.find((todo:HomeItem) => todo.actionId === newActionId))
        return

    // Check if chat has persona
    if (item.actionType === ItemActionType.CHAT) {
      const c = this.contentService.getConversationNamed(item.actionId)
      if (c?.persona && !this.userService.userHasPersona(c.persona.name))
        return
    }

    // Do not share an entry that has remindMe
    if (actionType === ItemActionType.SHARE) {
      const entry = this.entryService.getEntryById(event.entryId)
      if (entry?.hasWarning()) {
        console.log("Skipping SHARE home item, because entry has warnings")
        return
      }
    }
    // Abort if todo for this activity item is already maxed out
    if (item.maxNumberForTodos && item.maxNumberForTodos <= currentTodos.length) {
      console.log("addActivityHomeItem", "found maxNumberForTodos for ", item)
      // Remove the oldest one so that we can add the new one, don't abort
      currentTodos.sort((a:HomeItem, b:HomeItem) => a.updatedAt > b.updatedAt ? 1 : -1)
      const oldest = currentTodos[0]
      console.log("addActivityHomeItem", "removing oldest", oldest)
      this.removeActivityHomeItem(oldest)
    }
    const path = this.userService.getUserHomeItemsPath()
    const now = new Date()
    this.firebase.updateAt(path, {
      name: item.name,
      createdAt: now,
      updatedAt: now,
      state: ItemState.TODO,
      triggeredBy: event.eventType,
      entryId: event.entryId,
      challengeDocId: event.docId,
      actionType,
      actionId: newActionId,
      title: this.replaceTokens(item.title, event),
      message: this.replaceTokens(item.message, event),
      icon: item.icon,
      required: false,
      completed: false,
      onDismissed: item.onDismissed.toUpperCase(),
      ...dynamicProps
    })
  }

  removeActivityHomeItem(item: HomeItem) {
    const path = `${this.userService.getUserHomeItemsPath()}/${item.docId}`
    this.firebase.delete(path)
    // console.log("removeActivityHomeItem", item)
  }

  private replaceTokens(str: string, event: any) {
    const term = this.contentService.getSingularGlobal(event.id)
    return str
        .replace("$entryType", term)
        .replace("$displayName", this.entryService.getDisplayNameForId(event.entryId))
        .replace("$entryId", event.entryId)
        .replace("$docId", event.docId)
        .replace("$targetChatId", event.targetChatId)
        .replace("$targetChatTitle", event.targetChatTitle)
        .replace("$sourceChatTitle", event.sourceChatTitle)
  }

  handleAfterPointsEvent(event: any, cnts: any, points: any, item: ActivityItem, todoMap: Map<string, HomeItem[]>) {
    const total = points.totalPoints,
          previousTotal = points.totalPoints - event.points,
          pattern1 = /Every (\d+) Points/i,
          pattern2 = /After (\d+) Points/i,
          match1 = item.triggeredParam.match(pattern1),
          match2 = item.triggeredParam.match(pattern2)

    // console.log("handleAfterPointsEvent", event, cnts, points, item, total, previousTotal)
    if (match1) {
      const param:any = match1[1]
      if (param > 0) {
        const boundary = param * Math.max(Math.ceil(previousTotal / param), 1)
        // If we crossed over the points boundary, then add the home item
        if (previousTotal < boundary && total >= boundary) {
          // console.log("handleAfterPointsEvent: adding home item", item, previousTotal, boundary, total  )
          this.addActivityHomeItem(item, event, todoMap)
        }
      }
    }
    else if (match2) {
      const param:any = match2[1]
      if (param > 0) {
        if (previousTotal < param && total >= param) {
          // console.log("handleAfterPointsEvent: adding home item", item, previousTotal, total  )
          this.addActivityHomeItem(item, event, todoMap)
        }
      }
    }
  }

  handleEntryCreate(event: any, cnts: any, item: ActivityItem, todoMap: Map<string, HomeItem[]>) {
    // console.log("handleEntryCreate", event, cnts, item)
    // Check the trigger conditions
    if (item.triggeredEntrySpec && event.id === item.triggeredEntrySpec.name) {
      // console.log("Detected triggeredEntrySpec", event.id)
      if (item.triggeredParam) {
        if (/\d+/.test(item.triggeredParam)) {
          // console.log("Detected triggeredParam", item.triggeredParam, cnts[event.id])
          if (cnts[event.id] == item.triggeredParam) {
            this.addActivityHomeItem(item, event, todoMap)
          }
        }
      }
    }
    else if (!item.triggeredEntrySpec) {
      if (item.triggeredParam) {
        const everyCollectiblePattern = /Every (\d+) Collectibles/i
        const match = item.triggeredParam.match(everyCollectiblePattern)
        const collectibleTypes = this.contentService.getCollectibleTypes().map(t => t.name)
        if (match && collectibleTypes.includes(event.id)) {
          const param:any = match[1]
          // console.log("Detected every param", param)
          const cnt:number = collectibleTypes.map(t => cnts[t] || 0).reduce((acc, cur) => acc + cur, 0)
          // console.log("Detected collectibles cnt", cnt)
          if (cnt % param === 0) {
            const collectionCnt = cnts[item.actionId] || 0
            this.addActivityHomeItem(item, event, todoMap, { entriesRequired: collectionCnt + 1 })
          }
        }
      }
      else {
        // Apply to all entry types except those with parent entries
        const entry = this.entryService.getEntryById(event.entryId)
        if (entry && !entry.parentEntryId) {
          this.addActivityHomeItem(item, event, todoMap)
        }
      }
    }
  }

  handleChallengeJoin(event: any, item: ActivityItem, todoMap: Map<string, HomeItem[]>) {
    // console.log("handleChallengeJoin", event, item)
    if (item.triggeredParam) {
      const notCompletedPattern = /(.+) not completed/i
      const match = item.triggeredParam.match(notCompletedPattern)
      if (match) {
        const chatName = match[1]
        // console.log("Detected param", chatName)
        if (!this.chatQueueService.isCompleted(chatName))
          this.addActivityHomeItem(item, event, todoMap)
      }
    }
  }

  async advance(needsAdmin = false) {
    // Change state of level items to DONE or SKIPPED
    const isAdmin = await firstValueFrom(this.userService.isAdminRole$)
    if (needsAdmin && !isAdmin)
      return
    this.advancing$.next(true)
    timer(1000)
      .subscribe(async () => {
        await this.prepareToAdvance()
        this.advancing$.next(false)
    })
  }

  async prepareToAdvance() {
    // Change state of level items to DONE or SKIPPED
    const items = await firstValueFrom(this.todoItems$) || []
    for (const item of items) {
      if (item.completed)
        this.updateItemState(item, ItemState.DONE)
      else if (item.level)
        this.updateItemState(item, ItemState.SKIPPED)
    }
    const optionalsCompleted = items.filter(i => i.level && !i.required && i.completed).length
    const optionalsRemaining = items.filter(i => i.level && !i.required && !i.completed).length
    const level = await firstValueFrom(this.currentLevel$)
    if (level) {
      await this.generateLevelItems(level, items)
      await this.eventService.recordHomeLevelCompleted({ level, optionalsCompleted, optionalsRemaining  })
      await this.offersService.processOffer(Events.HomeLevelCompleted, `${level}`)
    }
  }

  async archive() {
    // Move all completed items to done
    await firstValueFrom(
      this.recommendedItems$
        .pipe(
          map(items => items.filter(i => i.completed)),
          tap(items => items.forEach(i => this.updateItemState(i, ItemState.DONE)))))
  }

  private getActionType(type: string): ItemActionType {
    return type === 'entry' ? ItemActionType.ENTRY : 
            type === 'chat' ? ItemActionType.CHAT :
            type === 'share' ? ItemActionType.SHARE : ItemActionType.ROUTE
  }

  async generateLevelItems(currentLevel: number, todos: HomeItem[]) {
    this.generatingLevelItems$.next(true)
    // Check for todos already marked done
    const doneItems = todos.filter(i => i.level && i.state === ItemState.DONE)
    const notDoneItems = todos.filter(i => i.level && i.state !== ItemState.DONE)
    // If there are no DONE items, but some not DONE items, then we recover the existing level from the notDoneItems
    // console.log("generateLevelItems found", { todos, doneItems, notDoneItems })
    if (!doneItems.length && notDoneItems.length) {
      const level = notDoneItems[0].level as number
      this.userService.setCurrentLevel(level)
    }
    else {
      // Find max level for DONE state items
      let maxLevel = doneItems.reduce((acc, curr) => Math.max(acc, curr.level || 0), 0)
      console.log("generateLevelItems found maxLevel", maxLevel, todos)
      // Handle case where currentLevel > 0, but todos are empty (unknown cause)
      maxLevel = maxLevel === 0 ? currentLevel : maxLevel
      const level = (maxLevel || 0) + 1
      const items = await firstValueFrom(this.getLevelItems(level))
      const path = this.userService.getUserHomeItemsPath()
      const now = new Date()
      const todoMap = this.todoMap$.getValue()
      if (items.length) {
        // set these early to prevent reentry on level 1 generation
        this.userService.setCurrentLevel(level) 
        this.eventService.setHomeLevelUserProperty(level)
        for (const item of items) {
          let create = true
          // Check Persona
          if (item.itemType === 'chat') {
            const c = this.contentService.getConversationNamed(item.conversation?.name)
            create = !c?.persona || this.userService.userHasPersona(c.persona.name)
          }
          // Check does not already exist
          if (todoMap.has(item.name))
            create = false
          else if (create) {
            // Check for concurrent creation by another session for the same user (testing scenarios mainly)
            const first = await firstValueFrom(this.getHomeItemNamed(item.name))
            create = !first
          }
          if (create) {
            this.firebase.updateAt(path, {
              name: item.name,
              createdAt: now,
              updatedAt: now,
              state: ItemState.TODO,
              actionType: this.getActionType(item.itemType),
              actionId:
                item.itemType === 'entry' ? item.entrySpec?.name :
                  item.itemType === 'chat' ? item.conversation?.name :
                    item.route,
              level: item.level,
              order: item.order,
              entriesRequired: item.entriesRequired,
              title: item.title,
              message: item.message,
              icon: item.icon,
              required: item.required,
              completed: false
            })
          }
        }
      }
      else if (level > 0) {
        // Levels completed
        // We may want to trigger an effect in the UI, but levels should no longer display
        this.userService.setCurrentLevel(-1)
        this.utilityService.presentToast("Congratulations! You have completed all levels.")
      }
    }
    this.generatingLevelItems$.next(false)
  }

  getLevelItems(level: number) {
    const path = `/content/config/${this.environment.releaseTag}/web/levelItems`
    return this.firebase.collection$(path, where('level', '==', level), orderBy('order'))
  }

  getHomeItemNamed(name: string) {
    const path = this.userService.getUserHomeItemsPath()
    return this.firebase.collection$(path, where('name', '==', name))
      .pipe(map(items => items.length ? items[0] : null))
  }

  getLevelNames() {
    return this.contentService.getGlobalsStartingWith('level.name.')
  }

  getActivityItems(): Observable<Map<string, ActivityItem[]>> {
    const path = `/content/config/${this.environment.releaseTag}/web/activityItems`
    return this.firebase.collection$(path)
      .pipe(
        map((items:ActivityItem[]) => {
          const result = new Map(items.map(item => [ item.triggeredBy, [] ]))
          for (const item of items)
            result.get(item.triggeredBy)?.push(item)
          return result
        }))
  }

  getAnnouncementItems(): Observable<Map<string, AnnouncementItem>> {
    const path = `/content/config/${this.environment.releaseTag}/web/announcementItems`
    return this.firebase.collection$(path)
      .pipe(
        map((items:AnnouncementItem[]) => 
          new Map(items.filter(a => a.announcementType).map(item => [ item.name, item ]))))
  }

  async deleteAll() {
    await firstValueFrom(this.userService.deleteUserCollection(UserCollectionTypes.HOMEITEMS))
    console.log("Deleted all home items")
  }

  getTodoSkippedHomeItems(): Observable<HomeItem[]> {
    return this.firebase.collectionWithConverter$(
        this.userService.getUserHomeItemsPath(),
        homeItemConverter,
        where('state', 'in', [ ItemState.TODO, ItemState.SKIPPED ]))
      .pipe(shareReplay(1))
  }

  async resetLevelParams() {
    // Must delete home items first to prevent other changes from triggering changes
    await this.deleteAll()
    await this.userService.deleteUserKey(LEVEL1ALERT_KEY)
    await this.userService.deleteUserKey(LEVEL1ADVANCEALERT_KEY)
    const announcedKeys = this.userService.getUserKeysStartingWith('announced.')
    for (const k of announcedKeys)
      await this.userService.deleteUserKey(k)
    this.alert1Dismissed$.next(false)
    this.alert2Dismissed$.next(false)
    await this.generateLevelItems(0, [])
  }

  dismissAdvanceAlert() {
    this.alert2Dismissed$.next(true)
    this.userService.setUserKey(LEVEL1ADVANCEALERT_KEY, true)
  }

  dismissFirstTimeAlert() {
    this.alert1Dismissed$.next(true)
    this.userService.setUserKey(LEVEL1ALERT_KEY, true)
  }

  showEnabledMessage() {
    const msg = this.contentService.getGlobal('leveladvance.alert.message') || 'You can now advance to the next level'
    // Use a toast to inform the user, but not possible to dismiss by clicking it, so adding a button
    // Alternatively, we could use an ion-modal on the levels page and have it watch for a flag to be set here
    this.utilityService.presentToast(`<b>${msg}</b>`, {
      color: null,
      cssClass: "alert-toast", 
      duration: 10000,
      position: "middle",
      buttons: [
        {
          icon: 'close-outline',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    } )
  }
  // getUncompletedHomeItems(id: string, type: string) {
  //   return this.firebase.collection$(
  //     this.userService.getUserHomeItemsPath(),
  //     where('state', 'in', [ ItemState.TODO, ItemState.SKIPPED ]),
  //     where('actionType', '==', type),
  //     where('actionId', '==', id ),
  //     where('completed', '==', false))
  // }

  markItemCompleted(item: HomeItem, value = true) {
    // const wasDisabled = this.advanceDisabled
    // this.advanceDisabled = !!items.find(item => item.required && !item.completed)
    // // If now enabled, present custom toast
    // if (wasDisabled && !this.advanceDisabled)
    //     this.showEnabledMessage()

    // console.log("markItemCompleted", item, value)
    if (!item.completed) {
      const path = this.userService.getUserHomeItemsPath() + '/' + item.docId
      this.firebase.updateAt(path, { completed: value, updatedAt: new Date() })
    }
  }

  updateItemState(item: HomeItem, state: ItemState) {
    const path = this.userService.getUserHomeItemsPath() + '/' + item.docId
    let data:any = { state, updatedAt: new Date() }
    if (state === ItemState.DONE)
      data = { ...data, completed: true }
    // Modify the item in place to avoid having to wait for the update
    item.state = state
    this.firebase.updateAt(path, data)
    // console.log("updateItemState", item, data)
  }

  async click(item: HomeItem) {
    // console.log("HomeItemService.click", item)
    if (item.actionType === ItemActionType.CHAT)
      this.router.navigate(['/conversation', item.actionId, { launchSource: 'HomeLevels', backLabel: 'Home' }])
    else if (item.actionType === ItemActionType.ENTRY) {
      const type = item.actionId
      const menu = await this.quickAddService.showDynamicQuickAdd(type)
      if (!menu)
        await this.quickAddService.showQuickAdd(type)
      // this.router.navigate(['/sectionentryform', { entrySpecId: item.actionId }])
    }
    else if (item.actionType === ItemActionType.ROUTE) {
      // Check if still clickable
      if (!item.clickableUntil || isFuture(item.clickableUntil)) {
        // Mark completed if clicked
        if (!item.transient)  // prevent if transient
          this.markItemCompleted(item, true)
        console.log("clicked", item)
        this.router.navigateByUrl(item.actionId)
      }
    }
    else if (item.actionType === ItemActionType.SHARE) {
      await this.showShareActionSheet(item)
    }
  }

  async showShareActionSheet(item: HomeItem) {
    const buttons = [
      {
        text: 'Share Entry Image',
        handler: () => {
          this.markItemCompleted(item, true)
          this.router.navigate( [ 'share-entry-tile', item.actionId ]) 
        }
      },
      {
        text: 'Share Link on Web',
        handler: async () => {
          const entry = this.entryService.getEntryById(item.actionId)
          if (entry) {
            this.markItemCompleted(item, true)
            await this.entryService.shareLink(entry)
          }
        }
      },
      {
        text: 'Preview Link on Web',
        handler: async () => {
          const entry = this.entryService.getEntryById(item.actionId)
          if (entry)
            this.entryService.launchWebEntryLink(entry) 
        }
      }
    ]
    await this.utilityService.showActionSheet('Share', buttons)
  }

  handleDismiss(item: HomeItem) {
    if (item.onDismissed == ItemDispositionType.REMOVED)
      this.removeActivityHomeItem(item)
    else if (item.onDismissed == ItemDispositionType.SKIPPED)
      this.updateItemState(item, ItemState.SKIPPED)
  }

  getUserTodos(): Observable<HomeItem[]> {
    return this.firebase.collectionWithConverter$(
      this.userService.getUserHomeItemsPath(), 
      homeItemConverter, 
      where('state', '==', ItemState.TODO))
      .pipe(
        debounceTime(500),
        shareReplay(1))
  }
}
