import { Inject, Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { BehaviorSubject, debounceTime, filter, firstValueFrom, switchMap, tap } from 'rxjs'
import { Stripe } from 'stripe'
import { PaymentIntent } from '@stripe/stripe-js'
import { AppSource, Consumable, PricingSpec, Receipt, STRIPE_CANCELLED_TRANSACTION_IDENTIFIER, ProductPricingOffer, UserPortalOffer, getStripeSecretKey, STRIPE_NOPAY_TRANSACTION_IDENTIFIER, AffiliateTerms } from '@cheaseed/node-utils'
import { FirebaseService } from './firebase.service'
import { CheaseedUser, SharedUserService } from './shared-user.service'
import { PurchaseUtilitiesService } from './purchase-utilities.service'

export interface StripeInputInterface {
    user: CheaseedUser,
    offer: ProductPricingOffer,
    consumableDocId: string,
    offers?: UserPortalOffer[],
    term?: AffiliateTerms,
    route?: ActivatedRouteSnapshot
}

@Injectable({
    providedIn: 'root'
})
export class CheaseedStripeService {
    userService = inject(SharedUserService)
    purchaseUtilService = inject(PurchaseUtilitiesService)

    stripe: Stripe
    modalController: any
    enabled$ = new BehaviorSubject(false)
    purchaseSession$ = new BehaviorSubject<StripeInputInterface | undefined>(undefined)
    paymentModal: any

    stripePaymentConsumable$ = this.purchaseSession$
        .pipe(
            filter(input => !!input),
            switchMap(input => this.firebase.doc$(this.userService.getUserConsumablePath(input?.consumableDocId as string))),
            debounceTime(300),
            tap(docData => console.log('received stripe consumable', docData))
        )

    constructor(
        @Inject('environment') private environment: any,
        @Inject('UtilityService') private utilityService: any,
        private firebase: FirebaseService) {

        this.stripe = new Stripe(getStripeSecretKey(environment.production ? "prod" : "dev"))
    }

    initialize(modalController: any) {
        // can be the ModalController instance from ionic/angular
        // or ionic/angular/standalone
        this.modalController = modalController
    }

    async cancel(offer: ProductPricingOffer, docId: string) {
        // not in portal; keep web app behavior
        if (this.environment.appSource === AppSource.App) {
            await this.purchaseUtilService.removeConsumable(docId)
            return
        }
        await this.updateConsumable(STRIPE_CANCELLED_TRANSACTION_IDENTIFIER, docId)
    }

    async noPayment(docId: string) {
        await this.updateConsumable(STRIPE_NOPAY_TRANSACTION_IDENTIFIER, docId)
    }

    async updateConsumable(transactionIdentifier: string, docId: string) {
        const path = this.userService.getUserConsumablePath(docId)
        const consumable = await this.firebase.getFirestoreDoc(path) as Consumable
        const receipt = consumable.purchaseSource
        receipt.transactionIdentifier = transactionIdentifier
        await this.firebase.updateAt(path, consumable)
    }

    /**
     * 
     * @param params 
     * @returns { true, null } if the payment did NOT succeed; {false, paymentIntent } otherwise
     */
    async buyStripeSilently(params: StripeInputInterface) {
        const user = this.userService.getCurrentUser()
        console.log('Buying from stripe silently', params)
        const res: any = { result: false }
        let paymentIntent
        // Sanity checks
        if (!user?.stripePaymentInfoSaved) {
            console.warn(`Stripe payment info saved field is not set for ${JSON.stringify(user)}`)
            res.result = true
            return res
        }

        try {
            const custId = user?.stripeCustomerId
            // if for some reason the stripe customer id is missing ...
            if (!custId) {
                console.warn(`No stripe customer id found for user ${user?.docId}. Resetting the stripePaymentInfoSaved attribute`)
                await this.userService.setStripePaymentInfoSaved(false)
                res.result = true
                res.paymentIntent = null
                return res
            }
            //Assume for now that a customer has only 1 payment method which is
            // what we use. 
            const paymentMethod =
                await this.stripe.customers.listPaymentMethods(user.stripeCustomerId as string, { limit: 1, expand: [] })
            console.log('Payment Methods', JSON.stringify(paymentMethod))
            if (paymentMethod.data.length === 0) {
                console.warn(`No payment method found on stripe for ${JSON.stringify(user)}`)
                await this.userService.setStripePaymentInfoSaved(false)
                res.result = true
                res.paymentIntent = null
                return res
            }
            paymentIntent = await this.stripe.paymentIntents.create({
                amount: this.getPurchasePrice(params.offer.price),
                currency: 'usd', //TODO currency handling
                customer: user.stripeCustomerId,
                //TODO - we are just taking the first payment method from the list
                // Need to think how we will handle multiple methods
                payment_method: paymentMethod.data[0].id,
                off_session: true,
                confirm: true,
                metadata: {
                    user: this.userService.getCurrentUserId() as string,
                    offer: JSON.stringify(params.offer)
                }
            })
            if (paymentIntent.status !== 'succeeded') {
                console.warn(`Stripe Silent payment failed, ${JSON.stringify(paymentIntent)}`)
                await this.userService.setStripePaymentInfoSaved(false)
                res.result = true
                res.paymentIntent = null
                return res
            }
        }
        catch (e) {
            console.error('Stripe Silent Payment', e)
            await this.userService.setStripePaymentInfoSaved(false)
            res.result = true
            res.paymentIntent = null
            return res
        }
        res.result = false
        res.paymentIntent = paymentIntent
        return res
    }
    
    getPurchasePrice(price: string): number {
        // price in the offer is of the pattern $1.99 ...
        return Math.round(parseFloat(price.substring(1)) * 100)
    }

    async getConsumableDoc(item: PricingSpec) {
        return await this.purchaseUtilService.getConsumableDocument(item)
    }

    convertPricingSpec(item: PricingSpec): ProductPricingOffer {
        const { price, ...obj } = item
        const offer = {
            ...obj, // PricingSpec without the price attr
            code: item.name,
            priceAsNumber: price,
            price: '$' + `${obj.specialPrice || price}`
        }
        console.log('After conversion of Pricing Spec, offer =', offer)
        return offer
    }

    async executeStripeSuccessLogic(obj: StripeInputInterface, paymentIntent: PaymentIntent | null) {
        console.log('executeStripeSuccessLogic', obj, paymentIntent)
        if (paymentIntent) {
            const r = this.purchaseUtilService.getStripePortalReceiptFrom(obj.offer, paymentIntent)
            const simulate = await firstValueFrom(this.enabled$)
            if (!simulate)
                await this.updateFirestoreInfo(obj.offer, obj.consumableDocId, r)
        }
        await this.userService.incrementSeedTypeBalance(obj.offer.seedCredits)
    }

    async updateFirestoreInfo(offer: ProductPricingOffer, docId: string, receipt: Receipt) {
        await this.purchaseUtilService.addConsumable(
            this.purchaseUtilService.createStripePortalConsumable(offer, receipt), 
            docId)
    }

    async launchPaymentModal(componentClass: any, componentProps: any) {
        // console.log('Component Props', componentProps)
        const modal = await this.modalController.create({
            id: 'payment-modal',
            component: componentClass,
            componentProps: componentProps,
            cssClass: 'purchase-modal',
            backdropDismiss: false
        })
        await modal.present()
        return modal        
    }

    async dismissPaymentModal() {
        this.paymentModal.dismiss()
        this.paymentModal = undefined
        // await this.modalController.dismiss(undefined, undefined, 'payment-modal')
    }

    async purchaseItem(data: { item: PricingSpec, offers: UserPortalOffer[], term: AffiliateTerms | undefined, user: CheaseedUser }, componentClass: any) {
        const docId = await this.getConsumableDoc(data.item)
        // Filter offers based on scope -- item.name must be in the offer.spec.scope if present
        const offers = data.offers.filter(offer => {
            const scope = offer.spec.scope?.map(s => s.name) || []
            if (!scope.length)
                return true
            else {
                const result = scope.includes(data.item.name)
                if (!result)
                    console.log(`Removing offer ${offer.name} because it has scope which does not include ${data.item.name}`)
                return result
            }
        })        
        const sessionParams: StripeInputInterface = {
            user: data.user, 
            offer: this.convertPricingSpec(data.item),
            offers,
            term: data.term,
            consumableDocId: docId
        }
        console.log('purchaseItem', sessionParams)
        this.purchaseSession$.next(sessionParams)
        // if (!this.paymentModal)
        this.paymentModal = await this.launchPaymentModal(componentClass, { input: sessionParams })
    }

}
