import { Inject, Injectable } from "@angular/core"
// import { Share } from '@capacitor/share'
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem'
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx'
import { toPng } from 'html-to-image'
import { CHATSHARE_MSG, ENTRYSHARE_MSG, TILESHARE_MSG, convertToCsv } from "@cheaseed/node-utils"
import { ContentService } from "./content.service"
import { BranchDeepLinks } from 'capacitor-branch-deep-links';
import { Platform } from "@ionic/angular"
import { CheaseedUser } from "./shared-user.service"

@Injectable({
  providedIn: "root",
})
export class MediaService {

  constructor(
    @Inject('environment') private environment:any,  // Injected in app.module
    @Inject('UtilityService') private utilityService: any,
    private platform: Platform,
    private contentService: ContentService,
    private socialShare: SocialSharing) { }

    convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
      const reader = new FileReader
      reader.onerror = reject
      reader.onload = () => {
          resolve(reader.result)
      }
      reader.readAsDataURL(blob)
    })

  async shareTile(id: string): Promise<any> {
    const img = this.contentService.getFullTakeawayUrl(id)
    console.log(`About to download ${img}`)
    const response = await fetch(img)
    const blob = await response.blob()
    //console.log("blob", blob.size, blob.type)
    const base64Data: string = await this.convertBlobToBase64(blob) as string
    const msg = this.contentService.getGlobal(TILESHARE_MSG)
    return await this.writeAndShare(msg, id + '.png', base64Data)
  }

  async shareEntryImage(selector: string, imgId: string): Promise<any> {
    return await this.shareImage(selector, imgId, ENTRYSHARE_MSG)
  }
  
  async shareImage(selector:string, imgId: string, shareMsg: string): Promise<any> {
    const node = document.querySelector(selector) as HTMLElement
    console.log("shareImage", node)
    const base64Data = await toPng(node)
    // console.log("shareImage base64data length", base64Data.length)

    const fname = imgId + '.png' //nowstr() + '.png'
    // await Filesystem.requestPermissions();
    const msg = this.contentService.getGlobal(shareMsg)
    return await this.writeAndShare(msg, fname, base64Data)
  }

  async shareCsv(csvId: string, data: any[], headers: string[], shareMsg: string): Promise<any> {
    const fname = csvId + '.csv'
    const msg = this.contentService.getGlobal(shareMsg)
    const csv = convertToCsv(data, headers)
    return await this.writeAndShare(msg, fname, csv, Encoding.UTF8)
  }

  async writeAndShare(msg:string, fileName:string, data: string, encoding: string|null = null) {
    console.log(`writing ${fileName} to ${Directory.Data}`, data)
    const options:any = {
      path: fileName,
      data: data,
      directory: Directory.Data
    }
    if (encoding)
      options['encoding'] = encoding
    // Write file
    await Filesystem.writeFile(options)
    const fileResult = await Filesystem.getUri({
      directory: Directory.Data,
      path: fileName
    });
    
    const result = await this.shareToSocial(msg, fileResult.uri)
    await Filesystem.deleteFile({path: fileName, directory: Directory.Data})
    return result
  }

  async shareToSocial(msg:string, imageURL: string|null, url? : string): Promise<any> { 
    const files = []
    if (imageURL)
      files.push(imageURL)
    const options = {
        message: msg, // not supported on some apps (Facebook, Instagram)
        subject: msg, // fi. for email
        files: files, // an array of filenames either locally or remotely
        url: url,
        chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
        //appPackageName: 'instagram', // Android only, you can provide id of the App you want to share with
       // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
        iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
      }
    try {
        console.log(`About to share ${JSON.stringify(options.files)}` )
        const res:any = await this.socialShare.shareWithOptions(options)
        console.log("shareToSocial res", res)
        if (this.platform.is('android')) {
          // Android always returns false, so we've decided to be optimistic and assume the share was successful in order to issue points
          res.completed = true
        }
        return res
    }
    catch(error) {
        console.log('Cannot share', error)
        return { completed: false }
    }
  }

  // Better workaround for iOS bug in Branch where web_only links dont get generated 
  // check for /e/ post domain when $web_only is set
  checkBranchUrl(url: string, properties: any) {
    let new_url = url
    if (properties['$web_only']) {
      const re = /https:\/\/(.+)\/e\/.+/
      if (!re.test(url)) {
        const index = url.indexOf('/', 8)
        //insert e/ after index
        new_url = [ url.slice(0, index+1), 'e/', url.slice(index+1) ].join('')
        console.log('Added /e to the branch generated url', new_url)
      }
    }
    return new_url
  }

  async shareBranchDeepLink(analytics: any, properties: any, shareText: string) {
    // await BranchDeepLinks.showShareSheet({ analytics, properties, shareText })
    // showShareSheet (doesn't work well on Android 5/4/2022)
    // showShareSheet doesn't return result, like socialShare.shareWithOptions does
    // so we use socialShare.shareWithOptions
    if (this.platform.is('capacitor')) {
      const short_url = await BranchDeepLinks.generateShortUrl({ analytics, properties })
      const url = this.checkBranchUrl(short_url.url, properties)
      console.log("generateBranchLink", 'url', JSON.stringify(url))
      return await this.shareToSocial(shareText, null, url)
    }
    else {
      console.log("BranchDeepLinks is not available on web")
      this.utilityService.notify({ 
        header: 'Share unavailable',
        message: "Sharing links is only available on a mobile device." })
      return { completed: false }
    }
  }

  private replaceTokens(str: string, chat: any, user?: CheaseedUser | null) {
    return str
      .replace("$title", chat.title)
      .replace("$chat", chat.title)
      .replace("$subtitle", chat.subtitle)
      .replace("$inviter", user?.name || 'A friend')
  }

  async generateBranchLinkChat(chat: any, user: CheaseedUser | null): Promise<any> {
    const analytics = {
      channel: 'app',
      feature: 'webchat',
      campaign: 'webchat',
    }

    const id = chat.name
    const takeawayUrl = this.contentService.getFullTakeawayUrl(`${id}.takeaway`)
    const og_image = takeawayUrl || this.contentService.getGlobal("webchat.social.imageurl")
    const og_description = this.replaceTokens(this.contentService.getGlobal("webchat.social.description"), chat, user)
    const og_title = this.replaceTokens(this.contentService.getGlobal("webchat.social.title"), chat, user)
    const shareText = this.replaceTokens(this.contentService.getGlobal(CHATSHARE_MSG), chat, user)
    const url = `${this.environment.linkHost}/chat/${id}`

    // optional fields
    const properties = {
      inviter: user?.docId,
      chat: id,
      $deeplink_path: `/conversation/${id}`,
      // $fallback_url: url,  // overwrites og tags, so use $ios_url, $android_url, $desktop_url instead
      $ios_url: url,
      $android_url: url,
      $desktop_url: url,
      $ipad_url: url,
      //$web_only: true, // chats are normal deep links if the app installed; otherwise  ios_url etc used to send to web
      $og_title: og_title,
      $og_description: og_description,
      $og_image_url: og_image,
      $og_url: url,
      $twitter_card: 'summary_large_image',
      $twitter_title: og_title,
      $twitter_description: og_description,
      $twitter_image_url: og_image,
      $twitter_site: '@cheaseed'
    }

    return await this.shareBranchDeepLink(analytics, properties, shareText)
  }

  async generateBranchLinkStatement(title: string, statementId: string, user: CheaseedUser | null) {
    console.log("generateBranchLinkStatement", title, statementId, user)
    // const msg = `${title} ${url}`
    // const result = await Share.share({ text: msg })
    // return result ? { completed: true, app: result.activityType } : { completed: false }

    const analytics = {
      channel: 'app',
      feature: 'webchat',
      campaign: 'webchat',
    }

    const url = `${this.environment.linkHost}/share/${statementId}`
    const previewUrl = this.contentService.getFullTakeawayUrl(statementId)
    const og_image = previewUrl || this.contentService.getGlobal("webstatement.social.imageurl")
    const og_description = this.contentService.getGlobal("webstatement.social.description")
    const og_title = this.replaceTokens(this.contentService.getGlobal("webstatement.social.title"), {}, user)
    const shareText = title // this.replaceTokens(this.contentService.getGlobal(CHATSHARE_MSG), chat, user)

    // optional fields
    const properties = {
      inviter: user?.docId,
      // chat: id,
      statement: statementId,
      $deeplink_path: `/statement/${statementId}`,
      // $fallback_url: url,  // overwrites og tags, so use $ios_url, $android_url, $desktop_url instead
      $ios_url: url,
      $android_url: url,
      $desktop_url: url,
      $ipad_url: url,
      //$web_only: true, // chats are normal deep links if the app installed; otherwise  ios_url etc used to send to web
      $og_title: og_title,
      $og_description: og_description,
      $og_image_url: og_image,
      $og_url: url,
      $twitter_card: 'summary_large_image',
      $twitter_title: og_title,
      $twitter_description: og_description,
      $twitter_image_url: og_image,
      $twitter_site: '@cheaseed'
    }

    return await this.shareBranchDeepLink(analytics, properties, shareText)
  }


}
