import { marked } from 'marked';
import Handlebars from 'handlebars/dist/cjs/handlebars';
import { diff, diffpercent } from './date-functions'
import { StatsKeys } from './constants'
import { PromptGuide } from './prompts-base'
import { convertToPipeSeparatedValues, splitTrim } from './utilities';
//import { registerHelper } from 'handlebars';
//import { Exception, Handlebars.SafeString, compile, Handlebars.escapeExpression } from 'handlebars';
import { StatsProvider } from './stats-base';

/**
 * handlebars-base is responsible for rendering text blocks defined on Statements, AttributeSpecs, and Options.
 *
 * A text block can contain:
 *
 * - HTML with inline styles
 * - [Markdown](https://www.npmjs.com/package/marked)
 * - [Handlebars templates](https://handlebarsjs.com/guide/)
 *
 * Handlebars templates use both [built-in helpers](https://handlebarsjs.com/guide/builtin-helpers.html#if) and custom helpers, described below.
 *
 * Note that these custom helpers accept $-variables, which are pointers to current user keys in the system, including $$, which represents the last response specified by a user.
 */

export class HandlebarsBase implements StatsProvider {
    /** @internal */
    shouldLog = true

    /** @internal */
    helpers: any

    /** @internal */
    constructor() {
        
        marked.setOptions({
            gfm: true,
            breaks: false
        });

    }

    /** @internal */
    log(...args) { if (this.shouldLog) console.log(...args) }
    /** @internal */
    error(...args) { console.error(...args) }

    /** @internal */
    initialize() {
        this.initializeHelpers(this.getHelpers())
        this.registerHandlebarHelpers(this.getHandlebarHelpers())
    }

    /** @internal */
    getHelpers(): any {
        return {
            log: this.log,
            error: this.error,
            getGlobal: this.getGlobal,
            getGlobalsStartingWith: this.getGlobalsStartingWith,
            getAttributeSpec: this.getAttributeSpec,
            getUserKey: this.getUserKey,
            getUserKeysStartingWith: this.getUserKeysStartingWith,
            lookupAttribute: this.lookupAttribute,
            lookupAttributeSpec: this.lookupAttributeSpec,
            generateEntriesAsReportObjects: this.generateEntriesAsReportObjects,
            generateCSVForEntries: this.generateCSVForEntries,
            generateJSONForEntries: this.generateJSONForEntries,
            getEntriesOfType: this.getEntriesOfType,
            getPromptGuides: this.getPromptGuides,
            extractPromptGuideFieldForTags: this.extractPromptGuideFieldForTags,
            multiOptionDescriptions: this.multiOptionDescriptions,   // for use in multiOptionDescriptionsAsString
            getPromptSpecNamed: this.getPromptSpecNamed,
        }
    }

    /** @internal */
    getHandlebarHelpers(): any {
        return {
            eq: this.eq,
            ne: this.ne,
            lt: this.lt,
            gt: this.gt,
            lte: this.lte,
            gte: this.gte,
            and: this.and,
            or: this.or,
            isIn: this.isIn,
            link: this.link,
            value: this.value,
            valueObject: this.valueObject,
            valueStartingWith: this.valueStartingWith,
            equals: this.equals,
            notEquals: this.notEquals,
            numOptionsSelected: this.numOptionsSelected,
            equalsOneOf: this.equalsOneOf,
            equalsNoneOf: this.equalsNoneOf,
            slice: this.slice,
            exists: this.exists,
            formatDate: this.formatDate,
            optionDescription: this.optionDescription,
            multiOptionDescriptions: this.multiOptionDescriptions,
            multiOptionDescriptionsAsString: this.multiOptionDescriptionsAsString,
            hasEntriesOfType: this.hasEntriesOfType,
            numEntriesOfType: this.numEntriesOfType,
            getReviewReadyStatus: this.getReviewReadyStatus,
            timediff: this.timediff,
            timediffpercent: this.timediffpercent,
            promptTemplate: this.promptTemplate,
            generateTimestamp: this.generateTimestamp,
            extractPromptGuides: this.extractPromptGuides,
            extractTacticalSummaries: this.extractTacticalSummaries,
            extractLanguageScripts: this.extractLanguageScripts,
            multiOptionDescriptionsAsBulletedList: this.multiOptionDescriptionsAsBulletedList,
            optionDescriptionForRankedIndex: this.optionDescriptionForRankedIndex,
            generateBulletedList: this.generateBulletedList,
            generateCSVForEntries: this.generateCSVForEntries,
            generateCSVForEntriesWithAttributeDateSince: this.generateCSVForEntriesWithAttributeDateSince,
            generateJSONForEntries: this.generateJSONForEntries,
            generateReportEntries: this.generateReportEntries,
            generateSatisfactionInsightsSummary: this.generateSatisfactionInsightsSummary,
            hasPersona: this.hasPersona,
            displayTerm: this.displayTerm,
            numEntriesCreatedSince: this.numEntriesCreatedSince,
            numEntriesWithAttributeDateSince: this.numEntriesWithAttributeDateSince,
            getGlobal: this.getGlobal,
            getUserKey: this.getUserKey,
            lookupAttributeSpec: this.lookupAttributeSpec,
            getEntriesOfType: this.getEntriesOfType,
            getAttributeSpecProperty: this.getAttributeSpecProperty,
            isUserAuthenticated: this.isUserAuthenticated,
            isChatPurchased: this.isChatPurchased,
            extractListFrom: this.extractListFrom,
            extractTextFrom: this.extractTextFrom,
            extractSublistFrom: this.extractSublistFrom,
            isUserGroupMember: this.isUserGroupMember,
            isUserPrepaidGroupMember: this.isUserPrepaidGroupMember,
            isUserAffiliateGroupMember: this.isUserAffiliateGroupMember,
        }
    }

    /** @internal */
    initializeHelpers(helpers) {
        this.helpers = helpers
    }

    // register helpers with Handlebars
    /** @internal */
    registerHandlebarHelpers(helpers) {
        Handlebars.registerHelper(helpers);
        Handlebars.registerHelper('helperMissing', function (...args) {
            const options = args[args.length - 1];
            throw new Handlebars.Exception('Unknown field: ' + options.name);
        });
    }

    /** @internal */
    // See https://gist.github.com/servel333/21e1eedbd70db5a7cfff327526c72bc5
    reduceOp(args, reducer) {
        args = Array.from(args);
        args.pop(); // => options
        const first = args.shift();
        return args.reduce(reducer, first);
    }

    /**
     * @param a - a handlebars expression
     * @param b - a handlebars expression
     * @category Logical
     * @returns Returns true if the value of a is in the list b
     *
     * @example
     *
     *    ```
     *     {{#if (isIn (value "Asking.answer") '["Yes" "Maybe"]') }}
     *     {{#if (isIn (timediff "null" (value "CurrentReviewInfo.reviewDate") "day") '[0, 1]') }}
     *    ```
     */
    isIn(a: any, b: any): boolean {
        const vals = (typeof b === 'string' ? JSON.parse(b) : b)
        return vals.indexOf(a) > -1
    }

    /**
     * @param a - a handlebars expression
     * @param b - a handlebars expression
     * @category Logical
     * @returns Returns true if the value of the expressions are equal
     *
     * @example
     *
     *    ```
     *     {{#if (eq (numOptionsSelected "Asking.experienceQuestions") 1)}}
     *    ```
     */
    eq(a: any, b: any): boolean { return a == b }
    /**
     * @param a - a handlebars expression
     * @param b - a handlebars expression
     * @category Logical
     * @returns Returns true if the value of the expressions are equal
     *
     * @example
     *
     *    ```
     *     {{#if (ne (numOptionsSelected "Asking.compensationQuestions") 0)}}
     *    ```
     */
    ne(a: any, b: any): boolean { return a != b }
    /**
     * @param a - a handlebars expression
     * @param b - a handlebars expression
     * @category Logical
     * @returns Returns true if a is less than b
     *
     * @example
     *
     *    ```
     *     {{#if (lt (numOptionsSelected "Asking.compensationQuestions") 3)}}
     *    ```
     */
    lt(a: any, b: any): boolean { return a < b }
    /**
     * @param a - a handlebars expression
     * @param b - a handlebars expression
     * @category Logical
     * @returns Returns true if a is greater than b
     *
     * @example
     *
     *    ```
     *     {{#if (gt (numOptionsSelected "Asking.compensationQuestions") 1)}}
     *    ```
     */
    gt(a: any, b: any): boolean { return a > b }
    /**
     * @param a - a handlebars expression
     * @param b - a handlebars expression
     * @category Logical
     * @returns Returns true if a is less than or equal to b
     *
     * @example
     *
     *    ```
     *     {{#if (lte (numOptionsSelected "Asking.compensationQuestions") 2)}}
     *    ```
     */
    lte(a: any, b: any): boolean { return a <= b }
    /**
     * @param a - a handlebars expression
     * @param b - a handlebars expression
     * @category Logical
     * @returns Returns true if a is greater than or equal to b
     *
     * @example
     *
     *    ```
     *     {{#if (gte (numOptionsSelected "Asking.compensationQuestions") 3)}}
     *    ```
     */
    gte(a: any, b: any): boolean { return a >= b }
    /**
     * @param a - a handlebars expression
     * @param b - a handlebars expression
     * @category Logical
     * @returns Returns true if both a and b are true
     *
     * @example
     *
     *    ```
     *     {{#if (and (eq (numOptionsSelected "Asking.compensationQuestions") 0 )
     *                (and 
     *                  (ne (numOptionsSelected "Asking.opportunityQuestions") 0 )
     *                  (ne (numOptionsSelected "Asking.experienceQuestions") 0 ))) }}
     *    ```
     */
    and(a: any, b: any): boolean { return a && b }
    /**
     * @param a - a handlebars expression
     * @param b - a handlebars expression
     * @category Logical
     * @returns Returns true if either a or b is true
     *
     * @example
     *
     *    ```
     *     {{#if (or (eq (numOptionsSelected "Asking.compensationQuestions") 0 )
     *              (ne (numOptionsSelected "Asking.opportunityQuestions") 0 )) }}
     *    ```
     */
    or(a: any, b: any): boolean { return a || b }

    /**
     * @param text - text for an HTML anchor element
     * @param url url for the HTML anchor element
     * @returns Returns HTML for an <a> element
     *
     * @example
     *
     *    ```
     *     {{ link "cheaseed.com" "http://www.cheaseed.com" }}
     *    ```
     */
    link(text: string, url: string) {
        text = Handlebars.escapeExpression(text)
        url = Handlebars.escapeExpression(url)
        const res = "<a target='cheaseedext' href='" + url + "'>" + text + "</a>"
        return new Handlebars.SafeString(res)
    }

    /**
     * @param key a global or user attribute key
     * @returns Returns the value of the key
     *
     * @example
     *
     *    ```
     *     {{ value "user.name" }}
     *     {{ value "user.name" true }}
     *    ```
     */
    value(key: string, stringifyJson = false) {
        // key = Handlebars.escapeExpression(key)
        let result = this.lookupAttribute(key)
        if (stringifyJson && typeof result !== 'string')
            result = JSON.stringify(result, null, 2)
        // console.log("value", key, result)
        return new Handlebars.SafeString(result)
    }

    valueObject(key: string) {
        return this.lookupAttribute(key)
    }

    /**
     * @param key a global or user attribute key that must contain a JSON array object
     * @param start the start index of the slice (0-based)
     * @param end the end index of the slice (0-based)
     * @returns Returns the slice of the array from start to end
     *
     * @example
     *
     *    ```
     *     {{ slice "Attribute.questionList" 0 3 }}
     *     {{ slice "Attribute.questionList" 4 8 }}
     *    ```
     */
    slice(key: string, start: number, end: number) {
        const result = this.lookupAttribute(key) || []
        return new Handlebars.SafeString(Array.isArray(result) ? result.slice(start, end) : [])
    }

    /**
     * @param key a global or user attribute key
     * @returns Returns the value of the key
     *
     * @example
     *
     *    ```
     *     {{ value "user.name" }}
     *    ```
     */
    valueStartingWith(key: string) {
        const keys = this.getUserKeysStartingWith(key)
        const result = keys.length > 0 
            ? keys.map(key => {
                const val = this.getUserKey(key)
                const str = typeof val === 'string' ? val : JSON.stringify(val, null, 2)
                return `Key: ${key}\n${str}\n\n`
            }).join("\n")
            : null
        this.log(`valueStartingWith for ${key} returning ${keys.length} userkeys, ${result.length} chars`)
        return new Handlebars.SafeString(result)
    }

    /**
     * @param key a global or user attribute key
     * @param val a string value or "null"
     * @returns Returns true if the value of the key equals val
     *
     * @example
     *
     *    ```
     *     {{#if (equals "Pitching.howManyPitches" "min1") }}
     *    ```
     */
    equals(key: string, val: string): boolean {
        // this.log({key}, {val})
        key = Handlebars.escapeExpression(key)
        val = val === "null" ? null : Handlebars.escapeExpression(val)
        const curr = this.lookupAttribute(key)
        if (curr && typeof curr === 'object') {
            throw new Handlebars.Exception('equals cannot evaluate a MULTIOPTIONS attribute, use equalsOneOf')
        }
        // this.log({curr}, {val})
        const result = (curr == val)  // must use == to handle undefined comparison
        this.log(`Handlebars checking equals ${key}:${curr} == ${val}`, result)
        return result
    }
    /**
     * @param key a global or user attribute key
     * @param val a string value or "null"
     * @returns Returns true if the value of the key does not equal val
     *
     * @example
     *
     *    ```
     *     {{#if (notEquals "Pitching.howManyPitches" "min1") }}
     *    ```
     */
    notEquals(key: string, val: string): boolean {
        key = Handlebars.escapeExpression(key)
        val = val === "null" ? null : Handlebars.escapeExpression(val)
        const curr = this.lookupAttribute(key)
        const result = (curr != val)  // must use == to handle undefined comparison
        this.log(`Handlebars checking notEquals ${key} != ${val}`, result)
        return result
    }
    /**
     * @param key a global or user attribute key
     * @category MULTIOPTIONS
     * @returns Returns number of options selected in a MULTIOPTIONS attribute
     *
     * @example
     *
     *    ```
     *     {{#if (gte (numOptionsSelected "Asking.experienceQuestions") 2)}}
     *    ```
     */
    numOptionsSelected(key: string): number {
        key = Handlebars.escapeExpression(key)
        const curr = this.lookupAttribute(key)
        const cnt = curr ? Object.keys(curr).filter(v => !!curr[v]).length : 0
        this.log(`Handlebars numOptionsSelected for ${key}`, cnt)
        return cnt
    }

    /**
    * Test whether a MULTIOPTIONS or OPTIONS attribute value contains at least one of the option values
    * contained in a list
    * 
    * @param key a user attribute key representing a MULTIOPTIONS or OPTIONS attribute
    * @param val a stringified array of strings
    * @category MULTIOPTIONS or OPTIONS
    * @returns Returns true if the key value is one of the strings in val
    *
    * @example
    *
    *    ```
    *     {{#if (equalsOneOf 'Onboarding.workType' '[ "workerType.gig", "workerType.self" ]' ) }}
    *    ```
    */
    equalsOneOf(key: string, val: string): boolean {
        key = Handlebars.escapeExpression(key)
        const vals = JSON.parse(val)
        let result
        const curr = this.lookupAttribute(key) || {}
        if (typeof curr === 'string')
            result = vals.includes(curr)
        else {
            const currkeys = Object.keys(curr).filter((v: string) => !!curr[v])
            const intersect = currkeys.filter(item => vals.includes(item))
            result = intersect.length > 0
        }
        this.log(`Handlebars checking equalsOneOf ${key} == ${val}`, result)
        return result
    }

    /**
     * @param key a user attribute key representing a MULTIOPTIONS attribute
     * @param val a stringified array of strings
     * @category MULTIOPTIONS
     * @returns Returns true if the key value is one of the strings in val
     *
     * @example
     *
     *    ```
     *     {{#if (equalsNoneOf 'Onboarding.workType' '[ "workerType.gig", "workerType.self" ]' ) }}
     *    ```
     */
    equalsNoneOf(key: string, val: string): boolean {
        key = Handlebars.escapeExpression(key)
        const vals = JSON.parse(val)
        const curr = this.lookupAttribute(key) || {}
        // Identify keys set
        const currkeys = Object.keys(curr).filter((key: string) => !!curr[key])
        // Intersect with vals provided
        const intersect = currkeys.filter(item => vals.includes(item))
        const result = intersect.length === 0
        this.log(`Handlebars checking equalsNoneOf ${key} == ${val}`, result)
        return result
    }

    /**
     * @param key a user attribute key
     * @returns Returns true if the key value is not null
     *
     * @example
     *
     *    ```
     *     {{#if (exists "StatsConfig.satisfactionStartDate")}}
     *    ```
     */
    exists(key: string): boolean {
        key = Handlebars.escapeExpression(key)
        return this.lookupAttribute(key) ? true : false
    }

    /**
    * @param key a user attribute key representing a date
    * @param format a moment format string (e.g. MM/DD/YYYY)
    * @returns Returns true if the key value is not null
    *
    * @example
    *
    *    ```
    *     {{formatDate "StatsConfig.satisfactionStartDate" "MM/DD/YYYY"}}
    *    ```
    */
    formatDate(key: string, format: string): any {
        throw new Error('Implemented in subclass')
    }

    /**
     * @param startDate start date YYYY-MM-DD, or "null" for today's date
     * @param endDate end date YYYY-MM-DD, or "null" for today's date
     * @param unit one of day, week, month
     * @returns Returns number of days/weeks/months between endDate and startDate, may be negative
     *
     * @example
     *
     *    ```
     *     {{ timediff "2021-05-01" "2021-05-18" "day" }}
     *     {{ timediff (value "ReviewConfig.reviewStartDate") (value "ReviewConfig.reviewEndDate") "day" }}
     *     {{ timediff "2021-05-01" "null" "day" }}
     *     {{ timediff "null" "2022-12-01" "day" }}
     *    ```
     */
    timediff(startDate: string, endDate: string, unit: string): number {
        const start = Handlebars.escapeExpression(startDate)
        const end = Handlebars.escapeExpression(endDate)
        const result = diff(start, end, unit)
        this.log(`Handlebars timediff ${start} ${end} ${unit}: ${result}`)
        return result
    }

    /**
     * @param startDate start date
     * @param endDate end date
     * @returns Returns a percentage representing today's date on the timeline between startDate and endDate
     *
     * @example
     *
     *    ```
     *     {{ timediffpercent "2021-05-01" "2022-05-01" }}
     *     {{ timediffpercent (value "ReviewConfig.reviewStartDate") (value "ReviewConfig.reviewEndDate") }}
     *    ```
     */
    timediffpercent(startDate: string, endDate: string): number {
        const start = Handlebars.escapeExpression(startDate)
        const end = Handlebars.escapeExpression(endDate)
        const result = diffpercent(start, end)
        this.log(`Handlebars timediffpercent ${start} ${end}: ${result}`)
        return result
    }

    /**
     * @param key a user attribute key for an OPTIONS inputType
     * @category OPTIONS
     * @returns Returns the description of the option selected or null if unselected
     *
     * @example
     *
     *    ```
     *     {{ optionDescription "Onboarding.getAdvanced" }}
     *    ```
     */
    optionDescription(key: string) {
        let result
        key = Handlebars.escapeExpression(key)
        // Note that this is the current context, not HandlebarsService (see below)
        const val = this.lookupAttribute(key)
        const spec = this.lookupAttributeSpec(key)
        const genkey = spec.inputTypeParams
            ? spec.inputTypeParams.optionsKey
            : `generated.${spec.name}`
        const genval: any[] = this.getUserKey(genkey)
        if (genval) {
            const idx = genval.findIndex((o: any) => o.name === val)
            result = idx >= 0 ? genval[idx].description : null
        }
        else if (spec) {
            const idx = spec.optionLinks.findIndex((o: any) => o.name === val)
            result = idx >= 0 ? spec.optionLinks[idx].description : null
            // this.log("found ", result)
        }
        return new Handlebars.SafeString(result)
    }

    /**
     * @param optionsKey a userkey storing generated options (name, description)
     * @param rankingKey a userkey storing a ranking of option names
     * @param index the index of the option in the ranking, 1 is the first ranked item
     * @category OPTIONS
     * @returns Returns the description of the option selected or null
     *
     * @example
     *
     *    ```
     *     {{ optionDescriptionForRankedIndex "generated.options.key" "ranking.key" 1 }}
     *    ```
     */
    optionDescriptionForRankedIndex(optionsKey: string, rankingKey: string, index: number) {
        const attribute = this.getAttributeSpec(rankingKey)
        const options = optionsKey.length > 0
            ? this.getUserKey(optionsKey) || []
            : attribute.optionLinks
        const ranking = this.getUserKey(rankingKey) || []
        const option = ranking.length > 0 
            ? (index > 0 && index <= ranking.length
                ? ranking[index - 1] 
                : null)
            : (index > 0 && index <= options.length
                ? options[index - 1] 
                : null)
        const optionIndex = options.findIndex((o: any) => o.name === option)
        const result = optionIndex >= 0 ? options[optionIndex].description : null
        this.log(`optionDescriptionForRankedIndex ${optionsKey} ${rankingKey}: ${JSON.stringify(ranking)} index: ${index}, result ${result}`)
        return result
    }

    /**
     * @param key a user attribute key for a MULTIOPTIONS inputType
     * @category MULTIOPTIONS
     * @returns Returns an array of selected strings 
     *
     * @example
     *
     *    ```
     *     {{ multiOptionDescriptions "Onboarding.getAdvanced" }}
     *    ```
     */
    multiOptionDescriptions(key: string): string[] {
        // Return a list of option descriptions for the options that are currently selected
        const result = []
        key = Handlebars.escapeExpression(key)
        const val = this.lookupAttribute(key)
        const spec = this.lookupAttributeSpec(key)  // must not be async
        if (!spec)
            throw new Error(`multiOptionDescriptions cannot find spec for ${key}`)
        // this.log(`multiOptionDescriptions looked up ${key}, value: ${JSON.stringify(val)}`)
        // Check for generated.key userkey
        const genkey = spec.inputTypeParams
            ? (spec.inputTypeParams.targetOptionsKey || spec.inputTypeParams.optionsKey)
            : `generated.${spec.name}`
        const genval: any[] = this.getUserKey(genkey)
        const nameKey = spec.inputTypeParams?.optionNameKey || 'name'
        const descKey = spec.inputTypeParams?.optionDescriptionKey || 'description'
        if (val) {
            if (genval) {
                // this.log(`multiOptionDescriptions looked up generated key ${genkey}, value: ${JSON.stringify(genval)}`)
                if (Array.isArray(genval)) {
                    for (const key of Object.keys(val).filter(k => !!val[k])) {
                        const idx = genval.findIndex(o => o[nameKey] === key)
                        result.push(idx >= 0 ? genval[idx][descKey] : key)
                    }
                }
                else
                    this.error(`Generated key ${genkey} is not an array`, JSON.stringify(genval))
            }
            else if (spec) {
                for (const key of Object.keys(val).filter(k => !!val[k])) {
                    const idx = spec.optionLinks.findIndex((o: any) => o[nameKey] === key)
                    if (idx >= 0)
                        result.push(spec.optionLinks[idx][descKey])
                }
            }
        }
        this.log(`multiOptionDescriptions found ${JSON.stringify(result)}`)
        return result
    }

    /**
     * @param key the name of an attribute for a MULTIOPTIONS inputType
     * @category MULTIOPTIONS
     * @returns Returns a string of option descriptions in the form of a bulleted list
     *
     * @example
     *
     *    ```
     *     {{ multiOptionDescriptionsAsBulletedList "Onboarding.getAdvanced" }}
     *    ```
     */
    multiOptionDescriptionsAsBulletedList(key: string) {
        // Return a string of option descriptions for the options that are currently selected as a bulleted list
        const result = this.multiOptionDescriptions(key)
        // const str = "\n<ul>" + result.map(s => `<li>${s}</li>`).join("\n") + "</ul>\n"
        // Rely on subsequent markdown processing to generate the html
        const str = "\n\n" + result.map(s => `* ${s}`).join("\n") + "\n"
        return new Handlebars.SafeString(str)
    }


    /**
     * @param items a list of items that may be strings or name/description objects of arbitrary length
     * @returns Returns a string in the form of an unordered bulleted list
     *
     * @example
     *
     *    ```
     *     {{ generateBulletedList (slice "Attribute.questionList" 0 3) }}
     *     {{ generateBulletedList (valueObject "Attribute.questionList") }}
     *    ```
     */
    generateBulletedList(items: any) {
        let arr = items?.string || items || []
        if (arr.items) // handle bad json output
            arr = arr.items
        let str = ''
        if (Array.isArray(arr))
            str = "\n" + arr.map(s => `- ${typeof s === 'string' ? s : s.description}`).join("\n") + "\n"
        else
            console.log("generateBulletedList called on a non-array type", typeof arr )
        return new Handlebars.SafeString(str)
    }

    /**
     * @param prefix the prefix for a set of global tags representing a group to be classified
     * @returns Returns a string of tags and their descriptions
     *
     * @example
     *
     *    ```
     *     {{ generateTagDescriptionList "tag.coach" }}
     *    ```
     */
    generateTagDescriptionList(prefix: string) {
        const tags = this.getGlobalsStartingWith(prefix)
        const result = tags.map(tag => {
            const val = this.getGlobal(tag.key)
            return `- ${tag.key}\n- "${val}"`
        })
        return result.join("\n")
    }

    /**
     * @param key a user attribute key for a MULTIOPTIONS inputType
     * @category MULTIOPTIONS
     * @returns Returns a concatenated list of selected option descriptions
     *
     * @example
     *
     *    ```
     *     {{ multiOptionDescriptionsAsString "Onboarding.getAdvanced" }}
     *    ```
     */
    multiOptionDescriptionsAsString(key: string): string {
        // Return a concatenated string of option descriptions for the options that are currently selected
        const result = this.multiOptionDescriptions(key)
        return result.join(", ")
    }

    /**
     * @param type the type of the entries
     * @param max the maximum number of entries to return
     * @param sinceDate only return entries more recent than this date
     * @category GPT
     * @returns Returns a JSON of entry reports
     *
     * @example
     *
     *    ```
     *     {{ generateJSONForEntries "Accomplishment" "5" "2023-01-01" }}
     *    ```
     */
    generateJSONForEntries(type: string, max: number, sinceDate: string) {
        const result = this.generateEntriesAsReportObjects(type, max, null, sinceDate)
        return JSON.stringify(result)
    }

    /**
     * @param type the type of the entries
     * @param max the maximum number of entries to return
     * @param sinceDate only return entries more recent than this date
     * @param headers a comma-separated list of headers to include in the CSV
     * @category GPT
     * @returns Returns a CSV of entry reports
     *
     * @example
     *
     *    ```
     *     {{ generateCSVForEntries "Accomplishment" "5" "name,dateOccurred,typeOfWork,description,empowermentRating,type,notes" "2023-01-01" }}
     *    ```
     */

    generateCSVForEntries(type: string, max: number, headers: string, sinceDate: string) {
        const result = this.generateEntriesAsReportObjects(type, max, null, sinceDate)
        const columns = headers ? headers.split(",") : []
        return convertToPipeSeparatedValues(result, columns)
    }

    /**
     * Return a pipe-delimited CSV output for the entries whose attribute attrName is greater than sinceDate
     * 
     * @param type the type of the entries
     * @param max the maximum number of entries to return
     * @param headers a comma-separated list of headers to include in the CSV
     * @param attrName the name of the attribute to use as the date
     * @param sinceDate only return entries more recent than this date
     * @category GPT
     * @returns Returns a CSV of entry reports
     *
     * @example
     *
     *    ```
     *     {{ generateCSVForEntriesWithAttributeDateSince "Accomplishment" "5" "name,dateOccurred,typeOfWork,description,empowermentRating,type,notes" "dateOccurred" "2023-01-01" }}
     *    ```
     */

    generateCSVForEntriesWithAttributeDateSince(type: string, max: number, headers: string, attrName: string, sinceDate: string) {
        const result = this.generateEntriesAsReportObjects(type, max, attrName, sinceDate)
        const columns = headers ? headers.split(",") : []
        return convertToPipeSeparatedValues(result, columns)
    }

    /**
     * Return a stream of text of previously generated reports of a given type
     * 
     * @param type the type of the entries
     * @category GPT
     * @returns Returns the text of generated reports
     *
     * @example
     *
     *    ```
     *     {{ generateReportEntries "SelfAssessmentReport" }}
     *    ```
     */
    generateReportEntries(type: string) {
        const reports:string[] = this.getEntriesOfType(type).map(e => e.attributes.report)
        return new Handlebars.SafeString(reports.join("\n"))
    }

    /**
     * @param name the name of the prompt
     * @category GPT
     * @returns Returns the prompt template
     *
     * @example
     *
     *    ```
     *     {{ promptTemplate "prompt.biases" }}
     *    ```
     */
    promptTemplate(name: string) {
        const spec = this.getPromptSpecNamed(name)
        // this.log("promptTemplate", spec?.promptTemplate)
        return new Handlebars.SafeString(spec?.promptTemplate)
    }

    
    /**
     * @param name the name of the chat
     * @category GPT
     * @returns Returns the chat summary
     *
     * @example
     *
     *    ```
     *     {{ chatSummary "MindsetOfNegotiation" }}
     *    ```
     */
    chatSummary(name: string) {
        throw new Error('implemented in subclass')
    }

    
    /**
     * We read Media metadata into memory and resolve all download urls at startup.
     * We then replace the named image with its corresponding url.
     * 
     * @param name an image name, as specified in Media
     * @category Image
     * @returns an HTML img element configured to read the corresponding image
     *
     * @example
     *
     *    ```
     *     {{ image "PinkPlusSign.png" }}
     *    ```
     */
    image(name: string) {
        throw new Error('implemented in subclass')
    }

    
    /**
     * @param name an image name, as specified in Media, or a publicly accessible url starting with 'http'
     * @param width the desired width of the image (will scale height)
     * @category Image
     * @returns an HTML img element configured to read the corresponding image and display at the specified width
     *
     * @example
     *
     *    ```
     *     {{ imagewidth "LaughStart.takeaway.png" 200 }}
     *    ```
     */
    imagewidth(name: string, width: number) {
        throw new Error('implemented in subclass')
    }

    
    /**
     * @param name the name of a chat
     * @returns true if the chat has already been successfully completed by the user
     *
     * @example
     *
     *    ```
     *     {{#if (isChatCompleted "WelcomeToCheaSeed")}}
     *    ```
     */
    isChatCompleted(name: string): boolean {
        throw new Error('implemented in subclass')
    }

    /**
     * @param chatName the name of a chat
     * @returns true if the current chat instance in-progress has already been purchased by the user
     *
     * @example
     *
     *    ```
     *     {{#if (isChatPurchased "AdviceReviewCoach" )}}
     *    ```
     */
    isChatPurchased(chatName: string): boolean {
        throw new Error('implemented in subclass')
    }

    /**
     * @returns true if the current user is a member of any group
     *
     * @example
     *
     *    ```
     *     {{#if (isUserGroupMember) )}}
     *    ```
     */

    isUserGroupMember() {
        throw new Error('implemented in subclass')
    }

    /**
     * @returns true if the current user is a member of a prepaid group
     *
     * @example
     *
     *    ```
     *     {{#if (isUserPrepaidGroupMember) )}}
     *    ```
     */

    isUserPrepaidGroupMember() {
        throw new Error('implemented in subclass')
    }

    /**
     * @returns true if the current user is a member of an affiliate group
     *
     * @example
     *
     *    ```
     *     {{#if (isUserAffiliateGroupMember) )}}
     *    ```
     */

    isUserAffiliateGroupMember() {
        throw new Error('implemented in subclass')
    }

    
    /**
     * @param name the name of a chat
     * @returns the title of the chat named name; null if there is no chat named name
     *
     * @example
     *
     *    ```
     *     {{ titleOfChatNamed "WelcomeToCheaSeed" }}
     *    ```
     */
    titleOfChatNamed(name: string): string {
        throw new Error('implemented in subclass')
    }

    
    /**
     * @param name the name of a series
     * @returns the title of the series named name; null if there is no such series
     *
     * @example
     *
     *    ```
     *     {{ titleOfSeriesNamed "Mindset of Negotiation" }}
     *    ```
     */
    titleOfSeriesNamed(name: string): string {
        throw new Error('implemented in subclass')
    }

    
    /**
     * @param name the name of a program
     * @returns true if the current program is named name
     *
     * @example
     *
     *    ```
     *     {{#if (isCurrentProgram "DipSlumpProgram")}}
     *    ```
     */
    isCurrentProgram(name: string): boolean {
        throw new Error('implemented in subclass')
    }

    
    /**
     * @returns string the current program's create date
     *
     * @example
     *
     *    ```
     *     {{ (timediff (currentProgramCreateDate) "null" "week") }}
     *    ```
     */
    currentProgramCreateDate(): string {
        throw new Error('implemented in subclass')
    }

    
    /**
    * @param attribute the name of an attribute
    * @returns any value of the most recent entry's attribute named attribute
    *
    * @example
    *
    *    ```
    *     {{getLastEntryAttribute "reviewDate"}}
    *    ```
    */
    getLastEntryAttribute(attribute: string) {
        throw new Error('implemented in subclass')
    }

    
    /**
     * @param name the name of a program
     * @returns the title of the program
     *
     * @example
     *
     *    ```
     *     {{titleOfChatNamed "DipSlumpProgram"}}
     *    ```
     */
    titleOfProgramNamed(name: string): string {
        throw new Error('implemented in subclass')
    }

    
    /**
     * get the state of the learn sequence (new, inprogress, completed)
     * 
     * @returns the state of the initial learn sequence
     *
     * @example
     *
     *    ```
     *     {{#if (eq (getLearnState) "new") }}
     *    ```
     */
    getLearnState(): string {
        throw new Error('implemented in subclass')
    }

    
    /**
     * plays a named audio asset, but only if sound is enabled
     * 
     * @param name the name of an audio clip in Media content
     * @returns an empty string
     *
     * @example
     *
     *    ```
     *     {{ playAudio "clink.mp3" }}
     *    ```
     */
    playAudio(name: string) {
        throw new Error('implemented in subclass')
    }

    
    /**
     * get the current points streak length
     * 
     * @returns the length of the current streak
     *
     * @example
     *
     *    ```
     *     {{#if (gt (getCurrentStreak) 3)}}
     *    ```
     */
    getCurrentStreak(): number {
        throw new Error('implemented in subclass')
    }

    /**
     * Return true if user is authenticated (not anonymous)
     * 
     * @returns true if user is authenticated
     *
     * @example
     *
     *    ```
     *     {{#if (isUserAuthenticated) )}}
     *    ```
     */
    isUserAuthenticated(): boolean {
        throw new Error('implemented in subclass')
    }

    //overridden in subclasses
    /**
     * Return the number of entries of a specific type created in the last n units of time
     * 
     * @param type the entry type
     * @param unit the unit of time ("days", "weeks", "months")
     * @param n the number of time units 
     * @returns the number of entries created since the time specified
     *
     * @example
     *
     *    ```
     *     {{#if (gt (numEntriesCreatedSince "Accomplishment" "week" "12") 10)}}
     *    ```
     */
    numEntriesCreatedSince(type: string, unit: string, n: number): any {
        throw new Error('Implemented in subclass')
    }
    //overridden in subclasses
    /**
     * Return the number of entries of a specific type based on the value of attributeName being greater than sinceDate
     * 
     * @param type the entry type
     * @param attrName the name of the attribute to use as the date
     * @param sinceDate the date to compare against
     * @returns the number of entries with attributeName since the date specified
     *
     * @example
     *
     *    ```
     *     {{#if (gt (numEntriesWithAttributeDateSince "Accomplishment" "dateOccurred" "2023-08-01") 10)}}
     *    ```
     */
    numEntriesWithAttributeDateSince(type: string, attrName: string, sinceDate: string): number {
        throw new Error('Implemented by subclass')
    }

    /**
     * Return the last computed review ready status
     * 
     *   0 - if # accomplishments remaining or # weeks remaining is zero (Done)
     *   1 - if # accomplishments remaining are more than 20% behind # weeks remaining (Behind)
     *   2 - if # accomplishments remaining are within 20% of # weeks remaining (OnPace)
     *   3 - if # accomplishments remaining are more than 20% ahead of # weeks remaining (Ahead)
     * 
     * @returns a value 0-3
     *
     * @example
     *
     *    ```
     *     {{#if (eq (getReviewReadyStatus) 2)}}
     *    ```
     */
    getReviewReadyStatus() {
        return this.getUserKey(StatsKeys.ReviewReadyStatusKey)
    }

    
    /**
     * Return whether the user has any favorites selected
     * 
     * @returns true if user has Favorites
     *
     * @example
     *
     *    ```
     *     {{#if (hasFavorites) }}
     *    ```
     */
    hasFavorites() {
        throw new Error('Implemented by subclass')
    }

    //overridden by subclasses
    /**
       * Return whether the user has any entries of specified type
       * 
       * @param type the entry type ("null" for all)
       * @returns true if user has > 0 entries of the specified type
       *
       * @example
       *
       *    ```
       *     {{#if (hasEntriesOfType "Accomplishment") }}
       *    ```
       */
    hasEntriesOfType(type: string): boolean {
        throw new Error('Implemented by subclass')
    }

    /**
     * Return the number of entries of specified types
     * 
     * @param type the comma-separated list of entry types (use "null" for all)
     * @returns the sum of the number of entries of each type
     *
     * @example
     *
     *    ```
     *     {{#if (gt (numEntriesOfType "Accomplishment") 5) }}
     *     {{#if (gt (numEntriesOfType "Accomplishment,Accolade") 10) }}
     *     {{#if (gt (numEntriesOfType "null") 20) }}
     *    ```
     */
    numEntriesOfType(type: string): number {
        if (type === 'null')
            return this.getEntriesOfType(null)?.length || 0
        else {
            const types = type.split(',')
            let sum = 0
            for (const t of types)
                sum += this.getEntriesOfType(t.trim())?.length || 0
            return sum
        }
    }

    
    /**
     * Return the chat summaries for the chats that match any of the tags in the provided list
     * 
     * @param tags the comma-separated list of globals that are stored as chat globaltags
     * @returns the text of the chat summaries that match any of the tags
     *
     * @example
     *
     *    ```
     *     {{ extractChatSummaries (value "user.coaching.likelyTags") }}
     *    ```
     */
    extractChatSummaries(tags: any) {
        throw new Error('Implemented by subclass')
    }

    /**
     * Return the unique tactical summaries for the tags in the provided list
     * 
     * @param tags the comma-separated list of globals that are stored as chat globaltags
     * @returns the text of the tactical summaries that match any of the tags
     *
     * @example
     *
     *    ```
     *     {{ extractTacticalSummaries (value "user.coaching.likelyTags") }}
     *    ```
     */
    extractTacticalSummaries(tags: any) {
        return this.extractPromptGuideFieldForTags(tags, 'tacticalSummary')
    }

    /**
     * Return the unique tactical language scripts for the tags in the provided list
     * 
     * @param tags the comma-separated list of globals that are stored as chat globaltags
     * @returns the text of the language scripts that match any of the tags
     *
     * @example
     *
     *    ```
     *     {{ extractLanguageScripts (value "user.coaching.likelyTags") }}
     *    ```
     */
    extractLanguageScripts(tags: any) {
        return this.extractPromptGuideFieldForTags(tags, 'languageScript')
    }

    /**
     * Return the prompt guides that match any of the tags in the provided list
     * 
     * @param tags the comma-separated list of globals that are stored as globaltags
     * @returns the text of the prompt guides that match any of the tags
     *
     * @example
     *
     *    ```
     *     {{ extractPromptGuides (value "user.coaching.likelyTags") }}
     *    ```
     */
    extractPromptGuides(tags: any) {
        return this.extractPromptGuideFieldForTags(tags, 'guideText')
    }

    /**
     * Return whether the user has specified persona
     * 
     * @param name The name of the persona
     * @returns true if the user has the named persona
     * @example
     *
     *    ```
     *     {{#if (hasPersona "Female") }}
     *    ```
     */
    hasPersona(name: string) {
        throw new Error('Implemented in subclass')
    }

    /**
     * Generate string output of the current date in user's timezone
     * 
     * @returns formatted date using MMM dd, yyyy
     * @example
     *
     *    ```
     *     {{ generateTimestamp }}
     *    ```
     */
    generateTimestamp(): any {
        throw new Error('Implemented in subclass')
    }

    /**
     * Get text of an attribute spec property
     * 
     * @param key the key of the attribute spec
     * @param attributeName the name of the attribute spec property
     * @returns the value of the property
     * @example
     *
     *    ```
     *     {{ getAttributeSpecProperty "CoachReview.reasonForTappingIn" "question" }}
     *    ```
     */
    getAttributeSpecProperty(key:string, attributeName: string): string {
        const spec = this.lookupAttributeSpec(key)
        return spec ? spec[attributeName] : null
    }

    /**
     * @param term human-readable term
     * @param key global key containing term definition content
     * @category Terms
     * @returns a link that when clicked will display the term definition
     *
     * @example
     *
     *    ```
     *     {{ displayTerm "Accomplishment" "term.Accomplishment" }}
     *    ```
     */
    displayTerm(term: string, key: string) {
        term = Handlebars.escapeExpression(term)
        key = Handlebars.escapeExpression(key)
        // See chat-player.component.ts for the clickTerm handler
        const event = `new CustomEvent('clickTerm', {detail: { term: '${term}', key: '${key}' }})`
        const html = `<a style="color: blue; text-decoration: underline; cursor: pointer;" onclick="window.dispatchEvent(${event}); window.event.stopPropagation()">${term}</a>`
        return new Handlebars.SafeString(html)
    }

    protected extractPromptGuideFieldForTags(tags: any, field = 'guideText') {
        let result = ''
        if (tags) {
            const str = typeof tags === 'string' ? tags : tags.string
            const tagList = splitTrim(str)
            // retrieve guides that match any of the tags
            const guides:PromptGuide[] = this.getPromptGuides().filter(p => p.globalTags?.map(g => g.name).some(g => tagList.includes(g)))
            // this.log('extractPromptGuides found', guides, str)
            // retrieve nun-null fields
            const summaries = guides.map(g => {
                const str = `Guide: ${g.name}\n\nTags: ${g.globalTags?.map(t => t.name).join(', ')}\n\n`
                const value = g[field]
                return value ? `${str}: ${value}` : null
            }).filter(s => s)
            result = summaries.length > 0 ? summaries.join('\n\n') : `No guides found matching tags: ${str}`
            this.log(`extractPromptGuideFieldForTags ${field} returning ${result.length} characters`)
        }
        return new Handlebars.SafeString(result)
    }

    /**
     * Return text extracted from specified heading in the specified userkey
     * 
     * @param key the userkey containing the text
     * @param heading the heading to extract
     * @returns the text contained within the heading
     * @example
     *
     *   ```
     *   {{ extractTextFrom "user.finalized.cpCareerDirection" "Approach for Discussion" }}
     *   ```
     */
    extractTextFrom(key: string, heading: string) {
        throw new Error('Implemented by subclass')
    }

    /**
     * Return depth 0 json list extracted from specified heading in the specified userkey
     * 
     * @param key the userkey containing the text
     * @param heading the heading to extract
     * @returns json list of objects containing the matching list items
     * @example
     *
     *   ```
     *   {{ extractListFrom "user.finalized.cpCareerDirection" "Extract List" }}
     *   {{ extractListFrom "user.finalized.cpCareerDirection" "Your Career Plan Exploration" }}
     *   ```
     */
    extractListFrom(key: string, heading: string) {
        throw new Error('Implemented by subclass')
    }

    /**
     * Return depth 1 json list extracted from specified heading in the specified userkey at specified depth 0 list item
     * 
     * @param key the userkey containing the text
     * @param heading the heading to extract
     * @param itemText the text of the depth 0 list item
     * @returns json list of objects containing the matching list items
     * @example
     *
     *   ```
     *   {{ extractListFrom "user.finalized.cpCareerDirection" "Your Career Plan Exploration" "Business Development Manager" }}
     *   ```
     */
    extractSublistFrom(key: string, heading: string, itemText: string) {
        throw new Error('Implemented by subclass')
    }

    /** @internal */
    // Compile Markdown and sanitize
    renderMarkdown(text: string): any {
        // Replace any spaces before a newline
        const str = text.replace(/\ +\n/g, "\n");
        // this.log("renderMarkdown", text.length, str.length)
        const block = (marked(str) as string).trim();
        return block;
    }

    /** @internal */
    format(content: string, cache: any = {}, noRendering = true): any {
        let block = content;
        if (!block)
            return null;
        // Transform Handlebars
        block = block.replace(/[\u2018\u2019\u201A\u201B]/g, "'")       // replace curly single quotes
                     .replace(/[\u201C\u201D\u201E\u201F]/g, '"')       // replace curly double quotes
                     .replace(/[\u2010\u2011\u2012\u2013\u2014]/g, "-") // replace various hyphens
        if (block.includes('{{')) {
            // this.log("Handlebars detected", block)
            // Replace any occurrence of $$ with lastResponse lookup in cache
            block = block.replace(/\$\$/g, "lastResponse");
            const template = Handlebars.compile(block);
            // Note how we provide instances in the context for use in the helpers above
            const context = { ...cache, ...this.helpers };
            try {
                block = template(context).trim();
            }
            catch (e: any) {
                this.error("Error compiling Handlebars for", block.substring(0, 80))
                throw new Error(e.message)
            }

            // Handle nested handlebars
            if (block.includes('{{') && content !== block) {
                // this.log("Handlebars nesting detected in", block);
                block = this.format(block, cache, true) as string;
            }
        }
        // this.log("about to call marked with", block)
        return block;
    }

    getPromptSpecNamed(name: string): any {
        throw new Error('Implemented by subclass')
    }

    /** @internal */
    lookupAttributeSpec(key: string): any {
        throw new Error('Implemented by subclass')
    }

    /** @internal */
    getGlobal(key: string): any {
        throw new Error('Implemented by subclass')
    }

    /** @internal */
    getGlobalsStartingWith(key: string): any {
        throw new Error('Implemented by subclass')
    }

    /** @internal */
    getAttributeSpec(attr: string): any {
        throw new Error('Implemented by subclass')
    }

    /** @internal */
    lookupAttribute(key: string): any {
        //return this.contentService.getGlobal(key) || this.userService.getUserKey(key)
        return this.getGlobal(key) || this.getUserKey(key)
    }

    /** @internal */
    getUserKey(key: string): any {
        throw new Error('Implemented by subclass')
    }

    /** @internal */
    getUserKeysStartingWith(prefix: string): any {
        throw new Error('Implemented by subclass')
    }

    /** @internal */
    getPromptGuides(): any {
        throw new Error('implemented bysubclass')
    }

    /** @internal */
    getEntriesOfType(type: string): any {
        throw new Error('implemented by subclass')
    }

    generateEntriesAsReportObjects(type: string, max: number, attributeName: string | null, sinceDate: string): any {
        throw new Error('Implemented in subclass')
    }

    formatWithUserKeys(content: string): any {
        throw new Error('Implemented in subclass')
    }

    /**
     * Return a text summary of Satisfaction insights
     * 
     * @returns string Sentences summarizing the user's satisfaction insights
     * @example
     *
     *    ```
     *     {{ generateSatisfactionInsightsSummary }}
     *    ```
     */
    generateSatisfactionInsightsSummary(): any {
        throw new Error('Implemented in subclass')
    }

}
