import { Injectable } from '@angular/core';
import { LoggerInterface, OpenAIConfig, OpenAIFactory } from '@cheaseed/node-utils';

@Injectable({
  providedIn: 'root',
})
export class OpenAIFactoryService  {
  
  factory: OpenAIFactory
  logger: LoggerInterface | undefined
  constructor() {
    this.factory = new OpenAIFactory()
  }
  // The logger is currently unused. Leaving it in here for future use
  setLogger(logger: LoggerInterface) {
    this.logger = logger
  }
  getOpenAPI(key: string, config: OpenAIConfig) {
    return this.factory.getOpenAPI(key, config)
  }
}