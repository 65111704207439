/* eslint-disable @typescript-eslint/no-explicit-any */
import { TIMESTAMP_MILLIS_ISO_FORMAT, YYYYMMDD_FORMAT } from './constants'
import { add as addFn, differenceInDays, differenceInMinutes, differenceInMonths, differenceInWeeks, format as formatFn, formatISO, sub } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

/*
  Consolidate use of date-fns here, replacing moment
  Limit use elsewhere to services
  All pages and components should use this service

  Moment is EOL: See https://momentjs.com/docs/
*/

export function guessTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function formatInLocalTimezone(d: Date, fmt: string = TIMESTAMP_MILLIS_ISO_FORMAT): string {
  const tz = guessTimezone()
  return formatInTimeZone(d, tz, fmt)
}

export function yyyymmddToLocalDate(isoString: string): Date {
  const [year, month, day] = isoString.split('-')
  return new Date(parseInt(year), parseInt(month) - 1, parseInt(day))
}

// This is really not necessary
export function toDate(val: any): Date {
  return new Date(val)
}

export function todaystr(fmt: string | undefined = undefined): string {
  return formatDate(new Date(), fmt || YYYYMMDD_FORMAT)
}

export function nowstr(fmt: string = TIMESTAMP_MILLIS_ISO_FORMAT): string {
  return formatDate(new Date(), fmt)
}

export function nowstrISO(): string {
  return (new Date()).toISOString()
}

export function timestr(hr: number, min: number): string {
  const d = new Date()
  d.setHours(hr)
  d.setMinutes(min)
  d.setSeconds(0)
  return formatDate(d, "yyyy-MM-dd'T'HH:mm:ss")
}

// Handle the case where an YYYY-MM-DD string must be converted to
// a Date object in the local timezone, and then formatted
export function formatISODate(isoString: Date | string, formatstr: string | undefined = undefined): string {
  return (typeof isoString === 'string')
    ? formatDate(yyyymmddToLocalDate(isoString), formatstr)
    : formatDate(isoString, formatstr)
}

export function formatDate(val: any, fmt: string | undefined = undefined): string {
  const type = typeof val
  if (type === 'string' && /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/.test(val)) {
    console.log('format encountered YYYY-MM-DD string:', val)
    // If this prints to console, the caller should use formatISODate()
  }
  const d = new Date(val)
  if (fmt)
    fmt = fmt.replace('YYYY', 'yyyy').replace('DD', 'dd')
  return fmt
    ? formatFn(d, fmt)
    : formatISO(d)
}

export function addstr(duration: any): string {
  return formatDate(add(duration))
}
export function addstrISO(duration: any): string {
  //TODO - should we use formatISO instead ? If so, need to change
  // all usages of toISOString to formatISO to be consistent
  return add(duration).toISOString()
}
export function add(duration: any): Date {
  return addFn(new Date(), duration)
}

export function addTo(val: any, n: any, unit: string): Date {
  if (!unit.endsWith('s'))
    unit += 's'
  return addFn(new Date(val), { [unit]: n })
}

export function subtractstr(duration: any): string {
  return formatDate(subtract(duration))
}

export function subtract(duration: any): Date {
  return sub(new Date(), duration)
}

export function subtractFrom(val: any, duration: any): Date {
  return sub(new Date(val), duration)
}

export function since(d: string, unit: any) {
  return diff(d, null, unit)
}

export function diff(start: string | undefined, end: string | null, unit: any) {
  const endDate = (!end || end === 'null') ? new Date() : new Date(end)
  const startDate = (!start || start === 'null') ? new Date() : new Date(start)
  if (unit === 'day')
    return differenceInDays(endDate, startDate)
  else if (unit === 'week')
    return differenceInWeeks(endDate, startDate)
  else if (unit === 'month')
    return differenceInMonths(endDate, startDate)
  else if (unit === 'minutes')
    return differenceInMinutes(endDate, startDate)
  else
    return 0
}

export function diffpercent(startdate: string, enddate: string) {
  const total = diff(startdate, enddate, 'day')
  const current = since(startdate, 'day')
  return current / total
}

export function isFuture(d: string): boolean {
  return new Date(d) > new Date()
}

export function isWithinHours(eventDate: string, withinHours: number) {
  const now = new Date(),
    mins = withinHours * 60,  // handle withinHours = 0.5, 0.1, 0.05, etc...
    within = addTo(new Date(eventDate),
      mins > 60 ? withinHours : mins,
      mins > 60 ? 'hours' : 'minutes')
  //console.log("comparing", now, within)            
  return (now < within)
}

