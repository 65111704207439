import { QueryDocumentSnapshot } from "@angular/fire/firestore"

export interface Gift {
    docId: string
    createdAt: Date
    type: 'GIFT_CARD' | 'OTHER'
    sender: string
    recipient: string
    message: string
    amount: number
    metadata: any
    consumedAt?: Date
}

export const GiftConverter = {
    toFirestore(sub: Gift) { return { ...sub } },
    fromFirestore(snapshot: QueryDocumentSnapshot): Gift {
      return this.fromFirestoreData(snapshot.data())
    },
    fromFirestoreData(data: any): Gift {
      data.createdAt = data.createdAt.toDate()
      data.consumedAt = data.consumedAt?.toDate()
      return data as Gift
    }  
  }

  export interface PortalOfferSpec {
    name: string;
    description?: string;
    code?: string;
    expiringIn?: string;
    expirationDate?: string;
    value?: number;
    scope?: { name: string }[];
    disabled?: boolean;
    devOnly?: boolean;
    triggerEvent: string;
    triggerConversation: { name: string };
    routeAfterActivation?: string;
  }
  
  export interface UserPortalOffer {
    docId?: string;
    userId: string;
    name: string;
    code: string;
    createdAt: Date;
    redeemedAt?: Date;
    expiresAt?: Date;
    spec: PortalOfferSpec;
  }
  
  export const UserPortalOfferConverter = {    
    toFirestore(sub: UserPortalOffer) { return { ...sub } },
    fromFirestore(snapshot: QueryDocumentSnapshot): UserPortalOffer {
      return this.fromFirestoreData(snapshot.data())
    },
    fromFirestoreData(data: any): UserPortalOffer {
      data.createdAt = data.createdAt.toDate()
      data.expiresAt = data.expiresAt?.toDate()
      data.redeemedAt = data.redeemedAt?.toDate()      
      return data as UserPortalOffer
    },
    fromArray(data: any[]): UserPortalOffer[] {
      return data.map(d => this.fromFirestoreData(d))
    }
  }
   