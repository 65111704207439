// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { AppSource } from "@cheaseed/node-utils";

export const environment = {
  appSource: AppSource.Web,
  production: false,
  useEmulators: false,
  publicHost: "https://share-dev.cheaseed.com",
  firebase: {
    apiKey: "AIzaSyCVSz4hXNSVo48Kn30aofjHunDn3eKeji4",
    authDomain: "cheaseed-dev-bb89c.firebaseapp.com",
    databaseURL: "https://cheaseed-dev-bb89c.firebaseio.com",
    projectId: "cheaseed-dev-bb89c",
    storageBucket: "cheaseed-dev-bb89c.appspot.com",
    messagingSenderId: "232970175996",
    appId: "1:232970175996:web:c1e032401f24a9812d9e79",
    measurementId: "G-XNV5T125K0"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
