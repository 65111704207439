import { Component, OnInit } from '@angular/core';
import { AudioService } from '@cheaseed/cheaseed-core';
import { SocialService } from '@cheaseed/shared/util';
import { environment } from '../environments/environment';

@Component({
  selector: 'cheaseed-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'web';

  constructor(
    private socialService: SocialService,
    private audio: AudioService
  ) { }

  ngOnInit() {
    // Initialize services
    this.audio.initialize()
    this.socialService.initialize(environment.publicHost)
    // await this.contentService.initialize()
  }

}
