import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { firstValueFrom, take } from 'rxjs'
@Injectable({
    providedIn: 'root'
})
export class SlackService {

    private options = {
        headers: new HttpHeaders(
            { 'Content-Type': 'application/x-www-form-urlencoded' }
        ),
        responseType: 'text' as 'json'
    }

    constructor(
        @Inject('environment') private environment: any,
        private http: HttpClient
    ) { }

    async postErrorOnSlack(userId: string, deviceInfo: string, error: Error, stackFrame: StackTrace.StackFrame[]) {
        const message = {
            channel: this.environment.globalErrors.channelName,
            text: error.message ? error.message : 'Error',
            attachments: [
                {
                    author_name: window.location.href,
                    color: 'danger',
                    title: `Stack Trace for ${userId} Device Info: ${deviceInfo}`,
                    text: stackFrame.toString().replace(/,/g, '\n') //error.stack
                }
            ]
        }
        try { 
            const data = await firstValueFrom(this.http.post(this.environment.globalErrors.slackURL, message, this.options))
            console.log('Posted to slack', data)
        }
        catch(err) {
            console.error('Error posting to slack', err)
        }
    }
}