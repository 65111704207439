/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, NgZone, inject } from '@angular/core'
import { BehaviorSubject, debounceTime, firstValueFrom, timer, map, combineLatestWith, filter, withLatestFrom, shareReplay } from 'rxjs'
import { FirstChatKey, HIDDEN, SERIES_COMPLETION_STATUS_KEY } from '@cheaseed/node-utils';
import { ContentService } from './content.service';
import { EntryService } from './entry.service';
import { Events, SharedEventService } from './shared-event.service';
import { SharedMessageService } from './shared-message.service';
import { SharedUserService } from './shared-user.service';
import { AuthService } from './auth.service';
import { PointsService } from './points.service';
import { Router } from '@angular/router';

export const PLAYLIST_KEY = 'user.playlist'
export const PINNED_KEY = 'user.pinned'
export const RECENTCHATS_KEY = 'user.recentChats'
export const INPROGRESS_KEY = 'user.chatsInProgress'
export const UPNEXT_KEY = 'user.upnext'
export const RECOMMENDEDCHATS_KEY = 'user.recommendedChats'
export const TAKEAWAYS_KEY = 'user.takeaways'
export const TAKEAWAYSCHANGED_KEY = 'user.takeawaysChanged'
@Injectable({
  providedIn: 'root'
})

export class ChatQueueService {

  private userService = inject(SharedUserService)
  private pointsService = inject(PointsService)
  private entryService = inject(EntryService)

  lastStats = ""
  pinned$ = new BehaviorSubject<any[]>([])
  playlist$ = new BehaviorSubject<any[]>([])
  upnext$ = new BehaviorSubject<any[]>([])
  chatsInProgress$ = new BehaviorSubject<any[]>([])
  conversationStatus$ = new BehaviorSubject<Map<string,any>|null>(null)
  recentChats$ = new BehaviorSubject<any[]>([])
  recommendedChats$ = new BehaviorSubject<any[]>([])
  takeaways$ = new BehaviorSubject<any[]>([])
  seriesCompletionStatus$ = new BehaviorSubject({})

  userStats$ = this.userService.user$.pipe(
    filter(user => !!user),
    combineLatestWith(
      this.pointsService.pointsStats$,
      this.entryService.entryCounts$,
      this.upnext$,
      this.playlist$,
      this.chatsInProgress$,
      this.pinned$,
      this.entryService.favorites$
    ),
    map(([user, points, entryCounts, upnext, playlist, inprogress, pinned, favorites ]) => {
      const typeCounts = Object.fromEntries(Object.entries(entryCounts).map(entry => [ "num" + entry[0], entry[1] ]))
      const numEntries = (Object.values(entryCounts) as number[]).reduce((acc:number, val:number) => { return acc + val }, 0)
      const stats = {
        ...typeCounts,
        numEntries,
        numUpnext: upnext.length,
        numPlaylist: playlist.length,
        numStarted: inprogress.length,
        numPinned: pinned.length,
        numFavorites: favorites?.size,
        hasAllowedNotifications: user?.hasAllowedNotifications,
        ...points,
      }
      // console.log('userStats$', stats)
      return stats
    }),
    shareReplay(1)
  )

  constructor(
    private contentService: ContentService,
    private eventService: SharedEventService,
    private messageService: SharedMessageService,
    private auth: AuthService,
    private zone: NgZone,
    private router: Router
  ) { }


  initializeKeyObservables() {
    this.loadPlaylist()
    this.loadRecentChats()
    this.loadUpnext()
    this.loadTakeaways()
    this.loadPinned()
    this.loadRecommendedChats()
    this.subscribeToDailyTimer()
  }

  subscribeToDailyTimer() {
    // Subscribe to daily timer, starting in 10 secs, once per day default thereafter
    // Use "user.upnextTimerFrequency" to override (see Advanced)
    // 3 mins = 180000, 1 hr = 3600000, 1 day = 86400000
    const freq = this.userService.getUserKey("user.upnextTimerFrequency") || 3600000
    timer(10000, freq)
      .pipe(
        this.auth.takeUntilAuth(),
        withLatestFrom(this.userStats$))
      .subscribe(([ timerCnt, stats ]) => {
        console.log(`received timer broadcast every ${freq} secs`, timerCnt)
        // this.checkForNewContent()
        // this.addWeeklyUpNextChats()
        this.trackUserStats(stats)
      })
  }

  private trackUserStats(stats: any) {
    if (stats) {
      const data = { ...stats }
      // Remove complex object from stats
      delete data.points
      delete data.streak
      const newStats = JSON.stringify(data)
      if (!this.lastStats || this.lastStats !== newStats) {
          this.eventService.recordUserStats(data)
          this.lastStats = newStats
          console.log("wrote user stats", stats)
      }
    }
  }

  async writeUserStats() {
    const stats = await firstValueFrom(this.userStats$)
    this.trackUserStats(stats)
  }

  // Handle Playlist Observable
  loadPlaylist() { 
    const arr = this.userService.getUserKey(PLAYLIST_KEY) || [] 
    this.playlist$.next(arr)
  }
  setPlaylist(val: any[]) { return this.userService.setUserKey(PLAYLIST_KEY, val) }
  isInPlaylist(id: any) {
    console.log("isInPlaylist", id, this.playlist$.getValue())
    return this.playlist$.getValue().find(item => item === id)
  }
  togglePlaylist(id: any) {
    if (this.isInPlaylist(id))
      this.removeFromPlaylist(id)
    else
      this.addToPlaylist(id)
  }
  addToPlaylist(id: any) { 
    const arr = this.playlist$.getValue()
    // Only add if not present already or in InProgress
    if (!arr.find(item => item === id)) {
      const result = Object.assign([], arr)
      result.push(id)
      this.playlist$.next(result) 
      this.setPlaylist(result)
    }
  }
  addMultipleToPlaylist(ids: string[]) { 
    const arr = this.playlist$.getValue()
    const newlist = Object.assign([], arr)
    ids = ids.filter(id => id)  // ensure ids are not null
    for (const id of ids) {
      if (!newlist.find(item => item === id)) {
          newlist.push(id)
      }
    }
    this.playlist$.next(newlist) 
    this.setPlaylist(newlist)
  }
  removeFromPlaylist(id: any) { 
    const arr = this.playlist$.getValue()
    const list = arr.filter(item => item !== id)
    if (list.length !== arr.length) {
      this.playlist$.next(list) 
      this.setPlaylist(list)
    }
  }

  // Handle Recents Observable
  loadRecentChats() { 
    const arr = this.userService.getUserKey(RECENTCHATS_KEY) || []
    this.recentChats$.next(arr)
  }
  addRecentChat(id: any) {
    const curr = this.recentChats$.getValue()
    const arr = Object.assign([], curr)
    const index = arr.indexOf(id)
    if (index > 0) {
      // Remove if already present beyond first position
      arr.splice(index, 1)
    }
    if (index !== 0) {
      // console.log("addRecentChat", id)
      arr.unshift(id)
      this.recentChats$.next(arr)
      this.userService.setUserKey(RECENTCHATS_KEY, arr) 
    }
  }

  // Handle Upnext Observable
  setUpnext(val: any[]) {     
    this.userService.setUserKey(UPNEXT_KEY, val)
    this.upnext$.next(val)
  }
  loadUpnext() { 
    const arr = this.userService.getUserKey(UPNEXT_KEY) || []
    this.upnext$.next(arr)
  }
  addUpnext(item:string) {
    const arr = this.upnext$.getValue()
    // Only add if not present already
    if (item && !arr.find(x => x === item)) {
      const newarr = Object.assign([], arr)
      newarr.unshift(item)
      const maxarr = newarr.slice(0, 20)  // Upnext is max length 20
      // console.log("set upnext from addUpnext", maxarr)
      this.setUpnext(maxarr)
    }
  }
  addUpnextList(adds: any[]) {
    // console.log("addUpnextList", adds)
    adds = adds.filter(c => c)
    const arr = this.upnext$.getValue()
    const newarr = Object.assign([], arr)
    for (const item of adds) {
      if (!arr.find(x => x === item)) {
        newarr.unshift(item)
        const conv = this.contentService.getConversationNamed(item)
        if (conv)
          this.messageService.generateChatMessage(this.userService.getCurrentUserId() as string, conv)
      }
    }
    if (arr.length !== newarr.length) {
      const maxarr = newarr.slice(0, 20)  // Upnext is max length 20
      // console.log("set upnext from addUpnext", maxarr)
      this.setUpnext(maxarr)
    }
  }

  removeUpnext(item: any) {
    const arr = this.upnext$.getValue()
    const newlist = arr.filter(x => x !== item)
    if (newlist.length !== arr.length) {
      // console.log("set upnext from removeUpnext", newlist)
      this.setUpnext(newlist)
    }
    return newlist
  }

  // Handle RecommendedChats Observable
  //
  // This key contains an array of { targetId, sourceId } or just targetId (no sourceId)
  //
  loadRecommendedChats() {
    const arr = this.userService.getUserKey(RECOMMENDEDCHATS_KEY) || []
    this.recommendedChats$.next(arr)
  }
  addRecommendedChat(targetId:string, sourceId:string) {
    const list = Object.assign([], this.recommendedChats$.getValue())
    // Handle source/target pairs or just targetId
    const hit = list.find((item:any) => item === targetId || item.targetId === targetId)
    if (!hit) {
      const item = { targetId, sourceId }
      console.log("addRecommendedChat", item)
      list.unshift(item)
      this.userService.setUserKey(RECOMMENDEDCHATS_KEY, list)
      this.recommendedChats$.next(list)
      this.pointsService.eventCreated$.next({ 
        eventType: Events.QueuedChat,
        targetId,
        sourceId })
    }
    return list
  }
  removeRecommendedChat(id: string) {
    const list = this.recommendedChats$.getValue()
    // Handle source/target pairs or just targetId
    const newlist = list.filter(item => !(item.targetId === id || item === id))
    if (newlist.length !== list.length) {
      this.userService.setUserKey(RECOMMENDEDCHATS_KEY, newlist)
      this.recommendedChats$.next(newlist) 
    }
    return newlist
  }

  // Handle Pinned Observable
  loadPinned() { 
    const arr = this.userService.getUserKey(PINNED_KEY) || [] 
    this.pinned$.next(arr)
  }
  isPinned(id: any) {
    // console.log("isPinned", id, this.pinned$.getValue())
    return this.pinned$.getValue().find(item => item.id === id)
  }
  togglePinConversation(chat:any) {
    if (this.isPinned(chat.name))
      this.unpinConversation(chat)
    else
      this.pinConversation(chat)
  }
  pinItem(type: string, id: string) {
    const list:any[] = Object.assign([], this.pinned$.getValue())
    // console.log("pinItem", type, id, list)
    if (!list.find(item => item.type === type && item.id === id)) {
      list.unshift({ type, id })
      // console.log("pinning", type, id, list)
      this.userService.setUserKey(PINNED_KEY, list)
      this.eventService.recordConversationPin(id) 
      this.pinned$.next(list)
    }
    return list
  }
  unpinItem(p: { id: any; }) {  
    const list:any[] = Object.assign([], this.pinned$.getValue())
    const newlist = list.filter(item => item.id !== p.id)
    // console.log("unpinItem", p)
    if (newlist.length !== list.length) {
      this.userService.setUserKey(PINNED_KEY, newlist)
      this.eventService.recordConversationUnpin(p.id) 
      this.pinned$.next(newlist) 
    }
    return newlist
  }
  // pinStatement(stmt: { name: string; }) { return this.pinItem( "Statement", stmt.name ) }
  pinConversation(conv: any) { return this.pinItem( "Conversation", conv.name ) }
  unpinConversation(conv: any) { return this.unpinItem( { id: conv.name  } ) }

  // Handle Takeaways Observable
  loadTakeaways() {
    const arr = this.userService.getUserKey(TAKEAWAYS_KEY) || []
    this.takeaways$.next(arr)
  }
  addTakeaway(id: any) {
    // Make sure we get a copy for splicing
    const arr = Object.assign([], this.takeaways$.getValue())
    // Only add if not present already
    const index = arr.indexOf(id)
    if (index > -1) {
      // Remove if already present
      arr.splice(index, 1)
    }
    arr.unshift(id)
    this.takeaways$.next(arr)
    this.userService.setUserKey(TAKEAWAYS_KEY, arr)
    this.userService.setUserKey(TAKEAWAYSCHANGED_KEY, true)
  }

  // Handle UpNext population
  async initializeUpNextChats() {
    const firstChats = this.contentService.masterSequence.slice(0, 1).map(c => c.conversation?.name)
    if (firstChats.length > 0) {
      await this.addInitialUpNextChats(firstChats)
    }  
  }

  async addInitialUpNextChats(chats: ArrayLike<unknown> | Iterable<unknown>) {
    console.log("set upnext from addInitialUpNextChats", chats)
    this.addUpnextList(Array.from(chats).reverse())
  }
   
  addSeriesToPlaylist(id: string) {
    const items = this.contentService.pathConversationMap.get(id)
    this.addMultipleToPlaylist(items.map((item: { id: any; }) => item.id))
  }

  getConversationStatus(id:string){
    const map = this.conversationStatus$.getValue()
    return map?.get(id)
  }   

  isCompleted(name: string) {
    const map = this.conversationStatus$.getValue()
    return !!map?.get(name)
  }

  async loadSeriesCompletionStatus(forceUpdate = false) {
    let statuses = this.userService.getUserKey(SERIES_COMPLETION_STATUS_KEY)
    if (!statuses || forceUpdate) {
      statuses = {}
      for (const series of this.contentService.pathMap.values()) {
        const status = this.computeSeriesCompletionStatus(series.name)
        statuses[series.name] = status
      }
      // console.log("recomputed seriesCompletionStatus", statuses)
      this.userService.setUserKey(SERIES_COMPLETION_STATUS_KEY, statuses)
    }
    this.seriesCompletionStatus$.next(statuses)
  }

  computeSeriesCompletionStatus(seriesName:string) {
    const chats = this.contentService.pathConversationMap.get(seriesName)
      .filter((c: { behaviors: string | string[]; persona: { name: string; }; }) => !c.behaviors?.includes(HIDDEN) && (!c.persona || this.userService.userHasPersona(c.persona.name))) || []
    const statuses = this.conversationStatus$.getValue()
    const completed = chats.filter((c: { name: string; }) => statuses?.get(c.name))
    const result = chats.length ? (completed.length / chats.length).toFixed(3) : 0
    const status = { total: chats.length, completed: completed.length, result: result }
    return status
  }

  loadUserEvents() {
    this.userService.getUserEventsEnded()
      .pipe(
        debounceTime(200),
        this.auth.takeUntilAuth())
      .subscribe(events => {
        const statusMap = new Map<string, any>()
        for (const ev of events) {
          const chat:any = this.contentService.getConversationNamed(ev.eventConversationId)
          // console.log("loadUserEvents found", chat?.id)
          if (chat) {
            chat.completed = true
            statusMap.set(ev.eventConversationId, ev.createdAt)
          }
        }
        console.log("loadUserEvents size", statusMap.size)
        this.conversationStatus$.next(statusMap)
        // this.loadSeriesCompletionStatus(true)  // force update
      })
  }

  getHydratedPlaylist() {
    return this.playlist$
      .pipe(
        // tap(data => console.log("getHydratedPlaylist", data)),
        map(data => data.map(item => this.contentService.getConversationNamed(item)).filter(c => c)),
        map(data => new Map(data.map(c => [c.name, c])))
      )
  }

  getHydratedPinned() {
    return this.pinned$
      .pipe(
        map(data => data.map(item => {
          const conv = this.contentService.getConversationNamed(item.id)
          return conv ? { 
              id: item.id,
              type: item.type, 
              title: conv.title,
              subtitle: conv.subtitle
            } : null 
          })
          .filter(c => c)),
        map(data => new Map(data.map((c:any) => [c.name, c])))
      )
  }

  // Always force completion of first chat if incomplete
  isFirstChatCompleted(): boolean {
    const firstChat = this.contentService.getGlobal(FirstChatKey)
    const completed = this.isCompleted(firstChat)
    // console.log("checking firstChat completed", firstChat, completed, markedCompleted)
    return firstChat && completed
  }

  launchFirstChat() {
    const firstChat = this.contentService.getGlobal(FirstChatKey)
    // console.log("Launching first chat", firstChat)
    this.zone.run(() => this.router.navigate(['conversation', firstChat]))
  }
  
}
