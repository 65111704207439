import { Inject, Injectable } from '@angular/core';
import { PURCHASE_TYPE, Purchases } from '@awesome-cordova-plugins/purchases/ngx'
import { Platform } from '@ionic/angular'

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  initialized: boolean;
  userId: string | undefined;
  useRevenueCat: boolean;

  constructor(
    @Inject('environment') private environment: any,
    private platform: Platform,
    private purchases: Purchases,
  ) {
    this.initialized = false
    this.useRevenueCat = false
  }
  async initialize(userId: string) {
    if (!this.platform.is('capacitor')) {
      console.warn('Purchases are supported only on mobile apps')
      return
    }
    try {
      console.log(`Initializing RevenueCat for user ${userId}`)
      this.purchases.setDebugLogsEnabled(true)
      if (this.platform.is('android')) {
        this.purchases.configureWith({ apiKey: this.environment.androidRevenueCatKey, appUserID: userId })
        //console.log('RevenueCat initialized for android')
      }
      else if (this.platform.is('ios')) {
        this.purchases.configureWith({ apiKey: this.environment.iosRevenueCatKey, appUserID: userId })
      }
      await this.purchases.logIn(userId)
      this.purchases.setEmail(userId)
      this.initialized = true
      //const info = await this.purchases.getCustomerInfo()
      //console.log('TRANSACTIONS', JSON.stringify(info.nonSubscriptionTransactions, null, 2))

    }
    catch (e) {
      console.error(`RevenueCat init error for user ${userId}`, e)
    }

  }

  async getOfferings() {
    const offers = await this.purchases.getOfferings()
    return offers.current
  }

  async getProducts(products: string[]) {
    return await this.purchases.getProducts(products, PURCHASE_TYPE.INAPP)
  }

  async logOut() {
    try {
      if (this.initialized && !this.purchases.isAnonymous())
        await this.purchases.logOut()
    }
    catch (e) {
      console.warn('Error logging out of RevenueCat', e)
    }
  }

  async hasValidSubscription() {
    if (!this.initialized)
      return false
    const info = await this.purchases.getCustomerInfo()
    return info.entitlements.active['default'].isActive
  }

  async getCustomerInfo() {
    return await this.purchases.getCustomerInfo()
  }

  getPurchasedProducts(userId: string) {
    if (!this.initialized) {
      this.initialize(userId)
    }
    // TODO
  }

  async getPurchasedSubscriptions(userId: string) {

    if (!this.initialized) {
      this.initialize(userId)
    }
    //TODO
  }

  async purchaseProduct(productName: string) {
    if (!this.initialized) {
      console.warn('Purchase not supported on web')
      return null
    }

    const info = await this.purchases.purchaseProduct(productName, null, PURCHASE_TYPE.INAPP)
    //console.log('RevenueCat Purchase Product returned', JSON.stringify(transaction))
    //const info = await this.purchases.getCustomerInfo()
    console.log('Customer Info', JSON.stringify(info.customerInfo, null, 2))
    return info.customerInfo

  }

  async purchaseSubscription(subscriptionName: string) {
    if (!this.initialized) {
      console.warn('Purchase not supported on web')
      return false
    }
    try {
      const transaction = await this.purchases.purchaseProduct(subscriptionName, null, PURCHASE_TYPE.SUBS)
      console.log('RevenueCat Purchase Product returned', JSON.stringify(transaction))
    }
    catch (e) {
      console.error('Error in purchase', e)
      return false
    }
    return true
  }

  async syncToRevenueCat() {
    // nothing to sync till the service is initialized when
    // the user logs in
    if (!this.initialized)
      return
    const c = await this.purchases.restorePurchases()
    console.log('RESTORE PURCHASES', JSON.stringify(c))
    const info = await this.purchases.getCustomerInfo()
    console.log('ACTIVE SUBS', JSON.stringify(info.activeSubscriptions))
    console.log('ENTITLEMENTS', JSON.stringify(info.entitlements))
    console.log('ACTIVE IN ANY', JSON.stringify(info.entitlements.activeInAnyEnvironment))
    console.log('Default active expiration date', JSON.stringify(info.entitlements.active['paid']?.expirationDate))
  }

}
