/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, from, takeUntil, filter, MonoTypeOperatorFunction, map, tap, shareReplay, withLatestFrom, combineLatest, firstValueFrom } from 'rxjs';
import {
  Auth,
  signInWithPopup,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithCredential,
  signInWithEmailAndPassword,
  signInWithRedirect,
  signInAnonymously,
  signOut,
  TwitterAuthProvider,
  UserCredential,
  authState,
  onAuthStateChanged,
  User,
  linkWithPopup, 
  sendSignInLinkToEmail,
  ActionCodeSettings,
  isSignInWithEmailLink,
  signInWithEmailLink,
  RecaptchaVerifier,
  signInWithPhoneNumber} from '@angular/fire/auth';

import { cfaSignIn, cfaSignOut } from 'capacitor-firebase-auth';
import {
  SignInWithApple,
  SignInWithAppleResponse,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { ANONYMOUS_USER_ID } from '@cheaseed/node-utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private afAuth = inject(Auth)

  public loggedIn$ = new BehaviorSubject<boolean|undefined>(undefined)
  private lastUser$ = new BehaviorSubject<User|undefined>(undefined)

  user$ = authState(this.afAuth)
    .pipe(
      withLatestFrom(this.lastUser$),
      // debounceTime(300),
      map(([ currUser, lastUser ]) => {
        console.log(`auth.user$ currUser ${currUser?.email || currUser?.uid}, lastuser ${lastUser?.email || lastUser?.uid}`)
        if (currUser && currUser.uid !== lastUser?.uid)
          this.lastUser$.next(currUser)
        if (currUser) 
          return { 
            ...currUser,
            email: currUser.email || currUser.uid,
            isAnonymousUser: currUser.email ? undefined : true
          }
        else {
          console.log('auth signing in anonymously')
          this.signInAnonymously()
          return null
        }
      }),
      shareReplay(1)
    )

  signInKey$ = new BehaviorSubject('portal.signin.message')
  
  // ephemeral property used during apple signin
  displayName: string | null = null;
  
  constructor(
    public platform: Platform
  )
    {
      onAuthStateChanged(this.afAuth, (user) => {
        // console.log("onAuthStateChanged", user)
        this.loggedIn$.next(!!user && !user.isAnonymous)
        // Save most recent anonymous user id for conversion
        if (user?.isAnonymous) {
          // this will disappear with the session
          sessionStorage.setItem(ANONYMOUS_USER_ID, user.uid) 
        }
      })        
  }
  
  getDisplayName() {
    return this.displayName
  }

  clearDisplayName() {
    this.displayName = null
  }

  takeUntilAuth<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil(
      combineLatest([ authState(this.afAuth), this.lastUser$ ])
        .pipe(
          // tap(data => console.log('takeUntilAuth', data)),
          filter(([ u, last ]) => !u || (u?.uid !== last?.uid)), // stop the subscription if no user or user changed
          // tap(data => console.log('takeUntilAuth filtered', data))
          ))
  }

  signInWithEmail(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(this.afAuth, email, password)
  }

  signUpWithEmail(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(this.afAuth, email, password)
  }

  signInAnonymously(): Promise<UserCredential> {
    return signInAnonymously(this.afAuth)
  }
 
  logout(): Promise<void> {
    this.loggedIn$.next(false)
    if (this.platform.is('capacitor')) {
      return firstValueFrom(<any>cfaSignOut())
    } else {
      return signOut(this.afAuth)
    }
  }

  isLoggedIn() {
    return this.loggedIn$.getValue()
  }

  getPhotoURL(signInProviderId: string, photoURL: string): string {
    // Default imgs are too small and our app needs a bigger image
    switch (signInProviderId) {
      case 'facebook.com':
        return photoURL + '?height=400';
      case 'password':
        return 'https://s3-us-west-2.amazonaws.com/ionicthemes/otros/avatar-placeholder.png';
      case 'twitter.com':
        return photoURL.replace('_normal', '_400x400');
      case 'google.com':
        return photoURL.split('=')[0];
      default:
        return photoURL;
    }
  }

  getAppleSignInRedirectURI(environment: any) {
    return 'https://' + environment.firebase.authDomain + '/__/auth/handler'
  }

  socialSignIn(providerName: string, environment: any, scopes?: Array<string>): Observable<any> {
    if (this.platform.is('capacitor')) {
      // For Apple Sign In (https://ionicacademy.com/sing-in-with-apple-firebase/)
      if (this.platform.is('ios') && providerName === 'apple.com') {
        const options: SignInWithAppleOptions = {
          clientId: environment.appleClientId,
          redirectURI: this.getAppleSignInRedirectURI(environment), //'https://www.yourfrontend.com/login',
          scopes: 'email name',
          state: '12345'
          //nonce: 'nonce'
        }
        return from(
          SignInWithApple.authorize(options)
            .then(async (res: SignInWithAppleResponse) => {
              console.log('AppleSignIn response', JSON.stringify(res, null, 2))
              if (res.response?.identityToken) {
                const appleResponse = res.response
                // If this is a first time auth, apple will return the givenName and familyName
                if(appleResponse.givenName && appleResponse.familyName) {
                  this.displayName = `${appleResponse.givenName || ''} ${appleResponse.familyName || ''}`.trim()
                }
                
                // Create a custom OAuth provider
                const provider = new OAuthProvider('apple.com')
                // Create sign in credentials with our token
                const credential = provider.credential({
                  idToken: appleResponse.identityToken
                })

                // TODO: Unclear what happens if first app login occurs via SIWA
                // In this case, firstname/lastname is passed back and we might need to create the
                // firebase user with the right displayName. Firstname/lastname is not passed back
                // on subsequent signins.

                // Call firebase to sign in with our created credentials
                return signInWithCredential(this.afAuth, credential);
              } 
              else {
                console.error('Unable to login with Apple Sign In', res)
                return null
              }
            })
            .catch((err) =>
              console.error('Unable to login with Apple Sign In', err)
            )
        );
      } else {
        // For Google and others
        return <any>cfaSignIn(providerName)
      }
    }
    else {
      const provider = new OAuthProvider(providerName);

      if (scopes) {
        scopes.forEach((scope) => {
          provider.addScope(scope);
        });
      }

      if (this.platform.is('pwa')) {
        // See above for proper PWA redirect handling (not implemented)
        return from(signInWithRedirect(this.afAuth, provider))
      } else {
        //console.log("will signInWithPopup", provider)
        //console.log('AUTH = ' + JSON.stringify(this.afAuth))
        return from(signInWithPopup(this.afAuth, provider))
      }
    }
  }

  signInWithFacebook(environment: any): Observable<any> {
    const provider = new FacebookAuthProvider();
    return this.socialSignIn(provider.providerId, environment);
  }

  signInWithGoogle(environment: any): Observable<any> {
    const provider = new GoogleAuthProvider();
    const scopes = ['profile', 'email'];
    const result = this.socialSignIn(provider.providerId, environment, scopes);
    return result
  }

  async convertFromAnonymous() {
    // When the user signs up, complete the sign-in flow for the user's authentication provider up to, but not including, calling one of the Auth.signInWith methods.
    // For example, get the user's Google ID token, Facebook access token, or email address and password.
    // Get an AuthCredential for the new authentication provider
    const auth = this.afAuth
    const currentUser = auth.currentUser as User
    //const idToken = await user.getIdToken()
    //const credential = GoogleAuthProvider.credential(idToken)
    //console.log({ user, idToken, credential})
    const provider = new GoogleAuthProvider()
    // Need to popup the google signin window here and then link anonymous credential to signed in user
    try {
      //const usercred = await linkWithCredential(auth.currentUser as User, credential)
      //TODO - if the selected user already has a login, linkWithPopup will throw an error
      // need to handle that case 
      const usercred = await linkWithPopup(currentUser, provider)
      //const usercred = await getRedirectResult(auth)
      let user: User
      if(usercred) {
        user = usercred.user
        console.log("Anonymous account successfully upgraded to", user)
        //this.
        //this.userService.user$.next(user)
      }
      else {
        console.log('usercred was null')
      }
    }
    catch(err) {
      console.log("Error upgrading anonymous account", err)
    }
  }

  signInWithTwitter(environment: any): Observable<any> {
    const provider = new TwitterAuthProvider();
    return this.socialSignIn(provider.providerId, environment);
  }

  signInWithApple(environment: any): Observable<any> {
    const provider = new OAuthProvider('apple.com');
    return this.socialSignIn(provider.providerId, environment);
  }

  async signInWithEmailLink(email: string, environment: any) {
    const actionCodeSettings: ActionCodeSettings = {
      url: `${environment.portalHost}/home`, // TODO send source as anonymous ID to transfer keys? vs link to anonymous account
      handleCodeInApp: true
    };
    console.log("signInWithEmailLink", email, actionCodeSettings)
    await sendSignInLinkToEmail(this.afAuth, email, actionCodeSettings)
    // Set emailForSignIn in localStorage for later use in another session
    localStorage.setItem('emailForSignIn', email)
  }

  isSignInWithEmailLink(url: string) {
    return isSignInWithEmailLink(this.afAuth, url)
  }

  async processEmailLinkSignIn(url: string) {
    console.log("processEmailLinkSignIn", url)
    // Confirm the link is a sign-in with email link.
    if (this.isSignInWithEmailLink(url)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn')
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation')
      }
      try {
        const result = await signInWithEmailLink(this.afAuth, email as string, url)
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
        console.log("processEmailLinkSignIn", result)
      }
      catch(e) {
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
      }
    }
  }

  async sendVerificationCode(phoneNumber: string, buttonId: string) {
    // specify the ID of the button that submits your sign-in form
    const verifier = new RecaptchaVerifier(this.afAuth, buttonId, {
      size: 'invisible',
      callback: (response: any) => {
        console.log("recaptchaVerifier callback", response)
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // onSignInSubmit();
      }
    })
    const confirmationResult = await signInWithPhoneNumber(this.afAuth, phoneNumber, verifier)
    console.log("signInWithPhone confirmationResult", confirmationResult)
    return confirmationResult
  }

  async signInWithPhone(code: string, confirmationResult: any) {
    const result = await confirmationResult.confirm(code)
    console.log("signInWithPhone result", result)

    // Get the AuthCredential object for the account
    // const credential = PhoneAuthProvider.credential(confirmationResult.verificationId, code)
    // signInWithCredential(this.afAuth, credential)
  }

  async register(email: string, password: string) {
    const response = await createUserWithEmailAndPassword(
      this.afAuth,
      email,
      password
    )
    return response
  }

  async login(email: string, password: string) {
    return await signInWithEmailAndPassword(this.afAuth, email, password)
  }

}
