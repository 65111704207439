import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isoDateStr',
  standalone: true
})
export class IsoDateStrPipe implements PipeTransform {
  transform(value: string | undefined, ...args: unknown[]): Date {
    return new Date(value as string)
  }
}
