import { Injectable } from '@angular/core';
import { AskLevelFactors, NetworkLevelFactors, PitchLevelFactors, StatsKeys, YYYYMMDD_FORMAT, diff, formatDate, since, subtractstr, StatsProvider } from '@cheaseed/node-utils';
import { Observable, combineLatestWith, debounceTime, map } from 'rxjs';
import { ContentService } from './content.service';
import { EntryService } from './entry.service';
import { SharedUserService } from './shared-user.service';

@Injectable({
  providedIn: 'root'
})
export class StatsManagerService implements StatsProvider {

  constructor(
    private entryService: EntryService,
    private userService: SharedUserService,
    private contentService: ContentService,
  ) { }

  getKey(key:string) {
    return this.userService.getUserKey(key)
  }

  getAttributeSpec(attr:string) {
    return this.contentService.getAttributeSpec(attr)
  }

  getGlobal(key:string) {
    return this.contentService.getGlobal(key)
  }

  computeSatisfactionMetrics(): Observable<any> {
    return this.entryService.recentHistory$
      .pipe(
        combineLatestWith(this.userService.watchUserKey(null, StatsKeys.SatisfactionStartDate)),
        map(([data, userkey]) => {
          const sinceDate = userkey?.value
          // console.log("computeSatisfactionMetrics", data, userkey, sinceDate)
          const sats = data.filter(entry => entry.subtype === 'TrackSatisfactionAction' && entry.attributes.actionDate > (sinceDate || "0"))
          const cnts:Record<string, number> = {}
          for (const action of sats) {
            const ans = action.attributes.satisfaction
            cnts[ans] = (cnts[ans] || 0) + 1
          }
          const attr = this.getAttributeSpec("TrackSatisfactionAction.satisfaction")
          const result = []
          const colorScheme = [ '#c1ddc9', '#6daf81', '#598065' ]
          for (let i=0; i < attr.scaleRange.length; i++) {
            const val = cnts[attr.scaleRange[i]] || 0
            result.push({ 
                name: `${attr.scaleLabels[i]}`, 
                value: val, 
                sinceDate: sinceDate,
                color: colorScheme[ i % 3 ]
              })
          }      
          return result
        })
      )
  }

  computeMarketSavvyMetrics(): Observable<any> {
    const sinceDate = this.getKey(StatsKeys.MarketSavvyStartDate)
    // console.log({sinceDate})
    return this.entryService.recentHistory$
      .pipe(
        map(data => data || []),
        map((data:any[]) => data.filter(entry => entry.type === 'MarketInfo' && 
          entry.attributes.dateCollected > (sinceDate || "0"))),
        // tap(data => console.log("computeMarketSavvyMetrics", data)),
        map((data:any[]) => { return {
          value: data.length,
          ready: !!sinceDate
        }})
      )
  }

  computePitchPreppedMetrics() {
    let sinceDate: string
    const startDate = this.getKey(StatsKeys.PitchFreshnessPeriod)
    const attrSpec = this.getAttributeSpec("Pitch.type")
    return this.entryService.recentHistory$
      .pipe(
        map(data => data || []),
        map((data:any[]) => data.filter(entry => entry.type === 'Pitch' || entry.subtype === 'PracticePitchAction')),
        map((entries:any[]) => {          
          const result = []
          if (startDate) {
            switch (startDate) {
              case 'pitchPeriod3Mos':
                sinceDate = subtractstr( { months: 3 } )
                break
              case 'pitchPeriod2Wks':
                sinceDate = subtractstr( { weeks: 2 } )
                break
              default:
                sinceDate = subtractstr( { months: 1 } )
                break
            }
            for (const opt of attrSpec.optionLinks) {
              const cell = {
                type: opt.name,
                description: opt.description,
                fresh: false,
                id: null
              }
              const matches = entries.filter(entry => entry.type === 'Pitch' && entry.attributes.type === opt.name)
              matches.sort((a,b) => a.createdAt < b.createdAt ? 1 : -1)
              const latestPitch = matches.shift()
              if (latestPitch) {
                const practices = entries.filter(entry => entry.subtype === 'PracticePitchAction' && 
                                          entry.parentEntryId === latestPitch.id &&
                                          formatDate(entry.attributes.actionDate) >= sinceDate)
                // If a practice or pitch.createDate is within freshness period
                cell.fresh = (practices.length > 0) ? true : (formatDate(latestPitch.createdAt) >= sinceDate)
                cell.id = latestPitch.id
              }
              result.push(cell)
            }
          }
          return result
        }))
  }

  setTargetAccomplishments() {
    const startDate = this.getKey(StatsKeys.ReviewStartDateUserKey),
        endDate = this.getKey(StatsKeys.ReviewEndDateUserKey),
        accompsPerWeek = this.getKey(StatsKeys.ReviewAccompsPerWeek) || 1.0,
        time_elapsed = since(startDate, 'week'),
        total_time = Math.max(diff(startDate, endDate, 'week'), 0),
        total_events = Math.round(total_time * accompsPerWeek) // no conversion
    this.userService.setUserKey(StatsKeys.ReviewTargetNumAccomplishments, total_events)
    return { startDate, endDate, time_elapsed, total_time, total_events }
  }

  setTargetNetworkLeads() {
    const startDate = this.getKey(StatsKeys.NetworkStartDate) as string,
        endDate = this.getKey(StatsKeys.NetworkEndDate) as string,
        target = this.getKey(StatsKeys.NetworkTargetLevel),
        time_elapsed = since(startDate, 'week'),
        total_time = Math.max(diff(startDate, endDate, 'week'), 0),
        total_events = Math.round(total_time * (NetworkLevelFactors[target] || NetworkLevelFactors["level.low"]))
    this.userService.setUserKey(StatsKeys.NetworkTargetNumLeadsKey, total_events)
    return { startDate, endDate, time_elapsed, total_time, total_events }
  }

  setTargetPitches() {
    const startDate = this.getKey(StatsKeys.PitchStartDate),
        endDate = this.getKey(StatsKeys.PitchEndDate),
        target = this.getKey(StatsKeys.PitchTargetLevel),
        time_elapsed = since(startDate, 'week'),
        total_time = Math.max(diff(startDate, endDate, 'week'), 0),
        total_events = Math.round(total_time * (PitchLevelFactors[target] || PitchLevelFactors["level.low"]))
    this.userService.setUserKey(StatsKeys.PitchTargetNumPitchesKey, total_events)
    return { startDate, endDate, time_elapsed, total_time, total_events }
  }

  setTargetAsks() {
    const startDate = this.getKey(StatsKeys.AskStartDate),
        endDate = this.getKey(StatsKeys.AskEndDate),
        target = this.getKey(StatsKeys.AskTargetLevel),
        time_elapsed = since(startDate, 'month'),
        total_time = Math.max(diff(startDate, endDate, 'month'), 0),
        total_events = Math.max(Math.round(total_time * (AskLevelFactors[target] || AskLevelFactors["level.low"])), 1)
    this.userService.setUserKey(StatsKeys.AskTargetNumAsksKey, total_events)
    return { startDate, endDate, time_elapsed, total_time, total_events }
  }

  computeStatsMetrics(
    targets: any, 
    entryType: string,
    dateAttribute: string,
    statusKey: string): Observable<any> 
  {
    const { startDate, endDate, time_elapsed, total_time, total_events } = targets
    // console.log("computeStatsMetrics target", entryType, targets)
    const rev:any = {}
    rev.sinceDate = formatDate(startDate, YYYYMMDD_FORMAT)
    rev.reviewDate = formatDate(endDate, YYYYMMDD_FORMAT)
    rev.total_weeks = total_time
    rev.weeks_progress = Math.min(time_elapsed, total_time)
    rev.weeksProgressPercent = (rev.weeks_progress / total_time) * 100
    rev.total_events = total_events
    rev.ready = false
    return this.entryService.recentHistory$
      .pipe(
        debounceTime(500), // can we decrease?
        map(data => data || []),
        map((data:any[]) => data.filter(entry => entryType === (entry.subtype || entry.type) && entry.attributes[dateAttribute] >= (rev.sinceDate || "9999"))),
        map((events:any[]) => {
          rev.event_progress = Math.min(events.length, rev.total_events)
          rev.eventProgressPercent = (rev.event_progress / rev.total_events) * 100
          const diff = (rev.eventProgressPercent - rev.weeksProgressPercent)/rev.weeksProgressPercent
          rev.status = diff > 0.20 ? 3 : diff < -0.20 ? 1 : 2
          if (rev.eventProgressPercent == 100 || rev.weeksProgressPercent == 100)
            rev.status = 0
          // If # events is zero and still in the first week, use "Get Started Now"
          if (events.length === 0 && rev.weeks_progress === 0)
            rev.status = 4
          const statusMessages = {
            0: "reviewStatsReset.message",
            1: "reviewStatsBehind.message",
            2: "reviewStatsOnPace.message",
            3: "reviewStatsAhead.message",
            4: "reviewStatsGetStarted.message" 
          }
          rev.message = statusMessages[rev.status]
          rev.subtitle = this.contentService.getGlobal(rev.message)
          rev.ready = rev.sinceDate && rev.total_events ? true : false
          this.userService.setUserKey(statusKey, rev.status)
          rev.statusSegmentIndex = rev.status === 4 ? 0 : rev.status
          // console.log("computeStatsMetrics", rev)
          // console.trace() 
          return rev
        }))
  }

  computeNetworkMetrics(): Observable<any> {
    return this.computeStatsMetrics(
      this.setTargetNetworkLeads(),
      'NetworkAction',
      'actionDate',
      StatsKeys.NetworkStatusKey
    )
  }

  computePitchStatsMetrics(): Observable<any> {
    return this.computeStatsMetrics(
      this.setTargetPitches(),
      'DeliverPitchAction',
      'actionDate',
      StatsKeys.PitchStatusKey
    )
  }

  computeAskStatsMetrics(): Observable<any> {
    return this.computeStatsMetrics(
      this.setTargetAsks(),
      'DeliverAskAction',
      'actionDate',
      StatsKeys.AskStatusKey
    )
  }

  computeReviewMetrics(): Observable<any> {
    return this.computeStatsMetrics(
      this.setTargetAccomplishments(),
      'Accomplishment',
      'dateOccurred',
      StatsKeys.ReviewReadyStatusKey
    )
  }
}
