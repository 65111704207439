import { Injectable, inject } from '@angular/core'
import { nowstr } from '@cheaseed/node-utils'
import { FirebaseService } from './firebase.service'
import { where } from '@angular/fire/firestore'
import { BehaviorSubject, catchError, debounceTime, distinctUntilChanged, filter, firstValueFrom, map, of, shareReplay, switchMap, tap, timeout } from 'rxjs'
import { ContentService } from './content.service'
import { SharedUserService } from './shared-user.service'

const DEFAULT_CURRENT_INDEX = 0
export const enum ChatStateStatus {
    INPROGRESS = 'INPROGRESS',
    COMPLETE = 'COMPLETE'
}

// Create custom Error class
export class ChatStateError extends Error { }

@Injectable({
    providedIn: 'root'
})
export class ChatStateService {

    private userService = inject(SharedUserService)
    private firebase = inject(FirebaseService)
    private contentService = inject(ContentService)

    userId: string | undefined
    chatMap$ = new BehaviorSubject<Map<string, ChatState>|null>(null)
    completionMap$ = new BehaviorSubject<Map<string, ChatState>|null>(null)
    lastChatId = ''
    
    chatsInProgress$ = this.userService.user$
        .pipe(
            filter(user => !!user),
            switchMap(user => this.firebase.collection$(
                getUserChatsCollectionPath(user?.docId as string),
                where('status', '==', ChatStateStatus.INPROGRESS))),
            distinctUntilChanged((prev:any[], curr:any[]) => this.isStateSame(prev, curr)),
            map(docs => {
                const m = new Map<string, ChatState>()
                docs.forEach((data:any) => {
                    m.set(data.chatName, chatStateConverter.fromFirestore(data))
                })
                this.chatMap$.next(m)
                return m
            }),
            shareReplay(1))

    chatsCompleted$ = this.userService.userLoggedIn$
        .pipe(
            switchMap(user => this.firebase.collection$(
                getUserChatsCollectionPath(user?.docId as string),
                where('status', '==', ChatStateStatus.COMPLETE))),
            debounceTime(1500),
            map(docs => {
                const m = new Map<string, ChatState>()
                docs.forEach((data:any) => {
                    m.set(data.chatName, chatStateConverter.fromFirestore(data))
                })
                return m
            }),
            shareReplay(1))

    constructor() {
        // TODO: Convert chatsInProgress to subscribe here as well
        this.chatsCompleted$
            .pipe(filter(data => !!data))
            .subscribe(data => {
                // console.log('setting chatsCompleted$', data)
                this.completionMap$.next(data)
            })
    }
    
    async getChatId(chatName: string) {
        // console.log("getChatId", chatName, this.chatMap)
        const chatMap = await firstValueFrom(this.chatsInProgress$)
        let chatState = chatMap.get(chatName)
        if (!chatState) {
            chatState = await this.createChat(chatName)
        }
        this.lastChatId = chatState.getChatId() as string
        return this.lastChatId
    }

    isStateSame(prev: any[], curr: any[]) {
        // Return false if lengths or currentIndex for each chatState is different
        if (prev.length !== curr.length)
            return false
        else {
            const prevMap = new Map(prev.map(s => [s.chatName, s.currentIndex]))
            const currMap = new Map(curr.map(s => [s.chatName, s.currentIndex]))
            for (let i = 0; i < prev.length; i++) {
                if (prevMap.get(prev[i].chatName) !== currMap.get(prev[i].chatName))
                    return false
            }
            return true
        }
    }

    initialize(userId: string) {
        this.userId = userId
    }

    getHydratedChatsInProgress() {
        return this.chatsInProgress$
          .pipe(
            // tap(data => console.log("getHydratedChatsInProgress", data)),
            map(m => m ? Array.from(m.keys()) : [] ),
            map(keys => keys.map(item => this.contentService.getConversationNamed(item)).filter(c => c)),
            map(data => new Map(data.map(c => [c.name, c])))
        )
    }

    async getChatState(chatName: string) {
        const chatMap = await firstValueFrom(this.chatsInProgress$)
        return chatMap.get(chatName)
    }

    getChatStateById$(userId: string, chatId: string) {
        console.log('getChatStateById$', chatId)
        return this.firebase.doc$(getUserChatsStatePath(userId, chatId))
            .pipe(map(data => data ? chatStateConverter.fromFirestore(data) : null))
    }

    async markChatComplete(chatName: string) {
        const chatState = await this.getChatState(chatName)
        if (!chatState) {
            // Fallback logic if chat is already completed
            if (this.isChatCompleted(chatName))
                return
            else 
                throw new ChatStateError(`Attempt to mark a non-existent chat as completed: ${chatName}`)
        }
        chatState.markComplete()        
        await this.saveChatObject(chatState)
        await this.removeExtraneousChatStates(chatState)
    }

    async waitForChatCompletion(chatName: string, secondsToWait: number) {
        return firstValueFrom(this.completionMap$
            .pipe(
                tap(data => console.log('waitForChatCompletion', data)),
                filter(completed => !!completed?.has(chatName)),
                timeout(secondsToWait * 1000),
                catchError(error => of(true)) // Return true if timeout
            ))
    }

    // Cleanup any 
    private async removeExtraneousChatStates(state: ChatState) {
        // Retrieve other IN_PROGRESS states for this chatName
        const docs = await firstValueFrom(this.firebase.collection$(getUserChatsCollectionPath(this.userId as string), where('status', '==', ChatStateStatus.INPROGRESS)))
        const others:ChatState[] = docs.filter((doc:ChatState) => doc.chatName === state.chatName)
        // Delete each chat except the one passed in
        for (const s of others) {
            const otherId = s.chatId as string
            if (otherId !== state.chatId) {
                // console.log('deleting extraneous chat state', otherId)
                await this.firebase.delete(getUserChatsStatePath(this.userId as string, otherId))
            }
        }
    }

    isChatPurchased(chatName: string) {
        const chatState = this.chatMap$.getValue()
        return !!chatState?.get(chatName)?.purchased
    }

    isChatCompleted(chatName: string) {
        const completed = this.completionMap$.getValue()
        console.log('isChatCompleted', chatName, completed?.has(chatName))
        return !!completed?.has(chatName)
    }

    async setChatPurchased(chatName: string) {
        const chatState = await this.getChatState(chatName)
        if (!chatState)
            throw new ChatStateError(`Attempt to mark a non-existent chat as purchased: ${chatName}`)
        chatState.setPurchased()
        await this.saveChatObject(chatState)
    }

    async resetCurrentIndex(chatName: string) {
        await this.setState(chatName, { 
            current: DEFAULT_CURRENT_INDEX, 
            contentTimestamp: this.contentService.contentConfigTimestamp$.value,
            lastValue: null 
        })
    } 

    async resetCurrentToLastPromptChainStart(chatName: string, statements: any[]) {
        const chatState = await this.getChatState(chatName)
        const lastPromptChainStartIndex = chatState?.lastPromptChainStartIndex as number
        console.log('resetting current to last prompt chain start', chatName, lastPromptChainStartIndex)
        await this.setState(chatName, { 
            current: lastPromptChainStartIndex,
            currentStatementName: statements[lastPromptChainStartIndex]?.name,
            lastValue: null, 
            lastPromptChainStartIndex })
    } 
    
    async saveChatObject(chatState: ChatState) {
        // console.log('saving chat state', JSON.stringify(chatState))
        await this.firebase.updateAt(getUserChatsStatePath(this.userId as string, chatState.getChatId() as string), chatState, true, chatStateConverter)        
    }

    private initializeChatState(chatName: string) {
        const now = nowstr()
        const c = new ChatState({
            chatName,
            createdAt: now,
            chatId: chatName + '-' + now,
            status: ChatStateStatus.INPROGRESS,
            contentTimestamp: this.contentService.contentConfigTimestamp$.value
        })
        return c
    }

    async createChat(chatName: string) {
        const chatState = await this.getChatState(chatName)
        if (chatState) {
            throw new ChatStateError(`Attempt to create a chat state which is already in progress ${chatName}`)
        }
        console.log('Creating new chat state for', chatName)
        const c = this.initializeChatState(chatName)
        await this.saveChatObject(c)
        return c
    }

    async setState(chatName: string, state: { 
        current: number,
        currentStatementName?: string,
        lastValue: any,
        lastPromptChainStartIndex?: number,
        contentTimestamp?: number
    }) {
        const chatState = await this.getChatState(chatName)
        if (!chatState)
            throw new ChatStateError(`Attempt to set state for chat that is not in progress ${chatName}`)
        chatState.currentIndex = state.current
        chatState.lastPromptChainStartIndex = state.lastPromptChainStartIndex as number
        chatState.lastValue = state.lastValue
        chatState.contentTimestamp = state.contentTimestamp
        chatState.currentStatementName = state.currentStatementName
        await this.saveChatObject(chatState)
    }
}
export class ChatState {
    chatId: string | undefined
    chatName: string | undefined
    createdAt: string | undefined
    contentTimestamp?: number
    completedAt?: string | null = null
    status: ChatStateStatus | undefined
    currentIndex = DEFAULT_CURRENT_INDEX
    currentStatementName: string | undefined
    lastValue?: string | null = null
    lastPromptChainStartIndex = 0
    purchased: boolean | undefined

    constructor(args: any) {
        Object.assign(this, args)
    }

    getChatId() {
        return this.chatId
    }

    isInProgress() {
        return this.status === ChatStateStatus.INPROGRESS
    }

    markComplete() {
        this.status = ChatStateStatus.COMPLETE
        //TODO - should we clear the lastValue and currentIndex
        this.completedAt = nowstr()
    }

    setPurchased() {
        this.purchased = true
    }

    /**
     * @deprecated
     * TODO - remove
     */
    reset() {
        this.currentIndex = DEFAULT_CURRENT_INDEX
        this.currentStatementName = undefined
        this.lastValue = null
        this.lastPromptChainStartIndex = 0
        this.status = ChatStateStatus.INPROGRESS
        this.completedAt = null
        //console.log('resetting chat state', JSON.stringify(this))
    }
}
export const chatStateConverter = {
    toFirestore(chatState: ChatState) {
        // In case we want to set a field to undefined in firestore,
        // we need to actually remove the field from the data being
        // sent in an update or set. Firestore does not accept the Javascript undefined
        // as a valid value. The way to do that is to set
        // the field to be removed to a value returned by deleteField()
        // For example, to remove this.lastValue, this.lastValue = deleteField()
        // which returns a sentinel FieldValue indicating the field should be removed
        // We have defined lastValue and other fields to have a null type which is accepted
        // by Firestore
       return { ...chatState }
    },
    // Cant type d as we want to share this between the app and cloud code which each use
    // different libraries; so keep it as any
    fromFirestore(obj: any) {
        return new ChatState(obj)
    }
}
export function getUserChatsStatePath(userId: string, chatId: string) {
    return getUserChatsCollectionPath(userId) + `/${chatId}`
}

export function getUserChatsCollectionPath(userId: string) {
    return `users/${userId}/chatState`
}