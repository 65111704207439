import {
  MAX_OPENAI_RETRIES,
  Prompt,
  OpenAIInterface,
  OpenAIConfig,
  OpenAIResult,
  LoggerInterface,
  OPENAI_40_TURBO_MODEL_NAME,
  getAzureOpenAIServer
} from '@cheaseed/node-utils';
import { AzureKeyCredential, ChatCompletions, ChatRequestMessage, OpenAIClient, OpenAIKeyCredential } from '@azure/openai';

export class OpenAICompletions implements OpenAIInterface {
  openai: OpenAIClient
  logger: LoggerInterface
  constructor(config: OpenAIConfig) {
    const server = getAzureOpenAIServer(config.env)
    this.openai = // config.isAzure ?
      new OpenAIClient(
        server.endpoint,
        new AzureKeyCredential(server.key),
        {
          retryOptions: { maxRetries: MAX_OPENAI_RETRIES, retryDelayInMs: 100 }
        })
      // :
      // new OpenAIClient(
      //   new OpenAIKeyCredential(OPENAI_API_KEY),
      //   {
      //     retryOptions: { maxRetries: MAX_OPENAI_RETRIES, retryDelayInMs: 100 }
      //   })
    this.logger = config.logger || console
    this.logger.log(`Using Azure OpenAI server: ${JSON.stringify(server)}`)
  }

  async logChatQuery(
    config: OpenAIConfig,
    prompt: string,
    prompts: Prompt[],
    expectsJson: boolean) {

    // Handle system prompts
    const model = config.model
    const msgs = prompts.map(p => {
      const req = { role: p.system ? 'system' : 'user', content: p.prompt }
      const resp = p.system ? null : { role: p.role, content: this.generateAsString(p.response) }
      return resp ? [req, resp] : [req]
    }).flat()
    const chatMsgs = [...msgs, { role: 'user', content: prompt }] as ChatRequestMessage[]
    this.logger.log(JSON.stringify({ messageLength: chatMsgs.length, model }))
    const startmsecs = Date.now()
    const options: any = {
      // top_p: 1,
      temperature: config.temperature,
      topP: config.topP
    }
    if (expectsJson && model === OPENAI_40_TURBO_MODEL_NAME) // only works with turbo
      options.responseFormat = { type: "json_object" }
    if (!config.isAzure)
      options.model = model // applicable if using OpenAI; ignored for AzureOpenAI 

    const res = await this.openai.getChatCompletions(
      model,
      chatMsgs,
      options)

    const elapsedMsec = Date.now() - startmsecs
    const response = res as ChatCompletions
    this.logger.log(`response took ${elapsedMsec} msecs ${JSON.stringify(response)}`)
    return {
      message: response.choices[0].message,
      finish_reason: response.choices[0].finishReason,
      input_usage: response.usage?.promptTokens,
      output_usage: response.usage?.completionTokens,
      usage: response.usage?.totalTokens,
      elapsedMsec,
      temperature: config.temperature,
      top_p: config.topP,
      model
    } as OpenAIResult

  }
  protected generateAsString(val: any) {
    return typeof val === 'string' ? val : JSON.stringify(val)
  }
}
